import React, { useState, useEffect } from "react";
import { CustomButton, CustomText, Video } from "../../components";
import Core from "../../common/clientSdk";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Utils from "../../common/utils";
import Icon from "../../components/core_component/icon";
import * as CampaignData from "../../common/data/campaign";
import { Loader } from "../loader";
import { BottomSheet } from "react-spring-bottom-sheet";
import { useNavigate } from "react-router-dom";
import { Image } from "../../components";
import { Rating } from "react-simple-star-rating";
import { Tooltip } from "react-tooltip";
import "react-spring-bottom-sheet/dist/style.css";
import InfluencerDetailModalShare from "../../modal/influencerDetialModalShare";
import HeaderShare from "../../modal/HeaderShare";
import NoteListInfluencer from "./listNoteInfluencer";
import PriceToCollectionInfluencer from "./priceToCollectionInfluencer";
const Share = ({
  influencerDp,

  categoryListData,
  getInfluencerLocation,
}) => {
  const [openRow, setOpenRow] = useState([]);
  const [influencerData, setInfluencerData] = useState([]);
  const [hoveredIcons, setHoveredIcons] = useState([]);
  const [btnLoader, setBtnLoader] = useState({});
  const [btnLoaderRejected, setBtnLoaderRejected] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [influencerDetail, setInfluencerDetail] = useState({});
  const [platformsData, setPlatformsData] = useState([]);
  const [influencerFlyOut, setInfluencerFlyOut] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [effectiveFollowers, setEffectiveFollowers] = useState(null);
  const [modalIsOpenNote, setModalIsOpenNote] = useState(false);
  const [modalIsOpenPrice, setModalIsOpenPrice] = useState(false);
  const [priceType, setPriceType] = useState(null);
  const [noteType, setNoteType] = useState(null);
  const [influencerId, setInfluencerId] = useState(null);
  const [collectionId, setCollectionId] = useState(null);
  const [feedback, setFeedback] = useState();
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768); // If screen width is less than or equal to 768px, consider it mobile
  };

  useEffect(() => {
    // Add an event listener to handle resize
    window.addEventListener("resize", handleResize);

    // Set initial value
    handleResize();

    // Cleanup listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    getCollectionInfluencerListByShareKey();
  }, []);

  console.log("process env", process.env);

  console.log("influencerData", influencerData);

  const Navigate=useNavigate()

  const { share_key } = useParams();

  function getCollectionInfluencerListByShareKey() {
    var params = {
      share_key: share_key,
    };
    Core.getCollectionInfluencerListByShareKey(
      getCollectionInfluencerListByShareKeySuccess,
      getCollectionInfluencerListByShareKeyFailed,
      (status) => setLoading(status),
      params
    );
  }
  function getCollectionInfluencerListByShareKeySuccess(response) {
    setInfluencerData(response.collection_deep_data);
    setCollectionId(
      response?.collection_deep_data?.collection_data?.collection_id
    );
  }

  console.log("influencerData", influencerData);

  function getCollectionInfluencerListByShareKeyFailed(error) {
    toast.error(error.map((item) => item.m).join(","));
  }
  function updateCollectionReviewStatus(reviewStatus, influencer_id) {
    var params = {
      review_status: reviewStatus,
      influencer_id: influencer_id,
      collection_id: influencerData.collection_data.collection_id,
    };
    Core.updateCollectionReviewStatus(
      updateCollectionReviewStatusSuccess,
      updateCollectionReviewStatusFailed,
      (status) => setBtnLoader({ [influencer_id]: status }),
      params
    );
  }
  function updateCollectionReviewStatusSuccess(response) {
    setInfluencerData((prevState) => ({
      ...prevState,
      collection_influencer_list: prevState?.collection_influencer_list?.map(
        (influencer) =>
          influencer?.influencer_data?.influencer_id ===
          response.influencer_deep_data?.influencer_data?.influencer_id
            ? { ...influencer, ...response.influencer_deep_data } // Update only matching influencer
            : influencer // Keep unchanged if no match
      ),
    }));
  }
  function updateCollectionReviewStatusFailed(error) {
    toast.error(error.map((item) => item.m).join(","));
  }
  function updateCollectionReviewStatusRejected(reviewStatus, influencer_id) {
    var params = {
      review_status: reviewStatus,
      influencer_id: influencer_id,
      collection_id: influencerData.collection_data.collection_id,
    };
    Core.updateCollectionReviewStatus(
      updateCollectionReviewStatusRejectedSuccess,
      updateCollectionReviewStatusRejectedFailed,
      (status) => setBtnLoaderRejected({ [influencer_id]: status }),
      params
    );
  }
  function updateCollectionReviewStatusRejectedSuccess(response) {
    setInfluencerData((prevState) => ({
      ...prevState,
      collection_influencer_list: prevState?.collection_influencer_list?.map(
        (influencer) =>
          influencer?.influencer_data?.influencer_id ===
          response.influencer_deep_data?.influencer_data?.influencer_id
            ? { ...influencer, ...response.influencer_deep_data } // Update only matching influencer
            : influencer // Keep unchanged if no match
      ),
    }));
  }

  console.log("influencerData", influencerData);

  function updateCollectionReviewStatusRejectedFailed(error) {
    toast.error(error.map((item) => item.m).join(","));
  }

  function getReviewStatus(status) {
    if (status == "ACCEPTED") {
      return (
        <CustomText p text={"Accepted"} textColor="text_completed_color" />
      );
    } else {
      return <CustomText p text={"Rejected"} textColor="text_debit_color" />;
    }
  }

  const openTable = (index) => {
    setOpenRow((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const getSelectedPlatformData = (platforms_data) => {
    return platforms_data?.find((item) => item.platform_id === "btll");
  };

  const formatPlatformData = (platforms_data, key) => {
    console.log("platforms_data", platforms_data);

    const selectedPlatformData = getSelectedPlatformData(platforms_data);

    return Utils.changeNumberFormate(selectedPlatformData?.[key]);
  };

  function openSocialMedia(platforms_data, key) {
    const openUrl = getSelectedPlatformData(platforms_data);
    window.open(
      Utils.getSocialUrl(openUrl.platform_id, openUrl.identifier_id, "_bank")
    );
  }

  function getInflencerLocation(influencer_data) {
    if (influencer_data["city_id"]) {
      return CampaignData.CITY.find(
        (city) => city.id == influencer_data["city_id"]
      )?.label;
    } else if (influencer_data["state"]) {
      return CampaignData.LOCATIONS[influencer_data["state"]]["n"];
    } else {
      return "-";
    }
  }

  const toggleFlyoutDropdown = () => {
    setInfluencerFlyOut(!influencerFlyOut);
  };

  function recentCollaboration() {
    return (
      <>
        <div>
          <div className="m-b-15">
            <CustomText
              h5
              text={"Social Analytics"}
              fontSize={"font_size_2xl"}
              textColor={"text_color_primary"}
              fontWeight={"Poppins_regular_large"}
              textAlign={"text_align_left"}
            />
          </div>
          {platformsData &&
            platformsData.map((item) => {
              if (item.platform_id === "btll" && effectiveFollowers === null) {
                setEffectiveFollowers(item.effective_followers);
              }
              if (item.platform_id == "btll") {
                return (
                  <div className="engagement-view-container">
                    <div className="engagement-view-inner-share ">
                      <div className="instagram-data-view">
                        <Icon iconName="reelsIconInstagram" />
                        <CustomText p text={"Instagram"} className="button2" />
                      </div>
                      <div className="instagram-data-view">
                        <CustomText
                          p
                          text={"Followers"}
                          className="caption_2"
                        />
                        <CustomText
                          p
                          text={
                            Utils.isNullOrUndefinedOrZero(item.followers)
                              ? "-"
                              : Utils.changeNumberFormate(item.followers)
                          }
                          className="button2"
                        />
                      </div>
                      <div className="instagram-data-view">
                        <CustomText
                          p
                          text={"Avg. Views"}
                          className="caption_2"
                        />
                        <CustomText
                          p
                          text={
                            Utils.isNullOrUndefinedOrZero(item.avg_views)
                              ? "-"
                              : Utils.changeNumberFormate(item.avg_views)
                          }
                          className="button2"
                        />
                      </div>
                      <div className="instagram-data-view">
                        <CustomText
                          p
                          text={"Avg. Likes"}
                          className="caption_2"
                        />
                        <CustomText
                          p
                          text={
                            Utils.isNullOrUndefinedOrZero(item.avg_likes)
                              ? "-"
                              : Utils.changeNumberFormate(item.avg_likes)
                          }
                          className="button2"
                        />
                      </div>
                      <div className="instagram-data-view">
                        <CustomText
                          p
                          text={"Avg. Comments"}
                          className="caption_2"
                        />
                        <CustomText
                          p
                          text={
                            Utils.isNullOrUndefinedOrZero(item.avg_comments)
                              ? "-"
                              : Utils.changeNumberFormate(item.avg_comments)
                          }
                          className="button2"
                        />
                      </div>
                      <div className="instagram-data-view">
                        <CustomText p text={"E.R."} className="caption_2" />
                        <CustomText
                          p
                          text={
                            !Utils.isNullOrUndefinedOrZero(item.engagement_rate)
                              ? item.engagement_rate.toFixed(2) + " %"
                              : "-"
                          }
                          className="button2"
                        />
                      </div>

                      {/* <div className="instagram-data-view">
                        <CustomText
                          p
                          text={"Effective Followers"}
                          className="caption_2"
                        />
                        {effectiveLoad ? (
                          <Lottie
                            options={defaultOptions}
                            height={15}
                            width={100}
                          />
                        ) : (
                          <CustomText
                            p
                            text={
                              effectiveFollowers
                                ? effectiveFollowers.toFixed(2) + " %"
                                : "NA"
                            }
                            className={
                              checkOrgSub?.feature?.max_brand_count > 1 &&
                              effectiveFollowers
                                ? "button2"
                                : "blur-text"
                            }
                          />
                        )}
                      </div> */}
                    </div>
                  </div>
                );
              } else {
                return (
                  <>
                    <div>
                      <div className="engagement-view-container">
                        <div className="engagement-view-inner-share">
                          <div className="instagram-data-view">
                            <Icon iconName="youtubeNewIcon" />
                            <CustomText
                              p
                              text={"Youtube"}
                              className="button2"
                            />
                          </div>
                          <div className="instagram-data-view">
                            <CustomText
                              p
                              text={"Avg. Views"}
                              className="caption_2"
                            />
                            <CustomText
                              p
                              text={
                                Utils.isNullOrUndefinedOrZero(item.avg_views)
                                  ? "-"
                                  : Utils.changeNumberFormate(item.avg_views)
                              }
                              className="button2"
                            />
                          </div>
                          <div className="instagram-data-view">
                            <CustomText
                              p
                              text={"Avg. Likes"}
                              className="caption_2"
                            />
                            <CustomText
                              p
                              text={
                                Utils.isNullOrUndefinedOrZero(item.avg_likes)
                                  ? "-"
                                  : Utils.changeNumberFormate(item.avg_likes)
                              }
                              className="button2"
                            />
                          </div>
                          <div className="instagram-data-view">
                            <CustomText
                              p
                              text={"Avg. Comments"}
                              className="caption_2"
                            />
                            <CustomText
                              p
                              text={
                                Utils.isNullOrUndefinedOrZero(item.avg_comments)
                                  ? "-"
                                  : Utils.changeNumberFormate(item.avg_comments)
                              }
                              className="button2"
                            />
                          </div>
                          <div className="instagram-data-view">
                            <CustomText p text={"E.R."} className="caption_2" />
                            <CustomText
                              p
                              text={
                                !Utils.isNullOrUndefinedOrZero(
                                  item.engagement_rate
                                )
                                  ? item.engagement_rate + " %"
                                  : "-"
                              }
                              className="button2"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              }
            })}
        </div>
      </>
    );
  }

  function renderInfluencerModal() {
    return (
      <BottomSheet
        open={isOpen}
        onDismiss={() => setIsOpen(false)}
        snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight]}
      >
        <div className="profile-main-container">
          {/* Profile Header */}
          <div className="profile-header">
            <Image
              source={
                influencerDetail.profile_picture
                  ? Utils.generateInfluencerDpUrl(
                      influencerDetail.influencer_id,
                      influencerDetail.profile_picture
                    )
                  : require("../../resources/images/user-copy.png")
              }
              fallBack={require("../../resources/images/user-copy.png")}
              className="profile-image"
            />
            <div className="profile-details">
              <CustomText
                h5
                text={influencerDetail?.name}
                fontSize="font-size-xl"
                textColor="text-primary"
                fontWeight="font-bold"
              />
              <div className="profile-rating">
                <Rating
                  initialValue={influencerDetail?.ratings}
                  size={20}
                  readonly
                />
              </div>
            </div>
          </div>

          {/* Profile Info */}
          <div className="profile-info">
            <div className="info-item">
              <CustomText
                p
                text="Location"
                fontSize="font-size-md"
                textColor="text-secondary"
                fontWeight="font-medium"
              />
              <CustomText
                p
                text={influencerDetail?.name}
                fontSize="font-size-md"
                textColor="text-dark"
                fontWeight="font-regular"
              />
            </div>

            <div className="info-item">
              <CustomText
                p
                text="Gender"
                fontSize="font-size-md"
                textColor="text-secondary"
                fontWeight="font-medium"
              />
              <CustomText
                p
                text={influencerDetail?.gender}
                fontSize="font-size-md"
                textColor="text-dark"
                fontWeight="font-regular"
              />
            </div>

            <div className="info-item">
              <CustomText
                p
                text="Languages"
                fontSize="font-size-md"
                textColor="text-secondary"
                fontWeight="font-medium"
              />
              <CustomText
                p
                text={influencerDetail?.language}
                fontSize="font-size-md"
                textColor="text-dark"
                fontWeight="font-regular"
              />
            </div>
          </div>

          {/* Category Section */}
          {/* <div className="profile-category">
            <CustomText
              p
              text="Category"
              fontSize="font-size-md"
              textColor="text-secondary"
              fontWeight="font-medium"
            />
            <div className="category-list">
              {categoryListData?.map((item, index) => (
                <div key={index} className="category-item">
                  <CustomText
                    p
                    text={item.name}
                    fontSize="font-size-md"
                    textColor="text-dark"
                    fontWeight="font-regular"
                  />
                  {index < categoryListData.length - 1 && (
                    <span className="category-divider">|</span>
                  )}
                </div>
              ))}
            </div>
          </div> */}
        </div>
        {platformsData?.length != 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              padding: "0px 20px",
            }}
          >
            <div> {recentCollaboration()}</div>
          </div>
        )}
      </BottomSheet>
    );
  }

  function openInfluencerModal(data) {
    setInfluencerDetail(data?.influencer_data);
    setPlatformsData(data?.platforms_data);

    if (isMobile) {
      setIsOpen(true);
      setInfluencerFlyOut(false);
    } else {
      toggleFlyoutDropdown();
      setIsOpen(false);
    }
  }

  const platFormName = (platforms_data, influencer_id) => {
    console.log("platforms_data", platforms_data);

    const handleMouseEnter = (index) => {
      const newHoveredIcons = [...hoveredIcons];
      newHoveredIcons[index] = true;
      setHoveredIcons(newHoveredIcons);
    };

    const handleMouseLeave = (index) => {
      const newHoveredIcons = [...hoveredIcons];
      newHoveredIcons[index] = false;
      setHoveredIcons(newHoveredIcons);
    };

    const handlePlatformClick = (url) => {
      // Open the platform URL here
      window.open(url, "_blank");
      // Reset all hover states to false after clicking
      setHoveredIcons(new Array(platforms_data.length).fill(false));
    };

    return (
      <>
        {platforms_data.length > 0 && platforms_data[0].platform_id != null && (
          <div>
            {/* <CustomText
              p
              text={"Social Presence"}
              fontSize={"font_size_small"}
              textColor={"text_color_black"}
              fontWeight={"Poppins_semiBold"}
            /> */}
          </div>
        )}
        <div style={{ marginTop: 2 }} className="flex-row">
          <div
            className={"platform-name-collection"}
            data-tooltip-id={`tooltip-${influencer_id}${platforms_data[0].platform_id}`}
            onMouseEnter={() =>
              handleMouseEnter(
                `${influencer_id}${platforms_data[0].platform_id}`
              )
            }
            onMouseLeave={() =>
              handleMouseLeave(
                `${influencer_id}${platforms_data[0].platform_id}`
              )
            }
            onClick={() =>
              handlePlatformClick(
                Utils.getSocialUrl(
                  platforms_data[0].platform_id,
                  platforms_data[0].identifier_id
                )
              )
            }
          >
            <CustomText
              p
              text={"@" + platforms_data[0].identifier_id}
              className="caption_2"
              textColor={"text_color_blue_500"}
            />
          </div>
          {/* {platforms_data.map((item, index) => {
            if (Utils.getSocialIcon(item.platform_id)) {
              return (
                <>
                  <div
                    key={index}
                    className={"platform-name"}
                    data-tooltip-id={`tooltip-${influencer_id}${item.platform_id}`}
                    onMouseEnter={() =>
                      handleMouseEnter(`${influencer_id}${item.platform_id}`)
                    }
                    onMouseLeave={() =>
                      handleMouseLeave(`${influencer_id}${item.platform_id}`)
                    }
                    onClick={() =>
                      handlePlatformClick(
                        Utils.getSocialUrl(item.platform_id, item.identifier_id)
                      )
                    }
                  >
                    <Icon
                      iconName={
                        hoveredIcons[`${influencer_id}${item.platform_id}`]
                          ? Utils.getSocialIconHover(item.platform_id)
                          : Utils.getSocialIcon(item.platform_id)
                      }
                      height={14}
                      width={14}
                    />

                    <CustomText
                      p
                      text={item.identifier_id}
                      className="caption_2"
                    />
                  </div>
                  <ReactTooltip
                    style={{ zIndex: 20 }}
                    id={`tooltip-${influencer_id}${item.platform_id}`}
                    place="bottom"
                    content={item.identifier_id}
                  />
                </>
              );
            }
            return null;
          })} */}
        </div>
      </>
    );
  };

  function openModalPrice() {
    if (modalIsOpenPrice == false) {
      setModalIsOpenPrice(true);
    } else {
      setModalIsOpenPrice(false);
    }
  }

  function closePriceModal() {
    setModalIsOpenPrice(false);
  }
  function openModalNote() {
    if (modalIsOpenNote == false) {
      setModalIsOpenNote(true);
    } else {
      setModalIsOpenNote(false);
    }
  }

  function closeNoteModal() {
    setModalIsOpenNote(false);
  }
  let textNode =
    "The word  is the cardinal number that represents the quantity 50. For example, you might say I have saved fifty dollars";
  return (
    <>
      <div>
        <HeaderShare />
      </div>
      {isLoading ? (
        <div className="fall-back-center-campaign">
          {" "}
          <Loader />
        </div>
      ) : (
        <div className="share">
          {renderInfluencerModal()}
          {influencerFlyOut && (
            <InfluencerDetailModalShare
              influencerFlyOut={influencerFlyOut}
              toggleFlyoutDropdown={toggleFlyoutDropdown}
              influencer_id={influencerDetail.influencer_id}
              influencerDetail={influencerDetail}
              platformsDetail={platformsData}
            />
          )}
          <table class="custom_table bordered">
            <thead>
              <tr>
                <th data-label className="share-influencer-table-heading ">
                  Influencer Details
                </th>
                <th data-label>Followers</th>
                <th data-label>Avg Views </th>
                <th data-label>E.R. (%)</th>
                {influencerData?.collection_data?.show_price ? (
                  <th data-label>Asking Price</th>
                ) : null}

                <th data-label>Note</th>
                <th data-label>Feedback</th>

                <th data-label>Action</th>
              </tr>
            </thead>
            <tbody>
              {influencerData?.collection_influencer_list?.map(
                (item, index) => (
                  <tr
                    class={"open"}
                    key={item?.influencer_data?.influencer_id}
                    onClick={() => openTable(index)}
                  >
                    <td className=" tr-1" data-label=" ">
                      <div
                        className="flex-row"
                        // onClick={() => openSocialMedia(item?.platforms_data)}
                      >
                        <div
                            onClick={() => window.open(`/influencer-detail/${item?.influencer_data?.influencer_id}`, "_blank")}
                          className="tc-1"
                        >
                          <Image
                            source={
                              item?.influencer_data?.profile_picture
                                ? Utils.generateInfluencerDpUrl(
                                    item?.influencer_data?.influencer_id,
                                    item?.influencer_data?.profile_picture
                                  )
                                : require("../../resources/images/user-copy.png")
                            }
                            fallBack={require("../../resources/images/user-copy.png")}
                            className="share-table-img"
                          />
                        </div>
                        <div class="share-infuencer-detail">
                          <div
                            className="button2_light"
                            onClick={() => window.open(`/influencer-detail/${item?.influencer_data?.influencer_id}`, "_blank")}
                          >
                            {item?.influencer_data?.name}
                          </div>
                          <div>
                            {platFormName(
                              item?.platforms_data,
                              item?.influencer_data?.influencer_id
                            )}
                          </div>
                        </div>
                      </div>
                    </td>

                    <td className="table-column-data" data-label="Followers -">
                      {!Utils.isObjectOrNullUndefinedZero(
                        Number(
                          formatPlatformData(item?.platforms_data, "followers")
                        )
                      )
                        ? formatPlatformData(item?.platforms_data, "followers")
                        : "-"}
                    </td>
                    <td className="table-column-data" data-label="Avg View -">
                      {!Utils.isObjectOrNullUndefinedZero(
                        Number(
                          formatPlatformData(item?.platforms_data, "avg_views")
                        )
                      )
                        ? formatPlatformData(item?.platforms_data, "avg_views")
                        : "-"}
                    </td>

                    <td className="table-column-data" data-label="E.R. -">
                      {!Utils.isObjectOrNullUndefinedZero(
                        Number(
                          formatPlatformData(
                            item?.platforms_data,
                            "engagement_rate"
                          )
                        )
                      ) &&
                      !isNaN(
                        formatPlatformData(
                          item?.platforms_data,
                          "engagement_rate"
                        )
                      )
                        ? Number(
                            formatPlatformData(
                              item?.platforms_data,
                              "engagement_rate"
                            )
                          ).toFixed(2)
                        : "-"}
                    </td>
                    {influencerData?.collection_data?.show_price ? (
                      <td
                        className="table-column-data"
                        data-label="Asking price -"
                      >
                        {item?.influencer_data?.price ? (
                          <CustomText p text={item?.influencer_data?.price} />
                        ) : (
                          "-"
                        )}
                      </td>
                    ) : null}

                    <td>
                      <div style={{ display: "flex", alignItems: "center",justifyContent:"center" }}>
                      <span
                        style={{ color: "rgb(25, 119, 242)", height: "20px" , }}
                        className="flex-row truncate"
                        data-tooltip-id="tooltip"
                        data-tooltip-content={item?.influencer_data?.user_note}
                      >
                        <CustomText
                          p
                          text={
                            item?.influencer_data?.user_note
                              ? item?.influencer_data?.user_note
                              : "..."
                          }
                          className=" m-r-6"
                        />
                        <Tooltip
                          style={{ whiteSpace: "pre-line" }}
                          id="tooltip"
                        />
                        <Icon iconName="infoIcon" />
                      </span>
                      </div>
                      
                    </td>
                    <td>
                      {" "}
                      <span
                        onClick={(index) => {
                          openModalNote();
                          setInfluencerId(item?.influencer_data?.influencer_id);
                          setNoteType("internal_note");
                          setFeedback(item?.influencer_data?.feedback);
                        }}
                        style={{ color: "rgb(25, 119, 242)", height: "20px",justifyContent:"center" }}
                        className="flex-row"
                      >
                        <div className="truncate" style={{width:"20px"}}>
                          <CustomText
                            p
                            text={
                              item?.influencer_data?.feedback
                                ? item?.influencer_data?.feedback
                                : "..."
                            }
                            className=" m-r-6"
                          />
                        </div>

                        <div>
                          <Icon iconName="EditIconCounter" />
                        </div>
                      </span>
                    </td>

                    <td className="table-column-data-btn" data-label="">
                      {
                        <span>
                          <div className="accept-reject">
                            <div className="accept">
                              <CustomButton
                                text={
                                  item?.influencer_data?.review_status ==
                                  "ACCEPTED"
                                    ? "Approved"
                                    : "Approve"
                                }
                                className="share-accept-btn"
                                background={
                                  item?.influencer_data?.review_status ==
                                  "ACCEPTED"
                                    ? "#95d5b2"
                                    : "#00000033"
                                }
                                onClick={() =>
                                  updateCollectionReviewStatus(
                                    "ACCEPTED",
                                    item?.influencer_data?.influencer_id
                                  )
                                }
                                isLoading={
                                  btnLoader[item?.influencer_data?.influencer_id]
                                }
                                fontSize={"font_size_small"}
                                borderRadius={4}
                                height={40}
                                padding={{
                                  top: 12,
                                  right: 12,
                                  bottom: 12,
                                  left: 12,
                                }}
                                textColor={"text_color_light"}
                                border={"none"}
                                boxShadow={"none"}
                                hoverColor={"var(--hover-color, #95d5b2)"}
                              />
                            </div>
                            <div className="reject">
                              <CustomButton
                                text={
                                  item?.influencer_data?.review_status ==
                                  "REJECTED"
                                    ? "Rejected"
                                    : "Reject"
                                }
                                onClick={() =>
                                  updateCollectionReviewStatusRejected(
                                    "REJECTED",
                                    item?.influencer_data?.influencer_id
                                  )
                                }
                                height={40}
                                textColor={"text_color_light"}
                                isLoading={
                                  btnLoaderRejected[
                                    item?.influencer_data?.influencer_id
                                  ]
                                }
                                fontSize={"font_size_small"}
                                borderRadius={4}
                                padding={{
                                  top: 12,
                                  right: 12,
                                  bottom: 12,
                                  left: 12,
                                }}
                                background={
                                  item?.influencer_data?.review_status ==
                                  "REJECTED"
                                    ? " #ff758f"
                                    : "#00000033"
                                }
                                border={"none"}
                                boxShadow={"none"}
                                hoverColor={"#ff758f"}
                              />
                            </div>
                          </div>
                        </span>
                      }
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
          <div>
            {/* <Pricing/> */}

            {modalIsOpenPrice && (
              <PriceToCollectionInfluencer
                closePriceModal={closePriceModal}
                collectionId={collectionId}
                influencerId={influencerId}
                //     setCollectionInfluencerList={setCollectionInfluencerList}
                priceType={""}
              />
            )}
            {modalIsOpenNote && (
              <NoteListInfluencer
                closeNoteModal={closeNoteModal}
                collectionId={collectionId}
                influencerId={influencerId}
                setCollectionInfluencerList={setInfluencerData}
                userNote={feedback}
                noteType={"feedback"}
              />
            )}
            <ToastContainer />
          </div>
        </div>
      )}
    </>
  );
};

export default Share;
