import React, { useEffect, useState } from "react";
import {
  Image,
  CustomText,
  FallBack,
  Video,
  PrimaryCta,
  InstagramComponent,
  FallBackBtn,
} from "../../components";
import SideMenu from "../sideMenu";
import Header from "../header";
import { InstagramEmbed, YouTubeEmbed, XEmbed } from "react-social-media-embed";
import { ToastContainer, toast } from "react-toastify";
import Core from "../../common/clientSdk";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import InfiniteScroll from "react-infinite-scroll-component";
import Utils from "../../common/utils";
import { Loader } from "../loader";
import { useSelector } from "react-redux";

import InstagramAnalytics from "./contentInstagramAnalytics";
import YoutubeAnalytics from "./contentYoutubeAnalytics";
import InfluencerContentCard from "../../features/influecnerContentCard";
const limit = 10;
function ContentList(props, { campaignId }) {
  const [contentList, setContentList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [contentId, setContentId] = useState(null);
  const [assignmentId, setAssignmentId] = useState(null);
  const [mediaUrl, setMediaUrl] = useState(null);
  const [start, setStart] = useState(0);
  const [next, setNext] = useState(10);
  const [assignmentInfluencerList, setAssignmentInfluencerList] = useState([]);
  const [assignmentUserLoading, setAssignmentUserLoading] = useState(false);

  useEffect(() => {
    AssignmentContentList(start, updateProgress);
    getAssignmentUser();
  }, []);

  const redux_selector = useSelector((state) => {
    return {
      UserData: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });
  const { UserData = {} } = redux_selector;
  const { brand_deep_data = {} } = UserData;

  function updateProgress(status) {
    setLoading(status);
  }

  function AssignmentContentList(start, updateProgress) {
    const params = {
      limit: limit,
      start: start,
      brand_id: brand_deep_data.brand_data.brand_id,
      campaign_id: props.campaignId,
    };
    Core.AssignmentContentList(
      AssignmentContentListSuccess,
      AssignmentContentListFailed,
      updateProgress,
      params
    );
  }

  function AssignmentContentListSuccess(response) {
    setLoading(false);

    if (next == 0) {
      setContentList(
        response?.assignment_list?.filter((item) => {
          return item?.assignment_data?.status == "COMPLETED";
        })
      );
    } else {
      setContentList([
        ...contentList,
        ...response?.assignment_list?.filter((item) => {
          return item?.assignment_data?.status == "COMPLETED";
        }),
      ]);
    }

    setNext(response?.pagination_data?.next);
  }

  console.log("contentList 1", contentList);

  function AssignmentContentListFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }
  function updateProgressAssignment(status) {
    setAssignmentUserLoading(status);
  }
  function getAssignmentUser() {
    var params = {
      campaign_id: props?.campaignId,
      status: "COMPLETED",
    };

    Core.getAssignmentUser(
      getAssignmentUserSuccess,
      getAssignmentUserFailed,
      updateProgressAssignment,
      params
    );
  }

  function getAssignmentUserSuccess(response_data) {
    setAssignmentInfluencerList(response_data?.["assignment_list"]);
    setAssignmentUserLoading(false);
    console.log("assignmentUserLoading", assignmentUserLoading);
  }

  function getAssignmentUserFailed(errorList) {
    toast.error(errorList.map((err) => err.m).join(", "));
  }
  const handleDownload = (media_id, isVideo, assignment_id) => {
    const mediaUrl = Utils.constructContentMediaUrl(assignment_id, media_id);

    // Create a temporary anchor element to trigger the download
    const a = document.createElement("a");
    a.href = mediaUrl;
    a.download = `downloaded_${isVideo ? "video" : "image"}.${media_id
      .split(".")
      .pop()}`;
    a.style.display = "none";

    document.body.appendChild(a);
    a.click();

    // Clean up the anchor element
    document.body.removeChild(a);
  };

  const influencerContentCards = [];
  function contentAssignmentInfluencerData() {
    assignmentInfluencerList?.forEach((item) => {
      const contentKey = Object.keys(item?.contents_deep_data)[0];
      const content = item?.contents_deep_data?.[contentKey]?.content_data;
      const influencer = item?.influencer_data;
      const campaign_data = item?.campaign_data;
      const assignmentId = item?.assignment_data?.assignment_id;
      const platform =
        item?.platforms_data[0] && item?.platforms_data[0].identifier_id;

      // Map the data to component props
      let influencerContentCardProps = {
        imageSource: content?.media_id, // Use media_id for thumbnail
        url: content?.url,
        content_id: content?.content_id,
        identifier_id:item?.platforms_data[0] && item?.platforms_data[0].identifier_id,
        platform_id:item?.platforms_data[0] && item?.platforms_data[0].platform_id,
        influencerDp: influencer?.profile_picture, // Profile picture
        influencerId: influencer?.influencer_id, // Profile picture
        influencerUsername: influencer?.name, // Influencer name
        assignmentId: assignmentId,
        influencerHandle: platform, // Handle with '@'
        likes: "1.2K", // Placeholder for likes, adjust based on data
        comments: "300", // Placeholder for comments
        campaignId: props.campaignId,
        //  views: `${Math.round(platform.avg_views / 1000)}K`, // Convert views to 'K' format
        date: new Date(
          item?.assignment_data?.date_added * 1000
        ).toLocaleDateString(), // Convert timestamp to readable date
        videoUrl: content?.url, // URL of the content
      };

      influencerContentCards?.push(influencerContentCardProps);
    });
  }

  function renderFallback() {
    if (!assignmentUserLoading && assignmentInfluencerList?.length == 0) {
      return (
        <>
          <div className="fall-back-center-campaign">
            <FallBackBtn
              heading="No Creatives Available"
              title="Please wait for the influencer to upload content."
            />
          </div>
        </>
      );
    }
  }
  
  return (
    <div style={{ minHeight: 500 }} className="m-t-12">
      {assignmentUserLoading ? (
        <div className="fall-back-center-campaign">
          <Loader />
        </div>
      ) : assignmentInfluencerList?.length>0 ? (
        <div>
          {contentAssignmentInfluencerData()}
          <div className="content_all_influencer_analytics_container">
            {influencerContentCards?.map((item) => {
              return <InfluencerContentCard item={item} />;
            })}
          </div>
        </div>
      ) : (
        renderFallback()
      )}
    </div>
  );
}

export default ContentList;
