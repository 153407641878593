import React, { useEffect, useState } from "react";
import {
  CustomText,
  CustomTextInput,
  Image,
  Popup,
  PrimaryCta,
  Sidebar,
  Video,
} from "../../components";
import Icon from "../../components/core_component/icon";
import Utils from "../../common/utils";
import { useDropzone } from "react-dropzone";
import { useSelector } from "react-redux";
import Core from "../../common/clientSdk";
import UploadImageS3 from "../../common/s3";
import { useToast } from "../../features/toastContainer";
import { InstagramEmbed, YouTubeEmbed, XEmbed } from "react-social-media-embed";
import { Loader } from "../loader";
import { Rating } from "react-simple-star-rating";

import Modal from "react-modal";
import { isCancel } from "axios";
function ContentOfflineContract(props) {
  const [assignmentContentData, setAssignmentContentData] = useState(
    Object.values(props.assignmentDetail.contents_deep_data)
  );

  const [contractContent, setContractContent] = useState("");
  const [uploading, setUploading] = useState(false);
  const [selectedLocalContent, setSelectedLocalContent] = useState([]);
  const [showImage, setShowImage] = useState(false);
  const [isFlyoutUrlSidebarOpen, setIsFlyoutUrlSidebarOpen] = useState(false);
  const [socialMediaUrl, setSocialMediaUrl] = useState("");
  const [savedUrl, setSavedUrl] = useState([]);
  const [showContent, setShowContent] = useState(false);
  const [contentData, setContentData] = useState({});
  const [generateMediaId, setGenerateMediaId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [mediaType, setMediaType] = useState();
  const [showEmbedUrl, setShowEmbedUrl] = useState("");
  const [markAsPaidLoading, setMarkAsPaidLoading] = useState(false);
  const showToast = useToast();
  const [rating, setRating] = useState(0);
  const [isRatingButtonLoading, setRatingButtonLoading] = useState(false);
  const [cancelContractModalOpen, setCancelContractModalOpen] = useState(false);
  const [isCancel, setIsCancel] = useState(false);

  useEffect(() => {
    setAssignmentContentData(
      Object.values(props.assignmentDetail.contents_deep_data)
    );
  }, [props.assignmentDetail.contents_deep_data]);
  useEffect(() => {
    if (contractContent) {
      setAssignmentContentData((prevData) =>
        prevData.map((dataItem) =>
          dataItem.content_data.content_id ===
          contentData.content_data.content_id
            ? {
                ...dataItem,
                content_data: {
                  ...dataItem.content_data,

                  social_media_url: generateMediaId ? generateMediaId : "",
                },
              }
            : dataItem
        )
      );
    }
  }, [contractContent]);
  const redux_selector = useSelector((state) => {
    return {
      UserData: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });
  const { UserData = {} } = redux_selector;
  const { brand_deep_data = {} } = UserData;
  const { org_deep_data = {} } = UserData;
  const { user_data = {} } = UserData;

  function postSubmitContentOutbound(item) {
    console.log("demo 11", item);

    let params = {
      assignment_id: props.assignmentDetail.assignment_data.assignment_id,
      content_id: item.content_data.content_id,
      content_media_id: item.content_data.social_media_url,
    };

    Core.postSubmitContentOutbound(
      postSubmitContentOutboundSuccess,
      postSubmitContentOutboundFailed,
      (status) => {},
      params
    );
  }

  function postSubmitContentOutboundSuccess(response) {
    console.log("response", response);
    setIsLoading(false);
    props.getUserAssignmentDetail();
    showToast("Content uploaded successfully", "success");
  }
  function postSubmitContentOutboundFailed(error) {
    console.log("error", error);
  }
  function postSubmitContentUrlOutbound(item) {
    setIsLoading(true);
    let params = {
      assignment_id: props.assignmentDetail.assignment_data.assignment_id,
      content_id: contentData.content_id,
      content_id: item.content_data.content_id,
      content_url: item.content_data.media_data,
    };

    Core.postSubmitContentUrlOutbound(
      postSubmitContentUrlOutboundSuccess,
      postSubmitContentUrlOutboundFailed,
      (status) => setIsLoading(status),
      params
    );
  }

  function postSubmitContentUrlOutboundSuccess(response) {
    console.log("response", response);
    props.getUserAssignmentDetail();
    showToast("Content url submitted successfully", "success");
  }
  function postSubmitContentUrlOutboundFailed(error) {
    console.log("error", error);
  }

  function postMarkAsPaid() {
    if (Object.keys(props?.assignmentDetail?.payout_data).length === 0) {
      showToast("Your task is pending...");
      return false;
    }
    let params = {
      assignment_id: props.assignmentDetail.assignment_data.assignment_id,
      influencer_id: props.assignmentDetail.influencer_data.influencer_id,
      payout_id: props?.assignmentDetail?.payout_data?.payout_id,
    };

    Core.postMarkAsPaidAssignment(
      postMarkAsPaidSuccess,
      postMarkAsPaidFailed,
      (status) => setMarkAsPaidLoading(status),
      params
    );
  }

  function postMarkAsPaidSuccess(response) {
    console.log("response", response);
    props.getUserAssignmentDetail();
    showToast("Contract has been successfully marked as paid.", "success");
  }
  function postMarkAsPaidFailed(error) {
    console.log("error", error);
  }
  function postCancelContractOutbound() {
    let params = {
      assignment_id: props.assignmentDetail.assignment_data.assignment_id,
    };

    Core.postCancelContractOutbound(
      postCancelContractOutboundSuccess,
      postCancelContractOutboundFailed,
      (status) => setIsCancel(status),
      params
    );
  }

  function postCancelContractOutboundSuccess(response) {
    props.getUserAssignmentDetail();
    CancelContractClose();
    showToast("Contract has been successfully cancelled.", "success");
  }
  function postCancelContractOutboundFailed(error) {
    showToast(error.map((item) => item.m).join(","), "error");
    CancelContractClose();
  }

  function generateUploadUrl(uploadedFileObject) {
    setUploading(true);
    var params = {
      action: "contract_content",
      id: props?.assignmentDetail?.assignment_data?.assignment_id,
      file_type: uploadedFileObject[0].type,
    };

    Core.getGenerateUploadUrl(
      (...arg) => generateUrlSuccess(uploadedFileObject, ...arg),
      generateUrlFailed,
      (status) => {},
      params
    );
  }

  function generateUrlSuccess(uploadedFileObject, response) {
    if (response) {
      setGenerateMediaId(response.upload_data.media_id);
      setContractContent({
        fileObj: uploadedFileObject,
        generateData: response,
      });
      setUploading(false);
      // upload image file on server call uploadImageS3
    } else {
      showToast("Something went wrong");
      setUploading(false);
    }
  }

  function fileUploadS3(item) {
    console.log("dem 333", contractContent);
    setIsLoading(true);
    UploadImageS3(
      contractContent.fileObj[0],
      contractContent.generateData.upload_data,
      (error) => {
        if (error) {
          return showToast("Upload failed", "info");
        }

        postSubmitContentOutbound(item);
      }
    );
  }
  function generateUrlFailed(error) {
    showToast("Something went wrong");
    setUploading(false);
  }
  const handleCropImage = (acceptedFiles) => {
    console.log("acceptedFiles", acceptedFiles);

    generateUploadUrl(acceptedFiles);
    setAssignmentContentData((prev) => [...prev, ...acceptedFiles[0]]);
    setSelectedLocalContent((prev) => [...prev, ...acceptedFiles]);
  };

  // influncer rating
  function postRatingInfluencer(influencer_id) {
    if (rating == 0) {
      return;
    }
    const params = {
      review_points: rating,
      assignment_id: props?.assignmentDetail?.assignment_data?.assignment_id,
      review: "active",
      influencer_id: props.assignmentDetail.influencer_data.influencer_id,
    };
    Core.postRatingInfluencer(
      getInfluncerRatingSuccess,
      getInfluncerRatingFailed,
      (status) => setRatingButtonLoading(status),
      params
    );
  }

  function getInfluncerRatingSuccess(response_data) {
    showToast("Thanks for rating the Influencer", "success");
    props.getUserAssignmentDetail();
  }

  function getInfluncerRatingFailed(errorList) {
    // openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleCropImage,
    accept: "image/*, video/mp4",
    multiple: true,
  });

  const clearUrl = () => {
    setSavedUrl([]);
    setSocialMediaUrl("");
  };
  // const clearContent = () => {
  //   setContractContent("");
  //   setSelectedLocalContent([]);
  // };

  const clearContent = (index) => {
    const updatedContent = [...selectedLocalContent];
    updatedContent.splice(index, 1);
    setSelectedLocalContent(updatedContent);
  };
  const clearUrlContent = (index) => {
    const updatedContent = [...savedUrl];
    console.log("updatedContent", updatedContent);

    updatedContent.splice(index, 1);
    setSavedUrl(updatedContent);
  };

  const handleClick = (index, type) => {
    setShowImage(!showImage);
    setMediaType(type);
  };

  function filterContentArray() {
    return assignmentContentData.filter(
      (item) =>
        !contentData.some(
          (excludeItem) => JSON.stringify(item) === JSON.stringify(excludeItem)
        )
    );
  }
  console.log("socialMediaUrl", socialMediaUrl);

  function saveSocialMediaUrl(item) {
    console.log("item", item);
    if (!validatorUrl(socialMediaUrl)) {
      // showToast("please enter correct url");
      return false;
    } else {
      setAssignmentContentData((prevData) =>
        prevData.map((dataItem) =>
          dataItem.content_data.content_id ===
          contentData.content_data.content_id
            ? {
                ...dataItem,
                content_data: {
                  ...dataItem.content_data,
                  media_data: socialMediaUrl ? socialMediaUrl : "",
                },
              }
            : dataItem
        )
      );
      setIsFlyoutUrlSidebarOpen(!isFlyoutUrlSidebarOpen);
      setSocialMediaUrl("");
    }
  }

  function openUrlContentSideBar(item) {
    setContentData(item);
    setIsFlyoutUrlSidebarOpen(!isFlyoutUrlSidebarOpen);
  }

  const handleFileContentChange = (event, item) => {
    const selectedFiles = Array.from(event.target.files);

    generateUploadUrl(selectedFiles);
    setContentData(item);
    setAssignmentContentData((prevData) =>
      prevData.map((dataItem) =>
        dataItem.content_data.content_id === item.content_data.content_id
          ? {
              ...dataItem,
              content_data: {
                ...dataItem.content_data,
                media_data: Array.isArray(dataItem.content_data.media_id)
                  ? [...dataItem.content_data.media_id, ...selectedFiles]
                  : [...selectedFiles],
              },
            }
          : dataItem
      )
    );

    setSelectedLocalContent((prev) => [...prev, ...selectedFiles]);
  };

  // const checkContentIds = contentData.map(
  //   (item) => item.content_data.content_id
  // );

  function CancelContractOpen() {
    setCancelContractModalOpen(true);
  }
  function CancelContractClose() {
    setCancelContractModalOpen(false);
  }

  const handleRating = (rate) => {
    setRating(rate);
  };
  const handleRemoveMedia = (contentId) => {
    setAssignmentContentData((prevData) =>
      prevData.map((item) =>
        item.content_data.content_id === contentId
          ? {
              ...item,
              content_data: Object.fromEntries(
                Object.entries(item.content_data).filter(
                  ([key]) => key !== "media_data"
                )
              ),
            }
          : item
      )
    );
  };

  function showContentFull(item, mediaSource) {
    if (
      Utils.typeMappings()[item?.content_data?.content_type_id] == "VIDEO" &&
      mediaType == "VIDEO"
    ) {
      return (
        <div
          onClick={() => handleClick()}
          className="fullscreen-image-upload-content"
        >
          <div>
            <div className="fullscreen-image-close-btn">
              {/* <Icon iconName="CloseRoundButtonIcon" /> */}
            </div>

            <Video
              source={mediaSource}
              width={600}
              height={337}
              type="video/mp4"
              className="content-image-upload fullscreen-show-content"
            />
          </div>
        </div>
      );
    } else if (mediaType == "IMAGE") {
      return (
        <div
          onClick={() => handleClick()}
          className="fullscreen-image-upload-content"
        >
          <div>
            <div className="fullscreen-image-close-btn">
              {/* <Icon iconName="CloseRoundButtonIcon" /> */}
            </div>

            <Image
              source={mediaSource}
              width={600}
              height={337}
              className="content-image-upload fullscreen-show-content"
            />
          </div>
        </div>
      );
    }
  }

  function ugcCampaignContent() {
    return (
      <>
        {assignmentContentData.map((item, index) => {
          const mediaType = Utils.checkVideoOrImage(
            item?.content_data?.media_data?.[0]?.type
          );

          console.log("ddddddddddddd", mediaType);
          const isVideo =
            mediaType === "video" ||
            item?.content_data?.media_data?.[0]?.type === "video/mp4";

          if (
            !item?.content_data?.media_data &&
            !item?.content_data?.media_id
          ) {
            return (
              <div
                className="upload-content-container"
                style={{ position: "relative" }}
                key={item?.content_data?.content_id}
              >
                <Icon iconName="InReviewContent" />
                <CustomText
                  p
                  className="subtitle1"
                  text={`Upload ${Utils.getContentTypeNameById(
                    item.content_data.content_type_id
                  )}`}
                  textColor="text_color_gray_500"
                />
                <input
                  style={{
                    position: "absolute",
                    height: "100%",
                    top: 0,
                    opacity: 0,
                  }}
                  type="file"
                  accept={
                    Utils.typeMappings()[
                      item?.content_data?.content_type_id
                    ] === "VIDEO"
                      ? "video/*"
                      : "image/*"
                  }
                  multiple
                  onChange={(e) => handleFileContentChange(e, item)}
                  className="mb-3"
                />
                <PrimaryCta
                  fifth
                  fontSize="font_size_large"
                  text="Upload Content"
                  className="blue-button"
                />
              </div>
            );
          }

          const mediaSource = item?.content_data?.media_id
            ? Utils.constructContentMediaUrl(
                props.assignmentDetail.assignment_data.assignment_id,
                item.content_data.media_id
              )
            : URL.createObjectURL(item?.content_data?.media_data?.[0]);

          return (
            <div key={index} className="product-sample-main justify-center">
              <div className="content-image-upload-maim-container">
                <div
                  className="clear-content-contract"
                  onClick={() => clearContent(index)}
                >
                  {/* <Icon iconName="crossCloseIcon" height={14} width={14} /> */}
                </div>

                <div className="upload-content-loader">
                  {uploading && <Loader />}
                </div>

                <div className="content-data-wrapper">
                  {Utils.typeMappings()[item?.content_data?.content_type_id] ==
                  "VIDEO" ? (
                    <video
                      preload="metadata"
                      style={{ height: 106, width: 106 }}
                      src={mediaSource}
                      onClick={() => handleClick(index, "VIDEO")}
                      className="content-image-upload"
                    />
                  ) : (
                    <img
                      alt="not found"
                      width="106px"
                      height="106px"
                      src={mediaSource}
                      onClick={() => handleClick(index, "IMAGE")}
                      className="content-image-upload"
                    />
                  )}
                  {!item?.content_data?.media_id && (
                    <div>
                      <PrimaryCta
                        fullWidth
                        onClick={() => fileUploadS3(item)}
                        fontSize="font_size_large"
                        text="Submit"
                        className="blue-button"
                        isLoading={isLoading}
                      />
                    </div>
                  )}

                  {!item?.content_data?.media_id && (
                    <a
                      className="remove-content text-12"
                      onClick={() =>
                        handleRemoveMedia(item?.content_data?.content_id)
                      }
                    >
                      Remove
                    </a>
                  )}
                </div>

                {showImage && showContentFull(item, mediaSource)}
              </div>
            </div>
          );
        })}

        <div className="Caption1" style={{ background: "#fff" }}></div>
      </>
    );
  }

  console.log("savedUrl", savedUrl);

  function SocialMediaCampaignContent() {
    return (
      <>
        {assignmentContentData.map((item, index) => {
          if (!item.content_data.media_data && !item.content_data.url) {
            return (
              <div className="upload-content-container">
                <Icon iconName="InReviewContent" />
                <CustomText
                  p
                  className="subtitle1"
                  text={`${Utils.getContentTypeNameById(
                    item.content_data.content_type_id
                  )} Url`}
                  textColor={"text_color_gray_500"}
                />
                <PrimaryCta
                  fifth
                  onClick={() => {
                    openUrlContentSideBar(item);
                  }}
                  fontSize={"font_size_large"}
                  text={"Add Url"}
                  className="blue-button"
                  //   isLoading={isLoadingButton}
                />
              </div>
            );
          } else {
            return (
              <div className=" upload-content-container">
                <div
                  className="content-pending-content-card"
                  onClick={() => {
                    openShowContent(item?.content_data?.url);
                    setShowEmbedUrl(item?.content_data?.url);
                  }}
                >
                  <CustomText
                    p
                    className="Body-1 content-url cursor-pointer"
                    text={
                      item?.content_data?.url
                        ? item?.content_data?.url
                        : item?.content_data?.media_data
                    }
                    textColor={"text_color_blue_500"}
                  />

                  <div>
                    <Icon
                      iconName={
                        item?.content_data?.url
                          ? "contentStatusCheckIcon"
                          : "pendingTimeIcon"
                      }
                      height={24}
                      width={24}
                    />
                  </div>
                </div>
                {!item.content_data.url && (
                  <div style={{ padding: "0px 10px" }}>
                    <PrimaryCta
                      fullWidth={true}
                      onClick={() => {
                        postSubmitContentUrlOutbound(item);
                      }}
                      fontSize={"font_size_large"}
                      text={"Submit"}
                      isLoading={isLoading}
                      className="blue-button"
                    />
                  </div>
                )}
                {!item?.content_data?.url && (
                  <a
                    className="remove-content text-12"
                    onClick={() =>
                      handleRemoveMedia(item?.content_data?.content_id)
                    }
                  >
                    Remove
                  </a>
                )}
              </div>
            );
          }
        })}
      </>
    );
  }
  function urlUploadSidebarHeader() {
    return (
      <CustomText
        p
        className="subtitle1"
        text={"Content Url"}
        textColor={"text_color_gray_500"}
      />
    );
  }

  const urlUploadSidebarFooter = () => (
    <div style={{ display: "flex", flex: 1, gap: 20 }}>
      <div style={{ flex: 1 }}>
        <PrimaryCta
          invert
          //   onClick={() => {
          //     clearFilterFun();
          //   }}
          textColor={"white"}
          fontSize={"font_size_large"}
          text={"Close"}
          isLoading={""}
        />
      </div>
      <div style={{ flex: 1 }}>
        <PrimaryCta
          fullWidth={true}
          onClick={() => {
            saveSocialMediaUrl();
          }}
          fontSize={"font_size_large"}
          text={"Save"}
          isLoading={isLoading}
          className="blue-button"
        />
      </div>
    </div>
  );

  function closeOpenFlyoutUrlSidebar() {
    setIsFlyoutUrlSidebarOpen(!isFlyoutUrlSidebarOpen);
  }

  function openShowContent(url) {
    setShowContent(true);

    console.log("socialMediaUrl", url);
  }
  function socialMedialEmbed() {
    const platform = "Instagram";
    // const mediaType =
    //   item?.assignment_data.media_id?.split(".").pop() === "mp4";

    return (
      <Popup
        isOpen={showContent}
        onClose={() => setShowContent(false)}
        headerText={"Content Preview"}
        // footerButton1Text={"Cancel"}
        // footerButton1Action={() => setShowContent(false)}
        // footerButton2Text={"Accept"}
        // footerButton2Action={() => setShowContent(false)}
        isLoading={""}
        width="30%"
        // height={"300px"}
        bottomSheetMaxHeight={0.6}
      >
        <>
          <div className="embed-container">
            {platform === "Instagram" && (
              <InstagramEmbed url={showEmbedUrl} width={325} />
            )}
            {platform === "youtube" && (
              <YouTubeEmbed url={showEmbedUrl} width={325} height={620} />
            )}
            {platform === "twitter" && (
              <XEmbed url={showEmbedUrl} width={325} />
            )}
          </div>

          {/* {item.assignment_data.media_id &&
          mediaType &&
          !item.assignment_data.url && (
            <div className="download-content">
              <Video
                source={Utils.constructContentMediaUrl(
                  item.assignment_data.assignment_id,
                  item.assignment_data.media_id
                )}
                width={300}
                height={420}
                className="content-media"
              />
              <PrimaryCta
                fifth
                onClick={() =>
                  handleDownload(
                    item?.assignment_data?.media_id,
                    true,
                    item?.assignment_data?.assignment_id
                  )
                }
                fontSize={"font_size_large"}
                text={"Download"}
                className="blue-button"
              />
            </div>
          )} */}
          {/* {item.assignment_data.media_id &&
          !mediaType &&
          !item.assignment_data.url && (
            <div className="download-content">
              <Image
                source={Utils.constructContentMediaUrl(
                  item.assignment_data.assignment_id,
                  item.assignment_data.media_id
                )}
                width={300}
                height={420}
                className="content-media"
              />
              <PrimaryCta
                fifth
                fontSize={"font_size_large"}
                text={"Download"}
                className="blue-button"
              />
            </div>
          )} */}
        </>
      </Popup>
    );
  }

  function SocialMediaUrlUpdate() {
    return (
      <Sidebar
        sidebarWidth={"35%"}
        isOpen={isFlyoutUrlSidebarOpen}
        onClose={closeOpenFlyoutUrlSidebar}
        Header={urlUploadSidebarHeader}
        headerAlignment="left"
        Footer={urlUploadSidebarFooter}
        // headerClassName={"influencer-detail-sidebar-header"}
      >
        <div className="content_social_url_container">
          <div className="m-b-5 flex-row">
            <CustomText
              p
              fontSize={"font_size_medium"}
              textColor={"text_color_primary"}
              text={"Social Media Url"}
              textAlign={"text_align_left"}
            />
          </div>
          <div>
            <CustomTextInput
              type="text"
              height={48}
              value={socialMediaUrl}
              placeholder="Social Media Url"
              onChange={(e) => setSocialMediaUrl(e.target.value)}
            />
          </div>
        </div>
      </Sidebar>
    );
  }

  function validatorUrl() {
    const urlRegEx = /^(http|https):\/\/[^ "]+$/;
    if (socialMediaUrl.length > 0 && !urlRegEx.test(socialMediaUrl)) {
      showToast("Please enter at correct url", "error");
      return false;
    }
    if (!socialMediaUrl) {
      showToast("Please enter at correct url", "error");
      return false;
    }

    return true;
  }
  const openUrl = () => {
    window.open(savedUrl, "_blank");
  };

  function contractCancelModal() {
    return (
      <Popup
        isOpen={cancelContractModalOpen}
        onClose={() => setCancelContractModalOpen(false)}
        headerText={"Cancel Contract"}
        footerButton1Text={"No"}
        footerButton1Action={() => setCancelContractModalOpen(false)}
        footerButton2Text={"Yes"}
        footerButton2Action={() => postCancelContractOutbound()}
        isLoading={isCancel}
        width="30%"
        // height={"300px"}
        bottomSheetMaxHeight={0.6}
      >
        <div className="flex-row justify-center m-t-10">
          <CustomText
            p
            className="Body-1"
            text={`Are you sure to cancel this contract ?`}
            textColor="text_color_gray_500"
          />
        </div>
      </Popup>
    );
  }
  return (
    <div className="">
      <div className="m-b-12 ">
        <CustomText
          p
          text={"Content Update offline"}
          textColor={"text_color_gray_500"}
          fontSize={"font_size_h7"}
          textAlign={"text_align_left"}
          fontWeight={"Inter_Bold"}
          lineHeight={"line_height_24"}
        />
      </div>

      <div className="content-container-main">
        <div className="content-status-container-main">
          <div className="content-upload-card-main-container">
            <div className="content-ugc-contract-card">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div className="m-r-8">
                  <Icon
                    iconName={Utils.socialMediaIcons(
                      assignmentContentData[0]?.platform_data?.platform_id
                    )}
                    height={24}
                    width={24}
                  />
                </div>

                <CustomText
                  p
                  className="content-pending-card-text"
                  text={assignmentContentData[0]?.platform_data?.name}
                  textColor={"text_color_gray_500"}
                />
              </div>
              {props.assignmentDetail.campaign_data?.type == "content" &&
                ugcCampaignContent()}
              {props.assignmentDetail.campaign_data?.type == "social_media" && (
                <>
                  {SocialMediaUrlUpdate()}
                  {socialMedialEmbed()}
                  {SocialMediaCampaignContent()}
                </>
              )}

              {/* {savedUrl &&
                savedUrl.map((item, index) => {
                  return (
                    <div className="m-b-12 upload-content-container">
                      <div
                        className="content-pending-content-card"
                        onClick={() => openShoeContent()}
                      >
                        <CustomText
                          p
                          className="Body-1 content-url cursor-pointer"
                          text={savedUrl && savedUrl}
                          textColor={"text_color_blue_500"}
                        />

                        <div>
                          <Icon
                            iconName={"contentStatusCheckIcon"}
                            height={24}
                            width={24}
                          />
                        </div>
                      </div>
                      <div style={{ padding: "0px 10px" }}>
                        <PrimaryCta
                          fullWidth={true}
                          onClick={() => {
                            saveSocialMediaUrl();
                          }}
                          fontSize={"font_size_large"}
                          text={"Submit"}
                          isLoading={""}
                          className="blue-button"
                        />
                      </div>
                      <a
                        className="remove-content text-12"
                        onClick={() => clearContent(index)}
                      >
                        Remove
                      </a>
                    </div>
                  );
                })} */}
            </div>
          </div>
        </div>
        {props?.assignmentDetail?.assignment_data?.assignment_status ==
          "COMPLETED" && (
          <div className="rating-star">
            <CustomText
              p
              text={"Rate your Collaboration Experience with"}
              textColor={"text_color_primary"}
              fontSize={"font_size_l"}
              className="rating-para"
            />
            <CustomText
              p
              text={props?.assignmentDetail?.influencer_data?.name}
              textColor={"text_color_primary"}
              fontSize={"font_size_2xl"}
              className="name-rating"
            />
            <div className="rating-icon">
              <Rating
                size={42}
                initialValue={
                  props?.assignmentDetail?.rating_data[0]?.review_points ||
                  rating
                }
                onClick={handleRating}
                readonly={
                  props?.assignmentDetail?.rating_data.length == 0
                    ? false
                    : true
                }
              />
            </div>
            {props?.assignmentDetail?.rating_data?.length == 0 && (
              <div style={{display:"flex",justifyContent:"center"}}>
                <PrimaryCta
                third
                fullWidth={true}
                fontSize={"font_size_large"}
                text={"Submit"}
                isLoading={isRatingButtonLoading}
                className={
                  rating == 0 ? "submit-rating" : "submit-rating-after"
                }
                // onClick={()=>postRatingInfluencer() }
                onClick={() => {
                  postRatingInfluencer();
                }}
              />
              </div>              
            )}
          </div>
        )}
      </div>
      {props?.assignmentDetail?.payout_data?.status != "COMPLETED" &&
        props?.assignmentDetail?.assignment_data?.assignment_status ==
          "COMPLETED" && (
          <div style={{ marginTop: "20px" }}>
            <PrimaryCta
              fullWidth={true}
              onClick={() => {
                postMarkAsPaid();
              }}
              fontSize={"font_size_large"}
              text={"Mark as Paid"}
              isLoading={markAsPaidLoading}
              className="blue-button"
            />
          </div>
        )}

      {props?.assignmentDetail?.assignment_data?.assignment_status ==
        "ACTIVE" && (
        <div className="m-t-16">
          <PrimaryCta
            fullWidth={true}
            onClick={CancelContractOpen}
            fontSize={"font_size_large"}
            text={"Cancel Contract"}
            isLoading={markAsPaidLoading}
            className="bg-gray-gray-300"
          />
        </div>
      )}

      {contractCancelModal()}
    </div>
  );
}

export default ContentOfflineContract;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "var(--general-secondary-color)",
    textAlign: "center",
    zIndex: "5",
    height: "40%",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "space-around",
    minWidth: "658px",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.86)",
    zIndex: 10,
  },
};

const customStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "var(--general-secondary-color)",
    borderColor: "var( --blue-blue-500)",
    textAlign: "center",
    zIndex: "5",
    maxHeight: "90%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    minWidth: "400px",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.86)",
    zIndex: 10,
  },
};
