import React, { useState, useEffect } from "react";
import {
  SocialMediaCards,
  CustomCheckBoxCircular,
  PrimaryCta,
} from "../../components";
import Core from "../../common/clientSdk";
import utils from "../../common/utils";
import { useSnackbar } from "react-simple-snackbar";
import contentList from "./../../resources/data/content_data.json";
import { ToastContainer, toast } from "react-toastify";
function SocialMediaPlatForm(props) {
  const campaign_data = props.campaignDetail.campaign_data;

  //const [contentList, setContentList] = useState();
  const [selectedPlatform, setSelectedPlatform] = useState({});
  const [openStatus, setOpenStatus] = useState(false);
  const [openSnackbar] = useSnackbar();

  // useEffect(() => {
  //   getCampaignContent();
  // }, []);

  useEffect(() => {
    getCampaignPlatform(contentList);
    contentList.map((item) => {
      selectedPlatform[item.platform_data.platform_id] = [];
    });
  }, [contentList]);

  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const socialMedia = props.campaignDetail;

  function getCampaignContent() {
    var params = {};
    Core.getCampaignContent(
      getCampaignContentSuccess,
      getCampaignContentFail,
      updateProgress,
      params
    );
  }

  function getCampaignContentSuccess(response) {
    //  setContentList(response.content_data);
    response.content_data.map((item) => {
      selectedPlatform[item.platform_data.platform_id] = [];
    });
  }

  function getCampaignContentFail(errorList) {
    toast(errorList.map((item) => item.m).join(", "));
  }

  function updateProgress(status) {}

  // get platform Data
  function getCampaignPlatform(contentList) {
    let platform_data = {};
    contentList &&
      contentList.forEach((item) => {
        if (item.platform_data.platform_id in platform_data) {
          return;
        } else {
          platform_data = {
            ...platform_data,
            [item["platform_data"]["platform_id"]]: item.platform_data,
          };
        }
      });

    return platform_data;
  }

  const handleToggle = (status, id) => {
    let newSelectedPlateform = { ...selectedPlatform };
    if (status.target.checked == true) {
      newSelectedPlateform[id] = [];
    } else {
      delete newSelectedPlateform[id];
    }
    setSelectedPlatform(newSelectedPlateform);
  };

  const handleCard = (id) => {
    let newSelectedPlateform = { ...selectedPlatform };

    if (newSelectedPlateform[id]) {
      delete newSelectedPlateform[id];
    } else {
      newSelectedPlateform[id] = [];
    }

    setSelectedPlatform(newSelectedPlateform);
  };

  function getPlatformContents(platform_id, contentList) {
    return contentList.filter(function (contentData) {
      return contentData["platform_data"]["platform_id"] == platform_id;
    });
  }

  function contentTypeOnToggle(status, content_id, platform_id) {
    let newSelectedPlatform = { ...selectedPlatform };
    if (status.target.checked == true) {
      newSelectedPlatform[platform_id].push(content_id);
    } else {
      newSelectedPlatform[platform_id] = newSelectedPlatform[
        platform_id
      ].filter((e) => {
        return e != content_id;
      });
    }

    console.log("newSelectedPlatform", newSelectedPlatform);

    // newSelectedPlatform[platform_id] = [content_id];
    Object.keys(newSelectedPlatform).forEach((selected_platform_id) => {
      if (selected_platform_id !== platform_id) {
        newSelectedPlatform[selected_platform_id] = [];
      }
    });

    setSelectedPlatform(newSelectedPlatform);
  }

  const handleBack = () => {
    props.previousStep();
  };

  const handleNext = () => {
    // console.log("toast", toast.info("hello"));
    let content_type_deep_data = [];
    Object.values(selectedPlatform).forEach((item) => {
      item.forEach((id) => {
        content_type_deep_data.push({
          content_data: {
            content_type_id: id,
          },
        });
      });
    });

    if (content_type_deep_data.length == 0) {
      toast.info("Please select atleast one content type");
      return false;
    } else {
      props.setCampaignDeepData((prev_state) => {
        return {
          ...prev_state,
          content_type_deep_data: content_type_deep_data,
        };
      });
    }

    props.nextStep();
  };

  // function showError(msg) {
  //   toast.error(msg);
  // }

  const handleFinish = () => {
    props.lastStep();
  };

  function SocialMediaDropDownScreen(item) {
    return (
      <div>
        <CustomCheckBoxCircular
          text={item.content_data.name}
          description={item.content_data.description}
          checked={
            selectedPlatform[item.content_data.platform_id] &&
            selectedPlatform[item.content_data.platform_id].includes(
              item.content_data.content_type_id
            )
          }
          handleCheckbox={(status) =>
            contentTypeOnToggle(
              status,
              item.content_data.content_type_id,
              item.content_data.platform_id
            )
          }
        />
      </div>
    );
  }

  return (
    <div className="create-campaign-container">
      <div className="type-campaign-container flex-column ">
        {contentList &&
          Object.values(getCampaignPlatform(contentList))
            .filter((item) => item.platform_id !== "6u73")
            .map((item, index) => {
              return (
                <div style={{ width: "100%" }}>
                  <SocialMediaCards
                    text={item.name}
                    iconName={utils.getPlatformIconNamebyId(item.platform_id)}
                    defaultChecked={Array.isArray(
                      selectedPlatform[item.platform_id]
                    )}
                    //onToggle={(status) => toggleIn(status, item.platform_id)}
                    onChange={(status) =>
                      handleToggle(status, item.platform_id)
                    }
                    onClickIcon={() => handleCard(item.platform_id)}
                    // onClickIcon={handleToggle}
                  />
                  {selectedPlatform[item.platform_id] && (
                    <div className="Social-media-dropDown-screen">
                      {getPlatformContents(item.platform_id, contentList).map(
                        (item, index) => {
                          return SocialMediaDropDownScreen(item);
                        }
                      )}
                    </div>
                  )}

                  {/* <div>{SocialMediaDropDownScreen()}</div>; */}
                </div>
              );
            })}
      </div>
      <div className="divide-line"></div>
      <div className="type-campaign-btn">
        <div style={{ maxWidth: 150 }}>
          <PrimaryCta
            invert
            onClick={handleBack}
            fontSize={"font_size_large"}
            text={"Back"}
          />
        </div>
        <PrimaryCta
          fifth
          onClick={handleNext}
          fontSize={"font_size_large"}
          text={"Next"}
        />
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        zIndex={9999999}
      />
    </div>
  );
}

export default SocialMediaPlatForm;
