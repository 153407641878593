import React, { useEffect, useState, useRef } from "react";
import {
  CustomIconCta,
  CustomText,
  CustomTextInput,
  FallBack,
  Image,
  PrimaryCta,
  Video,
  ViewCards,
} from "../../components";
import Core from "../../common/clientSdk";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Loader } from "../loader";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSnackbar } from "react-simple-snackbar";
import { MessageBox, Input, Button, MessageList } from "react-chat-elements";
import { format, parse, fromUnixTime } from "date-fns";
import "react-chat-elements/dist/main.css";
import SideMenu from "../sideMenu";
import Header from "../header";
import Utils from "../../common/utils";
import Icon from "../../components/core_component/icon";
import { Crisp } from "crisp-sdk-web";
import { ToastContainer, toast } from "react-toastify";
import GetContactPermissionModal from "../influecnerScreen/getContactPermissionModal";
import { useToast } from "../../features/toastContainer";
import ContactDetailInfluencer from "../../modal/contactDetailInfluencer";
import ContactInfluencerModal from "../../modal/contactInfluencerModal";
let limit = 10;
function MessagesScreen(props) {
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [assignmentList, setAssignmentList] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [influencerData, setInfluencerData] = useState({});
  const [assignmentDetail, setAssignmentDetail] = useState({});
  const [count, setCount] = useState(0);
  const [messageLoader, setMessagesLoader] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [influencerDetail, setInfluencerDetail] = useState({});
  const [influencerContactData, setInfluencerContactData] = useState({});

  const [start, setStart] = useState(0);
  const [next, setNext] = useState(0);
  const [contactModalIsOpen, setContactModalIsOpen] = useState(false);
  const [isLoadingContact, setLoadingContact] = useState(false);
  const [contactRequest, setContactRequest] = useState(true);
  const [isHover, setIsHover] = useState(false);
  const [openSnackbar] = useSnackbar();
  const inputRef = useRef(null);
  const assignmentId = useParams()?.id;
  const showToast = useToast();
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768); // If screen width is less than or equal to 768px, consider it mobile
  };

  useEffect(() => {
    // Add an event listener to handle resize
    window.addEventListener("resize", handleResize);

    // Set initial value
    handleResize();

    // Cleanup listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    getAssignmentBrandDetails();
  }, []);
  useEffect(() => {
    // This effect will trigger whenever assignmentList changes
    if (assignmentList[0] && !assignmentId) {
      const item = assignmentList[0];
      getInfluencerDetailPublic(item.influencer_data.influencer_id);
      setAssignmentDetail((prevData) => ({
        ...prevData,
        assignment_status: item.assignment_data.assignment_status,
      }));
      setInfluencerData((prevData) => ({
        ...prevData,
        name: item.influencer_data.name,
        influencer_id: item.influencer_data.influencer_id,
        profile_picture: item.influencer_data.profile_picture,
        assignment_id: item.assignment_data?.assignment_id,
      }));
    }
  }, [assignmentList]);
  useEffect(() => {
    getMessagesUserList(influencerData.assignment_id);
  }, [influencerData.assignment_id]);

  useEffect(() => {
    if (influencerDetail?.influencer_data?.influencer_id) {
      getNewInfluencerContact();
    }
  }, [influencerDetail?.influencer_data?.influencer_id]);
  // useEffect(() => {
  //   setMessagesLoader(false);
  // }, [messages.length]);

  const chatContainerRef = useRef(null);

  useEffect(() => {
    // Scroll to the bottom when new messages are added
    scrollToBottom();
  }, [messages.length]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      getMessagesUserList(influencerData.assignment_id);
    }, 10000);

    // Run initial fetch
    getMessagesUserList(influencerData.assignment_id);

    return () => clearInterval(intervalId);
  }, [influencerData]);

  function scrollToBottom() {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }

  function updateProgress(status) {
    setIsLoading(status);
  }

  var redux_selector = useSelector((state) => {
    return {
      UserData: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const { UserData = {} } = redux_selector;
  const { brand_deep_data = {} } = UserData;
  const { brand_data = {} } = brand_deep_data;
  const { all_permissions = {} } = UserData;
  const { org_deep_data = {} } = UserData;
  function getAssignmentBrandDetails() {
    var params = {
      brand_id: redux_selector.UserData.brand_deep_data.brand_data.brand_id,
      sort_by: "message",
    };
    Core.getAssignmentBrandDetails(
      getAssignmentBrandDetailsSuccess,
      getAssignmentBrandDetailsFailed,
      updateProgress,
      params
    );
  }

  function getAssignmentBrandDetailsSuccess(response_data) {
    setAssignmentList(response_data["assignment_list"]);
    if (assignmentId) {
      setIsMobile(false);
      const selectedAssignmentData = response_data.assignment_list.find(
        (assignment_deep_data) => {
          return (
            assignment_deep_data.assignment_data.assignment_id === assignmentId
          );
        }
      );
      getInfluencerDetailPublic(
        selectedAssignmentData?.influencer_data?.influencer_id
      );
      setAssignmentDetail({
        assignment_status:
          selectedAssignmentData?.assignment_data?.assignment_status,
      });
      setInfluencerData({
        name: selectedAssignmentData?.influencer_data?.name,
        influencer_id: selectedAssignmentData?.influencer_data?.influencer_id,
        profile_picture: selectedAssignmentData?.influencer_data?.profile_picture,
        assignment_id: selectedAssignmentData?.assignment_data?.assignment_id,
      });
    }
  }

  function getAssignmentBrandDetailsFailed(errorList) {
    // openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function getInfluencerDetailPublic(influencer_id) {
    const params = {
      influencer_id: influencer_id,
    };

    Core.getInfluencerDetailPublic(
      getInfluencerDetailPublicSuccess,
      getInfluencerDetailPublicFailed,
      () => {},
      params
    );
  }

  function getInfluencerDetailPublicSuccess(response) {
    setInfluencerDetail(response.influencer_deep_data);
  }

  function getInfluencerDetailPublicFailed(errorList) {
    showToast(errorList.map((err) => err.m).join(", "));
  }
  console.log("influencerDetail", influencerDetail);

  function getMessagesUserList(assignmentId) {
    const params = {
      assignment_id: influencerData.assignment_id,
    };
    Core.getMessagesUserList(
      getMessagesUserListSuccess,
      getMessagesUserListFailed,
      (status) => {},
      params
    );
  }

  function getMessagesUserListSuccess(response_data) {
    const reversedMessages = [...response_data.message].reverse();
    setMessages(reversedMessages);
    setMessagesLoader(false);
  }

  function getMessagesUserListFailed(errorList) {
    // openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  const handleKeyPress = (event) => {
    if (event && event.key === "Enter") {
      postMessagesUserList();
    }
  };
  function postMessagesUserList() {
    const params = {
      message_text: newMessage,
      assignment_id: influencerData.assignment_id,
    };
    Core.postMessagesUserList(
      postMessagesUserListSuccess,
      postMessagesUserListFailed,
      (status) => {},
      params
    );
    inputRef.current.value = "";
  }

  function postMessagesUserListSuccess(response_data) {
    getMessagesUserList();

    setNewMessage("");
  }

  function postMessagesUserListFailed(errorList) {
    toast.error(errorList);
  }

  function postDeleteMessage(progress_cb) {
    const params = { message_id: messages };
    Core.postDeleteMessage(
      postDeleteMessageListSuccess,
      postDeleteMessageListFailed,
      progress_cb,
      params
    );
  }

  function postDeleteMessageListSuccess(response_data) {}

  function postDeleteMessageListFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  // get contact detail influencer

  function getInfluencerContact() {
    var params = {
      influencer_id: influencerDetail?.influencer_data?.influencer_id,
      org_id: brand_deep_data?.brand_data?.org_id,
    };

    Core.getInfluencerContact(
      getInfluencerContactSuccess,
      getInfluencerContactFail,
      (status) => setLoadingContact(status),
      params
    );
  }

  function getInfluencerContactSuccess(demo, response) {
    setInfluencerDetail((prev) => ({
      ...prev,
      phone: response?.influencer_deep_data?.influencer_data?.phone,
      email: response?.influencer_deep_data?.influencer_data?.email,
    }));
    closeGetContactDetails();
  }

  function getContactSuccess(requestedContactResponse) {
    console.log("requestedContactResponse", requestedContactResponse);

    setContactRequest(requestedContactResponse);
  }
  function getInfluencerContactFail(error) {
    showToast(error.map((item) => item.m).join(""), "error");
  }

  console.log("rrrr", influencerDetail?.influencer_data?.influencer_id);

  function getNewInfluencerContact() {
    const params = {
      influencer_id: influencerDetail?.influencer_data?.influencer_id,
    };
    Core.getNewInfluencerContact(
      getNewInfluencerContactSuccess,
      getNewInfluencerContactFailed,
      () => {},
      params
    );
  }

  function getNewInfluencerContactSuccess(response_data, data) {
    console.log("influencerContactData con", response_data, data);
    setInfluencerContactData(data?.influencer_contact);
  }
  console.log("influencerContactData", influencerContactData);

  function getNewInfluencerContactFailed(errorList) {
    toast.info(errorList.map((item) => item.m).join(", "));
  }

  function dateTime(time) {
    const myDate = fromUnixTime(time);
    const formattedDate = format(myDate, "MMMM dd, yyyy HH:mm");
    return formattedDate;
  }

  console.log("assignmentDetail", assignmentDetail);

  function messageSideBar() {
    return (
      <div
        className="influencer_name_chat_container"
        style={{
          minWidth: 364,
          width: isMobile ? "100%" : 364,
          marginLeft: isMobile ? 0 : 20,

          marginRight: isMobile ? 0 : 20,
        }}
      >
        {/* <div className="influencer_name_chat">
          <Image
            source={
              influencerData.profile_picture
                ? Utils.generateInfluencerDpUrl(
                    influencerData.influencer_id,
                    influencerData.profile_picture
                  )
                : require("../../resources/images/user-copy.png")
            }
            className="influencer_message_profile"
          />
          <div>
            <CustomText p text={influencerData?.name} className="button2" />
          </div>
        </div> */}
        <div className="contract_message_list">
          {assignmentList &&
            assignmentList.map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    setMessagesLoader(true);
                    getInfluencerDetailPublic(
                      item.influencer_data.influencer_id
                    );
                    setIsMobile(false);
                    // getMessagesUserList(item.assignment_data?.assignment_id);
                    setInfluencerData({
                      name: item.influencer_data.name,
                      influencer_id: item.influencer_data.influencer_id,
                      profile_picture: item.influencer_data.profile_picture,
                      assignment_id: item.assignment_data?.assignment_id,
                    });
                    setAssignmentDetail({
                      assignment_status:
                        item?.assignment_data?.assignment_status,
                    });
                  }}
                  className={
                    influencerData.assignment_id ==
                    item.assignment_data?.assignment_id
                      ? "influencer_name_list_active"
                      : "influencer_name_list"
                  }
                >
                  <Image
                    source={
                      item.influencer_data.profile_picture
                        ? Utils.generateInfluencerDpUrl(
                            item.influencer_data.influencer_id,
                            item.influencer_data.profile_picture
                          )
                        : require("../../resources/images/user-copy.png")
                    }
                    fallBack={require("../../resources/images/user-copy.png")}
                    className="influencer_message_profile"
                  />
                  {item.assignment_data.unseen_brand == 1 && (
                    <div className="red-dot-pending-message"></div>
                  )}

                  <CustomText
                    p
                    text={item.influencer_data.name}
                    className="button2"
                  />
                </div>
              );
            })}
        </div>
      </div>
    );
  }

  function dateTime(time) {
    const myDate = fromUnixTime(time);
    const formattedDate = format(myDate, "MMMM dd, yyyy HH:mm");
    return formattedDate;
  }
  console.log("contactRequest", contactRequest);

  function messageChatScreen() {
    return (
      <div className="message_chat_box_container">
        <>
          <div className="message_chat_box_header-container">
            <div className="message_chat_box_header">
              <Image
                source={
                  influencerData.profile_picture
                    ? Utils.generateInfluencerDpUrl(
                        influencerData.influencer_id,
                        influencerData.profile_picture
                      )
                    : require("../../resources/images/user-copy.png")
                }
                fallBack={require("../../resources/images/user-copy.png")}
                className="influencer_message_profile"
              />
              <CustomText p text={influencerData?.name} className="button2" />
            </div>
            {assignmentDetail != "COMPLETED" && (
              <div
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                onClick={() => openGetContactDetails()}
                style={{ cursor: "pointer" }}
              >
                <Icon
                  iconName="contactCallIcon"
                />
              </div>
            )}

            {/* <ContactDetailInfluencer
              influencer_id={influencerDetail?.influencer_data?.influencer_id}
              influencerData={influencerDetail?.influencer_data}
              getContactSuccess={getContactSuccess}
              isLoadingContact={isLoadingContact}
              isActive={"message_screen"}
            /> */}

            <div
              className="mobile-icon-close-icon"
              onClick={() => setIsMobile(true)}
            >
              {" "}
              <Icon iconName="CrossBtnIcon" />
            </div>
          </div>
          <div
            ref={chatContainerRef}
            className="message_box_container"
            // style={{ overflowY: "auto", maxHeight: "370px" }}
          >
            {messageLoader ? (
              <div className="loader-mid">
                {" "}
                <Loader />
              </div>
            ) : (
              <>
                {" "}
                {messageLoader && messages.length == 0 ? (
                  <div className="loader-mid">
                    <Loader />
                  </div>
                ) : (
                  <>
                    {messages.length == 0 ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            textAlign: "center",
                            minHeight: "",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Icon
                            iconName={"messageFallBack"}
                            width={100}
                            height={105}
                          />
                          <div className="flex-column">
                            <div style={{ marginBottom: 10, marginTop: 5 }}>
                              <CustomText
                                p
                                fontSize={"font_size_large"}
                                text={"No Messages"}
                                textColor={"text_color_primary"}
                              />
                            </div>

                            <CustomText
                              p
                              fontSize={"font_size_large"}
                              text={"Start a conversation with influencer"}
                              textColor={"text_color_secondary"}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {" "}
                        {messages &&
                          messages.map((item) => {
                            return (
                              <div style={{}}>
                                <div
                                  className={
                                    item.sender_type == "user"
                                      ? "message_user"
                                      : "message_influencer"
                                  }
                                >
                                  <CustomText
                                    p
                                    text={item.message_text}
                                    className={
                                      item.sender_type == "user"
                                        ? "message_user_text"
                                        : "message_influencer_text"
                                    }
                                  />
                                  <div style={{ marginTop: 5 }}>
                                    <p style={{ fontSize: 10 }}>
                                      {"At " +
                                        Utils.convertUnixToTime(
                                          item.created_at
                                        )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          <div className="message_send_input_container">
            <input
              ref={inputRef}
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              className="message_send_input"
              placeholder="Type your message here..."
              onKeyPress={handleKeyPress}
            />
            <div
              className="send_button_message"
              onClick={() => postMessagesUserList()}
            >
              {!newMessage ? (
                <Icon iconName={"sendButtonNonActive"} />
              ) : (
                <Icon iconName={"sendButtonActive"} />
              )}
            </div>
          </div>
        </>
      </div>
    );
  }

  function openGetContactDetails() {
    setContactModalIsOpen(true);
  }
  function closeGetContactDetails() {
    setContactModalIsOpen(false);
  }

  return (
    <div className="dashboard-container background-color-primary">
      <SideMenu />
      <div className="main-screen">
        <Header />
        <div
          className="internal-chat-page-containe page-container-campaignList"
        >
          {isLoading ? (
            <div className="loader-mid">
              <Loader />
            </div>
          ) : (
            <>
              {assignmentList.length == 0 ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      textAlign: "center",
                      minHeight: "",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Icon
                      iconName={"messageFallBack"}
                      width={100}
                      height={105}
                    />
                    <div className="message-fall-back ">
                      <CustomText
                        p
                        text={"No Messages"}
                        fontSize={"font_size_2xl"}
                        height={"line_height_24"}
                        textColor={"text_color_primary"}
                      />
                      <CustomText
                        p
                        fontSize={"font_size_large"}
                        text={"Start a conversation with creator"}
                        textColor={"text_color_secondary"}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{
                      height: window.innerHeight - 150,
                    }}
                    className="message-and-siderbar-container"
                  >
                    {messageSideBar()}
                    {messageChatScreen()}
                  </div>
                  <div className="message-and-siderbar-mobile-container">
                    {isMobile ? (
                      <> {messageSideBar()}</>
                    ) : (
                      <>{messageChatScreen()}</>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </div>
        {/* {contactModalIsOpen && (
          <GetContactPermissionModal
            openGetContactDetails={openGetContactDetails}
            closeGetContactDetails={closeGetContactDetails}
            influencerData={influencerDetail?.influencer_data}
            org_deep_data={org_deep_data}
            getInfluencerContact={getInfluencerContact}
          />
        )} */}

        {contactModalIsOpen && (
          <ContactInfluencerModal
            openGetContactDetails={openGetContactDetails}
            closeGetContactDetails={closeGetContactDetails}
            influencerContactData={influencerContactData}
          />
        )}
      </div>
    </div>
  );
}

export default MessagesScreen;
