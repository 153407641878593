import React from "react";
import { useSelector } from "react-redux";
import Utils from "../common/utils";
import { useNavigate } from "react-router-dom";
import { CustomText, Image } from "../components";
import InfluencerContentSocialMedia from "./influencerContentSocialMedia";
import Icon from "../components/core_component/icon";

function InfluencerContentCard(props) {
  console.log("props00",props)
  const navigate = useNavigate();
  const redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const { BrandUser = {} } = redux_selector;
  const { brand_deep_data = {} } = BrandUser;
  const { user_data = {} } = BrandUser;
  const {
    imageSource,
    influencerDp,
    influencerUsername,
    influencerHandle,
    likes,
    comments,
    views,
    date,
    videoUrl,
    heartIconProps,
    commentIconProps,
    viewIconProps,
  } = props;

  const handlePlatformClick = (url) => {
    window.open(url, "_blank");
  };

  function imageVideo() {
    return props.item?.thumbnail ? (
      <div>
        <Image source={props.item?.thumbnail} />
      </div>
    ) : (
      <div>
        <video width="100%" height="100%" controls={false} poster={props.imageSource}>
          <source
            src={
              Utils.constructContentMediaUrl(props.item.assignmentId, props.item.imageSource) ||
              props.item?.url
            }
            type="video/mp4"
          />
        </video>
      </div>
    );
  }

  return (
    <div
      onClick={() => window.open(props.item.url, "_blank")}
      className="content-media-card-container cursor-pointer"
    >
      <div className="upper-content-media-card-container">
        <div className="upper-content-media-card">
          <div className="content-media-card-image">{imageVideo()}</div>
          <div className="influencer-detail-analytics-container">
            {/* Profile Picture Click */}
            <Image
              className="influencer-detail-analytics-dp"
              source={
                props?.item?.imageSource
                  ? Utils.generateInfluencerDpUrl(props?.item?.influencerId, props?.item?.influencerDp)
                  : props?.item?.profile_pic || "../assets/images/user-fallback.svg"
              }
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/influencer-detail/${props?.item?.influencerId}`);
              }}
              fallBack={require("../resources/images/user-copy.png")}
            />

            {/* Username Click */}
            <div>
              <div
                className="username-container"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/influencer-detail/${props?.item?.influencerId}`);
                }}
                style={{ cursor: "pointer" }}
              >
                <CustomText
                  p
                  text={props?.item?.influencerUsername}
                  className="button2_light m-l-3"
                  textColor={"text_color_light"}
                />
              </div>
              <div
                className="handle-container"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/influencer-detail/${props?.item?.influencerId}`);
                }}
                style={{ cursor: "pointer" }}
              >
                <CustomText
                  p
                  text={`@${props.item.influencerHandle}`}
                  className="caption_2 m-l-3"
                  textColor={"text_color_light"}
                />
              </div>
            </div>
          </div>
          <div className="social-media-icon-analytics">
            <Icon iconName="reelstIcon" />
          </div>
        </div>

        <InfluencerContentSocialMedia brand_deep_data={brand_deep_data} item={props.item} />
      </div>
    </div>
  );
}

export default InfluencerContentCard;
