import React, { useEffect, useState } from "react";
import {
  CustomRadioButton,
  CustomText,
  LinkCta,
  PrimaryCta,
  Sidebar,
} from "../../components";
import CheckoutParcel from "../../modal/checkoutParcel";
import { useSelector } from "react-redux";
import Utils from "../../common/utils";
import Core from "../../common/clientSdk";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Loader } from "../loader";
import { ToastContainer, toast } from "react-toastify";
import UpdateGSTModal from "../../modal/updateGSTModal";
import HeaderShare from "../../modal/HeaderShare";
import PaymentTypeCheckout from "../../features/paymentTypeCheckout";
function CheckoutPayment() {
  const [checkoutSummary, setCheckoutSummary] = useState();
  const [subscriptionBasicDetail, setSubscriptionBasicDetail] = useState({});
  const [subscriptionActiveData, setSubscriptionActiveData] = useState({});
  // const [notSubscribedUserPlan, setNotSubscribedUserPlan] = useState({});
  const [subscriptionBuyPlan, setSubscriptionBuyPlan] = useState(null);
  const [loadingPay, setLoadingPay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPaymentType, setIsPaymentType] = useState("online_payment");
  const [addGSTModalOpen, setAddGSTModalOpen] = useState(false);
  const [creditPolicySidebarOpen, setCreditPolicySidebarOpen] = useState(false);

  const place = useLocation();
  const { fromState } = place.state || {};

  var redux_selector = useSelector((state) => {
    return {
      Auth: state.BrandUserAuthReducer,
      UserData: state.BrandUserDataReducer,
    };
  });

  const {
    user_data,
    brand_deep_data,
    org_deep_data = {},
  } = redux_selector.UserData;

  const navigate = useNavigate();
  // const plan_id = useParams().id;

  const location = useLocation();
  const { plan_id } = location.state || {};

  let paymentData = [
    {
      id: "online_payment",
      title: "Online payment",
      sub_text: "Credit card, debit card, upi",
    },
    {
      id: "reelax_wallet",
      title: `Reelax wallet (Bal. ${Utils.convertFromINR(
        org_deep_data.org_data.balance,
        user_data?.country
      )})`,
      sub_text: "Quick payment",
    },
  ];

  useEffect(() => {
    if (plan_id) {
      getSubscriptionPlan();
    } else {
      subscriptionUserDetailBasic();
    }
  }, []);
  let credit = [
    {
      plan_id: "stndrd01",
      credit_id: "user001",
      quantity: 10,
      value: 1900,
      type: "BASIC",
      actual_value: 2000,
      gst: 18,
      gst_value: 342,
      total: 2242,
    },
    {
      plan_id: "stndrd01",
      credit_id: "user002",
      quantity: 50,
      value: 4750,
      type: "BASIC",
      actual_value: 5000,
      gst: 18,
      gst_value: 855,
      total: 5605,
    },
  ];

  function subscriptionUserDetailBasic() {
    const params = {
      org_id: org_deep_data?.org_data?.org_id,
    };

    Core.subscriptionUserDetailBasic(
      subscriptionUserDetailBasicSuccess,
      subscriptionUserDetailBasicFail,
      (status) => setLoading(status),
      params
    );
  }
  function subscriptionUserDetailBasicFail(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }
  function subscriptionUserDetailBasicSuccess(response, data) {
    console.log("response", response);
    console.log("data", data);

    setSubscriptionBasicDetail(data?.response?.basic_subscription_data);
    setSubscriptionActiveData(data?.response?.active_subscription_data);
  }
  function getSubscriptionPlan() {
    const params = {
      plan_id: plan_id,
      org_id: org_deep_data?.org_data?.org_id,
    };

    Core.getSubscriptionPlan(
      getSubscriptionPlanSuccess,
      getSubscriptionPlanFail,
      (status) => setLoading(status),
      params
    );
  }

  function getSubscriptionPlanSuccess(response, data) {
    setSubscriptionBuyPlan(data?.subscription_deep_data);
  }

  function getSubscriptionPlanFail(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  function CreateOrderCredit() {
    const params = {
      org_id: org_deep_data?.org_data?.org_id,
      c_id: checkoutSummary?.credit_id,
      quantity: checkoutSummary?.quantity,
      value: checkoutSummary?.actual_value
        ? checkoutSummary?.actual_value
        : checkoutSummary?.value,
      redirect_url: window.location.origin + "/checkout",
      platform: "web",
    };

    Core.subscriptionUserCreateCreditNew(
      CreateOrderCreditSuccess,
      CreateOrderCreditFail,
      (status) => setIsLoading(status),
      params
    );
  }

  function CreateOrderCreditSuccess(response, data) {
    console.log("demo1", response);
    console.log("demo2", data);

    createCreditOrder(data.credit_order_data);
  }

  function CreateOrderCreditFail(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  function walletCheckBalance() {
    const totalAmount = checkoutSummary?.total || 0;
    const walletBalance = org_deep_data?.org_data?.balance || 0;
    if (walletBalance < totalAmount) {
      toast("You don't have enough balance to complete this order.");
      return false;
    }
  }
  function postCreateOrderReelaxOrder() {
    const params = {
      org_id: org_deep_data?.org_data?.org_id,
      c_id: checkoutSummary?.credit_id,
      quantity: checkoutSummary?.quantity,
      value: checkoutSummary?.actual_value
        ? checkoutSummary?.actual_value
        : checkoutSummary?.value,
    };

    Core.postCreateOrderReelaxOrder(
      postCreateOrderReelaxOrderSuccess,
      postCreateOrderReelaxOrderFail,
      (status) => setIsLoading(status),
      params
    );
  }

  function postCreateOrderReelaxOrderSuccess(response) {
    console.log("response demo", response);

    toast.success("Payment successful! Thank you for your purchase.");
    navigate("/");
    window.location.reload();
  }

  function postCreateOrderReelaxOrderFail(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  // getTransactionHistoryList(start, updateProgress);
  // getOrgDataUser();

  const createCreditOrder = (order_data) => {
    const options = {
      key: order_data.payment_gateway_id,
      order_id: order_data?.pg_order_id,
      amount: order_data?.amount,
      currency: "INR",
      handler: function (response) {
        if (response) {
          toast.success("Payment successful! Thank you for your purchase.");
          navigate("/");
          window.location.reload();
        }
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  // change this funtion name from everywhere new name - createOrderSubscription
  // /order/user/create

  function createOrderSubscription() {
    const params = {
      org_id: org_deep_data?.org_data?.org_id,
      plan_id: plan_id,
    };

    Core.createOrderSubscription(
      createOrderSubscriptionSuccess,
      createOrderSubscriptionFail,
      (status) => setIsLoading(status),
      params
    );
  }

  function createOrderSubscriptionSuccess(response, data) {
    console.log("demo1", response);
    console.log("demo2", data);

    createSubscriptionOrder(data.response);
  }

  function createOrderSubscriptionFail(errorList) {
    toast.error(errorList.map((item) => item.m).join(","));
  }

  // console.log(
  //   "sandeep",

  //   Utils.convertFromINR(
  //     checkoutSummary?.total - org_deep_data.org_data.balance,
  //     user_data?.country,
  //     user_data.country
  //   )
  // );

  const handlePaymentChange = (itemId) => {
    const totalAmount = checkoutSummary?.value || 0;
    const walletBalance = org_deep_data?.org_data?.balance || 0;

    console.log("demo :344", itemId);
    if (itemId === "reelax_wallet" && walletBalance < totalAmount) {
      toast.error("You don't have enough balance to complete this order.");
      return false;
    } else {
      setIsPaymentType(itemId);
    }
  };

  const createSubscriptionOrder = (order_data) => {
    // Make an API call to your server to create a test order
    const options = {
      key: order_data.payment_gateway_id,
      order_id: order_data?.pg_order_id,
      amount: order_data?.amount,
      currency: "INR",
      handler: function (response) {
        if (response) {
          toast.success("Payment successful! Thank you for your purchase.");
          navigate("/");
          window.location.reload();
        }
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  const openModalGTS = () => {
    if (addGSTModalOpen == false) {
      setAddGSTModalOpen(true);
    } else {
      setAddGSTModalOpen(false);
    }
  };

  const closeModalGST = () => {
    setAddGSTModalOpen(false);
  };

  function createOrderCondition() {
    if (isPaymentType == "reelax_wallet") {
      postCreateOrderReelaxOrder();
    } else {
      CreateOrderCredit();
    }
  }

  function renderCheckoutHeader() {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: 15,
          paddingBottom: 15,
          background: "#CFCFEE",
        }}
      >
        <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
          <CustomText
            p
            text={"Save up to 55% Content"}
            className="Caption-3"
            textAlign={"text_align_left"}
          />
          <CustomText
            p
            text={"Save up to 55% Content"}
            className="typography14 "
            textAlign={"text_align_left"}
          />
        </div>
      </div>
    );
  }

  function renderSubscriptionDay(value) {
    return value / (3600 * 24);
  }

  console.log("sandeep", subscriptionBuyPlan);

  function totalBuyCredit() {
    if (isPaymentType == "reelax_wallet") {
      return Utils.convertFromINR(checkoutSummary?.value, user_data?.country);
    } else {
      return Utils.convertFromINR(checkoutSummary?.total, user_data.country);
    }
  }

  function renderCheckoutSummary() {
    return (
      <div style={{}}>
        {/* {paymentRadioButton()} */}
        <div className="checkout-upper-container">
          <div className="m-b-20">
            {/* <CustomText
              p
              text={"Checkout"}
              className="typography-centerH7"
              textAlign={"text_align_left"}
            /> */}

            <CustomText p text={"Checkout"} className="h6Text text-align" />
          </div>

          <div>
            <div className="m-b-18">
              <CustomText
                p
                text={"Purchase Summary"}
                className="caption-bold-3"
                textAlign={"text_align_left"}
              />
            </div>
            <div className="m-b-10 flex-spc-bt">
              <CustomText
                p
                text={subscriptionBuyPlan?.subscription_data?.name}
                className="button2"
                textAlign={"text_align_left"}
              />
              <CustomText
                p
                text={Utils.convertFromINR(
                  subscriptionBuyPlan?.subscription_data?.price,
                  user_data?.country
                )}
                className="button2"
                textAlign={"text_align_left"}
              />
            </div>
            <div className="m-l-24">
              <ul>
                <li>
                  {" "}
                  <div className="flex-row m-b-10">
                    <CustomText
                      p
                      text={"You are buying"}
                      className="subtitle1"
                      textAlign={"text_align_left"}
                    />
                    <CustomText
                      p
                      text={` ${subscriptionBuyPlan?.feature.free_credits}  Credits`}
                      className="button1 m-l-5"
                      textAlign={"text_align_left"}
                    />
                  </div>
                </li>
                <li>
                  <div className="flex-row m-b-10">
                    <CustomText
                      p
                      text={"Use these credits within"}
                      className="subtitle1"
                      textAlign={"text_align_left"}
                    />
                    <CustomText
                      p
                      text={
                        renderSubscriptionDay(
                          subscriptionBuyPlan?.subscription_data?.duration
                        ) + " days"
                      }
                      className="button1 m-l-5"
                      textAlign={"text_align_left"}
                    />
                  </div>
                </li>
              </ul>
            </div>
            {/* <div className=" flex-spc-bt">
              <CustomText
                p
                text={"Plan discount (12%) "}
                className="subtitle1 "
                textAlign={"text_align_left"}
              />
              <CustomText
                p
                text={Utils.convertFromINR(
                  checkoutSummary?.actual_value - checkoutSummary?.value,
                  user_data.country
                )}
                className="button1 m-l-5"
                textAlign={"text_align_left"}
              />
            </div> */}
          </div>
        </div>
        <div className="checkout-upper-container">
          <div className="m-b-10 flex-spc-bt">
            <CustomText
              p
              text={"Sub Total"}
              className="subtitle1"
              textAlign={"text_align_left"}
            />
            <CustomText
              p
              text={Utils.convertFromINR(
                subscriptionBuyPlan?.subscription_data?.price,
                user_data?.country
              )}
              className="subtitle1"
              textAlign={"text_align_left"}
            />
          </div>
          <div className="m-b-10 flex-spc-bt">
            <CustomText
              p
              text={`GST (${subscriptionBuyPlan?.subscription_data?.gst}%)`}
              className="subtitle1"
              textAlign={"text_align_left"}
            />
            <CustomText
              p
              text={Utils.convertFromINR(
                subscriptionBuyPlan?.subscription_data?.gst_value,
                user_data?.country
              )}
              className="subtitle1"
              textAlign={"text_align_left"}
            />
          </div>
          <div>
            {!org_deep_data?.org_data?.gst_number && (
              <div onClick={openModalGTS} className="m-t-8">
                <u className="add-gts-number">Add GST Number</u>
              </div>
            )}
          </div>
        </div>
        <div className="m-t-20 flex-spc-bt">
          <div className="flex-row-baseline">
            <CustomText
              p
              text={"Total"}
              className="button2"
              textAlign={"text_align_left"}
            />
            <CustomText
              p
              text={"(inc tax)"}
              className="caption_2_10"
              textAlign={"text_align_left"}
            />
          </div>
          <CustomText
            p
            text={Utils.convertFromINR(
              subscriptionBuyPlan?.subscription_data?.total,
              user_data?.country
            )}
            className="button2"
            textAlign={"text_align_left"}
          />
        </div>
        <div className="signin-right-side-top-container-div-5">
          <PrimaryCta
            className="login-right-side-input-field"
            fullWidth100
            onClick={() => createOrderSubscription()}
            fontSize={"font_size_large"}
            text={`Pay ${Utils.convertFromINR(
              subscriptionBuyPlan?.subscription_data?.total,
              user_data?.country
            )}`}
            //   onClick={brandLogin}
            isLoading={isLoading}
          />
        </div>
      </div>
    );
  }
  console.log("isPaymentType", isPaymentType);

  function renderBuyCredits() {
    return (
      <div className="create-campaign-subscription-main-container">
        <div style={{}} className="">
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <b>Checkout</b> &nbsp;
              <u
                onClick={() => creditPolicyOpen()}
                style={{ color: "#1977f2", cursor: "pointer" }}
              >
                How It Works?
              </u>
            </div>

            {subscriptionBasicDetail && (
              <div className="checkout-select-inner-container">
                <CheckoutParcel
                  credits={subscriptionBasicDetail?.credits}
                  user_data={user_data}
                  setCheckoutSummary={setCheckoutSummary}
                  fromState={fromState}
                  setIsPaymentType={setIsPaymentType}
                />
              </div>
            )}
          </div>
          <div className="m-t-16">
            <CustomText p text={"Payment Methods"} className="button2" />
            <div className="flex-row full-width gap-20 m-t-12">
              {paymentData.map((item, index) => {
                let isChecked = item.id == isPaymentType;
                return (
                  <PaymentTypeCheckout
                    label={item.title}
                    subText={item.sub_text}
                    value={index}
                    checked={isChecked}
                    onChange={() => handlePaymentChange(item.id)}
                    name="paymentType"
                  />
                );
              })}
            </div>
          </div>

          {/* {paymentRadioButton()} */}
          {checkoutSummary && (
            <div className="m-t-20">
              <CustomText p text={"Purchase Summary"} className="button2" />
              <div className="purchase-summary-inner-container  m-t-12 m-b-10">
                <CustomText p text={"Credits"} className="typographyH5" />
                <CustomText
                  p
                  text={checkoutSummary?.quantity}
                  className="typographyH5 "
                />
              </div>
              <div className="purchase-summary-inner-container  m-t-12 m-b-10">
                <CustomText p text={"Price"} className="typographyH5" />
                <CustomText
                  p
                  text={
                    checkoutSummary?.value
                      ? Utils.convertFromINR(
                          checkoutSummary?.value,
                          user_data.country
                        )
                      : Utils.convertFromINR(
                          checkoutSummary?.actual_value,
                          user_data.country
                        )
                  }
                  className="typographyH5 "
                />
              </div>

              {checkoutSummary?.actual_value && (
                <div className="purchase-summary-inner-container   m-t-12 ">
                  <div className="flex-row-baseline">
                    <CustomText
                      p
                      text={`Active Subscription Discount (${subscriptionActiveData?.feature?.discount_on_credits}%)`}
                      className="typographyH5"
                    />
                    {/* <CustomText
                    p
                    text={`(${props.active_subscription_data?.feature?.discount_on_credits}%)`}
                    className="caption_2_10 "
                  /> */}
                  </div>
                  <CustomText
                    p
                    text={Utils.convertFromINR(
                      checkoutSummary?.actual_value - checkoutSummary?.value,
                      user_data.country
                    )}
                    className="typographyH5 "
                  />
                </div>
              )}
              <div className="dash-border-bottom m-b-10 m-t-16"></div>
              <div className="purchase-summary-inner-container p-b-10 ">
                <CustomText p text={`Sub Total`} className="typographyH5 " />

                <CustomText
                  p
                  text={Utils.convertFromINR(
                    checkoutSummary?.value,
                    user_data.country
                  )}
                  className="typographyH5 "
                />
              </div>
              {}
              <div className="purchase-summary-inner-container dash-border-bottom p-b-20">
                {isPaymentType == "online_payment" && (
                  <>
                    {" "}
                    <div className="flex-row-baseline">
                      <CustomText
                        p
                        text={`GST (${checkoutSummary.gst}%)`}
                        className="typographyH5 "
                      />
                    </div>
                    <CustomText
                      p
                      text={Utils.convertFromINR(
                        checkoutSummary.gst_value,
                        user_data.country
                      )}
                      className="typographyH5"
                    />
                  </>
                )}
              </div>
              {!org_deep_data?.org_data?.gst_number && (
                <div onClick={openModalGTS} className="m-t-8">
                  <u className="add-gts-number">Add GST Number</u>
                </div>
              )}

              <div className="purchase-summary-inner-container m-t-16">
                <CustomText p text={"Total "} className="typography14 " />
                <CustomText
                  p
                  text={totalBuyCredit()}
                  className="typographyH5 "
                />
              </div>
            </div>
          )}

          <div className="signin-right-side-top-container-div-5 text-align">
            <PrimaryCta
              className="login-right-side-input-field"
              fullWidth100
              onClick={() => createOrderCondition()}
              fontSize={"font_size_large"}
              text={`Pay ${totalBuyCredit()}`}
              //   onClick={brandLogin}
              isLoading={isLoading}
            />
          </div>
        </div>
        {addGSTModalOpen && <UpdateGSTModal closeModal={closeModalGST} />}
      </div>
    );
  }
  function creditPolicyOpen() {
    setCreditPolicySidebarOpen(true);
  }
  function creditPolicyClose() {
    setCreditPolicySidebarOpen(false);
  }

  function reelaxCreditPolicy() {
    return (
      <Sidebar
        sidebarWidth={"40%"}
        isOpen={creditPolicySidebarOpen}
        onClose={creditPolicyClose}
        Header={creditPolicySidebarHeader}
      >
        <div className="p-20">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <h2 className="credit-terms-conditions">Campaign Creation</h2>
            </div>
            <div
              style={{
                borderRadius: "5px",
                backgroundColor: "#e8f1fe",
                padding: "5px 7px",
              }}
            >
              10 Credit
            </div>
          </div>

          <div
            style={{
              backgroundColor: "rgb(248, 250, 252)",
              borderRadius: "5px",
            }}
          >
            <p style={{ marginTop: "10px", padding: "10px" }}>
              Creating a campaign will deduct 10 credit from your account. The
              campaign will remain online in the marketplace for 1 month,
              accessible to influencers.
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <div>
              <h2 className="credit-terms-conditions">Campaign Renewal</h2>
            </div>
            <div
              style={{
                borderRadius: "5px",
                backgroundColor: "#e8f1fe",
                padding: "5px 7px",
              }}
            >
              10 Credit
            </div>
          </div>

          <div
            style={{
              backgroundColor: "rgb(248, 250, 252)",
              borderRadius: "5px",
            }}
          >
            <p style={{ marginTop: "10px", padding: "10px" }}>
              Renewing an existing campaign will deduct 10 credit. The campaign
              will remain live in the marketplace for an additional 1 month.
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <div>
              <h2 className="credit-terms-conditions">Request Price</h2>
            </div>
            <div
              style={{
                borderRadius: "5px",
                backgroundColor: "#e8f1fe",
                padding: "5px 7px",
              }}
            >
              1 Credit
            </div>
          </div>

          <div
            style={{
              backgroundColor: "rgb(248, 250, 252)",
              borderRadius: "5px",
            }}
          >
            <p style={{ marginTop: "10px", padding: "10px" }}>
              When you request a price from an influencer, 1 credit will be
              deducted. This allows the influencer to receive the campaign
              invite and provide their asking price.
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <div>
              <h2 className="credit-terms-conditions">Counter Offer</h2>
            </div>
            <div
              style={{
                borderRadius: "5px",
                backgroundColor: "#e8f1fe",
                padding: "5px 7px",
              }}
            >
              1 Credit
            </div>
          </div>

          <div
            style={{
              backgroundColor: "rgb(248, 250, 252)",
              borderRadius: "5px",
            }}
          >
            <p style={{ marginTop: "10px", padding: "10px" }}>
              Submitting a counteroffer to an influencer will deduct 1 credit.
              The influencer will either accept or reject the counteroffer,
              facilitating smooth negotiations.
            </p>
          </div>
        </div>
      </Sidebar>
    );
  }

  const creditPolicySidebarHeader = () => (
    <CustomText
      p
      text={"Reelax Credit Usage Terms"}
      className="side-bar-heading-text"
    />
  );

  function paymentRadioButton() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          // alignItems: "center",
          gap: "13px",
          marginTop: "15px",
          fontSize: "14px",
          color: "#3A3B43",
        }}
      >
        <div className="m-w-85" style={{ cursor: "pointer" }}>
          <CustomRadioButton
            name="online_payment"
            value="online_payment"
            checked={isPaymentType === "online_payment"}
            label="Online payment"
            onChange={handlePaymentChange}
          />
        </div>
        <div className="m-w-85" style={{ cursor: "pointer" }}>
          <CustomRadioButton
            name="Reelax_wallet"
            value="reelax_wallet"
            checked={isPaymentType === "reelax_wallet"}
            label={`Reelax wallet ( ${Utils.convertFromINR(
              org_deep_data.org_data.balance,
              user_data?.country
            )} )`}
            onChange={handlePaymentChange}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <HeaderShare showGoBackIcon={true} onGoBack={() => navigate("/")} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
        className="checkout-screen-main-container"
      >
        {loading ? (
          <div className="fall-back-center-campaign">
            <Loader />
          </div>
        ) : (
          <>
            {/* {renderCheckoutHeader()} */}
            <div className="checkout-screen">
              {!plan_id && renderBuyCredits()}

              {plan_id && renderCheckoutSummary()}
            </div>
          </>
        )}
        {reelaxCreditPolicy()}
      </div>
    </>
  );
}
export default CheckoutPayment;
