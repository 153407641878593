import React, { useState } from "react";

import Modal from "react-modal";
import { CustomText, Popup, PrimaryCta } from "../../components";
import Core from "../../common/clientSdk";
import { Loader } from "../loader";

import Utils from "../../common/utils";
import Icon from "../../components/core_component/icon";
import { useSnackbar } from "react-simple-snackbar";

import Multiselect from "multiselect-react-dropdown";
import * as CampaignData from "../../common/data/campaign";
import Style from "../../resources/style";

import { ToastContainer, toast } from "react-toastify";

function EditDemographicsScreen({
  audience_data,
  influencer_data,
  campaignId,
  campaign_data,
  getCampaignUserDetail,
  ...props
}) {
  let cate = Object.values(influencer_data.categories).map((item) => item.id);

  const [minFollower, setMinFollower] = useState(
    influencer_data.minimum_followers
  );
  const [categoryList, setCategoryList] = useState([]);

  // const [audienceAgeGroup, setAudienceAgeGroup] = useState(audience_data.age);
  const [influencerGender, setInfluencerGender] = useState(
    audience_data.gender
  );
  const [contentLanguage, setContentLanguage] = useState(
    audience_data.language
  );
  const [state, setState] = useState(
    audience_data.state && JSON.parse(audience_data.state)
  );
  const [openSnackbar] = useSnackbar();
  const [isLoading, setLoading] = useState(false);

  function updateProgress(status) {
      setLoading(status);
  }

  function getCategoryList() {
    var params = {};
    Core.getCategory(
      getCategorySuccess,
      getCategoryFail,
      updateProgress,
      params
    );
  }

  function getCategorySuccess(response) {
    setCategoryList(Object.values(response["category_list"]));
  }

  function getCategoryFail(errorList) {
    toast.info(errorList.map((item) => item.m).join(", "));
  }

  function updateDemographics() {
    if (!formValidate()) {
      return false;
    }
    let newCampaignData = {
      audience_data: {
        // age: audienceAgeGroup,
        gender: influencerGender,
        language: contentLanguage,
      },
      influencer_data: {
        minimum_followers: Number(minFollower),
      },
    };

    newCampaignData["audience_data"]["state"] = JSON.stringify(state);

    var params = {
      campaign_deep_data: JSON.stringify(newCampaignData),
      campaign_id: campaignId,
    };
    // if (contentLanguage.length != 0) {
    //   params.campaign_deep_data = JSON.stringify({
    //     audience_data: {
    //       language: contentLanguage,
    //     },
    //   });
    // }
    Core.postUpdateCampaign(
      updateDemographicsSuccess,
      updateDemographicsFail,
      updateProgress,
      params
    );
  }

  function updateDemographicsSuccess(response) {
    toast.success("Demographics updated Successfully");
    props.setIsPopupOpen(false);
    setLoading(false)
    getCampaignUserDetail();
  }

  function updateDemographicsFail(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  function formValidate() {
    // if (audienceAgeGroup.length == 0) {
    //   toast("Please select at least one age Group");
    //   return false;
    // }
    if (!influencerGender) {
      toast("Please select gender");
      return false;
    }

    if (contentLanguage.length == 0) {
      toast("Please select atleast one language");
      return false;
    }

    if (campaign_data.type == "social_media" && Number(minFollower) <= 999) {
      toast("Please enter more than 1000 followers");
      return false;
    }

    return true;
  }

  function updateAudienceAgeGroup(selectedList, selectedItem) {
    var value = [];
    selectedList.forEach((item) => {
      value.push(item.id);
    });

    // setAudienceAgeGroup(value);
  }

  function updateGender(selectedItem) {
    setInfluencerGender(
      selectedItem ? selectedItem.map((option) => option.id) : []
    );
  }

  function updateLanguage(selectedList, selectedItem) {
    var value = [];
    selectedList.forEach((item) => {
      value.push(item.id);
    });

    setContentLanguage(value);
  }

  function updateInfluencer(selectedList, selectedItem) {
    var value = [];
    selectedList.forEach((item) => {
      value.push(item.id);
    });

    setMinFollower(value);
  }

  function updateAddress(selectedList, selectedItem) {
    var value = [];
    selectedList.forEach((item) => {
      value.push(item?.id);
    });

    setState(value);
  }

  const cityId = audience_data?.state && JSON.parse(audience_data?.state);
  const cityNames = cityId && cityId?.map((id) => Utils.getLabelByIdA(id));

  return (
    <>
      <Popup
        isOpen={props.isPopupOpen}
        onClose={() => props.setIsPopupOpen(false)}
        headerText="Edit Demographics"
        footerButton1Text="Cancel"
        footerButton1Action={() => props.setIsPopupOpen(false)}
        footerButton2Text="Update"
        footerButton2Action={(updateProgress) =>
          updateDemographics(updateProgress)
        }
        isLoading={isLoading}
        // useBottomSheet={false}
        width={"480px"}
        // height={"400px"}
      >
        <div>
          <div className="edit-campaign-popup-p">
            <div className="edit-campaign-popup-c">
              <div className=" m-b-10">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Content Language"}
                  textAlign={"text_align_left"}
                />
              </div>
              <div>
                <Multiselect
                  selectedValues={Object.values(
                    Utils.getSelectedLanguageObjects(audience_data.language)
                  )}
                  placeholder="Choose language"
                  id="css_custom"
                  options={Object.values(CampaignData.LANGUAGE)}
                  onSelect={(selectedList, selectedItem) =>
                    updateLanguage(selectedList, selectedItem)
                  }
                  onRemove={(selectedList, selectedItem) =>
                    updateLanguage(selectedList, selectedItem)
                  }
                  selectionLimit={3}
                  singleSelect={false}
                  displayValue={"n"}
                  showArrow={false}
                  style={{
                    chips: Style.chips,
                    option: Style.option,
                    optionContainer: Style.optionContainer,
                    multiselectContainer: Style.multiselectContainerEdit,
                    searchBox: Style.searchBox,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="edit-campaign-popup-p">
            <div className="edit-campaign-popup-c">
              <div className=" m-b-10">
              <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Creator's Gender"}
                  textAlign={"text_align_left"}
                />
              </div>
              <div>
              <Multiselect
                placeholder="Choose gender"
                id="css_custom1"
                selectedValues={Object.values(
                  Utils.getSelectedGenderObjects([audience_data.gender])
                )}
                options={Object.values(CampaignData.GENDER)}
                onSelect={(selectedList, selectedItem) =>
                  updateGender(selectedList, selectedItem)
                }
                onRemove={(selectedList, selectedItem) =>
                  updateGender(selectedList, selectedItem)
                }
                //  disable={false}
                singleSelect={true}
                hidePlaceholder={true}
                displayValue={"n"}
                showArrow={false}
                style={{
                  chips: Style.chips,
                  option: Style.option,
                  optionContainer: Style.optionContainerGender,
                  multiselectContainer: Style.multiselectContainerEdit,
                  searchBox: Style.searchBox,
                }}
              />
              </div>
            </div>
          </div>
          <div className="edit-campaign-popup-p">
            <div className="edit-campaign-popup-c">
              <div className=" m-b-10">
              <CustomText
                  p
                  text={`Location`}
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  textAlign={"text_align_left"}
                />
              </div>
              <div>
              <Multiselect
                  selectedValues={cityNames}
                  placeholder="Select Location"
                  id="css_custom"
                  options={CampaignData.CITY}
                  onSelect={(selectedList, selectedItem) =>
                    updateAddress(selectedList, selectedItem)
                  }
                  onRemove={(selectedList, selectedItem) =>
                    updateAddress(selectedList, selectedItem)
                  }
                  singleSelect={false}
                  displayValue={"label"}
                  showArrow={false}
                  style={{
                    chips: Style.chips,
                    option: Style.option,
                    multiselectContainer: Style.multiselectContainerEdit,
                    searchBox: Style.searchBox1,
                    singleChip: {
                      padding: "0px 0px",
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </Popup>
    </>
  );
}

export default EditDemographicsScreen;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "20px 15px",
    transform: "translate(-50%, -50%)",
    background: "var(--general-secondary-color)",
    borderColor: "var( --blue-blue-500)",
    textAlign: "center",
    zIndex: "5",
    minHeight: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    minWidth: 352,
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.86)",
    zIndex: 10,
  },
};
