import React, { useState } from "react";
// import SideMenu from "../sideMenu";
import InfluencerDetailPage from "./influencerDetailPage";
import { useParams,useNavigate } from "react-router-dom";
import Header from "../header";
import HeaderShare from "../../modal/HeaderShare";

function InfluencerDetailMainPage() {
  const influencer_id = useParams().id;
const navigate = useNavigate();
  console.log("useParams", useParams());

  return (
    <div className="">
      {/* <SideMenu /> */}
      <div className="" style={{position:"fixed",top:0,backgroundColor:"white",zIndex:100,paddingBottom:10}}>
        <HeaderShare showGoBackIcon={false} onGoBack={() => navigate("/")} />
        </div>
        <div className="">
          <InfluencerDetailPage influencer_id={influencer_id}  />
        </div>
      
    </div>
  );
}

export default InfluencerDetailMainPage;
