import React from "react";
import { useState, useEffect, useRef } from "react";
import {
  ContentDropdown,
  ContentPostedDropdown,
  CustomText,
  Video,
  PostTypeDropdown,
  PrimaryCta,
  SortByDropdown,
  SearchFallBack,
  FallBackBtn,
} from "../../components";
import html2canvas from "html2canvas";
import { InstagramEmbed, YouTubeEmbed, XEmbed } from "react-social-media-embed";
import Modal from "react-modal";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Image } from "../../components";
import exportToCSV from "../../common/exportToCSV";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Utils from "../../common/utils";
import Icon from "../../components/core_component/icon";
import Core from "../../common/clientSdk";
import CustomTable from "../../components/customTable";
import { FallBack } from "../../components";
import { Loader } from "../loader";
import InfluencerDetailModal from "../../modal/influencerDetialModal";
import toast from "react-hot-toast";

const CONTENT_TYPE_MAP = {
  fxt1: "Instagram Image Post",
  ge03: "Instagram Video Post",
  utio: "Instagram Image Story",
  uyts: "Instagram Video Story",
  o5oi: "Instagram Reel",
  ttsn: "Youtube Video",
  vi82: "Youtube Shorts",
};

const InfluencerPage = (props) => {
  const [assignmentList, setAssignmentList] = useState([]);
  const [start, setStart] = useState(0);
  const [hoveredIcons, setHoveredIcons] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const navigate = useNavigate();
  const [searchName, setSearchName] = useState("");
  const [isDownloadOpen, setIsDownloadOpen] = useState(false);
  const [hovered, setHovered] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [influencerFlyOut, setInfluencerFlyOut] = useState(false);
  const [fullAssignmentList, setFullAssignmentList] = useState([]);
  const [selectedPostTypes, setSelectedPostTypes] = useState([]);
  const [influencerId, setInfluencerId] = useState(null);
  const [generateReportLoading, setGenerateReportLoading] = useState(false);
  const campaignId = props.campaignId;
  const [reportStatus, setReportStatus] = useState(null);
  const filteredAssignments = assignmentList?.filter((assignment) => {
    if (selectedPostTypes?.length === 0) return true;
    return Object.values(assignment?.contents_deep_data).some((data) =>
      selectedPostTypes.includes(
        CONTENT_TYPE_MAP[data?.content_data?.content_type_id]
      )
    );
  });

  const redux_selector = useSelector((state) => {
    return {
      UserData: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const { UserData = {} } = redux_selector;
  const { brand_deep_data = {} } = UserData || {};

  useEffect(() => {
    if (searchName.trim() === "") {
      setAssignmentList(fullAssignmentList);
    } else {
      const filteredData = fullAssignmentList.filter((item) =>
        item?.influencer_data?.name
          ?.toLowerCase()
          .includes(searchName.toLowerCase())
      );
      setAssignmentList(filteredData);
    }
  }, [searchName, fullAssignmentList]);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };
  const pdfRef = useRef();

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    campaignReportStatus("initial_Load");
  }, [campaignId]);

  const collectionData = useParams().id;
  let params = new URLSearchParams(collectionData);

  function updateProgress(status) {
    setLoading(status);
  }
  function getReportGenerationCtaString() {
    console.log("reportStatus", reportStatus);
    if (reportStatus?.report_status == "IN_PROGRESS") {
      return "Refresh";
    } else {
      return "Regenerate Report";
    }
  }
  function campaignReportStatus(type) {
    let params = {
      campaign_id: campaignId,
    };
    Core.campaignReportStatus(
      type == "report_generation"
        ? generateCampaignReportStatusSuccess
        : (...args) => campaignReportStatusSuccess(type, ...args),
      campaignReportStatusFailed,
      updateProgress,
      params
    );
  }

  function campaignReportStatusSuccess(type, res, response) {
    setReportStatus(response.response);
    if (type == "initial_Load") {
      getAssignmentUser(start, updateProgress);
      return;
    }
    if (response?.response?.report_status === "IN_PROGRESS") {
      if(getReportGenerationCtaString() !=="Refresh"){
        toast.success("Please Wait for some time we are generating report");
      }      
    } else if (response?.response?.report_status === "COMPLETED") {
      getAssignmentUser(start, updateProgress);
    } else if (response?.response?.report_status === null) {
      toast.error("Please generate report");
    }
  }
  function generateCampaignReportStatusSuccess(res, response) {
    setReportStatus((prev) => {
      return {
        ...prev,
        report_status: "IN_PROGRESS",
      };
    });

    if (response?.response?.report_status === "IN_PROGRESS") {
      if(getReportGenerationCtaString() !=="Refresh"){
        toast.success("Please Wait for some time we are generating report");
      }
    } else {
      getAssignmentContentReport();
    }
  }

  function campaignReportStatusFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  function getAssignmentContentReport() {
    let params = {
      campaign_id: campaignId,
    };
    Core.getAssignmentContentReport(
      getAssignmentContentReportSuccess,
      getAssignmentContentReportFailed,
      (status) => setGenerateReportLoading(status),
      params
    );
  }

  function getAssignmentContentReportSuccess(response) {
    // toast.success('Please Wait for some time we are generating report')
  }
  function getAssignmentContentReportFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  const campaignDetail = props.campaignDetail;
  console.log("campaignDetail", campaignDetail);

  function getAssignmentUser() {
    var params = {
      campaign_id: campaignId,
    };

    Core.getAssignmentInfluencerList(
      getAssignmentUserSuccess,
      getAssignmentUserFailed,
      updateProgress,
      params
    );
  }

  function getAssignmentUserSuccess(res, response_data) {
    setFullAssignmentList(response_data.response);
    setAssignmentList(response_data.response);
    setLoading(false);
  }

  function getAssignmentUserFailed(errorList) {}

  const openModal = (item) => {
    setCurrentItem(item);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentItem(null);
  };

  const handleSearchChange = (e) => {
    setSearchName(e.target.value);
  };

  const platFormName = (platform_data, influencer_id) => {
    const handleMouseEnter = (index) => {
      const newHoveredIcons = [...hoveredIcons];
      newHoveredIcons[index] = true;
      setHoveredIcons(newHoveredIcons);
    };

    const handleMouseLeave = (index) => {
      const newHoveredIcons = [...hoveredIcons];
      newHoveredIcons[index] = false;
      setHoveredIcons(newHoveredIcons);
    };

    const handlePlatformClick = (url) => {
      window.open(url, "_blank");
      setHoveredIcons(new Array(platform_data?.length).fill(false));
    };

    return (
      <div style={{ marginTop: 2 }} className="flex-row">
        <div
          className={"platform-name-collection"}
          data-tooltip-id={`tooltip-${influencer_id}${platform_data?.platform_id}`}
          onMouseEnter={() =>
            handleMouseEnter(`${influencer_id}${platform_data?.platform_id}`)
          }
          onMouseLeave={() =>
            handleMouseLeave(`${influencer_id}${platform_data?.platform_id}`)
          }
          onClick={() =>
            handlePlatformClick(
              Utils.getSocialUrl(
                platform_data?.platform_id,
                platform_data?.identifier_id
              )
            )
          }
        >
          <CustomText
            p
            text={
              platform_data?.platform_id == "btll"
                ? `@${platform_data?.identifier_id}`
                : platform_data?.identifier_id
            }
            className="caption_2"
            textColor={"text_color_blue_500"}
          />
        </div>
      </div>
    );
  };

  const tableSchema = [
    {
      name: "Influencer Name",
      selector: (row) => (
        <div
          style={{ display: "flex", alignItems: "center", padding: "10px 0" }}
        >
          <Image
            fallBack={require("../../resources/images/fallback.png")}
            source={
              row?.influencer_data?.profile_picture
                ? Utils.generateInfluencerDpUrl(
                    row?.influencer_data?.influencer_id,
                    row?.influencer_data?.profile_picture
                  )
                : require("../../resources/images/fallback.png")
            }
            onClick={() => {
              setInfluencerId(row?.influencer_data?.influencer_id);
              toggleFlyoutDropdown();
            }}
            className="table-profile"
          />
          <div>
            <div
              className="influencer-table-profile"
              onClick={() => {
                setInfluencerId(row?.influencer_data?.influencer_id);
                toggleFlyoutDropdown();
              }}
            >
              {row?.influencer_data?.name}
            </div>
            <div>
              {platFormName(
                row?.platforms_data,
                row?.influencer_data?.influencer_id
              )}
            </div>
          </div>
        </div>
      ),
    },

    {
      name: "Deliverables",
      maxWidth: "150px",
      selector: (row) => {
        const count =
          Object.values(campaignDetail?.content_type_deep_data | {}).length ||
          0;
        const totalContents = Object.values(
          row?.contents_deep_data || {}
        ).length;

        return (
          <>
            <Icon iconName="playIcon" /> {totalContents} of {totalContents}
          </>
        );
      },
      omit: isMobile,
    },

    {
      name: "Total Cost",
      selector: (row) => row?.reward_data?.value?.amount,
      omit: isMobile,
    },
    {
      name: "Likes",
      maxWidth: "150px",
      selector: (row) => {
        const analyticsData = selectedPostTypes.length
          ? Object.values(row?.content_analytics || {}).find((data) =>
              selectedPostTypes.includes(
                CONTENT_TYPE_MAP[data?.content_type_id]
              )
            )
          : Object.values(row?.content_analytics || {})[0]; // Default to first item

        return campaignDetail?.campaign_data?.type !== "content"
          ? Utils.changeNumberFormate(analyticsData?.like_count) || ""
          : "NA";
      },
      omit: isMobile,
    },
    {
      name: "Comments",
      selector: (row) => {
        const analyticsData = selectedPostTypes.length
          ? Object.values(row?.content_analytics || {}).find((data) =>
              selectedPostTypes.includes(
                CONTENT_TYPE_MAP[data?.content_type_id]
              )
            )
          : Object.values(row?.content_analytics || {})[0];

        return campaignDetail?.campaign_data?.type !== "content"
          ? Utils.changeNumberFormate(analyticsData?.comment_count) || ""
          : "NA";
      },
      omit: isMobile,
    },
    {
      name: "CPE",
      selector: (row) => {
        const analyticsData = selectedPostTypes.length
          ? Object.values(row?.content_analytics || {}).find((data) =>
              selectedPostTypes.includes(
                CONTENT_TYPE_MAP[data?.content_type_id]
              )
            )
          : Object.values(row?.content_analytics || {})[0];

        return campaignDetail?.campaign_data?.type !== "content"
          ? !isNaN(Number(analyticsData?.cpe)) && analyticsData?.cpe !== null
            ? Number(analyticsData?.cpe).toFixed(2)
            : "0.00"
          : "NA";
      },
      sortable: true,
      omit: isMobile,
    },
    {
      name: "CPV",
      selector: (row) => {
        const analyticsData = selectedPostTypes.length
          ? Object.values(row?.content_analytics || {}).find((data) =>
              selectedPostTypes.includes(
                CONTENT_TYPE_MAP[data?.content_type_id]
              )
            )
          : Object.values(row?.content_analytics || {})[0];

        return campaignDetail?.campaign_data?.type !== "content"
          ? !isNaN(Number(analyticsData?.cpv)) && analyticsData?.cpv !== null
            ? Number(analyticsData?.cpv).toFixed(2)
            : "0.00"
          : "NA";
      },
      sortable: true,
      omit: isMobile,
    },

    {
      name: "View Post",
      cell: (data) => (
        <span style={{ display: "flex", justifyContent: "center" }}>
          <PrimaryCta
            invertSmall
            iconPosition="left"
            iconName="eyeIcon"
            text="View Post"
            onClick={() => openModal(data)}
          />
        </span>
      ),
    },
  ];

  const customStyle = {
    headRow: {
      style: {
        backgroundColor: "var(--general-secondary-color)", // Change header background color
        color: "var(--gray-gray-300)", // Change header text color
        fontSize: "16px",
        fontWeight: 600,
        lineHight: "19.2px",
        border: "none",
      },
    },
    headCells: {
      style: {
        padding: "10px 0px 10px 20px",
      },
    },
    rows: {
      style: {
        cursor: "pointer",
        fontSize: "16px",
        fontWeight: 400,
        lineHight: "19.2px",
        color: "var(--gray-gray-400)",
        "&:hover": {
          fontWeight: 500,
          color: "var(--gray-gray-500)",
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "20px",
      },
    },
  };

  function renderLoader() {
    return (
      <div className="fall-back-center-campaign">
        <Loader />
      </div>
    );
  }

  function renderFallback() {
    if (!isLoading && assignmentList?.length === 0) {
      return (
        <div className="fall-back-center-campaign">
          {searchName == "" ? (
            <FallBackBtn
              heading={"No Influencer have completed contract"}
              title={
                "Once Influencers complete contract their list will show here"
              }
              widthIcon={100}
              heightIcon={100}
              onClick={() => campaignReportStatus("report_generation")}
              text={campaignDetail?.campaign_data?.type !== "content"?"Generate Report":""}
              isLoading={generateReportLoading}
            />
          ) : (
            <SearchFallBack
              heading={"Your search did not match any results."}
              title={"Make sure all words are spelled correctly"}
              subTitle={"Try different keywords"}
            />
          )}
        </div>
      );
    } else {
      return <></>;
    }
  }

  function SearchBarActive() {
    if (searchActive) {
      setSearchActive(false);
      setSearchName(""); // Clear search input
      setAssignmentList(fullAssignmentList); // Reset to original list
    } else {
      setSearchActive(true);
    }
  }

  const downloadExcel = () => {
    setIsDownloadOpen(false);

    const data = assignmentList.map((item) => {
      let content_data;
      const selectedContent = selectedPostTypes.length
        ? Object.values(item?.contents_deep_data || {}).find((data) =>
            selectedPostTypes.includes(
              CONTENT_TYPE_MAP[data?.content_data?.content_type_id]
            )
          )
        : Object.values(item?.contents_deep_data || {})[0];
      if (item?.assignment_data?.type === "social_media") {
        content_data = Utils.constructContentMediaUrl(
          item?.assignment_data?.assignment_id,
          Object.values(item?.contents_deep_data)[0]?.content_data?.media_id
        );
      } else {
        content_data = "";
      }
      const analyticsData = selectedPostTypes.length
        ? Object.values(item?.content_analytics || {}).find((data) =>
            selectedPostTypes.includes(CONTENT_TYPE_MAP[data?.content_type_id])
          )
        : Object.values(item?.content_analytics || {})[0];

      return {
        "Influencer Name": item?.influencer_data?.name,
        "Platform Handle": Utils.getSocialUrl(
          item?.platforms_data?.platform_id,
          item?.platforms_data?.identifier_id
        ),
        Deliverables: Object.values(item?.contents_deep_data || {}).length,
        "Total Cost": item?.reward_data?.value?.amount,
        Likes:
          campaignDetail?.campaign_data?.type !== "content"
            ? Utils.changeNumberFormate(analyticsData?.like_count) || ""
            : "NA",
        Comments:
          campaignDetail?.campaign_data?.type !== "content"
            ? Utils.changeNumberFormate(analyticsData?.comment_count) || ""
            : "NA",
        CPE:
          campaignDetail?.campaign_data?.type !== "content"
            ? Utils.changeNumberFormate(analyticsData?.cpe) || ""
            : "NA",
        CPV:
          campaignDetail?.campaign_data?.type !== "content"
            ? Utils.changeNumberFormate(analyticsData?.cpv) || ""
            : "NA",
        URL: selectedContent?.content_data?.url || "N/A",
      };
    });

    exportToCSV(data, "InfluencersData");
  };

  const downloadPDF = () => {
    setIsDownloadOpen(false);
    const input = pdfRef.current;

    const loadAllImages = () =>
      Promise.all(
        Array.from(input.querySelectorAll("img")).map((img) => {
          if (img.complete) return Promise.resolve();
          return new Promise((resolve) => {
            img.onload = resolve;
            img.onerror = resolve; // Prevent blocking if image fails to load
          });
        })
      );

    loadAllImages().then(() => {
      html2canvas(input, { useCORS: true, scale: 2 }).then((canvas) => {
        const imageURL = canvas.toDataURL("image/png");
        const pdf = new jsPDF("l", "mm", "a3", true);

        // Page dimensions
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();

        // Image dimensions
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;

        // Define padding values (Adjust as needed)
        const paddingX = 20; // Left and right padding
        const paddingY = 20; // Top and bottom padding

        // Adjusted width and height with padding
        const availableWidth = pdfWidth - 2 * paddingX;
        const availableHeight = pdfHeight - 2 * paddingY;
        const ratio = Math.min(
          availableWidth / imgWidth,
          availableHeight / imgHeight
        );

        // Centering the image with padding
        const imgX = (pdfWidth - imgWidth * ratio) / 2;
        const imgY = paddingY; // Start image after top padding

        pdf.addImage(
          imageURL,
          "PNG",
          imgX,
          imgY,
          imgWidth * ratio,
          imgHeight * ratio
        );
        pdf.save("Influencer.pdf");
      });
    });
  };
  const handleDownload = (media_id, isVideo, assignment_id) => {
    const mediaUrl = Utils.constructContentMediaUrl(assignment_id, media_id);
    const newWindow = window.open(mediaUrl, "_blank");

    if (newWindow) {
      newWindow.focus();
    } else {
      alert("Please allow popups for this website");
    }
  };
  function socialMedialEmbed(item) {
    const contentData =
      selectedPostTypes.length > 0
        ? Object.values(item?.contents_deep_data || {}).find((data) =>
            selectedPostTypes.includes(
              CONTENT_TYPE_MAP[data?.content_data?.content_type_id]
            )
          )?.content_data
        : Object.values(item?.contents_deep_data || {})[0]?.content_data;
    const platform = Utils.socialMediaPlatform(contentData?.content_type_id);
    const mediaType = contentData?.media_id?.split(".").pop() === "mp4";

    return (
      <>
        {contentData?.url && (
          <div className="embed-container">
            {platform === "Instagram" && (
              <InstagramEmbed url={contentData?.url} width={325} />
            )}
            {platform === "youtube" && (
              <YouTubeEmbed url={contentData?.url} width={325} height={620} />
            )}
            {platform === "twitter" && (
              <XEmbed url={contentData?.url} width={325} />
            )}
          </div>
        )}
        {contentData?.media_id && mediaType && !contentData?.url && (
          <div className="download-content">
            <Video
              source={Utils.constructContentMediaUrl(
                item?.content_analytics[Object.keys(item.content_analytics)[0]]
                  ?.assignment_id,
                contentData?.media_id
              )}
              width={300}
              height={420}
              className="content-media"
            />
            <PrimaryCta
              fifth
              onClick={() =>
                handleDownload(
                  contentData?.media_id,
                  true,
                  item?.content_analytics[
                    Object.keys(item.content_analytics)[0]
                  ]?.assignment_id
                )
              }
              fontSize={"font_size_large"}
              text={"Download"}
              className="blue-button"
            />
          </div>
        )}
        {contentData?.media_id && !mediaType && !contentData?.url && (
          <div className="download-content">
            <Image
              source={Utils.constructContentMediaUrl(
                item?.content_analytics[Object.keys(item.content_analytics)[0]]
                  ?.assignment_id,
                contentData?.media_id
              )}
              width={300}
              height={420}
              className="content-media"
            />
            <PrimaryCta
              fifth
              onClick={() =>
                handleDownload(
                  contentData?.media_id,
                  false,
                  item?.content_analytics[
                    Object.keys(item.content_analytics)[0]
                  ]?.assignment_id
                )
              }
              fontSize={"font_size_large"}
              text={"Download"}
              className="blue-button"
            />
          </div>
        )}
      </>
    );
  }

  const toggleFlyoutDropdown = () => {
    setInfluencerFlyOut(!influencerFlyOut);
  };
  
  console.log("isLoading", isLoading);
  return (
    <div className="campaign-influencer-tab">
      <div className="campaign-influencer-tab-top-section">
        <div>
          {assignmentList?.length > 0 && (
            <div style={{ display: "flex",gap:5,alignItems:"baseline" }}>
              <div>
                <CustomText
                  p
                  text={`${assignmentList?.length} Influencer${
                    assignmentList?.length > 1 ? "s" : ""
                  }`}
                  className="campaign-influencer-tab-heading"
                />
              </div>
              <div style={{fontSize:12}}>
                {reportStatus?.report_generated_at !== null && (
                  <CustomText
                    p
                    text={`Last generated at: ${new Date(
                      reportStatus?.report_generated_at * 1000
                    ).toLocaleString()}`}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <div className="campaign-influencer-tab-top-section-right-side">
          <div
            className="flex-row"
            style={{ display: "flex", gap: 16, alignItems: "center" }}
          >
            {searchActive && (
              <div className="bid-search-bar">
                <div className="search-icon">
                  <Icon iconName="Search" height={18} width={18} />
                </div>
                <div>
                  <input
                    className="bid-search-campaign"
                    type="text"
                    placeholder="Search name/user name"
                    onChange={handleSearchChange}
                    value={searchName}
                  />
                </div>

                <div
                  onClick={() => SearchBarActive()}
                  className="search-filter-icon"
                >
                  <Icon iconName="crossIcon" height={10} width={10} />
                </div>
              </div>
            )}
            {!searchActive && (
              <div className="cursor-pointer" onClick={() => SearchBarActive()}>
                <Icon iconName={"Search"} height={18} width={18} />
              </div>
            )}
          </div>
          {assignmentList?.length > 0 && (
            <div>
              <PostTypeDropdown
                campaignList={assignmentList}
                selectedTypes={selectedPostTypes}
                setSelectedTypes={setSelectedPostTypes}
              />
            </div>
          )}

          {assignmentList?.length > 0 && (
            <div className="" style={{}}>
              <PrimaryCta
                invert
                text="Download"
                twoIcons
                leftIconName="downloadBlueIcon"
                rightIconName="blueDownArrow"
                padding="9px"
                onClick={() => setIsDownloadOpen(!isDownloadOpen)}
              />
              {isDownloadOpen && (
                <div
                  className="download-dropdown"
                  style={{
                    position: "absolute",
                    background: "#fff",
                    border: "1px solid #E6E7E8",
                    borderRadius: "4px",
                    marginTop: "4px",
                    listStyle: "none",
                    width: "180px",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    zIndex: 10,
                  }}
                >
                  <div
                    style={{
                      padding: "10px",
                      cursor: "pointer",
                      borderBottom: "1px solid #E6E7E8",
                      background: hovered === "excel" ? "#F0F3F9" : "#ffffff",
                    }}
                    onClick={downloadExcel}
                    onMouseEnter={() => setHovered("excel")}
                    onMouseLeave={() => setHovered(null)}
                  >
                    Download as Excel
                  </div>
                  <div
                    style={{
                      padding: "10px",
                      cursor: "pointer",
                      background: hovered === "pdf" ? "#F0F3F9" : "#ffffff",
                    }}
                    onClick={downloadPDF}
                    onMouseEnter={() => setHovered("pdf")}
                    onMouseLeave={() => setHovered(null)}
                  >
                    Download as PDF
                  </div>
                </div>
              )}
            </div>
          )}
          {(assignmentList?.length > 0 && campaignDetail?.campaign_data?.type !== "content") && (
            <div>
              <PrimaryCta
                invert
                text={getReportGenerationCtaString()}
                padding="11px 16px"
                onClick={() => {
                  getReportGenerationCtaString() == "Refresh"
                    ? campaignReportStatus()
                    : campaignReportStatus("report_generation");
                }}
                isLoading={generateReportLoading}
              />
            </div>
          )}
        </div>
      </div>
      <div className="campaign-influencer-tab-bottom-section" ref={pdfRef}>
        <div className="table-contract">
          <CustomTable
            className="CustomTable"
            tableSchema={tableSchema}
            tableData={filteredAssignments}
            rowPaddingTop={16}
            paginationPerPage={15}
            rowPaddingBottom={16}
            customStyles={customStyle}
            noDataComponent={renderFallback()}
            progressComponent={renderLoader()}
            progressPending={isLoading}
            pagination
          />
        </div>
      </div>
      <Modal
        style={CancelReminder}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
      >
        <div onClick={closeModal} className="close-btn-icon">
          <Icon iconName="CloseRoundButtonIcon" />
        </div>
        {currentItem && socialMedialEmbed(currentItem)}
      </Modal>
      {
        <InfluencerDetailModal
          influencerFlyOut={influencerFlyOut}
          toggleFlyoutDropdown={toggleFlyoutDropdown}
          influencer_id={influencerId}
          org_id={brand_deep_data.brand_data.org_id}
        />
      }
    </div>
  );
};
export default InfluencerPage;
const CancelReminder = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "var(--general-secondary-color)",
    textAlign: "center",
    zIndex: "5",
    height: "40%",
    display: "flex",
    flexDirection: "column",
    minWidth: "300px",
    minHeight: "500px",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.95)",
    zIndex: 10,
  },
};
