import React from "react";
import {
  CustomText,
  FallBack,
  CustomTextInput,
  PrimaryCta,
  Popup,
} from "../../components";

import { useState, useEffect } from "react";
import Core from "../../common/clientSdk";
import Modal from "react-modal";
import { Loader } from "../loader";
import Icon from "../../components/core_component/icon";
import Utils from "../../common/utils";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import campaign from "../../common/data/campaign";
import { useToast } from "../../features/toastContainer";

const customStylesModal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "var(--general-secondary-color)",
    borderColor: "var(--gray-gray-50)",
    textAlign: "center",
    zIndex: "5",
    minHeight: "40%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    paddingLeft: 20,
    width: "368px",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.86)",
    zIndex: 10,
  },
};

function PriceToCollectionInfluencer({
  closePriceModal,
  setCollectionInfluencerList,
  collectionId,
  influencerId,
  priceType,
}) {
  const [price, setPrice] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const showToast = useToast();
  var redux_selector = useSelector((state) => {
    return {
      UserData: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const { UserData = {} } = redux_selector;
  const { brand_deep_data = {} } = UserData;
  const { user_data = {} } = UserData;
  const { brand_data = {} } = brand_deep_data;
  function addPriceToCollectionInfluencer() {
    if (price < 1) {
      showToast("Please Add Price");
      return false;
    }
    const params = {
      collection_id: collectionId,
      influencer_id: influencerId,
    };
    if (priceType == "price") {
      params.client_price = Utils.convertToINR(price, user_data.country);
    }

    if (priceType == "internal_price") {
      params.internal_price = Utils.convertToINR(price, user_data.country);
    }
    Core.addPriceToCollectionInfluencer(
      addPriceToCollectionInfluencerSuccess,
      addPriceToCollectionInfluencerFail,
      (status) => setLoading(status),
      params
    );
  }

  function addPriceToCollectionInfluencerSuccess(response) {
    closePriceModal();
    setCollectionInfluencerList((prevState) => ({
      ...prevState,
      collection_influencer_list: prevState?.collection_influencer_list?.map(
        (influencer) =>
          influencer?.influencer_data?.influencer_id ===
          response.influencer_deep_data?.influencer_data?.influencer_id
            ? { ...influencer, ...response.influencer_deep_data } // Update only matching influencer
            : influencer // Keep unchanged if no match
      ),
    }));
  }

  function addPriceToCollectionInfluencerFail(errorList) {
    toast.error(errorList);
  }


  function handlePriceChange(e) {
    const newValue = e.target.value;
    if (newValue === "" || /^[0-9\b]+$/.test(newValue)) {
      setPrice(newValue);
      setError("");
    } else {
      setError("Only numeric values are allowed.");
    }
  }

  function renderAddPrice() {
    return (
      <>
        <Popup
          isOpen={true}
          onClose={() => closePriceModal()}
          headerText="Price"
          footerButton1Text="Cancel"
          footerButton1Action={() => closePriceModal()}
          footerButton2Text="Update"
          footerButton2Action={() => addPriceToCollectionInfluencer()}
          isLoading={loading}
          // useBottomSheet={false}
          width={"480px"}
        >
          {/* <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              background: "#ffffff",

              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 40,
              paddingTop: 15,
            }}
          >
            <div className="">
              <CustomText
                h5
                text={"Add Cost"}
                textColor={"text_grey_400_color"}
                className="m-b-20 typography-centerH7"
              />
              <div onClick={closePriceModal} className="close-btn-icon">
                <Icon iconName="CloseRoundButtonIcon" />
              </div>
            </div>
          </div> */}
          <div>
            <div className="m-t-10">
              <div style={{ textAlign: "center" }} className="flex-row">
                <CustomText
                  h5
                  fontSize={"font_size_large"}
                  text={priceType == "price" ? "Client Cost" : "Internal Cost"}
                  textColor={"text_grey_400_color"}
                  fontWeight={"Poppins_regular_large"}
                  textAlign={"text_align_left"}
                  className="m-b-8"
                />
              </div>
              <CustomTextInput
                invert
                className={"input-height-counter"}
                value={price}
                onChange={handlePriceChange}
                onWheel={(e) => e.target.blur()}
                placeHolder={"Cost"}
                type="text"
              />
              {error && <p style={{ color: "red", fontSize: 12 }}>{error}</p>}
            </div>

            {/* <div className="m-t-40">
              <PrimaryCta
                fifth
                onClick={() => addPriceToCollectionInfluencer()}
                fontSize={"font_size_large"}
                className="collection-create-btn-2"
                text={"Add Cost"}
                isLoading={loading}
                // disabled={props.disabled}
              />

              <button
                style={{ height: 44 }}
                className="confirm-btn-bid  m-l-10"
                onClick={closePriceModal}
              >
                Cancel
              </button>
            </div> */}
          </div>
        </Popup>
      </>
    );
  }

  return <>{renderAddPrice()}</>;

}

export default PriceToCollectionInfluencer;
