// Dependencies
import React, { useState, useEffect, Suspense } from "react";

// Import Redux State (Private scope)
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

// Navigation
import RootNavigator from "./navigation/root_navigation";

import * as Actions from "./redux/actions";

// Core Sdk (Private Scope)
import Core from "./common/clientSdk.js";

import Utils from "./common/utils.js";
import WebStorage from "./common/web_storage";
import {
  ORG_ID_KEY,
  BRAND_ID_KEY,
  DEVICE_TOKEN,
} from "./common/web_storage_keys";
import { Loader } from "./screens/loader";
import ReactGA from "react-ga";
import { Crisp } from "crisp-sdk-web";
import {
  requestPermission,
  onMessageListener,
  messaging,
  getNotificationToken,
} from "./common/firebase.js";
import addNotification, { Notifications } from "react-push-notification";
import NotificationPrompt from "./screens/common/notification.js";
import { isIOS } from "react-device-detect";
import campaign from "./common/data/campaign.js";
import { ToastProvider } from "./features/toastContainer.js";

// import "primereact/resources/themes/saga-blue/theme.css"; // theme can be changed
// import "primereact/resources/primereact.min.css";
// import "primeicons/primeicons.css";
const TRACKING_ID = "G-PTRN15HW4P";

ReactGA.initialize(TRACKING_ID);

const App = function (props) {
  var navigate = useNavigate();

  const [appLoaded, setAppLoaded] = useState(false);
  const [loading, set_loading] = useState(false);
  const [error, set_error] = useState(null);
  const [notification, setNotification] = useState({});
  const orgId = WebStorage.getDataFromWebStorage(ORG_ID_KEY);
  let deviceToken = WebStorage.getDataFromWebStorage(DEVICE_TOKEN);

  useEffect(() => {
    // requestPermission();
    // onMessageListener().then((payload) => {
    //   console.log("payload", payload);
    //   addNotification({
    //     title: payload.notification.title,
    //     message: payload.notification.body,
    //     // message: "This is a very long message",
    //     theme: "darkblue",
    //     duration: 5000,
    //     // native: true, // when using native, your OS will handle theming.
    //   });
    //   setNotification({
    //     title: payload.notification.title,
    //     body: payload.notification.body,
    //   });
    // });
  }, []);

  useEffect(() => {
    // if (data) {
    //   console.log("kkk", JSON.parse(data.data).notification_data);
    //   notificationType = JSON.parse(data.data).notification_type;
    //   console.log("notificationType", notificationType);
    //   campaignId = JSON.parse(data.data).notification_data.bid_data.campaign_id;
    //   console.log("campaignId", campaignId);
    // }
  }, []);

  if (process.env.NODE_ENV == "production") {
    console.log = () => {};
    console.warn = () => {};
    console.error = () => {};
  }

  useEffect(() => {
    userSelfData(updateProgress);

    Crisp.configure("51773738-d935-4511-ae8c-4b55d5e0ab46");
    Crisp.chat.hide();
  }, []);

  // Initialize Private Functions
  var redux_dispatch = useDispatch();

  function registerTokenNotification(deviceToken, org_id) {
    const params = {
      device_token: deviceToken,
      platform: "web",
      org_id: org_id,
    };
    Core.adminNotificationSend(
      registerTokenNotificationSuccess,
      registerTokenNotificationFail,
      () => {},
      params
    );
  }

  const handleNotificationClick = (payload) => {
    console.log("payload", JSON.parse(payload.data.data));

    let { notification_type = {} } = JSON.parse(payload.data.data);
    let { notification_data = {} } = JSON.parse(payload.data.data);
    let { bid_data = {} } = notification_data;
    let { assignment_data = {} } = notification_data;
    let { assignment_id = {} } = notification_data;

    let campaignId = bid_data.campaign_id;
    let contractId = assignment_data.assignment_id;
    if (notification_type == "ORG_BID") {
      window.location.href = `https://brands.getreelax.com/campaigns/${campaignId}`;
    }
    if (notification_type == "ORG_CONTENT_UPLOAD") {
      window.location.href = `https://brands.getreelax.com/contracts/${contractId}`;
    }
    if (notification_type == "INFLUENCER_MESSEGE") {
      window.location.href = `https://brands.getreelax.com/message/${assignment_id}`;
    }
  };

  function registerTokenNotificationSuccess(response_data) {
    onMessageListener().then((payload) => {
      console.log("payload", payload);
      addNotification({
        title: payload.notification.title,
        message: payload.notification.body,
        // message: "This is a very long message",
        theme: "darkblue",
        duration: 5000,
        onClick: () => handleNotificationClick(payload),

        // native: true, // when using native, your OS will handle theming.
      });
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
        data: payload.data,
      });
    });
  }
  function registerTokenNotificationFail() {}

  /********************************************************************
  Get User Data Self

  @param {Function} progress_cb - manage status of screen

  No return
  *********************************************************************/
  function userSelfData(progress_cb) {
    const orgId = WebStorage.getDataFromWebStorage(ORG_ID_KEY);
    var params = { org_id: orgId };

    Core.userSelfData(
      userSelfDataSuccess,
      userSelfDataFailed,
      progress_cb,
      params
    );
  }

  /********************************************************************
  Brand Login success

  @param {Set} response_data - consisting user_data and brand_data

  No return
  *********************************************************************/

  // Function to find the brand with a specific brandId
  function findBrandById(brandId, brandList) {
    for (const brandData of brandList) {
      if (brandData.brand_data.brand_id === brandId) {
        return brandData;
      }
    }
    return null; // Return null if the brandId is not found
  }

  function userSelfDataSuccess(response_data) {
    const brandId = WebStorage.getDataFromWebStorage(BRAND_ID_KEY);
    const foundBrand = findBrandById(brandId, response_data.brand_list);
    const { org_data } = response_data.org_data;
    const { org_id } = org_data;

    // let new_user_data = {
    //   member_role: "campaign_manager",
    //   translation_key: true,
    //   wallet: true,
    //   campaign_list: true,
    //   influencer: true,
    //   contract: true,
    //   payment: true,
    //   setting: true,
    //   notification: true,
    //   report: true,
    //   brand: true,
    //   invite_team: true,
    //   manage_subscription: true,
    //   creatives: true,
    //   lists: false,
    //   dashboard: true,
    //   database: true,
    //   proposal: false,
    // };

    // if (new_user_data.lists) {
    //   navigate("/");
    // }

    // response_data.user_data.user_data.push();

    // Object.assign(response_data.user_data, new_user_data);

    //update brand user data in redux
    redux_dispatch({
      type: Actions.RULE_AND_PERMISSIONS,
      payload: {
        roles_and_permission: response_data.roles_and_permission,
        all_permissions: response_data.all_permissions,
      },
    });
    //update brand user data in redux
    redux_dispatch({
      type: Actions.UPDATE_BRAND_USER_DATA,
      payload: { user_data: response_data.user_data },
    });

    redux_dispatch({
      type: Actions.UPDATE_ORG_DATA,
      payload: { org_deep_data: response_data.org_data },
    });

    if (foundBrand) {
      // update brand data in redux
      redux_dispatch({
        type: Actions.UPDATE_BRAND_DATA,
        payload: { brand_deep_data: foundBrand },
      });
    } else {
      // update brand data in redux
      redux_dispatch({
        type: Actions.UPDATE_BRAND_DATA,
        payload: { brand_deep_data: response_data.brand_list[0] },
      });
    }
    // Update Auth token
    redux_dispatch({
      type: Actions.UPDATE_BRAND_USER_AUTH,
      payload: { auth_token: response_data.auth_id },
    });

    setAppLoaded(true);
    if (response_data.brand_list.length == 0) {
      // navigate("/add-brand-details");
    }
    if (
      window.Notification &&
      window.Notification.permission == "granted" &&
      messaging
    ) {
      getNotificationToken(
        messaging
        //    {
        //   vapidKey:
        //     "BNO86OR7dp1ui8MCxZ1hPeyxazD-jojQOJx2n6pF3rckWDvXbiHlIasBmJ81snYvHtIfP1sswXQY_8iWuPNb6rw",
        // }
      )
        .then((currentToken) => {
          if (currentToken) {
            registerTokenNotification(currentToken, org_id);
          } else {
            console.log("Failed to generate token");
          }
        })
        .catch((err) => {
          console.log("ERROR ", err);
        });
    }

    // } else {
    // navigate("/");
    // }
  }

  /********************************************************************
  Brand Login Failed

  @param {Array} error_list - consisting Error List

  No return
  *********************************************************************/
  function userSelfDataFailed(error) {
    set_error(error);
    // if (error_list) {
    //   error_list.err_l.forEach((item, index) => {
    //     set_error(item.m);
    //     return;
    //   });
    // }
    setAppLoaded(true);

    const nonLoginRoute = [
      "/signup",
      "/login",
      "/verify-email",
      "/forgot-password",
      "/privacy-policy",
      "/terms",
      "/influencer-detail",
      "/collection/share",
      "/contracts/share",
      "/credit-policy",
      "/contract-policy",
    ];

    let checkPath = window.location.pathname.split("/");
   console.log("checkPath",checkPath)
    if (checkPath.length >= 3) {
      checkPath.pop();
      checkPath = checkPath.join("/");
    }

    if (
      !nonLoginRoute.includes(window.location.pathname) &&
      !nonLoginRoute.includes(checkPath)
    ) {
      navigate("/");
    }
  }

  /********************************************************************
  Screen Progress

  @param {Boolean} status - true/false

  No return
  *********************************************************************/
  function updateProgress(status) {
    set_loading(status);
  }

  // Return App
  return (
    <>
      {!isIOS && <NotificationPrompt />}

      <Notifications position="top-right" />

      {loading ? (
        <div className="loader-mid">
          <Loader />
        </div>
      ) : (
        appLoaded && (
          <>
            <ToastProvider>
              {" "}
              <RootNavigator />
            </ToastProvider>
          </>
        )
      )}
    </>
  );
};

export default App;
