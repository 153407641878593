import React, { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { CustomText, CampaignListDropdown } from "../components";
import Core from "../common/clientSdk.js";
import SideMenu from "./sideMenu";
import Header from "./header";
import MarketPlaceCampaign from "./campaignDetailsParent/marketPlaceCampaigns.js";
import InhouseCampaignCampaigns from "./campaignDetailsParent/inhouseCampaign.js";
import CustomTable from "../components/customTable/index.js";
import { isMobile } from "react-device-detect";
import Utils from "../common/utils.js";
import { PrimaryCta } from "../components";
import { Loader } from "./loader.js";
import { FallBack } from "../components";

const campaignStatusList = [
  "ALL",
  "IN_REVIEW",
  "ACTIVE",
  "COMPLETED",
  "INACTIVE",
];

const CampaignListingScreen = () => {
  const [activeTab, setActiveTab] = useState("market_place");
  const [marketplaceFilterStatus, setMarketplaceFilterStatus] = useState("ALL");
  const [inhouseFilterStatus, setInhouseFilterStatus] = useState("ALL");
  const [creditsDetail, setCreditsDetail] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [campaignList, setCampaignList] = useState([]);
  const [totalRows, setTotalRows] = useState(null);
  const [statusColors] = useState({
      ACTIVE: " #009A29",
      INACTIVE: "#8e8e93",
      COMPLETED: "#3a3b43",
      IN_REVIEW: "#FB9400",
    });
  const navigate = useNavigate();
  var redux_selector = useSelector((state) => {
    return {
      UserData: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const {
    user_data,
    brand_deep_data,
    org_deep_data = {},
  } = redux_selector.UserData;
  console.log("organisationType",org_deep_data)

  useEffect(() => {
      getCampaignListUser(marketplaceFilterStatus); // Pass filterStatus to API
    }, [marketplaceFilterStatus]);
  

  function getCampaignListUser(page) {
      setIsLoading(true);
      let newPage = 0;
      if (page == 10) {
        newPage = 0 * Number(page);
      } else {
        newPage = 10 * Number(page) - 10;
      }
  
      var params = {
        brand_id: redux_selector.UserData.brand_deep_data.brand_data.brand_id,
      };
      if (marketplaceFilterStatus != "ALL") {
        params.status = marketplaceFilterStatus;
      }
  
      Core.getCampaignListUser(
        getCampaignListUserSuccess,
        getCampaignListUserFailed,
        () => {},
        params
      );
    }
  
    function getCampaignListUserSuccess(response_data) {
      setCampaignList(response_data["campaign_list"]);
      setTotalRows(response_data?.pagination_data?.count);
      setIsLoading(false);
    }
     function getCampaignListUserFailed(errorList) {
        toast.info(errorList.map((item) => item.m).join(", "));
        setIsLoading(false);
      }

  function getCreditDetail(progress_cb) {
    Core.getCreditDetail(
      (response) => setCreditsDetail(response.credit_data),
      (errorList) => toast.info(errorList.map((item) => item.m).join(", ")),
      progress_cb,
      {}
    );
  }

  function createCampaign() {
    if (creditsDetail < 10) {
      toast.info("Not enough credits to create a campaign");
      return;
    }
    navigate("/create-campaign");
  }
  function renderLoader() {
    if (isLoading) {
      return (
        <div className="fall-back-center-campaign">
          <Loader />
        </div>
      );
    }
    return null;
  }
  function renderFallBack() {
    if (!isLoading && filterFunction()?.length == 0) {
      return (
        <div className="fallback">
          <FallBack
            heading={"No live campaigns"}
            title={"Launch a new campaign"}
          />
          <div className="m-t-15">
            <PrimaryCta
              third
              iconPosition="left"
              iconName="plusIcon"
              fontSize={"font_size_regular"}
              className="create-campaign"
              text={"Create Campaign"}
              onClick={() => createCampaign()}
            />
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  }
  function CampaignClick(campaignId) {
    navigate(`/campaigns/${campaignId}`);
  }
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "var(--general-secondary-color)", // Change header background color
        color: "var(--gray-gray-300)", // Change header text color
        fontSize: "16px",
        fontWeight: 600,
        lineHight: "19.2px",
        border: "none",
      },
    },
    headCells: {
      style: {
        padding: "10px 0px 10px 20px",
      },
    },
    rows: {
      style: {
        cursor: "pointer",
        fontSize: "16px",
        fontWeight: 400,
        lineHight: "19.2px",
        "&:hover": {
          backgroundColor: "var(--general-secondary-color)",
          fontWeight: 600,
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "20px",
      },
    },
  };
  const tableSchema = [
    {
      name: "Campaign Name",
      selector: (row) => row["campaign_data"]["name"],
      // sortable: true,
      cell: (data) => (
        <span
          style={{ textAlign: "left" }}
          onClick={(e) => {
            CampaignClick(data.campaign_data.campaign_id);
            e.stopPropagation();
          }}
        >
          {Utils.truncateWithEllipsis(data.campaign_data.name, 60)}
        </span>
      ),
    },
    {
      name: "Views",
      selector: (row) => row["campaign_data"]["seen_count"],
      // sortable: true,
      maxWidth: "121px",
      omit: isMobile,
    },
    {
      name: "Proposals",
      selector: (row) => row["campaign_data"]["bid_count"],
      // sortable: true,
      maxWidth: "121px",

      //cell: (data) => <span>{data.campaign_data.bid_count}</span>,
    },
    {
      name: "Contracts",
      selector: (row) => row["campaign_data"]["assignment_count"],
      // sortable: true,
      maxWidth: "121px",
      omit: isMobile,
      //cell: (data) => <span>{data.campaign_data.assignment_count}</span>,
    },
    {
      name: "Status",
      selector: (row) =>
        Utils.assignmentStatusToString(row["campaign_data"]["status"]),
      // sortable: true,
      maxWidth: "195px",
      omit: isMobile,
      cell: (data) => (
        <span
          style={{ color: statusColors[data.campaign_data.status] }}
          onClick={(e) => {
            CampaignClick(data.campaign_data.campaign_id);
            e.stopPropagation();
          }}
        >
          {Utils.assignmentStatusToString(data.campaign_data.status)}
        </span>
      ),
    },
    {
      name: "Type",
      selector: (row) => Utils.getCampaignType(row["campaign_data"]["type"]),
      // sortable: true,
      maxWidth: "149px",
      omit: isMobile,
    },
  ];
  const filterFunction = () => {
    return (campaignList ? campaignList: [])
  };
  return (
    <div className="dashboard-container background-color-primary">
      <SideMenu />
      <div className="main-screen">
        <Header />
        <div className="page-container-campaignList">
          <div className="campaignlist-header" style={{ paddingBottom: 20 }}>
            <CustomText p text="Campaigns" />
            <CampaignListDropdown
              dropdownItems={campaignStatusList}
              onItemClick={(item) => {
                if (activeTab === "market_place") {
                  setMarketplaceFilterStatus(item);
                } else {
                  setInhouseFilterStatus(item);
                }
              }}
            />
          </div>
          {org_deep_data?.org_data?.organization_type === "AGENCY" ?
          <div className={activeTab === "market_place" ? "proposal-detail-container" : "campaign-detail-container"}>
            <div className="campaign-detail-upper-container">
              <div className="campaign-detail-tab-header">
                <div
                  className={activeTab === "market_place" ? "active" : ""}
                  onClick={() => setActiveTab("market_place")}
                >
                  <div className="campaign-detail-btn-tabs">
                    <CustomText p fontSize={"font_size_extra_large"} text={"Market Place"} />
                  </div>
                </div>
                <div
                  className={activeTab === "inhouse" ? "active" : ""}
                  onClick={() => setActiveTab("inhouse")}
                >
                  <div className="campaign-detail-btn-tabs">
                    <CustomText p fontSize={"font_size_extra_large"} text={"In-House"} />
                  </div>
                </div>
              </div>
            </div>
            {activeTab === "market_place" && <MarketPlaceCampaign filterStatus={marketplaceFilterStatus} />}
            {activeTab === "inhouse" && <InhouseCampaignCampaigns filterStatus={inhouseFilterStatus} />}
          </div>
          :
          <div className="">
                <div className="custom-table">
                  <CustomTable
                    className="CustomTable"
                    tableSchema={tableSchema}
                    tableData={filterFunction() ? filterFunction() : []}
                    paginationPerPage={15}
                    rowPaddingTop={16}
                    rowPaddingBottom={16}
                    customStyles={customStyles}
                    onRowClicked={(item) => {
                      CampaignClick(item.campaign_data.campaign_id);
                    }}
                    noDataComponent={renderFallBack()}
                    progressComponent={renderLoader()}
                    progressPending={isLoading}
                    paginationServer
                    pagination
                  />
                </div>
              </div>
              }
        </div>
      </div>
    </div>
  );
};

export default CampaignListingScreen;
