import React, { useState } from "react";
import {
  CustomText,
  CustomTextInput,
  PrimaryCta,
  Sidebar,
} from "../../components";
import Multiselect from "multiselect-react-dropdown";
import Select from "react-dropdown-select";
import Style from "../../resources/style";
import * as CampaignData from "../../common/data/campaign";
import Core from "../../common/clientSdk";
import * as Config from "./../../common/data/config";
import { useToast } from "../../features/toastContainer";
import { ToastContainer,toast } from "react-toastify";

console.log("Config", Config.SOCIAL_MEDIA);

const options = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "all", label: "All" },
];

const platformTypes = [
  {
    platformName: "btll",
    label: "Instagram",
  },
  {
    platformName: "nxjo",
    label: "Youtube",
  },
];

function AddInfluencerSidebar(props) {
  const [addInfluencerProfession, setAddInfluencerProfession] = useState([]);
  const [country, setCountry] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState([]);
  const [platform, setPlatform] = useState({
    platform_id: "btll",
    label: "Instagram",
  });
  const [addInfluencerPlatformId, setAddInfluencerPlatformId] = useState([
    {
      platform_id: "btll",
      label: "Instagram",
    },
  ]);
  const [addInfluencerGender, setAddInfluencerGender] = useState([]);
  const [platformHandle, setPlatformHandle] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const showToast = useToast();

  function postAddNewInfluencer() {
    const params = {
      collection_id: props.collectionId,
      identifier_id: platformHandle,
      platform_id: platform.platform_id,
      influencer_phone: phoneNumber,
      influencer_gender: addInfluencerGender,
      influencer_country: country,
      profession: addInfluencerProfession[0]?.value,
    };

    if (validationAddInfluencer()) {
      Core.postAddInfluencer(
        postAddInfluencerSuccess,
        postAddInfluencerFail,
        (status) => setIsLoading(status),
        params
      );
      console.log("params", JSON.stringify(params));
    }
  }

  function postAddInfluencerSuccess(response) {
  props.setIsAddInfluencer(true);
  props.addInfluencerModalHandler();
  showToast("Influencer added successfully");
  setAddInfluencerProfession([]);
  setAddInfluencerGender([]);
  setPlatformHandle("");
  setPhoneNumber("");
  setCountry("");
}

function postAddInfluencerFail(errorList) {
  toast.error(errorList.map((item) => item.m).join(", "));
  } 

  function updatePlatform(value) {
    setAddInfluencerPlatformId(value);
    setPlatform(value ? value[0].platform_id : []);
  }
  function updateAddInfluencerGender(value) {
    setAddInfluencerGender(value ? value[0].value : []);
  }

  function addNewInfluencerProfession(selectedItem) {
    setAddInfluencerProfession(selectedItem);
    if (selectedItem.length !== 0) {
    }
    console.log("selectedList", selectedItem);
  }

  function validationAddInfluencer() {
    if (!platformHandle) {
      showToast("Please enter social handle", "error");
      return false;
    }
    if (!/^(?!.*\.\.)(?!.*\.$)[a-zA-Z0-9._]{1,30}$/.test(platformHandle)) {
      showToast("Invalid social handle format", "error");
      return false;
    }

    return true;
  }

  function getAllProfession() {
    let all_profession = [];
    Object.keys(CampaignData.FULL_CATEGORIES.subcategories).forEach((key) => {
      CampaignData.FULL_CATEGORIES.subcategories[key].forEach((item) => {
        let value = item["id"];
        let label = item["name"];
        all_profession.push({ value, label });
      });
    });
    return all_profession;
  }
  const addInfluencerSidebarHeader = () => <div>Add Influencer</div>;

  const influencerSidebarFooter = () => (
    <div style={{ display: "flex", flex: 1, gap: 20 }}>
      <div style={{ flex: 1 }}>
        <PrimaryCta
          invert
          onClick={() => {
            props.addInfluencerModalHandler();
          }}
          textColor={"white"}
          fontSize={"font_size_large"}
          text={"Close"}
          isLoading={""}
        />
      </div>
      <div style={{ flex: 1 }}>
        <PrimaryCta
          fullWidth={true}
          onClick={() => {
            postAddNewInfluencer();
          }}
          fontSize={"font_size_large"}
          text={"Add"}
          isLoading={isLoading}
          className="blue-button"
        />
      </div>
    </div>
  );
  function flyoutBidListFilter() {
    return (
      <Sidebar
        isOpen={props.isAddInfluencer}
        sidebarWidth={"480px"}
        onClose={props.addInfluencerModalHandler}
        Header={addInfluencerSidebarHeader}
        Footer={influencerSidebarFooter}
        headerAlignment="left"
      >
        <div className="p-20">
          <div className="edit-campaign-popup-p flex-column">
            <div className="edit-campaign-popup-c">
              <div style={{ gap: 5 }} className="m-b-5 flex-row">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Platform"}
                  textAlign={"text_align_left"}
                />
              </div>
              <Select
                multi={false}
                options={Config.SOCIAL_MEDIA}
                values={addInfluencerPlatformId}
                labelField="label"
                valueField="platform_id"
                onChange={(value) => updatePlatform(value)}
                placeholder="Select Platform"
                autoFocus={false}
                color="#c8c2c2"
                style={{
                  border: "1px solid rgb(230, 231, 232)",
                  zIndex: "100",
                  backgroundColor: "#fff",
                  borderRadius: 4,
                  paddingBottom: "12px",
                  paddingTop: "12px",
                  option: {
                    backgroundColor: "red",
                  },
                }}
              />
            </div>
            <div style={{ textAlign: "left" }}>
              <div style={{ gap: 5 }} className="m-b-5 flex-row">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Platform Handle"}
                  textAlign={"text_align_left"}
                />
              </div>
              <CustomTextInput
                className="platform-handle-input"
                type="text"
                value={platformHandle}
                placeholder="Platform Handle"
                onChange={(e) => {
                  setPlatformHandle(e.target.value);
                }}
              />
            </div>
            <div className="" style={{ textAlign: "left" }}>
              <div style={{ gap: 5 }} className="m-b-5 flex-row">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Phone Number(Optional)"}
                  textAlign={"text_align_left"}
                />
              </div>
              <div>
                <CustomTextInput
                  className="platform-handle-input"
                  type="text"
                  value={phoneNumber}
                  placeholder="Phone Number"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
            </div>
            <div className="">
              <div className="m-b-5">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Profession(Optional)"}
                  textAlign={"text_align_left"}
                />
              </div>
              <Select
                multi={false}
                options={getAllProfession()}
                values={addInfluencerProfession}
                onChange={(value) => addNewInfluencerProfession(value)}
                placeholder="Select profession"
                autoFocus={false}
                color="#c8c2c2"
                // Customcolor
                style={{
                  border: "1px solid rgb(230, 231, 232)",
                  backgroundColor: "#fff",
                  borderRadius: 4,
                  paddingBottom: "12px",
                  paddingTop: "12px",
                  marginTop: "10px",
                  option: {
                    backgroundColor: "red",
                  },
                }}
              />
            </div>
            <div className="" style={{ textAlign: "left" }}>
              <div style={{ gap: 5 }} className="m-b-5 flex-row">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Gender(Optional)"}
                  textAlign={"text_align_left"}
                />
              </div>

              <div>
                <Select
                  multi={false}
                  options={Config.INFLUENCER_GENDER}
                  values={addInfluencerGender}
                  // labelField="label"
                  // valueField="value"
                  onChange={(value) => updateAddInfluencerGender(value)}
                  placeholder="Select Gender"
                  autoFocus={false}
                  color="#c8c2c2"
                  // Customcolor
                  style={{
                    border: "1px solid rgb(230, 231, 232)",
                    backgroundColor: "#fff",
                    borderRadius: 4,
                    paddingBottom: "12px",
                    paddingTop: "12px",
                    option: {
                      backgroundColor: "red",
                    },
                  }}
                />
              </div>
            </div>
            <div className="" style={{ textAlign: "left" }}>
              <div style={{ gap: 5 }} className="m-b-5 flex-row">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Country(Optional)"}
                  textAlign={"text_align_left"}
                />
              </div>
              <div>
                <CustomTextInput
                  className="discovery-search-influencer"
                  type="text"
                  placeholder="Country"
                  onChange={(e) => setCountry(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </Sidebar>
    );
  }
  return <div>{flyoutBidListFilter()}</div>;
}

export default AddInfluencerSidebar;
