import { useState } from "react";
import { CustomButton, Image, PrimaryCta } from "../components";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { IconBase } from "react-icons";
import { FaArrowLeft } from "react-icons/fa";
import Icon from "../components/core_component/icon";

const HeaderShare = ({ showGoBackIcon, onGoBack }) => {
  const redux_selector = useSelector((state) => {
      return {
        UserData: state.BrandUserDataReducer,
        Auth: state.BrandUserAuthReducer,
      };
    });
  
    const { UserData = {} } = redux_selector;
    const { brand_deep_data = {} } = UserData || {};
    console.log("brand_deep_data",brand_deep_data)
  const ArrowBackIcon = (props) => {
    return (
      <IconBase {...props}>
        {" "}
        <FaArrowLeft />{" "}
      </IconBase>
    );
  };
  const navigate = useNavigate();

  return (
    <div className="secondary-header">
      <div
        className="header-left"
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          background:"white",
          
        }}
      >
        {showGoBackIcon && (
          <div onClick={onGoBack} className="go-back-icon cursor-pointer">
            <Icon iconName="backIcon" />
          </div>
        )}
        <div
          onClick={() => navigate("/")}
          className="header-logo cursor-pointer"
        >
          <Image
            source={"../../assets/images/reelax-new-logo.svg"}
            height="40px"
          />
        </div>
      </div>

      {/* <div className="header-right">
        <div className="create-campaign-button">
          <CustomButton
            onClick={() =>
              (window.location.href = "https://calendly.com/reelax/onboarding")
            }
            fontSize={"font_size_small"}
            text={"Request demo"}
            borderRadius={4}
            padding={{ top: 8, right: 18, bottom: 8, left: 18 }}
            textColor={"text_color_light"}
            background={"#1777f1"}
            border={"none"}
            boxShadow={"none"}
            hoverColor={"var(--hover-color, #1977F2)"}
          />
        </div>
      </div> */}
      {!brand_deep_data?.brand_data?.org_id && <div style={{marginRight:20}}>
        <PrimaryCta
        fifth
        text="Login"
        onClick={()=>{window.location.href = "https://brands.getreelax.com"}}
        />
      </div>
      }      
    </div>
  );
};

export default HeaderShare;
