import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  CustomText,
  Sidebar,
  PrimaryCta,
  NotificationBanner,
} from "../../components";
import InfluencerProfile from "./influencerProfile";
import InfluencerProfileTab from "./influencerProfileTab";
import InfluencerInstagramTab from "./influencerInstagramTab";
import InfluencerYoutubeTab from "./influencerYoutubeTab";
import Core from "../../common/clientSdk";
import SideMenu from "../sideMenu";
import Icon from "../../components/core_component/icon";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import Utils from "../../common/utils";
import * as Actions from "../../redux/actions";
import { Loader } from "../loader";
import { useToast, } from "../../features/toastContainer";
import toast from "react-hot-toast";
function InfluencerDetailPage(props) {
  const navigate = useNavigate();
  const location = useLocation();

  // Get active tab from URL query params
  const searchParams = new URLSearchParams(location.search);
  const initialTab = searchParams.get("page") || "profile"; // Default to "profile"
  const [influencerData, setInfluencerData] = useState();
  const [categoryListData, setCategoryListData] = useState([]);
  const [platformsData, setPlatformsData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(initialTab);
  const [influencerContentList, setInfluencerContentList] = useState([]);
  const [influencerContentInstagramList, setInfluencerContentInstagramList] =
    useState([]);
  const [contactModalIsOpen, setContactModalIsOpen] = useState(false);
  const [checkOrgSub, setCheckOrgSub] = useState({});
  const [getContactDetails, setGetContactDetails] = useState([]);
  const [contactRequest, setContactRequest] = useState(true);
  const [isLoadingContact, setLoadingContact] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [contentLoading, setContentLoading] = useState(true);
  const showToast = useToast();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setInfluencerContentInstagramList([]);
    setInfluencerContentList([]);
    if (props?.influencer_id) {
      getInfluencerDetailPublic();
    }
  }, [props?.influencer_id]);

  const redux_dispatch = useDispatch();
  const redux_selector = useSelector((state) => ({
    UserData: state.BrandUserDataReducer || {}, // Ensure default value
    Auth: state.BrandUserAuthReducer || {},
  }));
  
  const { UserData = {} } = redux_selector;
  const { brand_deep_data = {} } = UserData || {}; // Safe fallback
  const { org_deep_data = {} } = UserData || {};
  const { all_permissions = {} } = UserData || {};

  function userSelfData() {
    var params = { org_id: brand_deep_data?.brand_data?.org_id };

    Core.userSelfData(
      userSelfDataSuccess,
      userSelfDataFailed,
      () => {},
      params
    );
  }

  function userSelfDataSuccess(response) {
    setCheckOrgSub(response?.org_data?.subscription_data);
    //update brand user data in redux
    redux_dispatch({
      type: Actions.UPDATE_BRAND_USER_DATA,
      payload: { user_data: response.user_data },
    });

    redux_dispatch({
      type: Actions.UPDATE_ORG_DATA,
      payload: { org_deep_data: response.org_data },
    });

    // Update Auth token
    redux_dispatch({
      type: Actions.UPDATE_BRAND_USER_AUTH,
      payload: { auth_token: response.auth_id },
    });
  }
  function userSelfDataFailed() {}

  function getInfluencerContact() {
    var params = {
      influencer_id: props?.influencer_id,
      org_id: brand_deep_data?.brand_data?.org_id,
    };

    Core.getInfluencerContact(
      getInfluencerContactSuccess,
      getInfluencerContactFail,
      (status) => setLoadingContact(status),
      params
    );
  }

  function getInfluencerContactSuccess(demo, response) {
    setInfluencerData((prev) => ({
      ...prev,
      phone: response?.influencer_deep_data?.influencer_data?.phone,
      email: response?.influencer_deep_data?.influencer_data?.email,
    }));
    closeGetContactDetails();
    userSelfData();
    setActiveTab("profile")
    toast.success("Contact Revealed Successfully")
  }

  function getInfluencerContactFail(error) {
    showToast(error.map((item) => item.m).join(""), "error");
  }

  function getInfluencerDetailPublic() {
    var params = { influencer_id: props?.influencer_id };
    Core.getInfluencerDetailPublic(
      getInfluencerDetailPublicSuccess,
      getInfluencerDetailPublicFailed,
      (status) => setLoading(status),
      params
    );
  }

  function getInfluencerDetailPublicSuccess(response) {
    console.log("response",response)
    setInfluencerData(response?.influencer_deep_data?.influencer_data);
    setPlatformsData(response?.influencer_deep_data?.platforms_data);
    setCategoryListData(
      Object.values(response?.influencer_deep_data?.categorys_data)
    );
    getUserInfluencerGetContent();
    getUserInfluencerGetInstagramContent();
    userSelfData();
  }

  function getInfluencerDetailPublicFailed(errorList) {
    showToast(errorList.map((item) => item.m).join(""), "error");
  }

  function getUserInfluencerGetInstagramContent() {
    setContentLoading(true);
    var params = {
      influencer_id: props?.influencer_id,
      platform_id: "btll",
    };
    Core.getUserInfluencerGetContent(
      getUserInfluencerGetInstagramContentSuccess,
      getUserInfluencerGetInstagramContentFailed,
      () => {},
      params
    );
  }

  function getUserInfluencerGetInstagramContentSuccess(response, data) {
    const updatedContentList = data?.content_list?.map((content) => ({
      ...content,
      platform_id: "btll",
    }));

    setInfluencerContentInstagramList(updatedContentList);
    setContentLoading(false);
  }

  function getUserInfluencerGetInstagramContentFailed(errorList) {}
  function getUserInfluencerGetContent() {
    var params = {
      influencer_id: props?.influencer_id,
      platform_id: "nxjo",
    };
    Core.getUserInfluencerGetContent(
      getUserInfluencerGetContentSuccess,
      getUserInfluencerGetContentFailed,
      () => {},
      params
    );
  }

  function getUserInfluencerGetContentSuccess(response, data) {
    const updatedContentList = data?.content_list?.map((content) => ({
      ...content,
      platform_id: "nxjo",
    }));
    setInfluencerContentList(updatedContentList);
  }

  function getUserInfluencerGetContentFailed(errorList) {
    showToast(errorList.map((item) => item.m).join(""), "error");
  }

  function checkCreditAndContactOpen() {
    if(UserData){
      if (org_deep_data?.org_data?.credits < 1) {
        navigate("/checkout");
      } else {
          getInfluencerContact();
      }
    }
    else{
      toast.error("Please login to view Contact.")
    }
    
  }

  function openGetContactDetails() {
    if (
      !Utils.checkRoleAndPermission(
        redux_selector,
        all_permissions?.["campaign"]?.["permissions"]?.["view_contact"]
      )
    ) {
      window.location.href = "https://brands.getreelax.com";
    return;
    } else {
      setContactModalIsOpen(true);
    }
  }

  function closeGetContactDetails() {
    setContactModalIsOpen(false);
  }

  const instagramEngagementData = platformsData.filter(
    (item) => item?.platform_id == "btll"
  );

  const youtubeEngagementData = platformsData.filter(
    (item) => item?.platform_id == "nxjo"
  );

  function ModalContent() {
    return (
      <Modal
        isOpen={() => openGetContactDetails()}
        onRequestClose={() => closeGetContactDetails()}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 11000,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          },
          content: {
            position: "relative",
            margin: "auto",
            maxWidth: "400px",
            minHeight: "400px",
            inset: "auto",
            display: "flex",
            flexDirection: "column",
            padding: 24,
            justifyContent: "space-between",
            borderRadius: 8,
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "30px",
          }}
        >
          <CustomText
            h5
            text={"Get Contact Details"}
            fontSize={"font_size_2xl"}
            textColor={"text_color_primary"}
            fontWeight={"Poppins_regular_large"}
          />
          <div className="cursor-pointer" onClick={closeGetContactDetails}>
            <Icon iconName="crossCloseIcon" />
          </div>
        </div>

        <div>
          <p>
            Unlock Contact details of{" "}
            <strong> '{influencerData?.name}' </strong> for 1 credit. Once
            unlocked, you will have lifetime access to it.
          </p>
        </div>

        <div
          style={{
            textAlign: "center",
            paddingTop: "30px",
            paddingBottom: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              color: "var(--blue-blue-500)",
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            <p>YOU NEED TO PAY</p>
          </div>
          <div
            style={{
              fontSize: "32px",
              fontWeight: "bold",
              color: "#000",
              marginBottom: "10px",
            }}
          >
            1 Credit
          </div>
          <div
            style={{
              borderBottom: "1px solid rgb(230, 230, 230)",
              width: "40%",
              marginBottom: "20px",
              paddingBottom: "10px",
            }}
          ></div>
          <div
            style={{
              fontSize: "14px",
              color: "rgb(102, 102, 102)",
            }}
          >
            <span>Available Credits: </span>
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              {org_deep_data?.org_data?.credits}
            </span>
          </div>
          {org_deep_data?.org_data?.credits < 1 && (
            <NotificationBanner status="credit_low" />
          )}
        </div>

        {/* bottom buttons cancel and next */}
        <div
          style={{
            padding: "20px",
            display: "flex",
            gap: "20px",
            justifyContent: "center",
            alignItems: "center",
          }}
        ><span style={{maxWidth:125, height:36}}>
          <PrimaryCta
            invert
            className="back-btn-campaign"
            onClick={() => closeGetContactDetails()}
            fontSize={"font_size_large"}
            text={"Cancel"}
          />
          </span>
          <PrimaryCta
            fifth
            onClick={() => checkCreditAndContactOpen()}
            className="blue-button-p-10-15"
            fontSize={"font_size_large"}
             isLoading={isLoadingContact}
            text={
              org_deep_data?.org_data?.credits < 1
                ? "Buy credits"
                : "Get contact"
            }
          />
        </div>
      </Modal>
    );
  }

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {" "}
          <div className="influencer-detail-header-main-container">
            <InfluencerProfile
              influencerData={influencerData}
              categoryListData={categoryListData}
              platformsData={platformsData}
              openGetContactDetails={openGetContactDetails}
              contactRequest={contactRequest}
            />
            <div className="tab_header_upper_container">
              <div className="influencer-detail-tab-header">
                <div
                  className={activeTab === "profile" ? "active" : ""}
                  onClick={() => setActiveTab("profile")}
                >
                  <div className="influencer-detail-btn-tabs">
                    <CustomText
                      p
                      fontSize={"font_size_medium"}
                      text={isMobile ? "Profile" : "Profile Summary"}
                      height={"line_height_22_5"}
                      fontWeight={"Inter_medium_500"}
                    />
                  </div>
                </div>
                {instagramEngagementData?.[0] && (
                  <div
                    className={activeTab === "instagram" ? "active" : ""}
                    onClick={() => setActiveTab("instagram")}
                  >
                    <div className="influencer-detail-btn-tabs flex-row gap-10">
                      <Icon iconName="instagramIcon" />
                      <CustomText
                        p
                        fontSize={"font_size_medium"}
                        text={
                          isMobile
                            ? "Instagram"
                            : `@${instagramEngagementData?.[0]?.identifier_id}`
                        }
                        height={"line_height_22_5"}
                        fontWeight={"Inter_medium_500"}
                      />
                    </div>
                  </div>
                )}

                {youtubeEngagementData?.[0] && (
                  <div
                    className={activeTab === "youtube" ? "active" : ""}
                    onClick={() => setActiveTab("youtube")}
                  >
                    <div className="influencer-detail-btn-tabs flex-row gap-10">
                      <Icon iconName="youtubeIcon" />
                      <CustomText
                        p
                        fontSize={"font_size_medium"}
                        text={
                          isMobile
                            ? "Youtube"
                            : `${youtubeEngagementData?.[0]?.identifier_id}`
                        }
                        height={"line_height_22_5"}
                        fontWeight={"Inter_medium_500"}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {contactModalIsOpen && <ModalContent />}
          <div className="influencer_detail_setting_detail_container">
            {activeTab === "profile" && (
              <InfluencerProfileTab
                influencerData={influencerData}
                platformsData={platformsData}
                influencerContentList={influencerContentList}
                influencerContentInstagramList={influencerContentInstagramList}
                checkOrgSub={checkOrgSub}
                setContactRequest={setContactRequest}
                isLoadingContact={isLoadingContact}
              />
            )}
            {activeTab === "instagram" && (
              <InfluencerInstagramTab
                influencerData={influencerData}
                hideEngagementRate={props?.hideEngagementRate}
                contentLoading={contentLoading}
                instagramEngagementData={instagramEngagementData}
                influencerContentList={influencerContentInstagramList}
              />
            )}
            {activeTab === "youtube" && (
              <InfluencerInstagramTab
                influencerData={influencerData}
                contentLoading={contentLoading}
                hideEngagementRate={props?.hideEngagementRate}
                instagramEngagementData={youtubeEngagementData}
                influencerContentList={influencerContentList}
              />
            )}
          </div>
        </>
      )}
    </>
  );
}

export default InfluencerDetailPage;
