import React, { useState, useRef, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line,
  Pie,
  Cell,
  Legend,
} from "recharts";
import Icon from "../../components/core_component/icon";
import { BrandCard, CustomText } from "../../components";
import { PieChart } from "react-minimal-pie-chart";
import Utils from "../../common/utils";
import InfluencerProfileContentCard from "./influencerProfileContentCard";
import contentList from "./../../resources/data/content_data.json";
import { avatarClasses } from "@mui/material";
import { Loader } from "../loader";
import { toast } from "react-toastify";
import Core from "../../common/clientSdk";
import { useSelector } from "react-redux";

const data = [
  { name: "English", value: 82.74, color: "#8B5CF6" },
  { name: "Hindi", value: 5.11, color: "#3B82F6" },
  { name: "Panjabi", value: 3.33, color: "#10B981" },
  { name: "Nepali", value: 2.81, color: "#F97316" },
  { name: "Bengali", value: 0.66, color: "#FACC15" },
  { name: "Persian", value: 0.58, color: "#C084FC" },
  { name: "Urdu", value: 0.55, color: "#EF4444" },
  { name: "Gujarati", value: 0.45, color: "#22C55E" },
];
function InfluencerInstagramTab(props) {
  const [selectedSection, setSelectedSection] = useState("overview");
  const [influencerContentSort, setInfluencerContentSort] = useState([]);
  const [contentToFilter, setContentToFilter] = useState("remove_filter");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [activeTab, setActiveTab] = useState("cities");
  const [loading, setLoading] = useState(false);
  const [instagramData, setInstagramData] = useState([]);

  const redux_selector = useSelector((state) => ({
    UserData: state.BrandUserDataReducer || {}, // Ensure default value
    Auth: state.BrandUserAuthReducer || {},
  }));

  console.log("influencerContentSort",influencerContentSort)

  const { UserData = {} } = redux_selector;
  const { brand_deep_data = {} } = UserData || {}; // Safe fallback
  const { org_deep_data = {} } = UserData || {};
  const { all_permissions = {} } = UserData || {};

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  console.log("props.influencerData", props.influencerData);
  useEffect(() => {
    if (Utils.isLoggedIn(brand_deep_data)) {
      if (
        props?.influencerData?.["influencer_id"] &&
        props?.instagramEngagementData?.[0]?.platform_id == "btll"
      ) {
        getInfluencerDemographics();
      }
    }
  }, [
    props?.influencerData?.["influencer_id"],
    props?.instagramEngagementData?.[0]?.platform_id == "btll",
  ]);

  const genderDataRaw =
    instagramData?.demography_data?.gender_demography?.total_value
      ?.breakdowns?.[0]?.results;
  const genderData = genderDataRaw?.map((entry) => {
    const gender = entry?.dimension_values?.[0];
    let color;

    if (gender === "M") {
      color = "rgb(45, 89, 245)";
    } else if (gender === "F") {
      color = "rgb(226, 137, 242)";
    } else {
      color = "rgb(150, 150, 150)";
    }

    return {
      title: gender === "M" ? "Male" : gender === "F" ? "Female" : "Other",
      value: parseFloat(entry.value),
      color: color,
    };
  });
  const genderMap = {
    M: "Male",
    F: "Female",
    U: "Others",
  };
  const demographyData = instagramData?.demography_data || {};

  const getTopCategory = (data, isGender = false) => {
    if (!data?.total_value?.breakdowns?.[0]?.results) return null;
    const results = data.total_value.breakdowns[0].results;
    if (results.length === 0) return null;

    const top = results.sort(
      (a, b) => parseFloat(b.value) - parseFloat(a.value)
    )[0];

    return {
      name: isGender
        ? genderMap[top.dimension_values?.[0]] || "Other"
        : top.dimension_values?.[0] || "-",
      percentage: top.value ? `${top.value}%` : "-",
    };
  };

  const topCity = getTopCategory(demographyData.city_demography);
  const topCountry = getTopCategory(demographyData.country_demography);
  const topGender = getTopCategory(demographyData.gender_demography, true);
  const topAgeGroup = getTopCategory(demographyData.age_demography);

  function updateProgress(status) {
    setLoading(status);
  }
  function getInfluencerDemographics() {
    let params = {
      influencer_id: props?.influencerData?.["influencer_id"],
      platform_id: "btll",
    };

    Core.getInfluencerDemographics(
      getInfluencerDemographicsSuccess,
      getInfluencerDemographicsFailed,
      updateProgress,
      params
    );
  }

  function getInfluencerDemographicsSuccess(res, response) {
    console.log("response", response);
    setInstagramData(response);
    setLoading(false);
  }

  function getInfluencerDemographicsFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }
  const filterMediaByType = (mediaList, typeMappings) => {
    const images = [];
    const videos = [];
    mediaList?.forEach((media) => {
      if (typeMappings[media["content_type_id"]] == "IMAGE") {
        images.push(media);
      }
      if (typeMappings[media["content_type_id"]] == "VIDEO") {
        videos.push(media);
      }
    });
    return { images, videos };
  };

  const { images, videos } = filterMediaByType(
    props?.influencerContentList,
    Utils.typeMappings()
  );

  console.log("props?.influencerContentList", props?.influencerContentList);

  const calculateAverageLikesAndComments = (posts) => {
    const totalLikes = posts.reduce((sum, post) => sum + post.like_count, 0);
    const totalComments = posts.reduce(
      (sum, post) => sum + post.comment_count,
      0
    );
    const totalView = posts.reduce((sum, post) => sum + post.view_count, 0);

    const avgLikes = totalLikes / posts.length;
    const avgComments = totalComments / posts.length;
    const avgView = totalView / posts.length;

    const cotentEngagementRate = ((avgLikes + avgComments) / avgView) * 100;

    return {
      totalView,
      avgLikes: avgLikes.toFixed(1),
      avgComments: avgComments.toFixed(1),
      avgView: avgView.toFixed(1),
      cotentEngagementRate: cotentEngagementRate.toFixed(1),
    };
  };

  const calculateTotalEngagementRatio = (posts) => {
    const totalEngagement = posts.reduce(
      (sum, post) => sum + post.like_count + post.comment_count,
      0
    );

    const totalViews = posts.reduce((sum, post) => sum + post.view_count, 0);

    const engagementRatio = ((totalEngagement / totalViews) * 100).toFixed(1); // Percentage
    return engagementRatio;
  };

  const averagesImages = calculateAverageLikesAndComments(images);
  const averagesVideos = calculateAverageLikesAndComments(videos);

  const engVideo = calculateTotalEngagementRatio(videos);

  useEffect(() => {
    setInfluencerContentSort(props.influencerContentList);
  }, [props.influencerContentList]);
  const sectionsRef = {
    overview: useRef(null),
    engagement: useRef(null),
    content: useRef(null),
    audience: useRef(null),
    brand: useRef(null),
    url: useRef(null),
  };

  const getMediaType = (contentTypeId) => {
    const content = contentList.find(
      (item) => item.content_data.content_type_id === contentTypeId
    );
    return content ? content.content_data.media_type : null;
  };

  function sortContentByRemoveFilter() {
    setInfluencerContentSort(props.influencerContentList);
    setContentToFilter("remove_filter");
  }
  function sortContentByViewCount() {
    const sortedData = [...props.influencerContentList].sort(
      (a, b) => b.view_count - a.view_count
    );

    setInfluencerContentSort(sortedData);
    setContentToFilter("view_count");
    // return sortedData;
  }

  function sortContentByLatestDate() {
    const latestPost = [...props.influencerContentList].sort(
      (a, b) => new Date(b.content_posted_on) - new Date(a.content_posted_on)
    );

    setInfluencerContentSort(latestPost);
    setContentToFilter("latest_date");
    // return sortedData;
  }

  function youtubeEngDataList() {
    let engDataList = [];

    props?.influencerContentList?.forEach((item) => {
      if (Utils.typeMappings()[item["content_type_id"]] == "VIDEO") {
        engDataList.push({
          view_count: item.view_count || "-",
          like_count: item.like_count || "-",
          comment_count: item.comment_count.toFixed(0) || "-",
          engagement:
            (
              ((item?.comment_count + item?.like_count) / item?.view_count) *
              100
            ).toFixed(0) || "-",
          post_time: item.content_posted_on,
        });
      }
    });
    engDataList = Utils.sortByKey(engDataList, "post_time", "asc");
    return engDataList;
  }

  function scrollToSection(section) {
    setSelectedSection(section);
    sectionsRef[section]?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }

  function renderSidebarInfluencer() {
    const sidebarItems = [
      { name: "Overview", key: "overview", iconName: "platformIcon" },
      { name: "Engagement", key: "engagement", iconName: "engHeartIcon" },
      { name: "Content", key: "content", iconName: "contentIcon" },
      { name: "Audience", key: "audience", iconName: "audienceIcon" },
      // { name: "Brands", key: "brand", iconName: "brandIcon" },
      {
        name: "Visit Profile",
        key: "url",
        iconName:
          props?.instagramEngagementData?.[0]?.platform_id == "btll"
            ? "instagramIcon"
            : "youtubeIcon",
      },
    ];

    const handleClick = (item) => {
      if (item.key === "url") {
        const url = Utils.getSocialUrl(
          props?.instagramEngagementData?.[0]?.platform_id,
          props?.instagramEngagementData?.[0]?.identifier_id
        );
        if (url) {
          window.open(url, "_blank", "noopener,noreferrer");
        } else {
          console.error("Invalid URL or data missing");
        }
      } else {
        scrollToSection(item.key);
      }
    };

    return (
      <div className="influencer-sidebar-profile-main-container  flex-column gap-30">
        {sidebarItems.map((item) => (
          <div
            onClick={() => handleClick(item)}
            key={item.name}
            className={`flex-row gap-10 cursor-pointer align-center ${
              selectedSection === item.key
                ? "selected-sidebar"
                : "gray-gray-300"
            }`}
          >
            <div
              style={{
                width: 18,
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Icon
                iconName={item.iconName}
                height={20}
                width={20}
                color={selectedSection == item.key && "#1878f3"}
              />
            </div>

            <CustomText p text={item.name} className="button1" />
          </div>
        ))}
      </div>
    );
  }

  function likeCommentRatioFun() {
    let likeCount = 0;
    let commentCount = 0;

    props.influencerContentList.forEach((item) => {
      likeCount += Number(item.like_count) || 0;
      commentCount += Number(item.comment_count) || 0;
    });

    return likeCount + commentCount;
  }
  console.log("averagesVideos", averagesVideos);
  function renderInstagramContentData() {
    return (
      <>
        {props?.instagramEngagementData?.[0]?.platform_id == "btll" && (
          <div className="profile-engagement-view-container m-b-20">
            <div>
              <div className="flex-row gap-10">
                <CustomText
                  p
                  text={"Performance Metrics"}
                  className="Caption-3 m-b-20"
                />
              </div>
              {props?.instagramEngagementData?.map((item) => {
                return (
                  <div
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      paddingBottom: 10,
                      borderBottom: "1px solid #e6e7e8",
                    }}
                  >
                    <div className="instagram-data-handle">
                      <Icon iconName="instagramIcon" />
                      <CustomText
                        p
                        text={`@${item?.identifier_id}`}
                        className="button2 instagram-data-handle-text"
                      />
                    </div>
                    <div className="engagement-view-inner ">
                      {item?.followers > 0 && (
                        <div className="instagram-data-view">
                          <CustomText
                            p
                            text={"Followers"}
                            className="typography14_left"
                          />
                          <CustomText
                            p
                            text={
                              Utils.isNullOrUndefinedOrZero(item?.followers)
                                ? "-"
                                : Utils.changeNumberFormate(item?.followers)
                            }
                            className="button2"
                          />
                        </div>
                      )}

                      {item?.content_count > 0 && (
                        <div className="instagram-data-view">
                          <CustomText
                            p
                            text={"Total Contents"}
                            className="typography14_left"
                          />
                          <CustomText
                            p
                            text={
                              Utils.isNullOrUndefinedOrZero(item?.content_count)
                                ? "-"
                                : item?.content_count > 1000
                                  ? Utils.changeNumberFormate(Number(item?.content_count).toFixed(1)) 
                                  : Number(item?.content_count) 
                            }                                                      
                            className="button2"
                          />
                        </div>
                      )}

                      {item?.avg_views > 0 && (
                        <div className="instagram-data-view">
                          <CustomText
                            p
                            text={"Avg. Views"}
                            className="typography14_left"
                          />
                          <CustomText
                            p
                            text={
                              Utils.isNullOrUndefinedOrZero(item?.avg_views)
                                ? "-"
                                : Utils.changeNumberFormate(item?.avg_views.toFixed(1))
                            }
                            className="button2"
                          />
                        </div>
                      )}

                      {item?.avg_likes > 0 && (
                        <div className="instagram-data-view">
                          <CustomText
                            p
                            text={"Avg. Likes"}
                            className="typography14_left"
                          />
                          <CustomText
                            p
                            text={
                              Utils.isNullOrUndefinedOrZero(item.avg_likes)
                                ? "-"
                                : Utils.changeNumberFormate(item.avg_likes.toFixed(1))
                            }
                            className="button2"
                          />
                        </div>
                      )}

                      {item?.avg_comments > 0 && (
                        <div className="instagram-data-view">
                          <CustomText
                            p
                            text={"Avg. Comments"}
                            className="typography14_left"
                          />
                          <CustomText
                            p
                            text={
                              Utils.isNullOrUndefinedOrZero(item?.avg_comments)
                                ? "-"
                                : Utils.changeNumberFormate(item?.avg_comments.toFixed(1))
                            }
                            className="button2"
                          />
                        </div>
                      )}

                      {item?.engagement_rate > 0 && (
                        <div className="instagram-data-view">
                          <CustomText
                            p
                            text={"E.R."}
                            className="typography14_left"
                          />
                          <CustomText
                            p
                            text={
                              !Utils.isNullOrUndefinedOrZero(
                                item?.engagement_rate
                              )
                                ? item.engagement_rate.toFixed(2) + " %"
                                : "-"
                            }
                          />
                        </div>
                      )}
                      {item?.impressions > 0 && (
                        <div className="instagram-data-view">
                          <CustomText
                            p
                            text={"Impressions"}
                            className="typography14_left"
                          />
                          <CustomText
                            p
                            text={Utils.changeNumberFormate(
                              item?.impressions.toFixed(1)
                            )}
                          />
                        </div>
                      )}
                      {item?.profile_views > 0 && (
                        <div className="instagram-data-view">
                          <CustomText
                            p
                            text={"Profile Views"}
                            className="typography14_left"
                          />
                          <CustomText 
                          p 
                          text={Utils.changeNumberFormate(
                              item?.profile_views.toFixed(1)
                            )} />
                        </div>
                      )}
                      {item?.reach > 0 && (
                        <div className="instagram-data-view">
                          <CustomText
                            p
                            text={"Reach"}
                            className="typography14_left"
                          />
                          <CustomText p text={Utils.changeNumberFormate(
                              item?.reach.toFixed(1)
                            )}/>
                        </div>
                      )}
                      {item?.total_interactions > 0 && (
                        <div className="instagram-data-view">
                          <CustomText
                            p
                            text={"Total Interactions"}
                            className="typography14_left"
                          />
                          <CustomText p text={Utils.changeNumberFormate(
                              item?.total_interactions.toFixed(1)
                            )}/>
                        </div>
                      )}

                      {item?.effective_followers > 0 && (
                        <div className="instagram-data-view">
                          <CustomText
                            p
                            text={"Fake Followers"}
                            className="typography14_left"
                          />
                          {item.effective_followers > 0 ? (
                            <CustomText
                              p
                              text={
                                (100 - item?.effective_followers).toFixed(0) +
                                "%"
                              }
                              className={
                                props.checkOrgSub?.feature?.fake_follower_check
                                  ? "button2"
                                  : "blur-text"
                              }
                            />
                          ) : (
                            <CustomText
                              p
                              text={"-"}
                              className={
                                props.checkOrgSub?.feature?.fake_follower_check
                                  ? "button2"
                                  : "blur-text"
                              }
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
              <div className="profile-engagement-view-inner">
                <div className="instagram-data-view">
                  <Icon
                    iconName={
                      props?.instagramEngagementData?.[0]?.platform_id == "btll"
                        ? "reelsIconInstagram"
                        : "youtubeIcon"
                    }
                  />
                  <CustomText p text={"Video"} className="button2" />
                </div>
                <div className="instagram-data-view">
                  <CustomText p text={"Avg. Views"} className="caption_2" />
                  <CustomText
                    p
                    text={Utils.changeNumberFormate(averagesVideos?.avgView)}
                    className="button2"
                  />
                </div>
                <div className="instagram-data-view">
                  <CustomText p text={"Avg. Likes"} className="caption_2" />
                  <CustomText
                    p
                    text={Utils.changeNumberFormate(averagesVideos?.avgLikes)}
                    className="button2"
                  />
                </div>
                <div className="instagram-data-view">
                  <CustomText p text={"Avg. Comments"} className="caption_2" />
                  <CustomText
                    p
                    text={Utils.changeNumberFormate(averagesVideos.avgComments)}
                    className="button2"
                  />
                </div>
                <div className="instagram-data-view">
                  <CustomText
                    p
                    text={"Content Engagement Rate"}
                    className="caption_2"
                  />
                  <CustomText
                    p
                    text={
                      isNaN(averagesVideos.cotentEngagementRate)
                        ? 0
                        : averagesVideos.cotentEngagementRate
                    }
                    className="button2"
                  />
                </div>
              </div>
              {images.length > 0 && (
                <div
                  className="profile-engagement-view-inner"
                  style={{ borderTop: "1px solid #e6e7e8" }}
                >
                  <div className="instagram-data-view">
                    <Icon iconName="instagramIcon" />
                    <CustomText p text={"Image"} className="button2" />
                  </div>

                  <div className="instagram-data-view">
                    <CustomText p text={"Avg. Likes"} className="caption_2" />
                    <CustomText
                      p
                      text={Utils.changeNumberFormate(averagesImages?.avgLikes)}
                      className="button2"
                    />
                  </div>
                  <div className="instagram-data-view">
                    <CustomText
                      p
                      text={"Avg. Comments"}
                      className="caption_2"
                    />
                    <CustomText
                      p
                      text={Utils.changeNumberFormate(
                        averagesImages.avgComments
                      )}
                      className="button2"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {props?.instagramEngagementData?.[0]?.platform_id == "btll" && (
          <div className="profile-engagement-view-container m-b-20">
            <div className="wrap-data-engagements-view m-b-20">
              <div className="flex-row gap-10">
                <CustomText
                  p
                  text={"LIKES - COMMENTS RATIO"}
                  className="button2_light"
                />
                {/* <Icon iconName={"infoCircleIcon"} /> */}
              </div>
              <div className="wrap-data-engagements-view-child">
                <CustomText
                  p
                  text={Utils.changeNumberFormate(
                    isFinite(
                      averagesVideos?.avgLikes / averagesVideos?.avgComments
                    )
                      ? (
                          averagesVideos?.avgLikes / averagesVideos?.avgComments
                        ).toFixed(1)
                      : "-"
                  )}
                  className="h6Text"
                />
              </div>
            </div>
            <div className="wrap-data-engagements-view">
              <div className="flex-row gap-10">
                <CustomText
                  p
                  text={"REEL VIEWS TO FOLLOWERS RATIO "}
                  className="button2_light"
                />
                {/* <Icon iconName={"infoCircleIcon"} /> */}
              </div>
              <div className="wrap-data-engagements-view-child">
                <CustomText
                  p
                  text={Utils.changeNumberFormate(
                    (
                      averagesVideos?.avgView /
                      (props?.instagramEngagementData[0]?.followers / 100)
                    )?.toFixed(1)
                  )}
                  className="h6Text"
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
console.log("props?.influencerData",props?.influencerData)
  function renderInstagramContent() {
    console.log("getHashTag()", getHashTag());
    return (
      <>
        <div className="m-b-20">
          {getHashTag()?.length > 0 && (
            <div className="profile-engagement-view-content-container">
              <div className="heading-info">
                <CustomText p text={"HASHTAGS"} className="Caption-3 m-b-20" />
                {/* <Icon iconName="infoCircleIcon" /> */}
              </div>

              <div className="influencer-profile-hag-tag-container m-b-15">
                {getHashTag()?.map((item, index) => (
                  <div key={index} className="influencer-profile-hag-tag">
                    <CustomText p text={item} className="typography14" />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        {influencerContentSort?.length > 0 && (
          <div className="m-b-20 profile-engagement-view-content-container">
            <div className="heading-info">
              <CustomText p text={"CONTENT ANALYTICS"} className="Caption-3" />
              {/* <Icon iconName="infoCircleIcon" /> */}
            </div>
            <div className="filter-influencer-content-cta-container">
              <div
                className={`cursor-pointer ${
                  contentToFilter === "remove_filter"
                    ? "filter-influencer-content-cta"
                    : ""
                }`}
                onClick={sortContentByRemoveFilter}
              >
                <CustomText p text={"All"} className="typography14" />
              </div>
              <div
                className={`cursor-pointer ${
                  contentToFilter === "view_count"
                    ? "filter-influencer-content-cta"
                    : ""
                }`}
                onClick={() => sortContentByViewCount("")}
              >
                <CustomText p text={"Top Posts"} className="typography14" />
              </div>
              <div
                className={`cursor-pointer ${
                  contentToFilter === "latest_date"
                    ? "filter-influencer-content-cta"
                    : ""
                }`}
                onClick={sortContentByLatestDate}
              >
                <CustomText p text={"Latest Posts"} className="typography14" />
              </div>
            </div>
            <div className="" style={{display:"flex",gap:20,overflowX:"auto",scrollbarWidth:"none"}}>
              {(influencerContentSort || []).map((item, index) => (
                console.log("itemsss",item),
                <InfluencerProfileContentCard
                  key={index}
                  item={item}
                  influencerData={props?.influencerData}
                />
              ))}
            </div>
          </div>
        )}
      </>
    );
  }

  const rawAgeGroupData =
    instagramData?.demography_data?.age_demography?.total_value?.breakdowns?.[0]
      ?.results || [];
  const sortedData = rawAgeGroupData?.sort((a, b) => {
    const ageA = parseInt(a?.dimension_values?.[0]); // Extract starting number
    const ageB = parseInt(b?.dimension_values?.[0]);
    return ageA - ageB;
  });
  function renderInstagramAudience() {
    return (
      <div>
        {topCity?.name && (
          <div className="profile-engagement-view-container">
            <div>
              <CustomText p text={"AUDIENCE"} className="Caption-3 m-b-20" />

              <div className="audi-count-main-container">
                {/* Top City */}
                {topCity?.name !== "-" && (
                  <div className="upper-audi-cart-data">
                    <div className="lower-audi-cart-data">
                      <CustomText p text={"TOP CITY"} className="Caption-3" />
                      <CustomText
                        h3
                        text={topCity?.name}
                        className="button2_light"
                      />
                      <div
                        style={{ display: "flex", gap: 5, color: "#8e8e93" }}
                      >
                        <CustomText
                          p
                          text={
                            <>
                              Audience from <strong>{topCity?.name}</strong> is
                              top <strong>{topCity?.percentage}</strong>
                            </>
                          }
                          className="button1"
                        />
                      </div>
                    </div>
                  </div>
                )}

                {/* Top Country */}
                {topCountry?.name !== "-" && (
                  <div className="upper-audi-cart-data">
                    <div className="lower-audi-cart-data">
                      <CustomText
                        p
                        text={"TOP COUNTRY"}
                        className="Caption-3"
                      />
                      <CustomText
                        h3
                        text={topCountry?.name}
                        className="button2_light"
                      />
                      <div
                        style={{ display: "flex", gap: 5, color: "#8e8e93" }}
                      >
                        <CustomText
                          p
                          text={
                            <>
                              Audience from <strong>{topCountry?.name}</strong>{" "}
                              is top <strong>{topCountry?.percentage}</strong>
                            </>
                          }
                          className="button1"
                        />
                      </div>
                    </div>
                  </div>
                )}

                {/* Top Gender */}
                {topGender?.name !== "-" && (
                  <div className="upper-audi-cart-data">
                    <div className="lower-audi-cart-data">
                      <CustomText p text={"TOP GENDER"} className="Caption-3" />
                      <CustomText
                        h3
                        text={topGender?.name}
                        className="button2_light"
                      />
                      <div
                        style={{ display: "flex", gap: 5, color: "#8e8e93" }}
                      >
                        <CustomText
                          p
                          text={
                            <>
                              Top gender is{" "}
                              <strong>
                                {topGender?.name}:&nbsp;{topGender?.percentage}
                              </strong>
                            </>
                          }
                          className="button1"
                        />
                      </div>
                    </div>
                  </div>
                )}

                {/* Top Age Group */}
                {topAgeGroup?.name !== "-" && (
                  <div className="upper-audi-cart-data">
                    <div className="lower-audi-cart-data">
                      <CustomText
                        p
                        text={"TOP AGE GROUP"}
                        className="Caption-3"
                      />
                      <CustomText
                        h3
                        text={topAgeGroup?.name}
                        className="button2_light"
                      />
                      <div
                        style={{ display: "flex", gap: 5, color: "#8e8e93" }}
                      >
                        <CustomText
                          p
                          text={
                            <>
                              Most common age group is{" "}
                              <strong>
                                {topAgeGroup?.name}:&nbsp;
                                {topAgeGroup?.percentage}
                              </strong>
                            </>
                          }
                          className="button1"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {instagramData?.demography_data?.city_demography?.total_value
          ?.breakdowns?.[0]?.results &&
          instagramData?.demography_data?.country_demography?.total_value
            ?.breakdowns?.[0]?.results && (
            <div className="profile-engagement-view-container">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <CustomText
                  p
                  text={"AUDIENCE GEOGRAPHY"}
                  className="Caption-3 m-b-10"
                />
                <div className="audience-tabs">
                  <button
                    className={activeTab === "cities" ? "active" : ""}
                    onClick={() => setActiveTab("cities")}
                  >
                    Cities
                  </button>
                  <button
                    className={activeTab === "countries" ? "active" : ""}
                    onClick={() => setActiveTab("countries")}
                  >
                    Countries
                  </button>
                </div>
              </div>

              <div className="audience-grid">
                {activeTab === "cities" &&
                  instagramData?.demography_data?.city_demography?.total_value?.breakdowns?.[0]?.results?.map(
                    (item, index) => (
                      <div key={index} className="audience-item">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            paddingBottom: 10,
                          }}
                        >
                          <div>{item?.dimension_values?.[0]}</div>
                          <div>{item?.value}%</div>
                        </div>
                        <div className="progress-bar-audience">
                          <div
                            className="progress-fill-audience"
                            style={{ width: `${item?.value}%` }}
                          ></div>
                        </div>
                      </div>
                    )
                  )}

                {activeTab === "countries" &&
                  instagramData?.demography_data?.country_demography?.total_value?.breakdowns?.[0]?.results?.map(
                    (item, index) => (
                      <div key={index} className="audience-item">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            paddingBottom: 10,
                          }}
                        >
                          <div>{item?.dimension_values?.[0]}</div>
                          <div>{item?.value}%</div>
                        </div>
                        <div className="progress-bar-audience">
                          <div
                            className="progress-fill-audience"
                            style={{ width: `${item?.value}%` }}
                          ></div>
                        </div>
                      </div>
                    )
                  )}
              </div>
            </div>
          )}
        {instagramData?.demography_data?.age_demography?.total_value
          ?.breakdowns?.[0]?.results && (
          <div className="profile-engagement-view-container">
            {/* Age Group Section */}
            <div className="age-group">
              <CustomText
                p
                text={"AUDIENCE AGE GROUP"}
                className="Caption-3 m-b-10"
              />
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={sortedData} barSize={40}>
                  <XAxis dataKey={(entry) => entry?.dimension_values?.[0]} />
                  <YAxis
                    tickFormatter={(value) => `${value}%`}
                    label={{
                      value: "Percentage",
                      angle: -90,
                      position: "insideLeft",
                    }}
                    domain={[0, 100]}
                    allowDecimals={false}
                  />
                  <Tooltip
                    content={({ active, payload }) => {
                      if (active && payload && payload.length) {
                        const ageGroup =
                          payload[0].payload.dimension_values?.[0];
                        const percent = payload[0].value;
                        return (
                          <div
                            style={{
                              background: "#fff",
                              padding: "8px",
                              border: "1px solid #ccc",
                            }}
                          >
                            <p>
                              <strong>Age-Group:</strong> {ageGroup}
                            </p>
                            <p>
                              <strong>Value:</strong> {percent}%
                            </p>
                          </div>
                        );
                      }
                      return null;
                    }}
                  />
                  <Bar dataKey="value" fill="#1977f2" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        )}

        {genderData && (
          <div className="profile-engagement-view-container">
            <CustomText
              p
              text={"AUDIENCE GENDER"}
              className="Caption-3 m-b-10"
            />
            <div style={{ display: "flex" }}>
              <div style={{ flex: 1, height: 200, width: 200, paddingTop: 20 }}>
                {" "}
                <PieChart
                  totalValue={100}
                  data={genderData}
                  lineWidth={18}
                  radius={50}
                  label={({ dataEntry }) => `${Math.round(dataEntry?.value)}%`}
                  labelStyle={{
                    fontSize: "5px",
                    fill: "#fff",
                  }}
                />
              </div>
              <div className="gender-data-tabs">
                {genderData?.map((item, index) => (
                  <div>
                    <button
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 10,
                        borderRadius: 24,
                        border: "1px solid #e4e7eb",
                        padding: 10,
                        background: "white",
                      }}
                    >
                      <button
                        style={{
                          borderRadius: "50%",
                          height: 16,
                          width: 16,
                          padding: 10,
                          border: "none",
                          background: item.color,
                        }}
                      ></button>
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 5,
                        }}
                      >
                        <p>
                          {`${item?.title}:`}&nbsp;{`${item?.value}%`}
                        </p>
                      </span>
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {/* <div className="profile-engagement-view-container">
          <CustomText
            p
            text={"AUDIENCE LANGUAGE"}
            className="Caption-3 m-b-10"
          />
          <div className="audience-language">
            <div style={{ flex: 1, height: 200, width: 200, paddingTop: 20 }}>
              <PieChart
                totalValue={100}
                data={data}
                lineWidth={18} // Controls the thickness of the donut ring
                radius={50} // Adjusts the size of the chart
                label={({ dataEntry }) => `${Math.round(dataEntry.value)}%`}
                labelStyle={{
                  fontSize: "5px",
                  fill: "#fff",
                }}
              />
            </div>
            <div className="language-list" style={{ flex: 1 }}>
              {data.map((item, index) => (
                <div className="language-item" key={index}>
                  <span
                    className="language-dot"
                    style={{ backgroundColor: item.color }}
                  ></span>
                  <span className="language-name">{item.name}</span>
                  <span className="language-value">{item.value}%</span>
                </div>
              ))}
            </div>
          </div>
        </div> */}
      </div>
    );
  }

  function chartInstagram() {
    return (
      <div>
        <PieChart
          totalValue={100}
          data={[
            { title: "One", value: 70, color: "green" },
            { title: "Two", value: 30, color: "red" },
          ]}
          lineWidth={18} // Controls the thickness of the donut ring
          radius={50} // Adjusts the size of the chart
          label={({ dataEntry }) => `${Math.round(dataEntry.percentage)}%`}
          labelStyle={{
            fontSize: "5px",
            fill: "#fff",
          }}
        />
      </div>
    );
  }
  function renderBarChart(data) {
    if (!data || data.length === 0) {
      return null;
    }

    const minEngagement = Math.min(...data.map((d) => d.engagement));
    const maxEngagement = Math.max(...data.map((d) => d.engagement));

    // Add some buffer for better visualization
    const buffer = 2;
    const yAxisDomain = [
      Math.max(0, minEngagement - buffer),
      maxEngagement + buffer,
    ];

    return (
      <div className="profile-engagement-view-container">
        <div className="m-b-20">
          <CustomText
            p
            fontSize={"font_size_medium"}
            text={"Content Progress"}
            height={"line_height_22_5"}
            fontWeight={"Inter_medium_500"}
          />
        </div>
        <div className="engagement-column-chart-containe text-align ">
          <div className="engagement-column-chart-inner-container">
            <div
              className="engagement-column-chart"
              style={{ transform: "rotate(270deg)" }}
            >
              <CustomText
                p
                fontSize={"font_size_medium"}
                text={"Content Engagement Rate %"}
                height={"line_height_22_5"}
                fontWeight={"Inter_medium_500"}
              />
            </div>
            <div className="chart-line-container">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  data={data}
                  barCategoryGap={0}
                  margin={{ top: 20, right: 20, bottom: 40, left: 20 }}
                >
                  {/* X-Axis for Posts */}
                  <XAxis
                    dataKey="post_time"
                    name="Posted On"
                    interval={0} // Force display all ticks
                    tickFormatter={Utils.convertUnixToHumanReadable}
                    tick={{ angle: -45, textAnchor: "end" }} // Rotates labels to prevent overlap
                  />
                  {/* Y-Axis for Engagement Count */}
                  <YAxis
                    dataKey="engagement"
                    type="number"
                    interval={0}
                    domain={yAxisDomain}
                  />

                  {/* Tooltip to show engagement on hover */}
                  <Tooltip
                    cursor={{ strokeDasharray: "3 3" }} // Optional: Dashed hover effect
                    formatter={(value) => `${value}%`} // Custom format for engagement
                    labelFormatter={(label) =>
                      `Posted On: ${Utils.convertUnixToHumanReadable(label)}`
                    }
                  />

                  {/* Line for Engagement Count */}
                  <Line
                    type="monotone"
                    name="Content Engagement Rate"
                    dataKey="engagement"
                    stroke="#1977f2"
                    connectNulls={true} // Connects missing values
                    dot={{ stroke: "#1977f2", strokeWidth: 2, r: 4 }} // Makes hover points more visible
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
          <CustomText
            p
            fontSize={"font_size_medium"}
            text={"Latest Posts"}
            height={"line_height_22_5"}
            fontWeight={"Inter_medium_500"}
          />
        </div>
      </div>
    );
  }

  function renderCardCount(text, value) {
    return (
      <div className="card-count-container  gap-20">
        <div>
          <CustomText p text={text} className="button1 gray-gray-400 m-b-15" />
        </div>
        <div>
          <CustomText p text={value} className="typography_18 gray-gray-500" />
        </div>
      </div>
    );
  }

  // function renderOverview() {
  //   return (
  //     <div className="influencer-reelax-score-main-container ">
  //       <div
  //         style={{
  //           width: "40%",
  //           display: "flex",
  //           flexDirection: "column",
  //         }}
  //       >
  //         <div className="m-b-20">
  //           <CustomText p text={"REELAX SCORE"} className="text-13" />
  //         </div>
  //         {chartInstagram()}
  //       </div>
  //       <div>
  //         <div className="m-b-20">
  //           <CustomText p text={"INSIGHTS FOR YOU"} className="text-13" />
  //         </div>
  //         <div className="overview-influencer-container">
  //           <div className="overview-left-side">
  //             <div className="influencer-reelax-score-container">
  //               <div>
  //                 <Icon iconName="plusGreenIcon" />
  //               </div>

  //               <div>
  //                 <CustomText
  //                   p
  //                   text={"REELAX SCORE"}
  //                   className="text-16  m-b-10"
  //                 />
  //                 <CustomText
  //                   p
  //                   text={
  //                     "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Et dolor voluptatem natus animi voluptatibus non officia, rerum iusto ab molestiae.</span>"
  //                   }
  //                   className="text-14"
  //                 />
  //               </div>
  //             </div>

  //             <div className="influencer-reelax-score-container">
  //               <div>
  //                 <Icon iconName="plusGreenIcon" />
  //               </div>

  //               <div>
  //                 <CustomText
  //                   p
  //                   text={"REELAX SCORE"}
  //                   className="text-16  m-b-10"
  //                 />
  //                 <CustomText
  //                   p
  //                   text={
  //                     "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Et dolor voluptatem natus animi voluptatibus non officia, rerum iusto ab molestiae.</span>"
  //                   }
  //                   className="text-14"
  //                 />
  //               </div>
  //             </div>
  //             <div className="influencer-reelax-score-container">
  //               <div>
  //                 <Icon iconName="plusGreenIcon" />
  //               </div>

  //               <div>
  //                 <CustomText
  //                   p
  //                   text={"REELAX SCORE"}
  //                   className="text-16  m-b-10"
  //                 />
  //                 <CustomText
  //                   p
  //                   text={
  //                     "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Et dolor voluptatem natus animi voluptatibus non officia, rerum iusto ab molestiae.</span>"
  //                   }
  //                   className="text-14"
  //                 />
  //               </div>
  //             </div>
  //           </div>
  //           <div className="overview-left-side">
  //             <div className="influencer-reelax-score-container">
  //               <div>
  //                 <Icon iconName="plusGreenIcon" />
  //               </div>

  //               <div>
  //                 <CustomText
  //                   p
  //                   text={"REELAX SCORE"}
  //                   className="text-16  m-b-10"
  //                 />
  //                 <CustomText
  //                   p
  //                   text={
  //                     "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Et dolor voluptatem natus animi voluptatibus non officia, rerum iusto ab molestiae.</span>"
  //                   }
  //                   className="text-14"
  //                 />
  //               </div>
  //             </div>
  //             <div className="influencer-reelax-score-container">
  //               <div>
  //                 <Icon iconName="plusGreenIcon" />
  //               </div>

  //               <div>
  //                 <CustomText
  //                   p
  //                   text={"REELAX SCORE"}
  //                   className="text-16  m-b-10"
  //                 />
  //                 <CustomText
  //                   p
  //                   text={
  //                     "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Et dolor voluptatem natus animi voluptatibus non officia, rerum iusto ab molestiae.</span>"
  //                   }
  //                   className="text-14"
  //                 />
  //               </div>
  //             </div>
  //             <div className="influencer-reelax-score-container">
  //               <div>
  //                 <Icon iconName="plusGreenIcon" />
  //               </div>

  //               <div>
  //                 <CustomText
  //                   p
  //                   text={"REELAX SCORE"}
  //                   className="text-16  m-b-10"
  //                 />
  //                 <CustomText
  //                   p
  //                   text={
  //                     "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Et dolor voluptatem natus animi voluptatibus non officia, rerum iusto ab molestiae.</span>"
  //                   }
  //                   className="text-14"
  //                 />
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  function getHashTag() {
    // Step 1: Extract and split hashtags safely
    const hashtagsArray = props?.influencerContentList
      ?.map((item) =>
        item?.hash_tags
          ? item?.hash_tags?.split(",").map((tag) => tag?.trim())
          : []
      )
      .flat(); // Flatten the array

    // Step 2: Flatten manually using reduce
    const flattened = hashtagsArray?.reduce(
      (acc, curr) => acc.concat(curr),
      []
    );

    // Step 3: Remove duplicates using indexOf
    const uniqueHashTags = flattened?.filter(
      (tag, index, arr) => arr.indexOf(tag) === index
    );

    return uniqueHashTags;
  }

  const handlePlatformClick = (url) => {
    // Open the platform URL here
    window.open(url, "_blank");
  };
  return (
    <div className="profile-tab-main-container">
      {renderSidebarInfluencer()}
      {props.contentLoading ? (
        <div style={{ minHeight: 500, margin: "auto", display: "flex" }}>
          <Loader />
        </div>
      ) : (
        <div className="flex-1 p-l-20 profile-overflow-container">
          <div
            style={{ display: "flex", gap: "20px", flexDirection: "column" }}
          >
            <div
              ref={sectionsRef.overview}
              className="card-count-main-container"
            >
              {renderCardCount(
                props?.instagramEngagementData?.[0]?.platform_id == "btll"
                  ? "FOLLOWERS"
                  : "SUBSCRIBERS",
                Utils.changeNumberFormate(
                  props?.instagramEngagementData[0]?.followers
                )
              )}
              {renderCardCount(
                "ENGAGEMENT RATE(%)",
                props?.instagramEngagementData[0]?.engagement_rate
              )}
              {renderCardCount(
                "TOTAL CONTENTS",
                props?.instagramEngagementData[0]?.content_count
              )}
            </div>
            {/* <div ref={sectionsRef.engagement}>{renderOverview()}</div> */}
            <div ref={sectionsRef.engagement}>
              {renderInstagramContentData()}
              <div className="m-t-10">
                {" "}
                {renderBarChart(youtubeEngDataList())}
              </div>
            </div>
            <div ref={sectionsRef.content}>{renderInstagramContent()}</div>
            <div ref={sectionsRef.audience}>{renderInstagramAudience()}</div>
            <div
              ref={selectedSection.url}
              onClick={() =>
                handlePlatformClick(
                  Utils.getSocialUrl(
                    props?.instagramEngagementData[0]?.platform_id,
                    props?.instagramEngagementData[0]?.identifier_id
                  )
                )
              }
            ></div>

            {/* {chartInstagram()} */}
          </div>
        </div>
      )}
    </div>
  );
}

export default InfluencerInstagramTab;
