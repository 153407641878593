import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Core from "../../common/clientSdk";
import { CustomText, Popup, PrimaryCta } from "../../components";
import { toast } from "react-toastify";

function CreateCampaignCollection(props) {
  console.log("props0000", props);

  const [isLoading, setLoading] = useState(false);

  function updateProgress(status) {
    setLoading(status);
  }

  function postAddInfluencerCollection() {
    const params = {
      collection_id: props.selectedCollectionId,
      influencer_id: props.influencer_id,
    };
    Core.postAddInfluencerCollection(
      postAddInfluencerCollectionSuccess,
      postAddInfluencerCollectionFailed,
      updateProgress,
      params
    );
  }

  function postAddInfluencerCollectionSuccess(response_data) {
    toast.success("Influencer Added List Successfully");
    props.getCollectionInfluencerList();
    setLoading(false);
    props.closeModal();
  }

  function postAddInfluencerCollectionFailed(errorList) {
    props.closeModal();
    props.getCollectionInfluencerList();
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  return (
    <div>
      <Popup
        isOpen={props.modalIsOpenCollectionGrid}
        onClose={() => {
          props.closeModal();
        }}
        headerText={"Shortlist"}
        footerButton1Text={"No"}
        footerButton1Action={() => {
          props.closeModal();
        }}
        footerButton2Text={"Yes"}
        footerButton2Action={() => postAddInfluencerCollection()}
        isLoading={isLoading}
        width="40%"
        bottomSheetMaxHeight={0.3}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItemsY: "center",
          }}
        >
          <CustomText p text={`Do you want to add influencer in list?`} />
        </div>
      </Popup>
    </div>
  );
}

export default CreateCampaignCollection;
