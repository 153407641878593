import React from "react";
import { TransitionHistory, CustomText, FallBack } from "../../components";
import SideMenu from "../sideMenu";
import Header from "../header";
import { useState, useEffect } from "react";
import Core from "../../common/clientSdk";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loader } from "../loader";
import { useSnackbar } from "react-simple-snackbar";
import Utils from "../../common/utils";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const limit = 10;
function CreditsTransactionScreen() {
  const [start, setStart] = useState(0);
  const [next, setNext] = useState(0);
  const [creditHistory, setCreditHistory] = useState([]);
  const [loading, setLoading] = useState(true);

  const [openSnackbar] = useSnackbar();
  const navigate = useNavigate();
  var redux_selector = useSelector((state) => {
    return {
      UserData: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const { UserData = {} } = redux_selector;
  const { brand_deep_data = {} } = UserData;
  const { user_data = {} } = UserData;
  const { brand_data = {} } = brand_deep_data;
  const { org_id = {} } = brand_data;

  useEffect(() => {
    getCreditsTransactionList(start, updateProgress);
  }, []);

  function updateProgress(status) {}

  function getCreditsTransactionList(startPage, progress_cb) {
    var params = {
      limit: limit,
      start: startPage,
    };
    Core.getCreditsTransactionList(
      getCreditsTransactionListSuccess,
      getCreditsTransactionListFailed,
      progress_cb,
      params
    );
  }

  function getCreditsTransactionListSuccess(response_data) {
    if (next == 0 || next == null) {
      // API called first time
      setCreditHistory(response_data["credit_list"]);
    } else {
      setCreditHistory([...creditHistory, ...response_data["credit_list"]]);
    }
    setNext(response_data?.pagination_data?.next);
    setLoading(false);
  }

  function getCreditsTransactionListFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function influencerDetailPage(influencer_id) {
    navigate(`/influencer/${influencer_id}`);
  }

  return (
    <div className="dashboard-container">
      <SideMenu />
      <div className="main-screen">
        <Header />
        <div className="page-container">
          {loading ? (
            <div className="loader-mid">
              <Loader />
            </div>
          ) : (
            <>
              <div className="team-member-header">
                <CustomText
                  p
                  text={"Credit Transaction"}
                  textColor={"text_color_light"}
                  fontSize={"font_size_2xl"}
                />
              </div>
              <InfiniteScroll
                dataLength={creditHistory.length}
                next={() => getCreditsTransactionList(next, updateProgress)}
                hasMore={next !== null}
                // loader={<Loader />}
              >
                {creditHistory.length !== 0 ? (
                  <div class="content-row-parent">
                    {creditHistory &&
                      creditHistory.map((item) => {

                        return (
                          <div className="content-row">
                            <TransitionHistory
                              Detail={` ${Utils.creditTypeSpent(
                                item.credit_type
                              )} at ${Utils.convertUnixToDate(
                                item.credit_date,
                                user_data.time_zone
                              )}`}
                              id_title={"Transaction ID:"}
                              Id={item.transaction_id.toUpperCase()}
                              AmountColor={
                                item.credit_type ==
                                  "USER_CREDIT_PURCHASE_DEBIT" ||
                                item.credit_type == "USER_CREDIT_SIGNUP_CREDIT"
                                  ? "text_credit_color"
                                  : "text_debit_color"
                              }
                              signColor={
                                item.credit_type ==
                                  "USER_CREDIT_PURCHASE_DEBIT" ||
                                item.credit_type == "USER_CREDIT_SIGNUP_CREDIT"
                                  ? "text_credit_color"
                                  : "text_debit_color"
                              }
                              Amount={`${item.credits} Credits`}
                              influencerTitle={
                                Utils.isNull(item.transaction_id)
                                  ? "Influencer ID"
                                  : ""
                              }
                              influencerId={
                                Utils.isNull(item.influencer_id)
                                  ? item.influencer_id
                                  : ""
                              }
                              onClick={
                                Utils.isNull(item.influencer_id)
                                  ? () =>
                                      influencerDetailPage(item.influencer_id)
                                  : ""
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                ) : (
                  <FallBack
                    heading={"No Credit Transaction available"}
                    title={"Credit Transaction list will be shown here"}
                  />
                )}
              </InfiniteScroll>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default CreditsTransactionScreen;
