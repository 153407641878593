import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Core from "../../common/clientSdk";
import { toast } from "react-toastify";
import HeaderShare from "../../modal/HeaderShare";
import { Loader } from "../loader";
import { FallBack } from "../../components";
import InfluencerContentCard from "../../features/influecnerContentCard";
import Utils from "../../common/utils";

const ContentResearchContent = () => {
  const { keyword } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [influencerContentCards, setInfluencerContentCards] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    getSearchInfluencerAI();
  }, [keyword]);

  useEffect(() => {
    contentAssignmentInfluencerData();
  }, [data]);

  function getSearchInfluencerAI() {
    let params = {
      search: decodeURIComponent(keyword),
      identifier: "reelax-content-ai",
      caption:"chunk"
    };

    Core.getSearchInfluencerAI(
      getSearchInfluencerAISuccess,
      getSearchInfluencerAIFailed,
      (status) => setLoading(status),
      params
    );
  }

  function getSearchInfluencerAISuccess(res, response) {
    console.log("API Response:", response); // Log full response

    if (response?.success && response?.response?.value) {
      setData(response?.response?.value); // Store influencer data array
      console.log("Extracted Data:", response?.response?.value); // Log extracted value array
    } else {
      console.error("Invalid API response structure:", response);
      setData([]); // Fallback to an empty array
    }
  }

  console.log("data", data);
  function getSearchInfluencerAIFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  function contentAssignmentInfluencerData() {
    if (!data) return; // Ensure data exists

    const mappedData = data?.map((item) => {
      const platform = item?.platform_id || "Unknown Platform";

      return {
        imageSource: null,
        thumbnail: item?.thumbnail,
        caption: item?.chunk ? item?.chunk : "",
        content_type_id: item?.content_type_id,
        identifier_id:item?.identifier_id,
        profile_pic: item?.profile_pic || "",
        shortcode: item?.shortcode,
        influencerUsername: item?.owner_name || "", 
        assignmentId: null,
        influencerId:item?.influencer_id,
        influencerHandle: item?.identifier_id, // Handle with '@'
        like_count: item?.like_count || 0, 
        comment_count: item?.comment_count || 0,
        campaignId: null,
        platform_id:platform,
        url: Utils.generateContentUrl(
          platform ,
          item?.content_type_id,
          item?.shortcode
        ),
        view_count: item?.view_count || 0,
      };
    });

    setInfluencerContentCards(mappedData); // Store in state
  }

  return (
    <div>
      <div
        className=""
        style={{
          position: "fixed",
          top: 0,
          backgroundColor: "white",
          zIndex: 100,
          paddingBottom: 10,
        }}
      >
        <HeaderShare showGoBackIcon={true} onGoBack={() => navigate("/content-research")} />
      </div>
      <div className="content-research-results">
        {loading ? (
          <div className="fall-back-center-campaign">
                    <Loader />
                  </div>
        ) : influencerContentCards.length > 0 ? (
          <div
            className="content_all_influencer_analytics_container"
            style={{ padding: 20 }}
          >
            {influencerContentCards.map((item, index) => (
              <InfluencerContentCard key={index} item={item} />
            ))}
          </div>
        ) : (
          <div className="fall-back-center-campaign">
            <FallBack
              heading={"No Results Found"}
              title={"Try other search..."}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ContentResearchContent;
