import React, { useState, useEffect } from "react";
import {
  CustomBox,
  CustomIconCta,
  CustomText,
  Image,
  ModalComponent,
  PrimaryCta,
} from "../../components";
import Icon from "../../components/core_component/icon";
import Utils from "../../common/utils";
import CreateCollectionGrid from "../collection/collectionGrid";
import Core from "../../common/clientSdk";
import InfluencerProfileContentCard from "./influencerProfileContentCard";
import * as CampaignData from "../../common/data/campaign";
function InfluencerProfile(props) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [modalIsOpenCollectionGrid, setModalIsOpenCollectionGrid] =
    useState(false);
  const [collectionData, setCollectionData] = useState({});
  const [triggeredBy, setTriggeredBy] = useState(null);
  const [selectedInfluencerRows, setSelectedInfluencerRows] = useState([]);
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [updateCollectionData, setUpdateCollectionData] = useState(null);
  const [openCollection, setOpenCollection] = useState(false);
  const [addInfluencerLoading, setAddInfluencerLoading] = useState(false);

  const [isHover, setIsHover] = useState(false);
  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
  };

  function addToCollectionModalOpen() {
    setToggleClearRows(!toggledClearRows);
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (updateCollectionData) {
      getCollectionInfluencerList();
    }
  }, [updateCollectionData]);

  function updateProgress() {}

  function getCollectionInfluencerList(isLoading) {
    const params = {
      collection_id: updateCollectionData?.collection_id,
    };
    Core.getCollectionInfluencerList(
      getCollectionInfluencerListSuccess,
      getCollectionInfluencerListFailed,
      (...args) => {
        updateProgress(...args);
        if (!isLoading) {
          return;
        }
      },
      params
    );
  }

  function getCollectionInfluencerListSuccess(response_data) {
    setCollectionData(response_data?.collection_deep_data?.collection_data);
  }

  function getCollectionInfluencerListFailed(errorList) {
    // toast.error(errorList.map((item) => item.m).join(", "));
    // setCollectionInfluencerList([]);
  }

  function postAddInfluencerCollection() {
    const params = {
      collection_id: updateCollectionData?.collection_id,
      influencer_id: props?.influencerData?.influencer_id,
    };
    Core.postAddInfluencerCollection(
      postAddInfluencerCollectionSuccess,
      postAddInfluencerCollectionFailed,
      () => {},
      params
    );
  }

  function postAddInfluencerCollectionSuccess(response_data) {
    // toast.success("Influencer Added Collection Successfully");
    addToCollectionModalClose();
  }

  function postAddInfluencerCollectionFailed(errorList) {
    addToCollectionModalClose();
    // toast.error(errorList.map((item) => item.m).join(", "));
  }

  const addToCollectionModalClose = () => {
    setOpenCollection(false);
  };

  let influencerDp = props.influencerData?.profile_picture
    ? Utils.generateInfluencerDpUrl(
        props.influencerData?.influencer_id,
        props.influencerData?.profile_picture
      )
    : require("../../resources/images/user-copy.png");

  function openModalCollectionGrid() {
    if (modalIsOpenCollectionGrid == false) {
      setModalIsOpenCollectionGrid(true);
    } else {
      setModalIsOpenCollectionGrid(false);
    }
  }
  function onSuccessModalCollectionGrid(collection_data) {
    if (collectionData) {
      setUpdateCollectionData(collection_data);
    }
    setSelectedInfluencerRows([]);
    handleClearRows();
  }
  function closeModalCollectionGrid() {
    setModalIsOpenCollectionGrid(false);
  }

  function renderInfluencerLeft() {
    return (
      <div className="influencer-profile-inner-container">
        <div>
          <Image
            className="influencer-detail-image"
            source={influencerDp}
            fallBack={require("../../resources/images/user-copy.png")}
          />
        </div>
        <div className="influencer-profile-personal-detail">
          <CustomText
            p
            text={props?.influencerData?.name}
            className="influencer-profile__name h6Text gray-gray-500"
          />
          {props.categoryListData.length > 0 && (
            <>
              <div className="influencer-profile-categories">
                <CustomText
                  p
                  text={
                    Utils.getSubCategoryData(props?.influencerData?.profession)
                      ?.name
                  }
                  className=" caption_3 gray-gray-500"
                />
              </div>
            </>
          )}

          <div className="influencer-profile-profession">
            <CustomText
              p
              text={props?.influencerData?.note}
              className="caption_3 gray-gray-500"
            />
          </div>
          {props.influencerData && (
            <div className="flex-row gap-8">
              <Icon iconName="locationNewIcon" />

              <CustomText
                p
                text={Utils.getLocationInfluencer(props?.influencerData)}
                className="caption_3"
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  const handleShortlistClick = () => {
    setTriggeredBy("shortlist");
  };

  function checkAddToCollectionInfluencer() {
    setUpdateCollectionData(!updateCollectionData);
  }

  function renderInfluencerRight() {
    return (
      <div className="profile-right-sidebar-btn-container">
        <div className="update-influencer-profile-container">
          {/* <a>update profile</a>
          <Icon iconName="refreshIcon" /> */}
          {/* <a className="update-influencer-profile-btn">update profile....</a>
          <div className="update-profile-icon">
            <Icon iconName="refreshIcon" color="#1977f2" />
          </div> */}
        </div>
        <div className=" influencer-detail-buttons" >
          {/* <CustomIconCta
            text="Add to list"
            onClick={() => {
              checkAddToCollectionInfluencer(
                props?.influencerData?.influencer_id
              );
            }}
            background={"#1977F2"}
            className="add-to-list-btn-cta"
            color={"white"}
            padding={!isMobile ? "7px 12px" : "7px 12px"}
          /> */}
          {!props.contactRequest?.is_requested && (
            <>
              {" "}
              {(props.influencerData?.has_phone_number ||
                props.influencerData?.has_email) && (
                <div
                  onMouseEnter={() => setIsHover(true)}
                  onMouseLeave={() => setIsHover(false)}
                >
                  <CustomIconCta
                    iconLeft="contactIcon"
                    iconColor={isHover && "#030304"}
                    text="Get Contact "
                    onClick={() => props.openGetContactDetails()}
                    className="contact-btn-cta"
                    background={"#fff"}
                    color={"#b3b3b6"}
                    border={"1px solid #b3b3b6"}
                    padding={!isMobile ? "7px 12px" : "7px 12px"}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
  return (
    <div className="influencer-profile-main-container">
      <section className="influencer-detail-top-container">
        {renderInfluencerLeft()}
        {renderInfluencerRight()}
      </section>

      <div style={{ zIndex: 10 }}>
        {updateCollectionData && (
          <CreateCollectionGrid
            closeModal={checkAddToCollectionInfluencer}
            id={props.influencerData?.influencer_id}
            collection_data={updateCollectionData}
            onSuccessModalCollectionGrid={onSuccessModalCollectionGrid}
            selectedInfluencerRows={selectedInfluencerRows}
            triggeredBy={triggeredBy}
          />
        )}
      </div>
    </div>
  );
}

export default InfluencerProfile;
