import React, { useEffect, useRef, useState } from "react";
import {
  CustomTextArea,
  CustomText,
  Image,
  UnderLinedLinkCta,
  PrimaryCta,
  CustomTextInput,
  Video,
} from "../../components";
import Icon from "../../components/core_component/icon";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Sdk from "../../common/core/module";
import moment from "moment";
//import { toast } from "react-hot-toast";
import Core from "../../common/clientSdk";
import { CustomDropzone } from "../common/dropzone";
import { useNavigate, useOutlet, useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { useSnackbar } from "react-simple-snackbar";
import UploadImageS3 from "../../common/s3";
import { useSelector } from "react-redux";
import Utils from "../../common/utils";
import { Loader } from "../loader";
import ReactGA from "react-ga";
import RichTextEditor from "react-rte";
import { WithContext as ReactTags } from "react-tag-input";
import CreateCampaignSuccess from "./createCampaignSuccess";
import ReviewCampaign from "./reviewCampaign";
import CampaignBestMatchInfluencer from "./campaignBestMatchInfluencer";
import SubscriptionModal from "../../modal/subscriptionModal";

const toolbarConfig = {
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "LINK_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
    "HISTORY_BUTTONS",
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
  HISTORY_BUTTONS: [{}],
  LINK_BUTTONS: [{}],
};
function CampaignDetails(props) {
  const currentUnixDate = Sdk.Campaign.getDateString(new Date());
  const [title, setTitle] = useState("");
  //const [instructionNote, setInstructionNote] = useState("");
  const [maxBidAmount, setMaxBidAmount] = useState("");
  const [endDate, setEndDate] = useState(currentUnixDate + 2592000);
  const [deliveryDate, setDeliveryDate] = useState(currentUnixDate + 2764800);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingDraft, setLoadingDraft] = useState(false);
  // const [toast] = useSnackbar();
  const [selectedLocalImage, setSelectedLocalImage] = useState([]);
  const [imageId, setImageId] = useState([]);
  const [isUploading, setUploading] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [ctaUrl, setCtaUrl] = useState("");
  const [productUrl, setProductUrl] = useState("");
  const [influencerCount, setInfluenceCount] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [ctaText, setCtaText] = useState("");
  const [tags, setTags] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState();
  const [createCampaignResponse, setCreateCampaignResponse] = useState(null);
  const [instructionNote, setInstructionNote] = useState(
    RichTextEditor.createEmptyValue()
  );
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpenSuccess, setModalIsOpenSuccess] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isBarter, setIsBarter] = useState(null);
  const [bestMatchModal, setBestMatchModal] = useState(false);
  const [openSubscription, setOpenSubscription] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const openModalBestMatch = () => {
    if (bestMatchModal == false) {
      setBestMatchModal(true);
    } else {
      setBestMatchModal(false);
    }
  };

  useEffect(() => {
    subscriptionUserDetail();
  }, []);

  const closeModalBestMatch = () => {
    setBestMatchModal(false);
  };
  const imageRef = useRef(null);
  const redux_selector = useSelector((state) => {
    return {
      UserData: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });
  const { UserData = {} } = redux_selector;
  const { brand_deep_data = {} } = UserData;
  const { org_deep_data = {} } = UserData;
  const { user_data = {} } = UserData;
  var navigate = useNavigate();
  const followerLevel =
    props.campaignDeepData.influencer_data?.minimum_followers;
  const paymentType = props?.campaignDeepData?.campaign_data?.collab_type;
  const campaignType = props?.campaignDeepData?.campaign_data.type;
  function handleFileClick() {
    imageRef.current.click();
  }
  const instructionNoteText = instructionNote.toString("html");

  function checkCredits() {
    return org_deep_data?.org_data?.credits > 9;
  }

  const handleClick = (index) => {
    setShowImage(!showImage);
    setSelectedIndex(index);
  };

  const openModalSuccess = () => {
    if (modalIsOpenSuccess == false) {
      setModalIsOpenSuccess(true);
    } else {
      setModalIsOpenSuccess(false);
    }
  };

  const closeModalSuccess = () => {
    setModalIsOpenSuccess(false);
  };

  function subscriptionUserDetail() {
    var params = {};
    Core.subscriptionUserDetail(
      subscriptionUserDetailSuccess,
      subscriptionUserDetailFailed,
      updateProgress,
      params
    );
  }

  function subscriptionUserDetailSuccess(response_data) {
    const plans = response_data?.subscription_list;
    setSubscriptionData(plans);
    props?.onLoadHeader && props?.onLoadHeader(plans);
  }

  function subscriptionUserDetailFailed(errorList) {
    openSubscriptionModal();
    toast.error(errorList);
  }

  function openSubscriptionModal() {
    setOpenSubscription(!openSubscription);
  }
  const openModal = () => {
    if (org_deep_data?.org_data?.credits < 10) {
      openSubscriptionModal();

      toast.info("Not enough credits to create a campaign");
      return false;
    } else {
      if (!formValidate()) {
        return false;
      } else {
        if (modalIsOpen == false) {
          setModalIsOpen(true);
        } else {
          setModalIsOpen(false);
        }
      }
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const updateEndDate = (date) => {
    var unixDate = Sdk.Campaign.getDateString(date);
    setEndDate(unixDate);
  };

  const updateDeliveryDate = (date) => {
    var unixDate = Sdk.Campaign.getDateString(date);
    setDeliveryDate(unixDate);
  };
  const handleBack = () => {
    props.previousStep();
  };

  function generateUploadUrl(uploadedFileObject) {
    var params = {
      action: "sample_content",
      id: props?.brand_id,
      file_type: uploadedFileObject.type,
    };
    setUploading(true);
    Core.getGenerateUploadUrl(
      generateUrlSuccess,
      generateUrlFailed,
      () => {},
      params,
      props?.auth_token,
      uploadedFileObject
    );
  }

  function generateUrlSuccess(response, uploadedFileObject) {
    if (response) {
      // upload image file on server call uploadImageS3
      UploadImageS3(uploadedFileObject, response?.upload_data, (error) => {
        setUploading(false);
        if (error) {
          return toast.info("Upload failed");
        }
        let newImageId = imageId;
        imageId.push(response?.upload_data?.media_id);
        setImageId(newImageId);
      });
    } else {
      setUploading(false);
      toast.info("Something went wrong");
    }
  }

  function generateUrlFailed(error) {
    toast.info("Something went wrong");
    setUploading(false);
  }

  const handleNext = () => {
    let newCampaignDeepData = props?.campaignDeepData;
    newCampaignDeepData["campaign_data"]["name"] = title;
    newCampaignDeepData["campaign_data"]["url"] = ctaUrl;
    newCampaignDeepData["campaign_data"]["product_price"] = Utils.convertToINR(
      productPrice,
      user_data?.country
    );
    newCampaignDeepData["campaign_data"]["product_url"] = productUrl;
    newCampaignDeepData["campaign_data"]["is_barter"] = isBarter;
    newCampaignDeepData["campaign_data"]["units"] = influencerCount;
    newCampaignDeepData["campaign_data"]["max_budget"] = Utils.convertToINR(
      Number(maxBidAmount),
      user_data?.country
    );
    newCampaignDeepData["campaign_data"]["end_date"] = endDate;
    newCampaignDeepData["campaign_data"]["delivery_date"] = deliveryDate;
    newCampaignDeepData["reward_data"]["type"] = "cash";
    newCampaignDeepData["instruction_data"] = {};
    newCampaignDeepData["instruction_data"]["instruction_note"] =
      instructionNoteText;
    newCampaignDeepData["sample_content_data"] = imageId;
    // if(){
    //         const params = {
    //     campaign_deep_data: JSON.stringify(newCampaignDeepData),
    //   };
    //   Core.createCampaignUser(
    //     createCampaignUserSuccess,
    //     createCampaignUserFailed,
    //     (status) => setLoading(status),
    //     params
    //   );
    // }
    if (!formValidate()) {
      return false;
    } else if (checkCredits()) {
      props.nextStep();
      // const params = {
      //   campaign_deep_data: JSON.stringify(newCampaignDeepData),
      // };
      // Core.createCampaignUser(
      //   createCampaignUserSuccess,
      //   createCampaignUserFailed,
      //   (status) => setLoading(status),
      //   params
      // );
    } else {
      props.setCampaignDeepData((prev_state) => {
        return newCampaignDeepData;
      });

      props.nextStep();
    }
  };

  function createCampaignUserSuccess(response) {
    setCreateCampaignResponse(response?.campaign_deep_data);
    // closeModal();
    openModalSuccess();

    setTimeout(() => {
      closeModalSuccess();
      openModalBestMatch();
    }, 2000);
  }

  function createCampaignUserFailed(errorList) {
    closeModal();
    toast.info(errorList.map((item) => item.m).join(", "));
    setLoading(false);
  }

  const postSaveCampaignUser = () => {
    let newCampaignDeepData = props?.campaignDeepData;
    newCampaignDeepData["campaign_data"]["name"] = title;
    newCampaignDeepData["campaign_data"]["url"] = ctaUrl;
    newCampaignDeepData["campaign_data"]["product_price"] = productPrice;
    newCampaignDeepData["campaign_data"]["product_url"] = productUrl;
    newCampaignDeepData["campaign_data"]["max_budget"] = Number(maxBidAmount);
    newCampaignDeepData["campaign_data"]["end_date"] = endDate;
    newCampaignDeepData["campaign_data"]["delivery_date"] = deliveryDate;
    newCampaignDeepData["reward_data"]["type"] = "cash";
    newCampaignDeepData["instruction_data"] = {};
    newCampaignDeepData["instruction_data"]["instruction_note"] =
      instructionNoteText;
    newCampaignDeepData["sample_content_data"] = imageId;

    if (!formValidate()) {
      return false;
    } else {
      const params = {
        campaign_deep_data: JSON.stringify(newCampaignDeepData),
      };

      Core.postSaveCampaignUser(
        postSaveCampaignUserSuccess,
        postSaveCampaignUserFailed,
        (status) => setLoadingDraft(status),
        params
      );
    }
  };

  function postSaveCampaignUserSuccess(response) {
    navigate("/campaigns");
  }

  function postSaveCampaignUserFailed(errorList) {
    toast.info(errorList);
  }

  function postUpdateCampaignSuccess(response) {
    navigate("/campaigns");
  }

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  function postUpdateCampaignFailed(errorList) {
    toast.info(errorList);
  }

  function updateProgress(status) {
    setLoading(status);
  }
  function googleAnalytics() {
    ReactGA.event({
      category: "User",
      action: "REELAX_ADS_WEB_LOGIN",
    });
  }

  const handleFinish = () => {
    props.lastStep();
  };
  const removeHtmlTags = () => {
    const doc = new DOMParser().parseFromString(
      instructionNoteText,
      "text/html"
    );
    return doc.body.textContent || "";
  };

  let description = removeHtmlTags();

  useEffect(() => {
    if (currentUnixDate == endDate) {
      toast.info("End date should be later than today");
    }
  }, [endDate]);

  function creatorLevel() {}

  function formValidate() {
    if (title?.length < 6) {
      toast.info("Please enter minimum 6 character for campaign title");
      return false;
    }

    if (paymentType == "AUCTION") {
      if (!maxBidAmount) {
        toast.info("Please enter budget");
        return false;
      }
      if (Number(Utils.convertToINR(maxBidAmount, user_data?.country)) < 1000) {
        toast.info(
          `Per Influencer Budget can not be lower than ${Utils.convertFromINR(
            1000,
            user_data?.country
          )}`
        );
        return false;
      }

      if (
        followerLevel == 10000 &&
        Number(Utils.convertToINR(maxBidAmount, user_data?.country)) < 5000
      ) {
        toast.info(
          `Per Influencer Budget can not be lower than  ${Utils.convertFromINR(
            5000,
            user_data?.country
          )}`
        );
        return false;
      }
      if (
        followerLevel == 50000 &&
        Number(Utils.convertToINR(maxBidAmount, user_data?.country)) < 10000
      ) {
        toast.info(
          `Per Influencer Budget can not be lower than ${Utils.convertFromINR(
            10000,
            user_data?.country
          )}`
        );
        return false;
      }

      if (
        followerLevel == 250000 &&
        Number(Utils.convertToINR(maxBidAmount, props?.user_data?.country)) <
          30000
      ) {
        toast.info(
          `Per Influencer Budget can not be more than ${Utils.convertFromINR(
            30000,
            props?.user_data?.country
          )}`
        );
        return false;
      }
    }

    if (!moment.unix(endDate)) {
      toast.info("Please select campaign end date");
      return false;
    }
    if (moment.unix(deliveryDate).diff(moment.unix(endDate), "days") < 1) {
      toast.info("Delivery date should be later than campaign end date");
      return false;
    }
    if (currentUnixDate >= endDate) {
      toast.info("End date should be later than today");
      return false;
    }
    if (description?.length < 20) {
      toast.info("Please enter at least 20 character in creator's instruction");

      return false;
    }

    const urlRegEx = /^(http|https):\/\/[^ "]+$/;
    if (ctaUrl?.length > 0 && !urlRegEx.test(ctaUrl)) {
      toast.info("Please enter at correct url");
      return false;
    }

    if (paymentType == "BARTER" && Number(influencerCount) < 10) {
      toast.info("Please fill atleast 10 influencer");
      return false;
    }
    if (Number(influencerCount) < 1) {
      toast.info("Please fill atleast 1 influencer");
      return false;
    }

    if (checked || paymentType == "BARTER") {
      if (!productPrice) {
        toast.info("Please Enter Product Price");
        return false;
      }
      if (Utils.convertToINR(productPrice, user_data?.country) < 100) {
        toast.info(
          `Product Price should be atleast ${Utils.convertFromINR(
            100,
            user_data?.country
          )}`
        );
        return false;
      }
      if (!productUrl) {
        toast.info("Please enter product url");
        return false;
      }
      if (isBarter !== 0 && isBarter !== 1 && paymentType !== "AUCTION") {
        toast.info("Please Select delivery type of product.");
        return false;
      }

      if (productUrl?.length > 0 && !urlRegEx.test(productUrl)) {
        toast.info("Please enter at correct product url");
        return false;
      }
    }

    return true;
  }

  // function showError(message) {
  //   toast.info.error(message);
  // }
  // drop zone start

  const handleCropImage = (acceptedFiles) => {
    if (acceptedFiles?.length + selectedLocalImage?.length <= 3) {
      const updatedImages = [...selectedLocalImage];
      for (let i = 0; i < acceptedFiles?.length; i++) {
        updatedImages.push(acceptedFiles[i]);
        generateUploadUrl(acceptedFiles[i]);
      }
      setSelectedLocalImage(updatedImages);
    } else {
      toast.info("Maximum 3 files allowed");
    }
  };

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    onDrop: handleCropImage,
    accept: "image/*, video/mp4", // Limit to image files
    // maxSize: 5000000, // Limit to 5MB per file
    multiple: true, // Allow multiple file uploads
  });

  // dropZone end

  //  tag add and delete
  const handleDelete = (i) => {
    const newTags = [...tags];
    newTags.splice(i, 1);
    setTags(newTags);
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const today = new Date();
  const nextMonth = new Date(today);
  nextMonth.setMonth(today.getMonth() + 1);

  return (
    <div>
      <div className="create-campaign-container">
        <div>
          <div className="create-campaign-detail-page m-t-15 type-campaign-container-new">
            <div className="m-b-10">
              <div className="m-b-8">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Title"}
                />
              </div>

              <CustomTextInput
                value={title}
                height={51}
                placeholder={"New year sale"}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="m-b-10">
              {paymentType == "AUCTION" && (
                <div>
                  <div
                    style={{ display: "flex", flexDirection: "row", gap: 4 }}
                    className="m-b-8"
                  >
                    <CustomText
                      p
                      fontSize={"font_size_medium"}
                      textColor={"text_color_primary"}
                      text={"Budget"}
                    />
                  </div>
                  <CustomTextInput
                    height={51}
                    placeholder={"Per influencer budget"}
                    value={maxBidAmount}
                    onChange={(e) =>
                      setMaxBidAmount(e.target.value.replace(/\D/g, ""))
                    }
                  />
                </div>
              )}
            </div>

            {/* <div className="date-picker">
              <div className="m-b-8">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"End Date"}
                />
              </div>
              <DatePicker
                className={"react-datepicker-size"}
                selected={moment.unix(endDate).toDate()}
                onChange={(date) => updateEndDate(date)}
                format="MM-dd-y"
                maxDate={nextMonth}
              />
            </div> */}
            {/* <div className="date-picker">
              <div className="m-b-8">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Delivery Date"}
                />
              </div>
              <DatePicker
                className={"react-datepicker-size"}
                selected={moment.unix(deliveryDate).toDate()}
                onChange={(date) => updateDeliveryDate(date)}
                format="MM-dd-y"
              />
            </div> */}

            <div className="m-b-10">
              <div className="m-b-8">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Destination Link"}
                />
              </div>
              <CustomTextInput
                value={ctaUrl}
                height={51}
                placeholder={"e.g. https://mystore.com/collection"}
                onChange={(e) => setCtaUrl(e.target.value)}
              />
            </div>
            <div className="m-b-10">
              <div className="m-b-8">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Influencer count"}
                />
              </div>
              <CustomTextInput
                type="number"
                value={influencerCount}
                height={51}
                placeholder={"Number of Influencers"}
                onChange={(e) =>
                  setInfluenceCount(e.target.value.replace(/\D/g, ""))
                }
              />
            </div>

            {/* {campaignType == "social_media" && (
              <div className="">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_light"}
                  text={"Link Text"}
                />

                <CustomTextInput
                  invert
                  value={ctaText}
                  placeholder={"Link Text"}
                  onChange={(e) => setCtaText(e.target.value)}
                />
              </div>
            )} */}

            {/* {campaignType == "social_media" && (
              <div>
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_light"}
                  text={"Tag"}
                />

                <ReactTags
                  tags={tags}
                  handleDelete={handleDelete}
                  handleAddition={handleAddition}
                  autofocus={false}
                  inputFieldPosition="top"
                  classNames="react-tag-create-campaign"
                />
              </div>
            )} */}
          </div>
          <div className="flex-column p-m-r-15 p-m-l-15">
            <div>
              {paymentType == "AUCTION" && (
                <div className="m-t-8 flex-row ">
                  <input
                    type="checkbox"
                    checked={checked}
                    onChange={handleCheckboxChange}
                    style={{ width: "20px", height: "20px", cursor: "pointer" }}
                  />
                  <CustomText
                    p
                    fontSize={"font_size_medium"}
                    textColor={"text_color_primary"}
                    text={"Influencer purchase the product?"}
                    className="m-l-5"
                  />
                </div>
              )}
            </div>
            <div>
              {(checked || paymentType == "BARTER") && (
                <div
                  style={{ gap: 20 }}
                  className="flex-row analytics-container"
                >
                  <div className="m-t-8 width-100" style={{flex:1}}>
                    <div className="m-b-8">
                      <CustomText
                        p
                        fontSize={"font_size_medium"}
                        textColor={"text_color_primary"}
                        text={"Product URL"}
                      />
                    </div>
                    <CustomTextInput
                      value={productUrl}
                      height={51}
                      placeholder={"e.g. https://mystore.com/collection"}
                      onChange={(e) => setProductUrl(e.target.value)}
                    />
                  </div>
                  <div className="m-t-8 width-100" style={{flex:1}}>
                    <div className="m-b-8">
                      <CustomText
                        p
                        fontSize={"font_size_medium"}
                        textColor={"text_color_primary"}
                        text={"Product Price"}
                      />
                    </div>
                    <CustomTextInput
                      type="number"
                      height={51}
                      value={productPrice}
                      placeholder={"Product Price"}
                      onChange={(e) =>
                        setProductPrice(e.target.value.replace(/\D/g, ""))
                      }
                    />
                  </div>
                </div>
              )}
            </div>

            <div>
              {paymentType == "BARTER" && (
                <div className=" barter-in-payment-type">
                  <div className="m-t-8 flex-row " style={{flex:1}}>
                    <input
                      type="radio"
                      checked={isBarter === 1}
                      onChange={() => setIsBarter(1)}
                      style={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                      }}
                    />
                    <CustomText
                      p
                      fontSize={"font_size_medium"}
                      textColor={"text_color_primary"}
                      text={"Brand will deliver the product."}
                      className="m-l-5"
                    />
                  </div>
                  <div style={{ width: 345, flex:1}} className="m-t-8 flex-row ">
                    <input
                      type="radio"
                      checked={isBarter === 0}
                      onChange={() => setIsBarter(0)}
                      style={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                      }}
                    />
                    <CustomText
                      p
                      fontSize={"font_size_medium"}
                      textColor={"text_color_primary"}
                      text={"Influencer will purchase the product"}
                      className="m-l-5"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* <div>
            <CustomText
              p
              fontSize={"font_size_medium"}
              textColor={"text_color_light"}
              text={"Maximum Bid Amount"}
            />

            <CustomTextInput
              invert
              text={"Maximum Followers"}
              placeholder={"|Max followers requiredl"}
              value={maxBidAmount}
              onChange={(e) =>
                setMaxBidAmount(e.target.value.replace(/\D/g, ""))
              }
            />
          </div> */}
          {/* <div>
            <CustomText
              p
              fontSize={"font_size_medium"}
              textColor={"text_color_light"}
              text={"End Date"}
            />

            <DatePicker
              className={"react-datepicker-size"}
              selected={moment.unix(endDate).toDate()}
              onChange={(date) => updateEndDate(date)}
              format="MM-dd-y"
            />
          </div> */}
          {/* <div>
            <CustomText
              p
              fontSize={"font_size_medium"}
              textColor={"text_color_light"}
              text={"Delivery Date"}
            />
            <DatePicker
              className={"react-datepicker-size"}
              selected={moment.unix(deliveryDate).toDate()}
              onChange={(date) => updateDeliveryDate(date)}
              format="MM-dd-y"
            />
          </div> */}
        </div>

        <div className="campaign-detail-textArea p-m-r-15 p-m-l-15">
          <div className="m-b-8">
            {" "}
            <CustomText
              p
              textColor={"text_color_primary"}
              text={"Script and Instructions for Creator"}
            />
          </div>

          <div className="details-text-area">
            <RichTextEditor
              toolbarConfig={toolbarConfig}
              value={instructionNote}
              onChange={(newValue) => setInstructionNote(newValue)}
            />
            {/* <CustomTextArea
              invert
              placeholder={"Provide clear product image"}
              value={instructionNote}
              onChange={(e) => setInstructionNote(e.target.value)}
            /> */}
          </div>
        </div>
        <div className="m-t-16  p-m-r-15 p-m-l-15">
          <div className="m-b-8">
            <CustomText
              p
              textColor={"text_color_primary"}
              text={"Sample Content (Max 3)"}
            />
          </div>

          <div style={{ position: "relative" }}>
            <div {...getRootProps({ className: "dropzone" })}>
              <div className="upload-content ">
                <div className="upload-content-inner">
                  <input {...getInputProps()} />

                  <Image
                    source={"../assets/images/Uploadicon.svg"}
                    height={72}
                    width={82}
                  />
                  <div className="flex-row m-b-5">
                    <CustomText
                      p
                      fontSize={"font_size_large"}
                      text={"Drag & drop files or "}
                      textColor={"text_color_black"}
                    />
                    <UnderLinedLinkCta
                      fontSize={"font_size_large"}
                      text={`\u00A0Browse`}
                      textColor={"text_Gradient_Color"}
                      // onClick={open}
                    />
                  </div>

                  <div>
                    <CustomText
                      p
                      fontSize={"font_size_label"}
                      textColor={"text_light_weight_color"}
                      text={"Supported formates: JPEG, PNG, MP4 "}
                    />
                  </div>
                </div>

                <div style={{ position: "absolute" }}>
                  {isUploading && <Loader />}
                </div>
              </div>
            </div>
          </div>

          <div className=" flex-row">
            <div className="product-sample-main">
              {selectedLocalImage &&
                selectedLocalImage.map((image, index) => {
                  return (
                    <>
                      {image.type == "video/mp4" ? (
                        <video
                          preload="metadata"
                          style={{ height: 106, width: 106 }}
                          src={URL.createObjectURL(image)}
                          onClick={() => handleClick(index)}
                        />
                      ) : (
                        <img
                          alt="not fount"
                          width={"106px"}
                          height={"106px"}
                          src={URL.createObjectURL(image)}
                          onClick={() => handleClick(index)}
                        />
                      )}

                      {showImage &&
                        selectedIndex === index && ( // Check if selectedIndex matches current index
                          <div className="fullscreen-image-sample-content">
                            <div style={{ position: "relative" }}>
                              <div
                                className="fullscreen-image-close-btn"
                                onClick={() => handleClick(index)}
                              >
                                <Icon iconName="CloseRoundButtonIcon" />
                              </div>
                              {image.type == "video/mp4" ? (
                                <Video
                                  source={URL.createObjectURL(image)}
                                  width={600}
                                  height={337}
                                  type={"video/mp4"}
                                />
                              ) : (
                                <Image
                                  source={URL.createObjectURL(image)}
                                  width={600}
                                  height={337}
                                />
                              )}
                            </div>
                          </div>
                        )}
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <div>
        {modalIsOpen && (
          <ReviewCampaign
            closeModal={closeModal}
            handleNext={handleNext}
            imageId={imageId}
            isLoading={isLoading}
            title={title}
            maxBidAmount={maxBidAmount}
            endDate={endDate}
            deliveryDate={deliveryDate}
            instructionNote={instructionNoteText}
            props={props}
            postSaveCampaignUser={postSaveCampaignUser}
            isLoadingDraft={isLoadingDraft}
          />
        )}

        {bestMatchModal && (
          <CampaignBestMatchInfluencer
            createCampaignResponse={createCampaignResponse}
            closeModal={closeModalBestMatch}
          />
        )}

        {modalIsOpenSuccess && (
          <CreateCampaignSuccess closeModal={closeModalSuccess} />
        )}
      </div>
      <div className="divide-line"></div>
      <div className="type-campaign-btn">
        <div style={{ maxWidth: 150 }}>
          <PrimaryCta
            invert
            onClick={handleBack}
            fontSize={"font_size_large"}
            text={"Back"}
          />
        </div>
        <div>
          <PrimaryCta
            fifth
            onClick={handleNext}
            className="blue-button"
            fontSize={"font_size_large"}
            isLoading={isLoading}
            text={"Submit"}
          />
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        zIndex={9999999}
      />

      {openSubscription && (
        <SubscriptionModal
          subscriptionData={subscriptionData}
          closeModal={openSubscriptionModal}
          orgData={org_deep_data?.org_data}
        />
      )}
    </div>
  );
}

export default CampaignDetails;
