import React, { useEffect, useState } from "react";
import { CustomText } from "../components";
import Core from "../common/clientSdk";
import { toast, ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import Lottie from "react-lottie";
import Utils from "../common/utils";
import * as LoadingUI from "../resources/data/loading.json";
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: LoadingUI,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
function ContactDetailInfluencer(props) {
  const [influencerData, setInfluencerData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isRequested, setIsRequested] = useState(true);
  const redux_selector = useSelector((state) => ({
    UserData: state.BrandUserDataReducer || {}, // Ensure default value
    Auth: state.BrandUserAuthReducer || {},
  }));
  
  const { UserData = {} } = redux_selector;
  const { brand_deep_data = {} } = UserData || {}; // Safe fallback
  const { org_deep_data = {} } = UserData || {};
  const { all_permissions = {} } = UserData || {};
  useEffect(() => {
      if (props?.influencer_id && Utils.isLoggedIn(brand_deep_data)) {
        getInfluencerContactRequested();
      }
    
  }, [props.isLoadingContact, props.influencerData]);
  function getInfluencerContactRequested() {
    var params = {
      influencer_id: props?.influencer_id,
      org_id: brand_deep_data?.brand_data?.org_id,
    };

    Core.getInfluencerContactRequested(
      getInfluencerContactRequestedSuccess,
      getInfluencerContactRequestedFail,
      (status) => setIsLoading(status),
      params
    );
  }

  function getInfluencerContactRequestedSuccess(demo, response) {
    props.getContactSuccess(response);
    setInfluencerData(response?.influencer_deep_data?.influencer_data);
    setIsRequested(response.is_requested);
  }

  function getInfluencerContactRequestedFail(error) {
    toast.error(error.map((item) => item.m).join(""));
  }
  function getContactNumberShow(influencerData) {
    if (Utils.isLoggedIn(brand_deep_data)) {
      if (influencerData?.has_phone_number) {
        return influencerData?.phone ? `+91 ${influencerData.phone}` : `+91 **********`;
      } else {
        return "Not available";
      }
    } else {
      return props?.influencerData?.has_phone_number==true? `+91 **********` : "Not Available";
    }
  }
  function getContactEmailShow(influencerData) {  
    if (Utils.isLoggedIn(brand_deep_data)) {
      if (influencerData?.has_email) {
        return influencerData?.email ? influencerData.email : "*****@gmail.com";
      } else {
        return "Not available";
      }
    } else {
      return props?.influencerData?.has_email==true ? "*****@gmail.com" : "Not Available";
    }
  }
  

  if (!isRequested && props.isActive == "message_screen") {
    return <></>;
  }

  return (
    <div className="contract-show-influencer-container">
      <div className="influencer_address_detail ">
        <div className="flex-row " style={{ gap: "4px" }}>
          <CustomText
            p
            text={"Phone Number"}
            fontSize={"font_size_large"}
            textColor={"text_color_primary"}
            fontWeight={"rubik_regular"}
            height={"line_height_19_2"}
          />
          <div
            className="new-content-box"
            style={{ position: "relative", bottom: "4px" }}
          >
            <p> New </p>
          </div>
        </div>
        {isLoading ? (
          <div>
            {" "}
            <Lottie options={defaultOptions} height={15} width={100} />
          </div>
        ) : (
          <CustomText
            p
            text={getContactNumberShow(influencerData)}
            fontSize={"font_size_large"}
            textColor={"text_color_primary"}
            fontWeight={"Inter_medium_500"}
            height={"line_height_19_2"}
          />
        )}
      </div>
      <div className="influencer_address_detail ">
        <div className="flex-row " style={{ gap: "4px" }}>
          <CustomText
            p
            text={"Email Address"}
            fontSize={"font_size_large"}
            textColor={"text_color_primary"}
            fontWeight={"rubik_regular"}
            height={"line_height_19_2"}
          />
          <div
            className="new-content-box"
            style={{ position: "relative", bottom: "4px" }}
          >
            <p> New </p>
          </div>
        </div>
        {isLoading ? (
          <div>
            {" "}
            <Lottie options={defaultOptions} height={15} width={100} />
          </div>
        ) : (
          <CustomText
            p
            text={getContactEmailShow(influencerData)}
            fontSize={"font_size_large"}
            textColor={"text_color_primary"}
            fontWeight={"Inter_medium_500"}
            height={"line_height_19_2"}
          />
        )}
      </div>
    </div>
  );
}

export default ContactDetailInfluencer;
