import React, { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {
  AnalyticsCard,
  CustomText,
  FallBack,
  FallBackBtn,
  SearchFallBack,
  ViewCards,
  PrimaryCta,
  Image,
  Video,
} from "../../components";
import { InstagramEmbed, YouTubeEmbed, XEmbed } from "react-social-media-embed";
import Icon from "../../components/core_component/icon";
import Core from "../../common/clientSdk";
import { Loader } from "../loader";
import Utils from "../../common/utils";
import { useSelector } from "react-redux";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import InstagramAnalytics from "./contentInstagramAnalytics";
import YoutubeAnalytics from "./contentYoutubeAnalytics";
import { ToastContainer, toast } from "react-toastify";
import * as CampaignData from "../../common/data/campaign";
import InfluencerDetailModal from "../../modal/influencerDetialModal";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import InfluencerContentCard from "../../features/influecnerContentCard";
import { useToast } from "../../features/toastContainer";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const responsiveSocial = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
function AnalyticsCampaign(props, { campaignId, brandId, orgId }) {
  const [isLoading, setLoading] = useState(false);
  const [campaignReport, setCampaignReport] = useState("");
  const [contentList, setContentList] = useState([]);
  const [campaignAnalytic, setCampaignAnalytic] = useState("");
  const [campaignContentAnalytic, setCampaignContentAnalytic] = useState("");
  const [influencerId, setInfluencerId] = useState(null);
  const [influencerFlyOut, setInfluencerFlyOut] = useState(false);
  const [influencerList, setInfluencerList] = useState();
  const [assignmentInfluencerList, setAssignmentInfluencerList] = useState([]);
  const [reportType, setReportType] = useState("user");
  const showToast = useToast();
  const [pdfLoader, setPDFLoader] = useState(false);
  const [activeTab, setActiveTab] = useState("words");
  const campaignStatus = props?.campaignDetail?.campaign_data?.status;
  const pdfRef = useRef();
  const influencerClass = useRef();
  var redux_selector = useSelector((state) => {
    return {
      UserData: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  console.log("props.campaignDetail", props?.campaignDetail);
  const { UserData = {} } = redux_selector;

  const { user_data = {} } = UserData;
  const { all_permissions = {} } = UserData;
  const { brand_deep_data = {} } = UserData;
  const { org_deep_data = {} } = UserData || {};

  useEffect(() => {
    // if (campaignStatus == "ACTIVE" || campaignStatus == "COMPLETED") {
    //   getCampaignReport();
    //   campaignAnalytics();
    //   campaignContentAnalytics();
    // }
    getAssignmentUser();
    AssignmentContentList();
    getCampaignReport();
    campaignAnalytics();
    campaignContentAnalytics();
  }, []);

  function updateProgress(status) {
    setLoading(status);
  }

  function getAssignmentUser() {
    var params = {
      campaign_id: props?.campaignId,
      status: "COMPLETED",
    };

    Core.getAssignmentUser(
      getAssignmentUserSuccess,
      getAssignmentUserFailed,
      updateProgress,
      params
    );
  }

  function getAssignmentUserSuccess(response_data) {
    setAssignmentInfluencerList(response_data?.["assignment_list"]);
  }

  function getAssignmentUserFailed(errorList) {
    toast.error(errorList.map((err) => err.m).join(", "));
  }

  function AssignmentContentList() {
    const params = {
      brand_id: brand_deep_data?.brand_data?.brand_id,
      campaign_id: props?.campaignId,
    };
    Core.AssignmentContentList(
      AssignmentContentListSuccess,
      AssignmentContentListFailed,
      updateProgress,
      params
    );
  }

  function AssignmentContentListSuccess(response) {
    setLoading(false);
    // setInfluencerList(response?.assignment_list?.influencer_deep_data);
    setContentList(
      response?.assignment_list?.filter((item) => {
        return item?.assignment_data?.status == "COMPLETED";
      })
    );

    // setNext(response?.pagination_data?.next);
  }

  function AssignmentContentListFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  function getCampaignReport() {
    const params = {
      campaign_id: props?.campaignId,
    };
    Core.getCampaignReport(
      getCampaignReportSuccess,
      getCampaignReportFailed,
      updateProgress,
      params
    );
  }

  function getCampaignReportSuccess(response_data) {
    setCampaignReport(response_data);
  }

  function getCampaignReportFailed(errorList) {
    // openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function campaignAnalytics() {
    const params = {
      campaign_id: props?.campaignId,
    };
    Core.campaignAnalytics(
      campaignAnalyticsSuccess,
      campaignAnalyticsFailed,
      updateProgress,
      params
    );
  }

  function campaignAnalyticsSuccess(response_data) {
    setCampaignAnalytic(response_data?.analytics_data);
  }

  function campaignAnalyticsFailed(errorList) {
    // openSnackbar(errorList.map((item) => item.m).join(", "));
  }
  function campaignContentAnalytics() {
    const params = {
      org_id: props?.orgId,
      brand_id: props?.brandId,
      campaign_id: props?.campaignId,
    };
    Core.campaignContentAnalytics(
      campaignContentAnalyticsSuccess,
      campaignContentAnalyticsFailed,
      updateProgress,
      params
    );
  }

  function campaignContentAnalyticsSuccess(response_data) {
    setCampaignContentAnalytic(response_data?.campaign_content_analytics_data);
  }

  function campaignContentAnalyticsFailed(errorList) {
    // openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function updateReportType() {
    if (reportType == "client") {
      setReportType("user");
    } else {
      setReportType("client");
    }
  }

  function getStringReportCta() {
    if (reportType == "client") {
      return "Self Report";
    } else {
      return "Client Report";
    }
  }

  function getReportTotalSpent() {
    if (reportType == "client") {
      return campaignReport?.total_spent_client || 0;
    } else {
      return campaignReport?.total_spent || 0;
    }
  }

  let Engagement =
    Number(campaignContentAnalytic?.total_like_count) +
    Number(campaignContentAnalytic?.total_comment_count);
  let totalEngagement =
    Engagement / Number(campaignContentAnalytic?.total_play_count);

  let avgEngagement = totalEngagement * 100;
  let cpfValue =
    getReportTotalSpent() / campaignContentAnalytic?.total_play_count;
  let cpEValue = getReportTotalSpent() / Engagement;
  let cpmValue =
    getReportTotalSpent() / campaignContentAnalytic?.total_play_count;

  console.log("cpmValue", cpmValue);

  function ContentReachData() {
    return (
      <div className="analytics-social-media-data">
        <div className="influencer_address_detail m-b-16">
          <div className="flex-row">
            {" "}
            <Icon iconName="heartIcon" />
            <CustomText
              p
              text={"Like"}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"rubik_regular"}
              height={"line_height_19_2"}
            />
          </div>

          <CustomText
            p
            text={"76k"}
            fontSize={"font_size_large"}
            textColor={"text_color_primary"}
            fontWeight={"Inter_medium_500"}
            height={"line_height_19_2"}
          />
        </div>
        <div className="influencer_address_detail m-b-16">
          <div className="flex-row">
            <Icon iconName="commentIcon" />
            <CustomText
              p
              text={"Comment"}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"rubik_regular"}
              height={"line_height_19_2"}
            />
          </div>

          <CustomText
            p
            text={"89k"}
            fontSize={"font_size_large"}
            textColor={"text_color_primary"}
            fontWeight={"Inter_medium_500"}
            height={"line_height_19_2"}
          />
        </div>
        <div className="influencer_address_detail m-b-16">
          <div className="flex-row">
            {" "}
            <Icon iconName="viewEyes" />{" "}
            <CustomText
              p
              text={"View Rate"}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"rubik_regular"}
              height={"line_height_19_2"}
            />{" "}
          </div>

          <CustomText
            p
            text={"89k"}
            fontSize={"font_size_large"}
            textColor={"text_color_primary"}
            fontWeight={"Inter_medium_500"}
            height={"line_height_19_2"}
          />
        </div>
        <div className="influencer_address_detail">
          <div className="flex-row">
            <Icon iconName="rupeeIcon" />
            <CustomText
              p
              text={"CPV"}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"rubik_regular"}
              height={"line_height_19_2"}
            />
          </div>

          <CustomText
            p
            text={"90k"}
            fontSize={"font_size_large"}
            textColor={"text_color_primary"}
            fontWeight={"Inter_medium_500"}
            height={"line_height_19_2"}
          />
        </div>
      </div>
    );
  }


  const toggleFlyoutDropdown = () => {
    if (
      !Utils.checkRoleAndPermission(
        redux_selector,
        all_permissions?.["campaign"]?.["permissions"]?.["view_profile"]
      )
    ) {
      showToast();
    } else {
      setInfluencerFlyOut(!influencerFlyOut);
    }
  };
  const downloadPdf = () => {
    setPDFLoader(true);
    const input = pdfRef.current;

    // Wait for all images to load
    const loadAllImages = () =>
      Promise.all(
        Array.from(input.querySelectorAll("img")).map((img) => {
          if (img.complete) return Promise.resolve();
          return new Promise((resolve) => {
            img.onload = resolve;
            img.onerror = resolve; // Prevent blocking if image fails to load
          });
        })
      );

    loadAllImages().then(() => {
      html2canvas(input, { useCORS: true, scale: 2 }).then((canvas) => {
        const imageURL = canvas.toDataURL("image/png");
        const pdf = new jsPDF("l", "mm", "a3", true);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
        const imgX = (pdfWidth - imgWidth * ratio) / 2;
        const imgY = 30; // Adjust as needed for padding
        pdf.addImage(
          imageURL,
          "PNG",
          imgX,
          imgY,
          imgWidth * ratio,
          imgHeight * ratio
        );
        pdf.save("Analytics.pdf");
        setPDFLoader(false);
      });
    });
  };

  function renderFallback() {
    if (campaignStatus == "COMPLETED") {
      return (
        <>
          <div className="fall-back-center-campaign">
            <FallBackBtn
              heading="Campaign Completed"
              title="Your campaign got completed or expired."
              // text="Repost Job"
              // onClick={openModalRepost}
            />
          </div>
        </>
      );
    } else if (campaignStatus == "IN_REVIEW") {
      return (
        <div className="fall-back-center-campaign">
          <FallBack
            heading={"Your campaign is under review"}
            title={
              "You will start receiving proposals as soon as it gets reviewed"
            }
          />
        </div>
      );
    } else {
      return (
        <>
          <div className="fall-back-center-campaign">
            <FallBack
              heading={"No Analytics Available"}
              // title={"Please wait until you get a new proposal"}
            />
          </div>
        </>
      );
    }
  }
  console.log("jhh", cpEValue);

  const handleDownload = (media_id, isVideo, assignment_id) => {
    const mediaUrl = Utils.constructContentMediaUrl(assignment_id, media_id);

    // Create a temporary anchor element to trigger the download
    const a = document.createElement("a");
    a.href = mediaUrl;
    a.download = `downloaded_${isVideo ? "video" : "image"}.${media_id
      .split(".")
      .pop()}`;
    a.style.display = "none";

    document.body.appendChild(a);
    a.click();

    // Clean up the anchor element
    document.body.removeChild(a);
  };
  function renderCampaignContent() {
    return (
      <>
        {contentList.length !== 0 && (
          <div className="analytics-list-header m-t-32 m-b-12">
            <CustomText
              p
              text={"Top Content"}
              textAlign={"text_align_left"}
              fontSize={"font_size_xl"}
              textColor={"text_color_primary"}
            />
          </div>
        )}

        {contentList.length !== 0 ? (
          <div className="all_influencer_analytics_container">
            <Carousel responsive={responsiveSocial}>
              {contentList.map((item) => {
                const platform = Utils.socialMediaPlatform(
                  item?.assignment_data.content_type_id
                );

                const mediaType =
                  item?.assignment_data?.media_id?.split(".").pop() == "mp4";
                if (item?.assignment_data?.status == "COMPLETED") {
                  return (
                    <>
                      {item.assignment_data.url && (
                        <>
                          {platform == "Instagram" && (
                            <InstagramAnalytics
                              campaignId={campaignId}
                              contentId={item.assignment_data?.content_id}
                              mediaUrl={item.assignment_data.url}
                              assignmentId={item?.assignment_data.assignment_id}
                              dateModified={item.assignment_data?.date_modified}
                              brandId={brand_deep_data.brand_data.brand_id}
                              orgId={brand_deep_data.brand_data.org_id}
                              height={350}
                              width={300}
                            />
                          )}
                        </>
                      )}
                      {item.assignment_data.url && (
                        <div className="embed-container">
                          {platform === "youtube" && (
                            <YoutubeAnalytics
                              campaignId={campaignId}
                              contentId={item.assignment_data?.content_id}
                              mediaUrl={item.assignment_data.url}
                              assignmentId={item?.assignment_data.assignment_id}
                              dateModified={item.assignment_data?.date_modified}
                              brandId={brand_deep_data.brand_data.brand_id}
                              orgId={brand_deep_data.brand_data.org_id}
                              height={300}
                              width={300}
                            />
                          )}
                        </div>
                      )}
                      {item.assignment_data?.url && (
                        <div className="embed-container">
                          {platform === "twitter" && (
                            <XEmbed
                              url={item.assignment_data?.url}
                              width={325}
                            />
                          )}
                        </div>
                      )}

                      {item.assignment_data?.media_id &&
                        mediaType &&
                        !item.assignment_data?.url && (
                          <div className="download-content">
                            <Video
                              source={Utils.constructContentMediaUrl(
                                item?.assignment_data.assignment_id,
                                item.assignment_data?.media_id
                              )}
                              width={325}
                              height={620}
                              className="content-media"
                            />
                            <PrimaryCta
                              fifth
                              onClick={() =>
                                handleDownload(
                                  item.assignment_data?.media_id,
                                  true,
                                  item.assignment_data.assignment_id
                                )
                              }
                              fontSize={"font_size_large"}
                              text={"Download"}
                              isLoading={""}
                            />
                          </div>
                        )}

                      {item.assignment_data.media_id &&
                        !mediaType &&
                        !item.assignment_data.url && (
                          <div className="download-content">
                            <Image
                              source={Utils.constructContentMediaUrl(
                                item.assignment_data.assignment_id,
                                item.assignment_data.media_id
                              )}
                              width={325}
                              height={520}
                              className="content-media"
                            />

                            <PrimaryCta
                              fifth
                              onClick={() =>
                                handleDownload(
                                  item.assignment_data.media_id,
                                  true,
                                  item.assignment_data?.assignment_id
                                )
                              }
                              fontSize={"font_size_large"}
                              text={"Download"}
                              isLoading={""}
                            />
                          </div>
                        )}
                    </>
                  );
                }
              })}
            </Carousel>
          </div>
        ) : (
          <>
            {" "}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "auto",
              }}
              className="fall-back-center-campaign"
            >
              {/* <FallBack
                heading={"No Creatives Available"}
                // title={"Please wait until you get a new proposal"}
              /> */}
            </div>
          </>
        )}
      </>
    );
  }

  function renderCampaignContentInfluencer() {
    return (
      <>
        {contentList?.length !== 0 && (
          <div className="analytics-list-header m-t-30 m-b-12">
            <CustomText
              p
              text={"Top Content"}
              textAlign={"text_align_left"}
              fontSize={"font_size_xl"}
              textColor={"text_color_primary"}
            />
          </div>
        )}
      </>
    );
  }
  function getInflencerLocation(influencer_data) {
    if (!influencer_data) {
      return "-";
    }

    if (influencer_data?.["city_id"]) {
      return CampaignData.CITY.find(
        (city) => city?.id == influencer_data?.["city_id"]
      )?.label;
    } else if (influencer_data?.["state"]) {
      return CampaignData?.LOCATIONS[influencer_data["state"]]?.n;
    } else {
      return "-";
    }
  }
  console.log("CampaignData", CampaignData);

  function filteringData() {
    if (assignmentInfluencerList?.length == 0) {
      return assignmentInfluencerList;
    }
    const filteredData = assignmentInfluencerList?.filter((item) =>
      item?.platforms_data?.some((platform) => platform?.platform_id === "btll")
    );
    return filteredData;
  }

  const InfluencerCards = ({ influencerList }) => {
    if (influencerList && filteringData()) {
      return (
        <div
          useRef={influencerClass}
          className={
            "content_all_influencer_analytics_container cursor-pointer"
          }
        >
          {filteringData() &&
            filteringData()?.map((influencer, index) => (
              <div
                className="suggest-influcener-card"
                key={index}
                onClick={(e) => {
                  toggleFlyoutDropdown();
                  setInfluencerId(influencer?.influencer_data.influencer_id);
                }}
              >
                <div
                  className="suggest-influcener-card-img "
                  style={{ cursor: "pointer" }}
                >
                  <Image
                    source={
                      influencer?.influencer_data?.profile_picture
                        ? Utils.generateInfluencerDpUrl(
                            influencer?.influencer_data?.influencer_id,
                            influencer?.influencer_data?.profile_picture
                          )
                        : "../assets/images/user-fallback.svg"
                    }
                    fallBack={"../assets/images/user-fallback.svg"}
                    alt="Profile"
                    height={200}
                  />

                  {/*                
                <div className="request-price" onClick={ ""}>
                  <div className="inner-request-price" style={{fontSize : "14px" , display: 'flex'}}> 
  
                  Request Price
                  <Icon iconName="rightArrow" width={16} height={16} />
                  </div>
                </div> */}
                </div>
                <div className="suggest-influcner-card-content">
                  <div>
                    <CustomText
                      p
                      text={influencer?.influencer_data?.name}
                      className="text-new-blue text-16 font-600"
                    />
                    <CustomText
                      p
                      text={
                        influencer?.influencer_data?.state_id
                          ? `${getInflencerLocation(
                              influencer.influencer_data
                            )}, India`
                          : "-"
                      }
                      className="text-new-gray font-medium m-t-4 text-14"
                    />
                  </div>
                  <div className="suggest-card-analytics">
                    <div>
                      <p className="text-12 font-600 text-new-gray">
                        Followers
                      </p>
                      <p className="text-14 text-new-blue font-600 m-t-4">
                        {Utils.changeNumberFormate(
                          influencer?.platforms_data[0]?.followers || "-"
                        )}
                      </p>
                    </div>
                    <div className="average-views-in-card">
                      <p className="text-12 font-600 text-new-gray">
                        Average Views
                      </p>
                      <p className="text-14 text-new-blue m-t-4 text-align font-600">
                        {Utils.changeNumberFormate(
                          influencer?.platforms_data[0]?.avg_views || "-"
                        )}
                      </p>
                    </div>
                    <div>
                      <p className="text-12 font-600 text-new-gray text-right">
                        ER
                      </p>
                      <p className="text-14 m-t-4 text-new-blue font-600">
                        {influencer?.platforms_data[0]?.engagement_rate != null
                          ? `${influencer.platforms_data[0].engagement_rate.toFixed(
                              1
                            )}%`
                          : "-"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      );
    }
  };
  function renderInfluecnerCard() {}

  function renderCostKPI() {
    return (
      <>
        <div className="analytics-list-header m-t-22">
          <div>
            <CustomText
              p
              text={"Cost KPI"}
              textAlign={"text_align_left"}
              fontSize={"font_size_xl"}
              textColor={"text_color_primary"}
            />
          </div>

          <div className="analytics-download ">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: 20,
              }}
            >
              <div className="">
                {org_deep_data?.org_data?.organization_type === "AGENCY" && (
                  <PrimaryCta
                    invertSmall
                    onClick={updateReportType}
                    isLoading={pdfLoader}
                    text={getStringReportCta()}
                  />
                )}
              </div>
              <div className="">
                <PrimaryCta
                  invertSmall
                  onClick={downloadPdf}
                  iconPosition="left"
                  iconName="pdfIcon"
                  isLoading={pdfLoader}
                  // padding="8px 24px"
                  text={"Download"}
                />
              </div>
            </div>
            <div></div>
          </div>
        </div>

        <>
          <div className="analytics-card-container ">
            <AnalyticsCard
              title={"Total Spent"}
              backgroundColor={"#DEECFF"}
              value={` ${
                getReportTotalSpent()
                  ? Utils.convertFromINR(
                      getReportTotalSpent(),
                      user_data?.country
                    )
                  : 0
              }`}
              iconName="totalSpentIcon"
            />

            <AnalyticsCard
              title={"CPV"}
              backgroundColor={"#F6E3FF"}
              value={`  ${
                cpmValue < Infinity
                  ? Utils.convertFromINR(cpmValue.toFixed(2), user_data?.country)
                  : "0"
              }`}
              iconName="cpvIcon"
            />
            <AnalyticsCard
              title={"CPE"}
              backgroundColor={"#CEFFDE"}
              // backgroundColor={"rgb(222 255 233)"}
              value={`${
                cpEValue < Infinity
                  ? Utils.convertFromINR(cpEValue, user_data?.country)
                  : "0"
              }`}
              iconName="cpeIcon"
            />
            <AnalyticsCard
              title={"CPF"}
              backgroundColor={"#FFF0DD"}
              value={`${
                cpfValue > 0 &&
                campaignReport?.total_reach != 0 &&
                cpfValue < Infinity
                  ? Utils.convertFromINR(
                      cpfValue?.toFixed(2),
                      user_data?.country
                    )
                  : 0
              }`}
              iconName="cpfIcon"
            />
          </div>
        </>
      </>
    );
  }

  function renderCampaignAnalytics() {
    return (
      <>
        <>
          <div className="analytics-list-header m-t-30">
            <CustomText
              p
              text={"Campaign Analytics"}
              textAlign={"text_align_left"}
              fontSize={"font_size_xl"}
              textColor={"text_color_primary"}
            />
          </div>
          <div className="analytics-card-container ">
            <AnalyticsCard
              title={"Influencers Live"}
              value={
                campaignAnalytic?.assignment_count
                  ? Utils.changeNumberFormate(campaignAnalytic?.assignment_count)
                  : 0
              }
              iconName="liveIcons"
              backgroundColor={"#FFE3F1"}
            />
            <AnalyticsCard
              title={"Posts Live"}
              value={
                campaignAnalytic?.assignment_count
                  ? Utils.changeNumberFormate(campaignAnalytic?.assignment_count)
                  : 0
              }
              iconName="heartLikeIcon"
              backgroundColor={"#C0FFFD"}
            />
            {props?.campaignDetail?.campaign_data?.is_marketplace == 1 && (
              <AnalyticsCard
                title={"Views"}
                value={
                  campaignReport?.total_view
                    ? Utils.changeNumberFormate(campaignReport?.total_view)
                    : 0
                }
                iconName="viewIcon"
                backgroundColor={"#DEECFF"}
              />
            )}

            <AnalyticsCard
              title={"Campaign Reach"}
              value={Utils.changeNumberFormate(campaignReport?.total_reach)}
              iconName="reachIcon"
              backgroundColor={"#F6E3FF"}
            />
          </div>
        </>
      </>
    );
  }

  function renderContentKPI() {
    return (
      <>
        <div className="analytics-list-header m-t-30 ">
          <CustomText
            p
            text={"Content Analytics "}
            textAlign={"text_align_left"}
            fontSize={"font_size_xl"}
            textColor={"text_color_primary"}
          />
        </div>

        <>
          <div className="analytics-card-container ">
            <AnalyticsCard
              title={"Likes"}
              backgroundColor={"#CEFFDE"}
              value={Utils.changeNumberFormate(
                campaignContentAnalytic?.total_like_count
              )}
              iconName="likeIcon"
            />

            <AnalyticsCard
              title={"Comments"}
              backgroundColor={"#FFF0DD"}
              value={Utils.changeNumberFormate(
                campaignContentAnalytic?.total_comment_count
              )}
              iconName="commentIcon"
            />
            <AnalyticsCard
              title={"Views"}
              backgroundColor={"#C0FFFD"}
              value={`${
                campaignContentAnalytic?.total_play_count
                  ? Utils.changeNumberFormate(
                      campaignContentAnalytic?.total_play_count
                    )
                  : "0"
              }`}
              iconName="blueViewIcon"
            />
            <AnalyticsCard
              title={"Engagement Rate"}
              backgroundColor={"#FFE3F1"}
              value={`${avgEngagement ? avgEngagement.toFixed(2) : "0"} %`}
              iconName="engagementIcon"
            />
          </div>
        </>
      </>
    );
  }

  function renderInfluencerCategoryPerformance() {
    return (
      <>
        <div className="analytics-list-header m-t-30 ">
          <CustomText
            p
            text={"Influencer category Performance"}
            textAlign={"text_align_left"}
            fontSize={"font_size_xl"}
            textColor={"text_color_primary"}
          />
        </div>
        <div className="flex-row gap-10">
          <div>
            <ViewCards view_cards>
              <div className="flex-row gap-10">
                <CustomText
                  p
                  text={"Influencer Category"}
                  fontSize={"font_size_large"}
                  textAlign={"text_align_left"}
                  textColor={"text_color_secondary"}
                />
              </div>
            </ViewCards>
            <ViewCards view_cards>
              <div className="campaign-detail-card m-b-12">
                <div className="flex-row gap-10">
                  <Icon iconName="liveInfluencerIcon" height={20} width={20} />
                  <CustomText
                    p
                    text={"Influencers Live:"}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="campaign-detail-card m-b-12">
                <div className="flex-row gap-10">
                  <Icon iconName="postLiveIcon" />
                  <CustomText
                    p
                    text={"Posts Live:"}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="campaign-detail-card m-b-12">
                <div className="flex-row gap-10">
                  <Icon iconName="campaignReachIcon" />
                  <CustomText
                    p
                    text={"Campaign Reach:"}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="campaign-detail-card m-b-12">
                <div className="flex-row gap-10">
                  <Icon iconName="engHeartIcon" height={20} width={20} />
                  <CustomText
                    p
                    text={"Engagements:"}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="campaign-detail-card m-b-12">
                <div className="flex-row gap-10">
                  <Icon iconName="reelstIcon" height={20} width={20} />
                  <CustomText
                    p
                    text={"Reel Views:"}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="campaign-detail-card m-b-12">
                <div className="flex-row gap-10">
                  <Icon iconName="percentageIcon" />
                  <CustomText
                    p
                    text={"Engagement Rate:"}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="campaign-detail-card m-b-12">
                <div className="flex-row gap-10">
                  <Icon iconName="percentageIcon" />
                  <CustomText
                    p
                    text={`View Rate`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
            </ViewCards>
          </div>
          <div>
            <ViewCards view_cards>
              <div className="">
                <CustomText
                  p
                  text={"Nano(1K-10K)"}
                  fontSize={"font_size_large"}
                  textAlign={"text_align_left"}
                  textColor={"text_color_secondary"}
                />
              </div>
            </ViewCards>
            <ViewCards view_cards>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
            </ViewCards>
          </div>
          <div>
            <ViewCards view_cards>
              <div className="">
                <CustomText
                  p
                  text={"Micro (10K-100K)"}
                  fontSize={"font_size_large"}
                  textAlign={"text_align_left"}
                  textColor={"text_color_secondary"}
                />
              </div>
            </ViewCards>
            <ViewCards view_cards>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
            </ViewCards>
          </div>
          <div>
            <ViewCards view_cards>
              <div className="">
                <CustomText
                  p
                  text={"Macro (100K-500K)"}
                  fontSize={"font_size_large"}
                  textAlign={"text_align_left"}
                  textColor={"text_color_secondary"}
                />
              </div>
            </ViewCards>
            <ViewCards view_cards>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
            </ViewCards>
          </div>
          <div>
            <ViewCards view_cards>
              <div className="">
                <CustomText
                  p
                  text={"Mega (500K-1M)"}
                  fontSize={"font_size_large"}
                  textAlign={"text_align_left"}
                  textColor={"text_color_secondary"}
                />
              </div>
            </ViewCards>
            <ViewCards view_cards>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
            </ViewCards>
          </div>
          <div>
            <ViewCards view_cards>
              <div className="">
                <CustomText
                  p
                  text={"A-Listers (1M+)"}
                  fontSize={"font_size_large"}
                  textAlign={"text_align_left"}
                  textColor={"text_color_secondary"}
                />
              </div>
            </ViewCards>
            <ViewCards view_cards>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
            </ViewCards>
          </div>
        </div>
      </>
    );
  }

  const InfluencerMetrics = () => {
    const hashtags = campaignContentAnalytic?.hash_tags || [];
  const wordMap = campaignContentAnalytic?.word_map || [];
    return (
      <div className="influencer-matrix" style={{ }}>
        {/* Engagement Metrics Card */}
        <div>
          <div className="influencer-tab-bottom-section-headings">
            <CustomText
              p
              text={"Engagement "}
              textAlign={"text_align_left"}
              fontSize={"font_size_xl"}
              textColor={"text_color_primary"}
            />
          </div>
          <div style={cardStyle}>
            <h2 style={titleStyle}>
              {Utils.changeNumberFormate(
                campaignContentAnalytic?.total_like_count +
                  campaignContentAnalytic?.total_comment_count
              )}
            </h2>
            <p className="influencer-para">
              No. Of People Engaged On Influencer's Posts
            </p>
            <div style={{ marginTop: 16 }}>
              <MetricItem
                icon={<Icon iconName="likes" />}
                label="Likes"
                value={Utils.changeNumberFormate(
                  campaignContentAnalytic?.total_like_count
                )}
              />
              <MetricItem
                icon={<Icon iconName="comments" />}
                label="Comments"
                value={Utils.changeNumberFormate(
                  campaignContentAnalytic?.total_comment_count
                )}
              />
              <MetricItem
                icon={<Icon iconName="engagementRate" />}
                label="Engagement Rate"
                value={`${avgEngagement ? avgEngagement.toFixed(2) : "0"} %`}
              />
              <MetricItem
                icon={<Icon iconName="cpe" />}
                label="CPE"
                value={`${
                  cpEValue < Infinity
                    ? Utils.convertFromINR(cpEValue, user_data?.country)
                    : "0"
                }`}
              />
            </div>
          </div>
        </div>
        <div>
          <div className="influencer-tab-bottom-section-headings">
            <CustomText
              p
              text={"Views"}
              textAlign={"text_align_left"}
              fontSize={"font_size_xl"}
              textColor={"text_color_primary"}
            />
          </div>
          <div style={cardStyle}>
            <h2 style={titleStyle}>{`${
              campaignContentAnalytic?.total_play_count
                ? Utils.changeNumberFormate(
                    campaignContentAnalytic?.total_play_count
                  )
                : "0"
            }`}</h2>
            <p className="influencer-para">
              No. Of People Viewed Influencer's Posts
            </p>
            <div style={{ marginTop: 16 }}>
              <MetricItem
                icon={<Icon iconName="reelView" />}
                label="Views"
                value={Utils.changeNumberFormate(
                  campaignContentAnalytic?.total_play_count
                )}
              />
              <MetricItem
                icon={<Icon iconName="viewRate" />}
                label="View Rate"
                value={`${
                  campaignReport.total_reach
                    ? Utils.changeNumberFormate(
                        (campaignContentAnalytic?.total_play_count / campaignReport?.total_reach) * 100
                      )
                    : "0"
                }%`}                
              />
              <MetricItem
                icon={<Icon iconName="cpv" />}
                label="CPV"
                value={`  ${
                  cpmValue < Infinity
                    ? Utils.convertFromINR(
                        cpmValue.toFixed(2),
                        user_data?.country
                      )
                    : "0"
                }`}
              />
            </div>
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <div
            className="influencer-tab-bottom-section-headings"
            style={{
              display: "flex",
              marginBottom: 8,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <CustomText
                p
                text={"Wordmap"}
                textAlign={"text_align_left"}
                fontSize={"font_size_xl"}
                textColor={"text_color_primary"}
              />
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <button
                style={
                  activeTab === "words" ? activeButtonStyle1 : buttonStyle1
                }
                onClick={() => setActiveTab("words")}
              >
                Top Words
              </button>
              <button
                style={
                  activeTab === "hashtags" ? activeButtonStyle2 : buttonStyle2
                }
                onClick={() => setActiveTab("hashtags")}
              >
                Top Hashtags
              </button>
            </div>
          </div>
          <div style={wordMapStyle}>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
                gap: "8px",
              }}
            >
              {activeTab === "words"
              ? wordMap.map((item, index) => (
                  <p key={index} style={{ color: "rgb(90, 91, 98)", fontWeight: "500" }}>
                    {index + 1}. {item.text}
                  </p>
                ))
              : hashtags.map((tag, index) => (
                  <p key={index} style={{ color: "rgb(90, 91, 98)", fontWeight: "500" }}>
                    {index + 1}. {tag}
                  </p>
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const MetricItem = ({ icon, label, value }) => (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginTop: "4px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 6,
          fontSize: 13,
          color: "#5A5B62",
          fontWeight: 500,
          lineHeight: "120%",
        }}
      >
        <span>{icon}</span>
        <span>{label}</span>
      </div>
      <span style={{ color: "#024399", fontWeight: "bold" }}>{value}</span>
    </div>
  );

  const cardStyle = {
    background: "#F5F8FF",
    padding: "16px",
    borderRadius: "8px",
    border: "1px solid #DADAD9",
    minHeight: 180,
    width: 300,
  };

  const titleStyle = {
    color: "#024399",
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "6px",
  };

  const wordMapStyle = {
    background: "#F5F8FF",
    padding: "16px",
    flex: 1,
    borderRadius: "8px",
    border: "1px solid #DADAD9",
  };

  const buttonStyle1 = {
    background: "#ffffff",
    color: "#5A5B62",
    padding: "4px 12px",
    borderRadius: "4px 0 0 4px",
    cursor: "pointer",
    boxShadow: "inset 0 0 0 0.5px #E6E7E8",
    outline: "none",
    border: "none",
  };
  const buttonStyle2 = {
    background: "#FFFFFF",
    color: "#5A5B62",
    padding: "4px 12px",
    borderRadius: "0 4px 4px 0",
    cursor: "pointer",
    boxShadow: "inset 0 0 0 0.5px #E6E7E8",
    border: "none",
    outline: "none",
  };

  const activeButtonStyle1 = {
    ...buttonStyle1,
    background: "#1977F2",
    color: "#FFFFFF",
    border: "none",
    boxShadow: "none",
  };
  const activeButtonStyle2 = {
    ...buttonStyle2,
    background: "#1977F2",
    color: "#FFFFFF",
    border: "none",
    boxShadow: "none",
  };

  console.log("assignmentInfluencerList", assignmentInfluencerList);
  const influencerContentCards = [];
  function contentAssignmentInfluencerData() {
    assignmentInfluencerList?.forEach((item) => {
      const contentKey = Object.keys(item?.contents_deep_data)[0];
      const content = item?.contents_deep_data?.[contentKey]?.content_data;
      const influencer = item?.influencer_data;
      const campaign_data = item?.campaign_data;
      const assignmentId = item?.assignment_data?.assignment_id;
      const platform =
        item?.platforms_data[0] && item?.platforms_data[0]?.identifier_id;

      // Map the data to component props
      let influencerContentCardProps = {
        imageSource: content?.media_id, // Use media_id for thumbnail
        url: content?.url,
        content_id: content?.content_id,
        identifier_id:
          item?.platforms_data[0] && item?.platforms_data[0]?.identifier_id,
        platform_id:
          item?.platforms_data[0] && item?.platforms_data[0]?.platform_id,
        influencerDp: influencer?.profile_picture, // Profile picture
        influencerId: influencer?.influencer_id, // Profile picture
        influencerUsername: influencer?.name, // Influencer name
        assignmentId: assignmentId,
        influencerHandle: platform, // Handle with '@'
        likes: "1.2K", // Placeholder for likes, adjust based on data
        comments: "300", // Placeholder for comments
        campaignId: props?.campaignId,
        //  views: `${Math.round(platform.avg_views / 1000)}K`, // Convert views to 'K' format
        date: new Date(
          item?.assignment_data?.date_added * 1000
        ).toLocaleDateString(), // Convert timestamp to readable date
        videoUrl: content?.url, // URL of the content
      };

      influencerContentCards?.push(influencerContentCardProps);
    });
  }
  return (
    <div style={{ minHeight: 500, position: "relative" }} className="">
      {isLoading ? (
        <div className="fall-back-center-campaign">
        <Loader />
        </div>
      ) : (
        <>
          {!campaignReport && !campaignAnalytic ? (
            <>{renderFallback()}</>
          ) : (
            <>
              <div className="pdf-save-container" ref={pdfRef}>
                {renderCostKPI()}
                {renderCampaignAnalytics()}
                {renderContentKPI()}
                {contentAssignmentInfluencerData()}
                {InfluencerMetrics()}
                {renderCampaignContentInfluencer()}
                <div className="content_all_influencer_analytics_container">
                  {influencerContentCards?.map((item) => {
                    return <InfluencerContentCard item={item} />;
                  })}
                </div>
                {influencerFlyOut && (
                  <InfluencerDetailModal
                    influencerFlyOut={influencerFlyOut}
                    toggleFlyoutDropdown={toggleFlyoutDropdown}
                    influencer_id={influencerId}
                    org_id={brand_deep_data?.brand_data?.org_id}
                  />
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default AnalyticsCampaign;
