import React, { useEffect, useState, useRef } from "react";
import {
  CustomIconCta,
  CustomText,
  CustomTextArea,
  FallBack,
  H1,
  Image,
  PrimaryCta,
  Video,
  ViewCards,
} from "../../components";
import Core from "../../common/clientSdk";
import utils from "../../common/utils";
import Icon from "../../components/core_component/icon";
import { useSelector } from "react-redux";
import Style from "../../components/core_component/style";
import { useNavigate, useParams } from "react-router-dom";
import ContentStatusScreen from "./contentStatusScreen";
import { Loader } from "../loader";
import Modal from "react-modal";
import { useSnackbar } from "react-simple-snackbar";
import Utils from "../../common/utils";
import { Rating } from "react-simple-star-rating";
import ReactHtmlParser from "react-html-parser";
import { ToastContainer, toast } from "react-toastify";
import {
  format,
  parse,
  fromUnixTime,
  nextWednesday,
  setHours,
  setMinutes,
} from "date-fns";
import {
  Avatar,
  MessageBox,
  Input,
  Button,
  MessageList,
} from "react-chat-elements";
import "react-chat-elements/dist/main.css";
import { PieChart } from "react-minimal-pie-chart";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Crisp } from "crisp-sdk-web";
import * as CampaignData from "../../common/data/campaign";
import ContentOfflineContract from "./contentOfflineContract";
import GetContactPermissionModal from "../influecnerScreen/getContactPermissionModal";
import { useToast } from "../../features/toastContainer";
import ContactDetailInfluencer from "../../modal/contactDetailInfluencer";
import ContactInfluencerModal from "../../modal/contactInfluencerModal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "var(--general-secondary-color)",
    //borderColor: "var( --blue-blue-500)",
    textAlign: "center",
    zIndex: "5",
    height: "60%",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "space-around",
    minWidth: "300px",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.86)",
    zIndex: 10,
  },
};
let limit = 10;
function ContractsDetailsScreen(props) {
  const [assignmentDetail, setAssignmentDetail] = useState({});
  const [influencerDetail, setInfluencerDetail] = useState({});
  const [campaignDetail, setCampaignDetail] = useState();
  const [isLoading, setLoading] = useState(true);
  const [createOrder, setCreateOrder] = useState();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const [ratingData, setRatingData] = useState([]);
  const [reviewerId, setReviewerId] = useState([]);
  const [messages, setMessages] = useState([]);
  const [start, setStart] = useState(0);
  const [next, setNext] = useState(0);
  const [newMessage, setNewMessage] = useState();
  const [messageLoader, setMessagesLoader] = useState(false);
  const [openSnackbar] = useSnackbar();
  const chatBoxInput = useRef(null);
  const assignment_id = useParams().id;
  const [followersData, setFollowersData] = useState([]);
  const [categoryListData, setCategoryListData] = useState([]);
  const [followersCount, setFollowersCount] = useState("-");
  const [platformsData, setPlatformsData] = useState([]);
  const [showImage, setShowImage] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [contactModalIsOpen, setContactModalIsOpen] = useState(false);
  const [isLoadingContact, setLoadingContact] = useState(false);
  const [contactRequest, setContactRequest] = useState();
  const [isHover, setIsHover] = useState(false);
  const [influencerContactData, setInfluencerContactData] = useState();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const showToast = useToast();
  const [statusColors] = useState({
    ACTIVE: "text_color_green",
    INACTIVE: "text_color_red",
    COMPLETED: "text_color_gray_500",
  });
  const navigate = useNavigate();

  useEffect(() => {
    getUserAssignmentDetail(updateProgress);
    setTimeout(() => {
      getMessagesUserList(updateProgress);
    }, 10000);
    // Crisp.chat.hide();
    // return () => Crisp.chat.show();
  }, []);

  useEffect(() => {
    if (influencerDetail?.influencer_data?.influencer_id) {
      getNewInfluencerContact();
      console.log("sandeep");
    }
  }, [influencerDetail?.influencer_data?.influencer_id]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  function updateProgress(status) {
    setLoading(status);
  }
  function openModal() {
    setIsOpen(true);
  }

  console.log("contactRequest", contactRequest);

  function closeModal() {
    setIsOpen(false);
  }

  const handleRating = (rate) => {
    setRating(rate);
  };
  // Utility function to scroll MessageList to bottom:
  const scrollToBottom = () => {
    const mlistElement = document.getElementsByClassName("rce-mlist")[0];

    if (typeof mlistElement !== "undefined") {
      mlistElement.scrollTop = mlistElement.scrollHeight;
    }
  };
  // Listener for when the state changes:
  useEffect(() => {
    setTimeout(() => scrollToBottom(), 3000);
  }, []);

  // Listener for when the state changes:
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  var redux_selector = useSelector((state) => {
    return {
      UserData: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const { UserData = {} } = redux_selector;
  const { brand_deep_data = {} } = UserData;
  const { user_data = {} } = UserData;
  const { brand_data = {} } = brand_deep_data;
  const { org_id = {} } = brand_data;
  const { all_permissions = {} } = UserData;
  const { org_deep_data = {} } = UserData;

  function getInfluencerDetailPublic(influencer_id) {
    const params = {
      influencer_id: influencer_id,
    };

    Core.getInfluencerDetailPublic(
      getInfluencerDetailPublicSuccess,
      getInfluencerDetailPublicFailed,
      () => {},
      params
    );
  }
  console.log("influencerContactData", influencerContactData);

  function getInfluencerDetailPublicSuccess(response) {
    setInfluencerDetail(response.influencer_deep_data);
    setPlatformsData(response.influencer_deep_data.platforms_data);
    setCategoryListData(
      Object.values(response.influencer_deep_data.categorys_data)
    );

    if (
      response.influencer_deep_data.platforms_data &&
      response.influencer_deep_data.platforms_data.length
    ) {
      let followerCount = 0;
      let newFollowersData = [];
      response.influencer_deep_data.platforms_data.forEach((platform) => {
        if (platform?.followers) {
          followerCount = followerCount + Number(platform?.followers);

          newFollowersData.push({
            title: Utils.socialMediaName(platform.platform_id),
            value: platform?.followers,
            color: Utils.socialMediaColor(platform.platform_id),
          });
        }
      });
      setFollowersCount(followerCount);
      setFollowersData(newFollowersData);
    }
    //  setLoading(false);
  }

  function getInfluencerDetailPublicFailed(errorList) {
    toast.info(errorList.map((err) => err.m).join(", "));
  }

  function getUserAssignmentDetail() {
    const params = {
      assignment_id: assignment_id,
      // brand_id: brand_data.brand_id,
    };
    Core.getUserAssignmentDetail(
      getUserAssignmentDetailSuccess,
      getUserAssignmentDetailFailed,
      () => {},
      params
    );
  }

  function getUserAssignmentDetailSuccess(response_data) {
    setAssignmentDetail(response_data.assignment_deep_data);
    getInfluencerDetailPublic(
      response_data.assignment_deep_data.influencer_data.influencer_id
    );
  }

  function getUserAssignmentDetailFailed(errorList) {
    toast.info(errorList.map((item) => item.m).join(", "));
  }

  function getNewInfluencerContact() {
    const params = {
      influencer_id: influencerDetail?.influencer_data?.influencer_id,
    };
    Core.getNewInfluencerContact(
      getNewInfluencerContactSuccess,
      getInfluencerContactFailed,
      () => {},
      params
    );
  }

  function getNewInfluencerContactSuccess(response_data, data) {
    console.log("response_data con", response_data);
    setInfluencerContactData(data.influencer_contact);
  }

  function getInfluencerContactFailed(errorList) {
    toast.info(errorList.map((item) => item.m).join(", "));
  }

  // message user api call
  function getMessagesUserList() {
    const params = {
      assignment_id: assignment_id,
    };
    Core.getMessagesUserList(
      getMessagesUserListSuccess,
      getMessagesUserListFailed,
      (status) => setMessagesLoader(status),
      params
    );
  }

  function getMessagesUserListSuccess(response_data) {
    setMessages(response_data.message.reverse());

    setMessagesLoader(false);
  }

  function getMessagesUserListFailed(errorList) {
    toast.info(errorList.map((item) => item.m).join(", "));
  }

  // get contact detail influencer

  function getInfluencerContact() {
    var params = {
      influencer_id: influencerDetail?.influencer_data?.influencer_id,
      org_id: brand_deep_data?.brand_data?.org_id,
    };

    Core.getInfluencerContact(
      getInfluencerContactSuccess,
      getInfluencerContactFail,
      (status) => setLoadingContact(status),
      params
    );
  }

  function getInfluencerContactSuccess(demo, response) {
    setInfluencerDetail((prev) => ({
      ...prev,
      phone: response?.influencer_deep_data?.influencer_data?.phone,
      email: response?.influencer_deep_data?.influencer_data?.email,
    }));
    closeGetContactDetails();
    getUserAssignmentDetail();
    // userSelfData();
  }

  function getContactSuccess(requestedContactResponse) {
    setContactRequest(requestedContactResponse);
  }

  function getInfluencerContactFail(error) {
    showToast(error.map((item) => item.m).join(""), "error");
  }

  function postMessagesUserList() {
    const params = {
      message_text: newMessage,
      assignment_id: assignment_id,
    };
    Core.postMessagesUserList(
      postMessagesUserListSuccess,
      postMessagesUserListFailed,
      (status) => setMessagesLoader(status),
      params
    );
    chatBoxInput.current.value = "";
  }

  function postMessagesUserListSuccess(response_data) {
    setMessagesLoader(false);
    getMessagesUserList();
  }

  function postMessagesUserListFailed(errorMessage) {
    toast.info(errorMessage);
  }

  function postDeleteMessage(progress_cb) {
    const params = { message_id: messages };
    Core.postDeleteMessage(
      postDeleteMessageListSuccess,
      postDeleteMessageListFailed,
      progress_cb,
      params
    );
  }

  function postDeleteMessageListSuccess(response_data) {}

  function postDeleteMessageListFailed(errorList) {
    toast.info(errorList.map((item) => item.m).join(", "));
  }

  function postRatingInfluencer() {
    if (!reviewValidation()) {
      return false;
    }
    const params = {
      assignment_id: assignmentDetail.assignment_data.assignment_id,
      influencer_id: assignmentDetail.influencer_data.influencer_id,
      review_points: rating,
      review: review,
    };

    Core.postRatingInfluencer(
      postRatingInfluencerSuccess,
      postRatingInfluencerFailed,
      updateProgress,
      params
    );
  }

  function postRatingInfluencerSuccess(response_data) {
    setLoading(false);
    toast.info("Thanks submit your feedback");
    closeModal();
    getUserAssignmentDetail(updateProgress);
  }

  function postRatingInfluencerFailed(errorList) {
    closeModal();
    toast.info(errorList.map((err) => err.m).join(", "));
  }

  const {
    rating_data = {},
    assignment_data = {},
    campaign_data = {},
    reward_data = {},
    instruction_data = {},
  } = assignmentDetail;

  useEffect(() => {
    if (campaign_data.campaign_id) {
      getCampaignUserDetail(campaign_data.campaign_id);
    }
  }, [campaign_data?.campaign_id]);

  // campaign details api call
  function getCampaignUserDetail(campaignId) {
    const params = {
      brand_id: brand_data.brand_id,
      campaign_id: campaignId,
    };
    Core.getCampaignUserDetail(
      getCampaignUserDetailSuccess,
      getCampaignUserDetailFailed,
      updateProgress,
      params
    );
  }

  function getCampaignUserDetailSuccess(response_data) {
    setCampaignDetail(response_data.campaign_data);
    setLoading(false);
  }

  function getCampaignUserDetailFailed(errorList) {
    // toast.info(errorList.map((item) => item.m).join(", "));
  }

  useEffect(() => {
    if (Array.isArray(rating_data)) {
      const ratingFilterData = rating_data.filter(
        (item) => item.reviewer_id !== org_id
      );
      setRatingData(ratingFilterData);
    }
  }, [rating_data, org_id]);
  useEffect(() => {
    if (Array.isArray(rating_data)) {
      const reviewerId = rating_data.find((item) => item.reviewer_id == org_id);
      setReviewerId(reviewerId);
    }
  }, [rating_data, org_id]);

  const { value = {} } = reward_data;

  function postCreateOrderAssignment(progress_cb) {
    const params = {
      assignment_id: assignmentDetail.assignment_data.assignment_id,
      brand_id: assignmentDetail.assignment_data.brand_id,
      redirect_url: window.location.origin + "/payment_success",
      platform: "web",
    };
    Core.postCreateOrderAssignment(
      postCreateOrderAssignmentSuccess,
      postCreateOrderAssignmentFailed,
      progress_cb,
      params
    );
  }

  function postCreateOrderAssignmentSuccess(response_data) {
    setCreateOrder(response_data.assignment_order_data);

    const pgOrderId = response_data.assignment_order_data.pg_order_id;
    redirectToPaymentPage(pgOrderId);
    // setLoading(false);
  }

  function postCreateOrderAssignmentFailed(errorList) {
    if (errorList.length) {
      toast.info(errorList.map((item) => item.m).join(", "));
    }
  }

  // function postCreditOrderFailed(errorList) {
  //   toast.info(errorList.map((item) => item.m).join(", "));
  // }

  const phonePayGateWay = process.env.REACT_APP_API_PHONE_PAY_URL;

  function redirectToPaymentPage(pgOrderId) {
    const paymentUrl = `${phonePayGateWay}${pgOrderId}`;
    window.location.href = paymentUrl;
  }

  const { influencer_data = {} } = influencerDetail;

  function reviewValidation() {
    if (!rating) {
      toast.info("please enter rating");
      return false;
    }
    if (!review) {
      toast.info("please enter review");
      return false;
    }
    return true;
  }

  function dateTime(time) {
    const myDate = fromUnixTime(time);
    const formattedDate = format(myDate, "MMMM dd, yyyy");
    return formattedDate;
  }

  const chatBoxRef = useRef(null);

  const handleClick = () => {
    setShowImage(!showImage);
  };

  const handleVideoClick = () => {
    setShowVideo(!showVideo);
  };

  function SampleContent(campaignDetail) {
    if (campaignDetail) {
      return (
        campaignDetail.sample_content_data &&
        campaignDetail.sample_content_data.map((item, index) => {
          if (item.sample_content_id.split(".").pop() == "mp4") {
            return (
              <div key={index} className="product-sample-main">
                <div
                  id={index}
                  onClick={() => handleVideoClick()}
                  className="m-r-6"
                >
                  <video
                    controlsList="nodownload"
                    preload="metadata"
                    style={{ height: 106, width: 106 }}
                    src={utils.generateCampaignSampleUrl(
                      campaignDetail.campaign_data.brand_id,
                      item.sample_content_id
                    )}
                  />
                </div>

                {showVideo && (
                  <div className="fullscreen-image">
                    <div style={{ position: "relative" }}>
                      <div
                        onClick={() => handleVideoClick()}
                        className="fullscreen-image-close-btn"
                      >
                        <Icon iconName="CloseRoundButtonIcon" />
                      </div>
                      <Video
                        source={utils.generateCampaignSampleUrl(
                          campaignDetail.campaign_data.brand_id,
                          item.sample_content_id
                        )}
                        width={600}
                        height={337}
                        type={"video/mp4"}
                      />
                    </div>
                  </div>
                )}
              </div>
            );
          } else {
            return (
              <>
                <div className="product-sample-main">
                  <div onClick={() => handleClick()}>
                    <Image
                      source={utils.generateCampaignSampleUrl(
                        campaignDetail.campaign_data.brand_id,
                        item.sample_content_id
                      )}
                      width={106}
                      height={106}
                    />
                  </div>

                  {showImage && (
                    <div className="fullscreen-image">
                      <div style={{ position: "relative" }}>
                        <div
                          onClick={() => handleClick()}
                          className="fullscreen-image-close-btn"
                        >
                          <Icon iconName="CloseRoundButtonIcon" />
                        </div>
                        <Image
                          source={utils.generateCampaignSampleUrl(
                            campaignDetail.campaign_data.brand_id,
                            item.sample_content_id
                          )}
                          width={600}
                          height={337}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </>
            );
          }
        })
      );
    }
  }

  let influencerDp = influencerDetail?.influencer_data?.profile_picture
    ? Utils.generateInfluencerDpUrl(
        influencerDetail?.influencer_data?.influencer_id,
        influencerDetail?.influencer_data?.profile_picture
      )
    : require("../../resources/images/user-copy.png");

  function influencerDetailPage(influencer_id) {
    navigate(`/influencer/${influencer_id}`);
  }

  function influencerDetails() {
    return (
      <>
        <div className="m-b-12">
          <CustomText
            p
            text={"Influencer Details"}
            textColor={"text_color_gray_500"}
            fontSize={"font_size_h7"}
            textAlign={"text_align_left"}
            fontWeight={"Inter_Bold"}
            lineHeight={"line_height_24"}
          />
        </div>
        <div className="influencer-detail-container">
          <div
            style={{ textAlign: "center" }}
            className="influencer_detail_container"
          >
            <Image
              fallBack={require("../../resources/images/user-copy.png")}
              source={influencerDp}
              onClick={() =>
                influencerDetailPage(
                  influencerDetail?.influencer_data?.influencer_id
                )
              }
              className="influencer-user-profile"
            />
            <div className="influencer-details-section">
              <CustomText
                h5
                text={influencerDetail?.influencer_data?.name}
                fontSize={"font_size_2xl"}
                textColor={"text_color_primary"}
                fontWeight={"Poppins_regular_large"}
              />
              <div className="flex-row">
                <Rating
                  initialValue={influencerDetail?.influencer_data?.ratings}
                  size={20}
                  readonly={true}
                />
                <div className="m-l-8">
                  <CustomText
                    p
                    text={influencerDetail?.influencer_data?.ratings.toFixed(1)}
                    fontSize={"font_size_small"}
                    textColor={"text_color_primary"}
                    fontWeight={"Inter_regular_small"}
                    height={"line_height_15_6"}
                  />
                </div>
              </div>

              <CustomText
                p
                text={influencerDetail?.influencer_data?.note}
                // noOfLine={"twoLines"}
                fontSize={"font_size_large"}
                textColor={"text_color_primary"}
                fontWeight={"Inter_regular_small"}
              />
              <div className="flex-row m-t-16" style={{alignItems: "center",gap:20}}>
                <div className="">
                  <PrimaryCta
                    fifth
                    iconName={"icChat"}
                    text="Chat"
                    onClick={() => navigate(`/message/${assignment_id}`)}
                  />
                </div>
                {assignmentDetail?.assignment_data?.assignment_status !=
                  "COMPLETED" && (
                  <>
                    <div
                      onMouseEnter={() => setIsHover(true)}
                      onMouseLeave={() => setIsHover(false)}
                      onClick={() => openGetContactDetails()}
                      style={{display:"flex",cursor:"pointer"}}
                    >
                      <Icon
                        iconName="contactCallIcon"
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="influencer_address_detail_container">
            {influencerDetail?.influencer_data?.state && (
              <div className="influencer_address_detail m-b-16">
                <CustomText
                  p
                  text={"Location"}
                  fontSize={"font_size_large"}
                  textColor={"text_color_primary"}
                  fontWeight={"rubik_regular"}
                  height={"line_height_19_2"}
                />
                <CustomText
                  p
                  text={
                    influencerDetail?.influencer_data &&
                    getInfluencerLocation(influencerDetail?.influencer_data)
                  }
                  className="Caption-3"
                  // fontSize={"font_size_large"}
                  textColor={"text_color_primary"}
                  // fontWeight={"Inter_semi_bold_500"}
                  // height={"line_height_19_2"}
                />
              </div>
            )}

            {influencerDetail?.influencer_data?.gender && (
              <div className="influencer_address_detail m-b-16">
                <CustomText
                  p
                  text={"Gender"}
                  fontSize={"font_size_large"}
                  textColor={"text_color_primary"}
                  fontWeight={"rubik_regular"}
                  height={"line_height_19_2"}
                />
                <CustomText
                  p
                  text={
                    influencerDetail?.influencer_data?.gender &&
                    Utils.capitalizeFirstLetter(
                      influencerDetail?.influencer_data?.gender
                    )
                  }
                  fontSize={"font_size_large"}
                  textColor={"text_color_primary"}
                  fontWeight={"Inter_Medium"}
                  height={"line_height_19_2"}
                />
              </div>
            )}

            {influencerDetail?.influencer_data?.language && (
              <div className="influencer_address_detail m-b-16">
                <CustomText
                  p
                  text={"Languages"}
                  fontSize={"font_size_large"}
                  textColor={"text_color_primary"}
                  fontWeight={"rubik_regular"}
                  height={"line_height_19_2"}
                />
                <CustomText
                  p
                  text={
                    influencerDetail?.influencer_data?.language &&
                    Utils.capitalizeFirstLetter(
                      influencerDetail?.influencer_data?.language
                    )
                  }
                  fontSize={"font_size_large"}
                  textColor={"text_color_primary"}
                  fontWeight={"Inter_Medium"}
                  height={"line_height_19_2"}
                />
              </div>
            )}

            {/* <ContactDetailInfluencer
              influencer_id={influencerDetail?.influencer_data?.influencer_id}
              influencerData={influencerDetail?.influencer_data}
              getContactSuccess={getContactSuccess}
              isLoadingContact={props.isLoadingContact}
            /> */}
          </div>

          <div className=" influencer-detail-category-platform">
            <div className="">
              <CustomText
                p
                text={"Category"}
                fontSize={"font_size_large"}
                textColor={"text_color_primary"}
                fontWeight={"Poppins_regular_large"}
              />
              <div className="category-background-influencer-container">
                {categoryListData &&
                  categoryListData.map((item, index) => {
                    return (
                      <>
                        {" "}
                        <div className="category-background-influencer">
                          <CustomText
                            p
                            text={item.name}
                            fontSize={"font_size_large"}
                            textColor={"text_color_primary"}
                            fontWeight={"rubik_regular"}
                          />
                        </div>{" "}
                        <div
                          style={{ color: "#8E8E93", fontSize: "large" }}
                          className="m-t-16"
                        >
                          {index < categoryListData.length - 1 ? "|" : ""}
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="influencer_social_media_container">
            <div className="m-b-16">
              <CustomText
                p
                text={"Social Analytics"}
                fontSize={"font_size_large"}
                textColor={"text_color_primary"}
                fontWeight={"Poppins_regular_large"}
              />
            </div>
            <div className="social_media_chart_icon">
              <div style={{ height: 143 }}>
                {followersData.length > 0 && (
                  <PieChart
                    lineWidth={25}
                    paddingAngle={2}
                    totalValue={followersCount}
                    data={followersData}
                    // label={({ dataEntry }) => dataEntry.title}
                    labelStyle={(index) => ({
                      fill: followersData[index].color,
                      fontSize: "5px",
                      fontFamily: "sans-serif",
                    })}
                  />
                )}
              </div>
              <div className="m-l-24">
                <div className="influencer_social_media_id_icon_container">
                  {platformsData.map((item) => {
                    function openUrl() {
                      window.open(
                        Utils.getSocialUrl(
                          item.platform_id,
                          item.identifier_id
                        ),
                        "_blank"
                      );
                    }

                    return (
                      <div className="influencer_social_media_id m-b-16">
                        <div
                          onClick={() => openUrl()}
                          className="influencer_social_media_id_icons"
                        >
                          <Icon
                            iconName={Utils.socialMediaIcons(item.platform_id)}
                            height={40}
                            width={40}
                          />
                          <div>
                            <CustomText
                              p
                              text={Utils.socialMediaName(item.platform_id)}
                              fontSize={"font_size_small"}
                              textColor={"text_color_primary"}
                              fontWeight={"rubik_regular"}
                            />
                            <CustomText
                              p
                              text={item.identifier_id}
                              fontSize={"font_size_extraSmall"}
                              textColor={"text_color_primary"}
                              fontWeight={"rubik_regular"}
                            />
                          </div>
                        </div>
                        <div>
                          <CustomText
                            p
                            text={
                              !Utils.isUndefined(item.followers)
                                ? Utils.changeNumberFormate(item.followers)
                                : "0"
                            }
                            fontSize={"font_size_extraSmall"}
                            textColor={"text_color_primary"}
                            fontWeight={"rubik_regular"}
                          />
                          <CustomText
                            p
                            text={
                              !Utils.isUndefined(item.following)
                                ? Utils.changeNumberFormate(item.following)
                                : "0"
                            }
                            fontSize={"font_size_extraSmall"}
                            textColor={"text_color_primary"}
                            fontWeight={"rubik_regular"}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  function getInfluencerLocation(influencer_data) {
    if (influencer_data["city_id"]) {
      return CampaignData.CITY.find(
        (city) => city.id == influencer_data["city_id"]
      )?.label;
    } else if (influencer_data["state"]) {
      return CampaignData.LOCATIONS[influencer_data["state"]]["n"];
    } else {
      return "-";
    }
  }

  function contractDetails() {
    let campaignDeliveryDate = "";
    if (campaign_data?.delivery_date) {
      campaignDeliveryDate = fromUnixTime(
        campaign_data?.delivery_date ? campaign_data?.delivery_date : ""
      );
    }

    let assignmentDeliveryDate = "";
    if (assignment_data?.assignment_delivery_date) {
      assignmentDeliveryDate = fromUnixTime(
        assignment_data?.assignment_delivery_date
          ? assignment_data?.assignment_delivery_date
          : ""
      );
    }

    const deliveryDate = format(
      new Date(
        assignmentDeliveryDate
          ? Utils.unixtimeToTimezoneTime(
              assignmentDeliveryDate,
              user_data.time_zone
            )
          : Utils.unixtimeToTimezoneTime(
              campaignDeliveryDate,
              user_data.time_zone
            )
      ),
      "dd MMMM yyyy"
    );

    return (
      <>
        <div className="m-b-12">
          <CustomText
            p
            text={"Contract Details"}
            textColor={"text_color_gray_500"}
            fontSize={"font_size_h7"}
            textAlign={"text_align_left"}
            fontWeight={"Inter_Bold"}
            lineHeight={"line_height_24"}
          />
        </div>
        <div class="contract-details-container">
          <div className="contract-details-container-row">
            <div className="contract-details-container-col-1">
              <CustomText
                p
                text={"Campaign Name"}
                textColor={"text_color_gray_500"}
                className="Body-1"
              />
            </div>
            <div className="contract-details-container-col-2">
              <CustomText
                p
                text={campaign_data?.name}
                textColor={"text_color_gray_500"}
                className="Caption-3"
              />
            </div>
          </div>
          <div className="seperater" />
          <div className="contract-details-container-row">
            <div className="contract-details-container-col-1">
              <CustomText
                p
                text={"Status"}
                textColor={"text_color_gray_500"}
                className="Body-1"
              />
            </div>

            <div className="contract-details-container-col-2">
              <CustomText
                p
                text={utils.assignmentStatusToString(
                  assignmentDetail?.assignment_data?.assignment_status
                )}
                textColor={
                  statusColors[
                    assignmentDetail?.assignment_data?.assignment_status
                  ]
                }
                className="Caption-3"
              />
            </div>
          </div>
          <div className="seperater" />
          <div className="contract-details-container-row">
            <div className="contract-details-container-col-1">
              <CustomText
                p
                text={"Contract ID"}
                textColor={"text_color_gray_500"}
                className="Body-1"
              />
            </div>
            <div className="contract-details-container-col-2">
              <CustomText
                p
                text={assignment_data?.assignment_id}
                textColor={"text_color_gray_500"}
                className="Caption-3"
              />
            </div>
          </div>

          <div className="seperater" />
          <div className="contract-details-container-row">
            <div className="contract-details-container-col-1">
              <CustomText
                p
                text={"Delivery Date"}
                textColor={"text_color_gray_500"}
                className="Body-1"
              />
            </div>
            <div className="contract-details-container-col-2">
              <CustomText
                p
                text={deliveryDate}
                textColor={"text_color_gray_500"}
                className="Caption-3"
              />
            </div>
          </div>
          <div className="seperater" />
          <div className="contract-details-container-row">
            <div className="contract-details-container-col-1">
              <CustomText
                p
                text={"Reward Amount"}
                textColor={"text_color_gray_500"}
                className="Body-1"
              />
            </div>
            <div className="contract-details-container-col-2">
              <CustomText
                p
                text={
                  reward_data?.value?.amount == 0
                    ? "-"
                    : Utils.convertFromINR(
                        reward_data?.value?.amount,
                        user_data?.country
                      )
                }
                textColor={"text_color_gray_500"}
                className="Caption-3"
              />
            </div>
          </div>
          {campaign_data.is_marketplace == 0 && (
            <>
              <div className="seperater" />
              <div className="contract-details-container-row">
                <div className="contract-details-container-col-1">
                  <CustomText
                    p
                    text={"Client Cost"}
                    textColor={"text_color_gray_500"}
                    className="Body-1"
                  />
                </div>

                <div className="contract-details-container-col-2">
                  <CustomText
                    p
                    text={
                      !assignment_data?.reward_value_client
                        ? "-"
                        : Utils.convertFromINR(
                            assignment_data?.reward_value_client,
                            user_data?.country
                          )
                    }
                    textColor={"text_color_gray_500"}
                    className="Caption-3"
                  />
                </div>
              </div>
            </>
          )}

          <div className="seperater" />
          <div className="contract-details-container-row">
            <div className="contract-details-container-col-1">
              <CustomText
                p
                text={"Content Type"}
                textColor={"text_color_gray_500"}
                className="Body-1"
              />
            </div>
            <div className="contract-details-container-col-2">
              <CustomText
                p
                text={utils.getCampaignType(campaign_data?.type)}
                textColor={"text_color_gray_500"}
                className="Caption-3"
              />
            </div>
          </div>
          {campaignDetail?.instruction_data?.instruction_note && (
            <>
              <div className="seperater" />
              <div className="contract-details-container-row">
                <div className="contract-details-container-col-1">
                  <CustomText
                    p
                    text={"Instructions"}
                    textColor={"text_color_gray_500"}
                    className="Body-1"
                  />
                </div>
                <div className="contract-details-container-col-2">
                  <div
                    className="Caption-3 text-anywhere"
                    style={{ color: "var(--gray-gray-500)" }}
                  >
                    {ReactHtmlParser(
                      campaignDetail.instruction_data.instruction_note
                    )}
                  </div>
                </div>
              </div>
            </>
          )}

          {campaignDetail?.sample_content_data.length !== 0 && (
            <>
              <div className="seperater" />
              <div className="contract-details-container-row">
                <div className="contract-details-container-col-1">
                  <CustomText
                    p
                    text={"Sample"}
                    textColor={"text_color_gray_500"}
                    className="Body-1"
                  />
                </div>
                <div
                  className="contract-details-container-col-2"
                  style={{ display: "flex", flexWrap: "wrap" }}
                >
                  {SampleContent(campaignDetail)}
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  }

  function contentStatus() {
    return (
      <>
        {assignment_data.assignment_status == "PAYMENT_PENDING" && (
          <div className="m-t-32 full-width text-align">
            <PrimaryCta
              fifth
              fontSize={"font_size_large"}
              fullWidth={true}
              text={"Pay Creator"}
              className="blue-button"
              isLoading={isLoading}
              onClick={() => {
                postCreateOrderAssignment(updateProgress);
              }}
            />
          </div>
        )}

        {assignment_data.assignment_status == "COMPLETED" && (
          <>
            <>
              {ratingData.map((item) => {
                const myDate = fromUnixTime(item.date_added);
                const formattedDate = format(myDate, "MMMM dd,");
                return (
                  <div className="m-t-24">
                    <ViewCards title={"Creator Rating"}>
                      <div className=" flex-column">
                        <div className="rating-date-name">
                          <CustomText
                            p
                            text={formattedDate}
                            textColor={"text_color_light"}
                            fontSize={"font_size_medium"}
                            fontWeight={"500px"}
                          />
                          <CustomText
                            p
                            text={`${influencer_data.name} Gave you feedback`}
                            fontSize={"font_size_medium"}
                            // textAlign={"text_align_left"}
                            textColor={"text_color_primary"}
                            fontWeight={"500px"}
                          />
                        </div>
                      </div>

                      <div className="rating-feedback">
                        <div className="m-t-8">
                          <Rating
                            // isDisable={true}
                            initialValue={item.review_points}
                            size={18}
                            readonly={true}
                          />
                        </div>
                        <div>
                          <CustomText
                            p
                            text={item.review}
                            fontSize={"font_size_medium"}
                            textAlign={"text_align_left"}
                            textColor={"text_color_primary"}
                            fontWeight={"500px"}
                          />
                        </div>
                      </div>
                    </ViewCards>
                  </div>
                );
              })}
            </>

            {!reviewerId && (
              <div className="m-t-32 full-width text-align">
                <PrimaryCta
                  fifth
                  className="blue-button"
                  fontSize={"font_size_large"}
                  fullWidth={true}
                  text={"Add Ratings"}
                  isLoading={isLoading}
                  onClick={() => openModal()}
                />
              </div>
            )}
          </>
        )}
      </>
    );
  }

  function openGetContactDetails() {
    setContactModalIsOpen(true);
  }
  function closeGetContactDetails() {
    setContactModalIsOpen(false);
  }

  console.log("contactModalIsOpen", contactModalIsOpen);

  return (
    <div style={{ overflow: "hidden", minHeight: 500 }}>
      {isLoading ? (
        <div style={{ display: "flex", justifyContent: "center" ,alignItems:"center",height:"90vh"}}>
          <Loader />
        </div>
        
      ) : (
        <>
          <div style={{display:"flex"}}>
            <div className="contract-details-box-container">
              <div className="contract-details-box">{influencerDetails()}</div>

              <div className="contract-details-box">{contractDetails()}</div>

              <div className="contract-details-box">
                {assignmentDetail.campaign_data.is_marketplace == 0 ? (
                  <ContentOfflineContract
                    assignmentDetail={assignmentDetail}
                    getUserAssignmentDetail={getUserAssignmentDetail}
                  />
                ) : (
                  <ContentStatusScreen
                    getUserAssignmentDetail={getUserAssignmentDetail}
                  />
                )}
              </div>
            </div>
            {/* {contactModalIsOpen && (
              <GetContactPermissionModal
                openGetContactDetails={openGetContactDetails}
                closeGetContactDetails={closeGetContactDetails}
                influencerData={influencerDetail?.influencer_data}
                org_deep_data={org_deep_data}
                getInfluencerContact={getInfluencerContact}
              />
            )} */}
            {contactModalIsOpen && (
              <ContactInfluencerModal
                openGetContactDetails={openGetContactDetails}
                closeGetContactDetails={closeGetContactDetails}
                influencerContactData={influencerContactData}
              />
            )}

            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              style={customStyles}
            >
              <div onClick={closeModal} className="close-btn-icon m-b-15">
                <Icon iconName="CloseRoundButtonIcon" />
              </div>
              <div className="campaign-text-heading">
                <CustomText
                  p
                  text={"Creator Rating"}
                  textColor={"text_color_primary"}
                  fontSize={"font_size_2xl"}
                />
              </div>
              <Rating
                onClick={handleRating}
                onPointerEnter={""}
                onPointerLeave={""}
                onPointerMove={""}
                size={60}
                /* Available Props */
              />
              <div
                className="m-t-20"
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
                <CustomText
                  p
                  text={"Add a review*"}
                  textColor={"text_color_primary"}
                  fontSize={"font_size_medium"}
                />
              </div>
              <div className=" brand-text-area">
                <CustomTextArea
                  value={review}
                  onChange={(e) => setReview(e.target.value)}
                />
              </div>
              <div className="m-t-32 full-width text-align">
                <PrimaryCta
                  fifth
                  className="blue-button"
                  fontSize={"font_size_large"}
                  fullWidth={true}
                  text={"SUBMIT YOUR FEEDBACK"}
                  isLoading={isLoading}
                  onClick={() => postRatingInfluencer()}
                />
              </div>
            </Modal>
          </div>
        </>
      )}
    </div>
  );
}

export default ContractsDetailsScreen;
