import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Icon from "../../components/core_component/icon";
import Core from "../../common/clientSdk";
import { useNavigate } from "react-router-dom";
import {
  CustomText,
  FallBack,
  FallBackBtn,
  PrimaryCta,
  Sidebar,
} from "../../components";
import CreateCollectionList from "./collectionList";
import CreateCollectionModal from "./createCollection";
import Utils from "../../common/utils";
import { Loader } from "../loader";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

const customStylesModal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "var(--general-secondary-color)",
    borderColor: "var(--gray-gray-50)",
    textAlign: "center",
    zIndex: "10000000",
    minHeight: "70%",
    height: "70%",
    display: "flex",
    flexDirection: "column",
    paddingLeft: 20,
    width: "600px",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.86)",
    zIndex: 10,
  },
};

const CreateCollectionGrid = ({
  closeModal,
  id,
  campaignId,
  onSuccessModalCollectionGrid,
  collection_data,
  selectedInfluencerRows,
  triggeredBy,
  modalIsOpenCollectionGrid,
}) => {
  const [collectionList, setCollectionList] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(false);
  const [next, setNext] = useState(0);
  const [collectionId, setCollectionId] = useState(
    collection_data?.collection_id
  );
  const [collectionData, setCollectionData] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingAddToList, setIsLoadingAddToList] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    getCollectionList(0, updateProgress);
  }, []);

  function updateProgress(status) {
    setLoading(status);
  }

  const redux_selector = useSelector((state) => {
    console.log("state", state);
    return {
      UserData: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });
  const { UserData = {} } = redux_selector;
  const { brand_deep_data = {} } = UserData;
  const { user_data = {} } = UserData;
  const { brand_data = {} } = brand_deep_data;

  function getCollectionList(start, process) {
    const params = {
      org_id: brand_data?.org_id,
      limit: 10,
      start: start,
    };
    Core.getCollectionList(
      (...args) => getCollectionListSuccess(start, ...args),
      getCollectionListFailed,
      updateProgress,
      params
    );
  }

  function getCollectionListSuccess(start, response_data) {
    if (
      !collection_data?.collection_id &&
      response_data.collection_list.length > 0
    ) {
      setCollectionId(response_data?.collection_list[0].collection_id);
      setCollectionData(response_data?.collection_list[0]);
      if (start == 0) {
        setCollectionList(response_data.collection_list);
      } else {
        setCollectionList([
          ...collectionList,
          ...response_data.collection_list,
        ]);
      }
      setNext(response_data?.pagination_data.next);
    }

    if (response_data?.collection_list?.length == 0) {
      openModalCollection();
    }
  }

  function getCollectionListFailed(errorList) {
    console.error("Failed to fetch collections", errorList);
  }

  function updateProgressShortlist(status) {
    setIsLoadingAddToList(status);
  }

  function addToListShortListChecker() {
    if (triggeredBy === "shortlistAll") {
      shortlistAll();
    } else {
      postAddInfluencerCollection();
    }
  }
  function shortlistAll() {
    let influencer_data = {
      influencer_ids: selectedInfluencerRows,
      collection_id: collectionId,
    };
    const params = {
      influencer_data: JSON.stringify(influencer_data),
    };
    Core.postShortlistAll(
      shortlistAllSuccess,
      shortlistAllFailed,
      updateProgressShortlist,
      params
    );
  }

  function shortlistAllSuccess() {
    toast.success("Influencers Added to List Successfully");
    closeModal();
    if (typeof onSuccessModalCollectionGrid == "function") {
      onSuccessModalCollectionGrid();
    }
  }
  function shortlistAllFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }
  function postAddInfluencerCollection() {
    const params = {
      influencer_id: id,
      collection_id: collectionId,
    };
    Core.postAddInfluencerCollection(
      postAddInfluencerCollectionSuccess,
      postAddInfluencerCollectionFailed,
      updateProgressShortlist,
      params
    );
  }

  function postAddInfluencerCollectionSuccess(response_data) {
    toast.success("Influencer Added to List Successfully");
    if (typeof onSuccessModalCollectionGrid == "function") {
      onSuccessModalCollectionGrid(collectionData);
    }
    closeModal();
  }

  function postAddInfluencerCollectionFailed(errorList) {
    console.log("errorList", errorList);
    if (typeof onSuccessModalCollectionGrid == "function") {
      onSuccessModalCollectionGrid(collectionData);
    }
    closeModal();

    toast.error(errorList.map((item) => item.m).join(", "));
  }

  const openModalCollection = () => {
    if (modalIsOpen == false) {
      setModalIsOpen(true);
    } else {
      setModalIsOpen(false);
    }
  };

  const closeModalCollection = () => {
    setModalIsOpen(false);
  };

  function filterSidebarHeader() {
    return (
      <CustomText
        p
        text={"List"}
        textColor={"text_color_primary"}
        fontSize={"font_size_2xl"}
        className="typographyH7"
      />
    );
  }
  console.log("collectionList", collectionList);
  const filterSidebarFooter = () => (
    <div style={{ display: "flex", flex: 1, gap: 20 }}>
      <div style={{ flex: 1 }}>
        <PrimaryCta
          invert
          onClick={
            collectionList.length == 0 ? closeModal : openModalCollection
          }
          fontSize={"font_size_large"}
          text={collectionList.length == 0 ? "Cancel" : "Create New"}
          isLoading={""}
        />
      </div>
      <div style={{ flex: 1 }}>
        <PrimaryCta
          fullWidth={true}
          onClick={
            collectionList.length == 0
              ? openModalCollection
              : () => addToListShortListChecker()
          }
          fontSize={"font_size_large"}
          text={collectionList.length == 0 ? "Create List" : "Add to List"}
          isLoading={isLoading}
          // className="blue-button"
        />
      </div>
    </div>
  );

  return (
    <>
      <Sidebar
        isOpen={modalIsOpenCollectionGrid}
        sidebarWidth={"36%"}
        onClose={closeModal}
        Header={filterSidebarHeader}
        Footer={filterSidebarFooter}
        headerAlignment="left"
      >
        <div className="collection-grid-card-container">
          <>
            {collectionList.length == 0 ? (
              <div className="small-fallback-container">
                <FallBackBtn
                  heading={"Oops!! you don't have any list"}
                  title={
                    "You can add your shortlisted influencers in list, so that you can share them with anyone and get feedback"
                  }
                  widthIcon={100}
                  heightIcon={100}
                />
              </div>
            ) : (
              <>
                <div
                  style={{
                    overflowY: "auto",
                    height: "100%",
                    scrollbarWidth: "none",
                  }}
                  className="collection-grid-container"
                >
                  <InfiniteScroll
                    dataLength={collectionList.length}
                    next={() => getCollectionList(next, updateProgress)}
                    hasMore={true}
                    height={"70vh"}
                    scrollbarWidth="none"
                    className="collection-infinite-scroller"
                    scrollableTarget="collection-invite-scroller"
                    //   loader={<h4>Loading...</h4>}
                  >
                    {collectionList.map((collection, index) => (
                      <div>
                        <div
                          key={index}
                          className={
                            collectionId !== collection.collection_id
                              ? "collection-grid-card"
                              : "collection-grid-card-active"
                          }
                          onClick={() => {
                            setSelectedCard(true);
                            setCollectionId(collection.collection_id);
                            setCollectionData(collection);
                          }}
                        >
                          <div className="collection-grid-card-name gap-10">
                            <div>
                              <CustomText
                                p
                                text={collection.collection_name}
                                className=" Caption-3"
                              />
                            </div>
                            <div>
                              {/* <CustomText
                                p
                                text={
                                  collection.platform_id == "nxjo"
                                    ? "Youtube"
                                    : "Instagram"
                                }
                                className="caption_2"
                              /> */}

                              <Icon
                                iconName={Utils.socialMediaIcons(
                                  collection.platform_id || "btll"
                                )}
                                height={24}
                                width={24}
                              />
                            </div>
                          </div>
                          <div className="collection-grid-date gap-10">
                            <CustomText
                              p
                              text={`Created on:`}
                              className="  typographyH5"
                            />
                            <CustomText
                              p
                              text={Utils.convertUnixToDate(
                                collection.date_added,
                                user_data.time_zone
                              )}
                              //  fontSize={"font_size_tag"}
                              textColor={"text_color_gray"}
                              className="caption_2_10"
                              // textAlign={"text_align_left"}
                            />
                          </div>
                        </div>
                        <div className="divider"></div>
                      </div>
                    ))}
                  </InfiniteScroll>
                </div>
              </>
            )}
          </>
        </div>
      </Sidebar>

      <CreateCollectionModal
        modalIsOpen={modalIsOpen}
        closeModalCollection={closeModalCollection}
        getCollectionList={getCollectionList}
        campaignId={campaignId}
        updateProgress={updateProgress}
      />
    </>
  );
};

export default CreateCollectionGrid;
