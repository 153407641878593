import React from "react";
import {
  CustomBox,
  CustomText,
  FallBack,
  Image,
  TopHeading,
  PrimaryCta,
  CampaignList,
  AssignmentList,
  FilterBtn,
  Video,
  AnalyticsCard,
} from "../components";
import SideMenu from "./sideMenu";
import Header from "./header";
import MathPlus from "../resources/images/math-plus.svg";
import RightArrowO from "../resources/images/arrow-right-circle-thin.svg";
import Instagram from "../resources/images/instagram.svg";
import Creator from "../resources/images/creator.png";
import { Link, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { NavLink } from "react-router-dom";
import { PieChart } from "react-minimal-pie-chart";
import InfiniteScroll from "react-infinite-scroll-component";
import utils from "../common/utils";
import Core from "../common/clientSdk";
import { useSnackbar } from "react-simple-snackbar";
//import { Tooltip as ReactTooltip } from "react-tooltip";
import Tooltip from "@mui/material/Tooltip";
import Style from "../components/core_component/style";
import * as PreviewCampaign from "../common/data/preview_campaign";
import { Crisp } from "crisp-sdk-web";
// import CanvasJSReact from "@canvasjs/react-charts";

// const CanvasJSChart = CanvasJSReact.CanvasJSChart;
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ToastContainer, toast } from "react-toastify";
import Sdk from "../common/core/module";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
import CampaignListingScreen from "./campaignListingScreen";
import CustomTable from "../components/customTable";
import { Loader } from "./loader";
import AssignmentListScreen from "./campaignDetailsParent/assignmentListScreen";
import Utils from "../common/utils";
import ReportSidebar from "./report-sidebar";
import ReactGA from "react-ga";
import Icon from "../components/core_component/icon";
import ReviewCampaign from "./createCampaignScreen/reviewCampaign";
import QuickCreateCampaign from "./createCampaignScreen/quickCreateCampaign";
// import { messaging, requestNotificationPermission } from "../common/firebase";
const limit = 10;

const videooSample = require("../resources/video/beauty.mp4");
const DashboardScreen = function (props) {
  const [assignmentList, setAssignmentList] = useState([]);
  const [campaignList, setCampaignList] = useState([]);
  const [start, setStart] = useState(0);
  const [loading, setLoading] = useState(true);
  const [next, setNext] = useState(0);
  const [orgData, setOrgData] = useState({});
  const [address, setAddress] = useState({});
  const [creditsDetail, setCreditsDetail] = useState({});
  const [activeTab, setActiveTab] = useState("instagram");
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [hoveredVideo, setHoveredVideo] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(null);
  const [modalIsOpenQuick, setModalIsOpenQuick] = useState(false);
  const [bundleType, setBundleType] = useState("FASHION_BEAUTY");
  const [isLoading, setIsLoading] = useState(false);
  const [brandReport, setBrandReport] = useState({});
  const [openSnackbar] = useSnackbar();

  const [statusColors, setStatusColors] = useState({
    ACTIVE: "#E89822",
    INACTIVE: "red",
    COMPLETED: "#68D184",
    IN_REVIEW: "#1977f2",
  });

  var navigate = useNavigate();

  useEffect(() => {
    getBrandReports();
  }, []);

  useEffect(() => {
    Crisp.chat.show();
    return () => Crisp.chat.hide();
  }, []);

  // useEffect(() => {
  //   if ("token") {
  //     onMessage(messaging, (payload) => {
  //       console.log("Message received. ", payload);
  //       // addNotification({
  //       //   title: payload.notification.title,
  //       //   message: payload.notification.body,
  //       //   theme: "darkblue",
  //       // });
  //     });
  //   }
  // }, []);

  const openModal = () => {
    if (modalIsOpen == false) {
      setModalIsOpen(true);
    } else {
      setModalIsOpen(false);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    toast.success("Campaign Created Successfully");
  };

  const openModalQuick = (item) => {
    if (modalIsOpenQuick == false) {
      setBundleType(item);
      setModalIsOpenQuick(true);
    } else {
      setModalIsOpenQuick(false);
    }
  };

  const closeModalQuick = () => {
    setModalIsOpenQuick(false);
  };

  //data campaign call start

  function createCampaign() {
    // if (address && is_verified) {
    if (org_deep_data?.org_data?.credits < 10) {
      openSnackbar("Not enough credits for create campaign");
      return false;
    } else {
      navigate("/create-campaign");
    }
    // } else {
    //   navigate("/company-settings");
    //   openSnackbar("Profile not completed");
    // }
  }

  const redux_selector = useSelector((state) => {
    return {
      UserData: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const {
    user_data,
    brand_deep_data,
    org_deep_data = {},
  } = redux_selector.UserData;

  function getCampaignListUser(startPage, progress_cb) {
    var params = {
      brand_id: redux_selector.UserData.brand_deep_data.brand_data.brand_id,
      limit: limit,
      start: startPage,
      // invert: "true",
      // status: "COMPLETED",
    };
    Core.getCampaignListUser(
      getCampaignListUserSuccess,
      getCampaignListUserFailed,
      progress_cb,
      params
    );
  }

  function getCampaignListUserSuccess(response_data) {
    if (next == 0) {
      setCampaignList(response_data["campaign_list"]);
    } else {
      setCampaignList([...campaignList, ...response_data["campaign_list"]]);
    }
    setNext(response_data?.pagination_data?.next);
  }

  function getCampaignListUserFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function currencyConverter(value) {
    return Utils.convertFromINR(value, user_data.country);
  }
  function getCreditDetail(progress_cb) {
    var params = {};
    Core.getCreditDetail(
      getCreditDetailSuccess,
      getCreditDetailFailed,
      progress_cb,
      params
    );
  }

  function getCreditDetailSuccess(response_data) {
    setCreditsDetail(response_data.credit_data);
    setLoading(false);
  }

  function getCreditDetailFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function getBrandReports() {
    setIsLoading(true);
    if (redux_selector.UserData.brand_deep_data) {
      var params = {
        brand_id:
          redux_selector?.UserData?.brand_deep_data?.brand_data?.brand_id,
      };
      Core.getBrandReports(
        getBrandReportsSuccess,
        getBrandReportsFailed,
        () => {},
        params
      );
    }
  }

  function getBrandReportsSuccess(response) {
    setBrandReport(response);
    getCampaignListUser(0, updateProgress);
    getAssignmentBrandDetails(0, updateProgress);
    setIsLoading(false);
  }

  function getBrandReportsFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function getOrgDataUser(progress_cb) {
    const params = {
      org_id: redux_selector.UserData.brand_deep_data.brand_data.org_id,
    };
    Core.getOrgDataUser(
      getOrgDataUserSuccess,
      getOrgDataUserFailed,
      progress_cb,
      params
    );
  }

  function getOrgDataUserSuccess(response_data) {
    setOrgData(response_data.org_data.org_data);
    setAddress(response_data.org_data.address_data.address_premise);
    setLoading(false);
  }

  function getOrgDataUserFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function getAssignmentBrandDetails(startPage, progress_cb) {
    var params = {
      brand_id: redux_selector.UserData.brand_deep_data.brand_data.brand_id,
      limit: limit,
      invert: true,
      status: "COMPLETED",
    };
    Core.getAssignmentBrandDetails(
      getAssignmentBrandDetailsSuccess,
      getAssignmentBrandDetailsFailed,
      progress_cb,
      params
    );
  }

  function getAssignmentBrandDetailsSuccess(response_data) {
    setAssignmentList(response_data["assignment_list"]);
  }

  function getAssignmentBrandDetailsFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function updateProgress(status) {
    setLoading(status);
  }

  function CampaignClick(campaignId) {
    navigate(`/campaigns/${campaignId}`);
  }
  function navigateToAssignmentDetails(assignmentId) {
    navigate(`/contracts/${assignmentId}`);
  }

  let completedAssignment = 0;

  let totalAssignment;

  function getDataDeliverables(contents_deep_data) {
    totalAssignment = Object.keys(contents_deep_data).length;

    Object.keys(contents_deep_data).map((item) => {
      const { status } = contents_deep_data[item].content_data;
      if (status == "COMPLETED") {
        completedAssignment++;
      }

      return (
        completedAssignment +
        " " +
        "out of " +
        totalAssignment +
        " images ready to review"
      );
    });
    return (
      completedAssignment +
      " out of " +
      totalAssignment +
      " images ready to review"
    );
  }
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const columns = [
    {
      name: "Creator Name",
      selector: (row) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Image
            fallBack={require("../resources/images/fallback.png")}
            source={
              row.influencer_data.profile_picture
                ? utils.generateInfluencerDpUrl(
                    row.influencer_data.influencer_id,
                    row.influencer_data.profile_picture
                  )
                : require("../resources/images/fallback.png")
            }
            className="table-profile"
          />
          <div className="influencer-table-profile">
            {row.influencer_data.name}
          </div>
        </div>
      ),
    },
    {
      name: "Amount",
      selector: (row) => row.reward_data.value.amount,
    },
    {
      name: "Deliverables",
      selector: (row) => getDataDeliverables(row.contents_deep_data),
    },
    {
      name: "Status",
      selector: (row) => row.assignment_data.assignment_status,
      conditionalCellStyles: [
        {
          when: (row) => row.assignment_data.assignment_status === "ACTIVE",
          style: Style.text_active_color,
        },
        {
          when: (row) =>
            row.assignment_data.assignment_status === "IN_PROGRESS",
          style: Style.text_active_color,
        },
        {
          when: (row) => row.assignment_data.assignment_status === "CANCELLED",
          style: Style.text_pending_color,
        },
        {
          when: (row) =>
            row.assignment_data.assignment_status === "PAYMENT_PENDING",
          style: Style.text_pending_color,
        },
        {
          when: (row) => row.assignment_data.assignment_status === "COMPLETED",
          style: Style.text_completed_color,
        },
      ],
    },
  ];
  function navigateToContractDetails(assignment_id) {
    navigate("/contract/" + assignment_id);
  }
  const { is_kyc_done, is_verified } = orgData;

  const quickCreateCampaignData = [
    {
      video: require("../resources/video/reel.mp4"),
      post_name: "Instagram Reel Bundle",
      post_pack: "(Pack of 50 Reels)",
      bundleType: "FASHION_BEAUTY",
      description:
        "50 Nano Influencers of any gender and age will \n create  Instagram Reel and post on Instagram ",
    },
    {
      video: require("../resources/video/story.webp"),
      post_name: "Instagram Story Share",
      post_pack: "(Pack of 50 Stories)",
      bundleType: "FASHION_BEAUTY_STORY",
      description:
        "50 Nano Influencers of any gender  and \n age will  add your content on their stories ",
    },
    {
      video: require("../resources/video/ugc.mp4"),
      post_name: "UGC Videos Bundle ",
      post_pack: "(Pack of 20 Reels)",
      bundleType: "FASHION_BEAUTY_CONTENT",
      description:
        "20 UGC video mp4 files from nano \n influencers of any age and any category",
    },
    {
      video: require("../resources/video/beauty.mp4"),
      post_name: "Instagram Beauty Queens ",
      post_pack: "(Pack of 10 Reels)",
      bundleType: "FASHION_BEAUTY_MICRO",
      description:
        " 10  Instagram Reels from Good quality \n Beauty and Fashion category nano Influencers ",
    },
    {
      video: require("../resources/video/tech.mp4"),
      post_name: "Tech Influencers ",
      post_pack: "(Pack of 5 Reels)",
      bundleType: "TECHNOLOGY_MICRO",
      description:
        "5 Instagram Reels from Good quality \n Tech category nano Influencers ",
    },
    {
      video: require("../resources/video/food.mp4"),
      post_name: "Restaurant Visit",
      post_pack: "(Pack of 50 Reels)",
      bundleType: "FOOD_DRINK_MICRO",
      description:
        "6 store visit from Micro Influencers you will get \n good quality content on their instagram profile.",
    },
  ];

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  function quickCampaignCreateCard() {
    return (
      <div className="quick-create-campaign-main-container">
        <Carousel
          additionalTransfrom={0}
          arrows
          autoPlaySpeed={5000}
          centerMode={false}
          className="carousel"
          containerClass="container"
          draggable
          focusOnSelect={false}
          itemClass="carousel-item"
          keyBoardControl
          minimumTouchDrag={20}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 2000,
                min: 1024,
              },
              items: 3,
              partialVisibilityGutter: 40,
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0,
              },
              items: 1,
              partialVisibilityGutter: 30,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464,
              },
              items: 2,
              partialVisibilityGutter: 30,
            },
          }}
          sliderClass=""
          slidesToSlide={1}
        >
          {quickCreateCampaignData.map((item, index) => {
            return (
              <div class="quick-inner-create-campaign-container card-carousel">
                <div
                  className="quick-create-campaign-image"
                  key={index}
                  onMouseEnter={() => {
                    setHoveredIndex(index);
                    setHoveredVideo(true);
                  }}
                  onMouseLeave={() => {
                    setHoveredIndex(null);
                    setHoveredVideo(false);
                  }}
                >
                  {item.video.split(".").pop() == "mp4" ? (
                    <video
                      className="bundle-card-img"
                      width={120}
                      autoPlay
                      muted
                      loop
                    >
                      <source src={item.video} type="video/mp4" />
                    </video>
                  ) : (
                    <Image
                      width={"150px"}
                      className="width-120 bundle-card-img"
                      source={item.video}
                    />
                  )}

                  {hoveredIndex === index && (
                    <div class="quick-create-campaign-button">
                      <PrimaryCta
                        fontSize={"font_size_regular"}
                        // className="quick-create-campaign"
                        text={"Create Campaign"}
                        textColor={"text_color_primary"}
                        onClick={() => openModalQuick(item.bundleType)}
                      />
                    </div>
                  )}
                </div>
                <div>
                  <div
                    style={{ zIndex: 102000, cursor: "pointer" }}
                    className="m-t-15 flex-row "
                  >
                    <CustomText
                      h5
                      text={item.post_name}
                      className="button2 m-r-4"
                    />
                    <div>
                      {/* <div key={index}>
                        <ReactTooltip
                          style={{ background: "#1A1A1A", zIndex: 10200000000 }}
                          id={index + 1}
                          // variant="info"
                          zIndex={10000}
                          place="top"
                          data-tooltip-offset={10}
                          data-tooltip-position-strategy="absolute"
                          content={
                            <div style={{ whiteSpace: "pre-wrap" }} key={index}>
                              {item.description}
                            </div>
                          }
                        />
                      </div> */}
                      <Tooltip
                        title={item.description}
                        sx={{ bgcolor: "blue", color: "red" }}
                        arrow
                      >
                        {" "}
                        <Icon iconName="infoIcon" />
                      </Tooltip>
                    </div>
                  </div>
                  <div className="m-t-4">
                    <CustomText p text={item.post_pack} />
                  </div>
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    );
  }

  return (
    <div className="dashboard-container background-color-primary">
      <SideMenu />
      <div className="main-screen">
        <Header />
        <div className="page-container">
          {/* <div className="quick-create-campaign-container">
            <div className="campaign-detail-upper-container">
              <div className="campaign-detail-tab-header">
                <div
                  className={activeTab === "instagram" ? "active" : ""}
                  onClick={() => handleTabClick("instagram")}
                >
                  <div className="quick-campaign-detail-btn-tabs">
                    <Icon ="instagramNewIcon" />
                    <CustomText
                      p
                      // textColor={"text_color_primary"}
                      fontSize={"font_size_extra_large"}
                      text={"Instagram"}
                      className="button2"
                    />
                  </div>
                </div>
                <div
                  className={activeTab === "proposal-page" ? "active" : ""}
                  onClick={() => handleTabClick("proposal-page")}
                >
                  <div className="quick-campaign-detail-btn-tabs">
                    <Icon iconName="youtubeNewIcon" />
                    <CustomText
                      p
                      // textColor={"text_color_primary"}
                      fontSize={"font_size_extra_large"}
                      text={"Youtube"}
                      className="button2"
                    />
                  </div>
                </div>
                <div
                  className={activeTab === "contracts-page" ? "active" : ""}
                  onClick={() => handleTabClick("contracts-page")}
                >
                  <div className="quick-campaign-detail-btn-tabs">
                    <Icon iconName="xAppNewIcon" />
                    <CustomText
                      p
                      // textColor={"text_color_primary"}
                      fontSize={"font_size_extra_large"}
                      text={"X"}
                      className="button2"
                    />
                  </div>
                </div>
              </div>
            </div>
            {activeTab === "instagram" && <>{quickCampaignCreateCard()}</>}
            {activeTab === "proposal-page" && "youtube"}
            {activeTab === "contracts-page" && "X app"}
          </div> */}

          <div>
            {loading ? (
              <div className="loader-mid">
                <Loader />
              </div>
            ) : (
              <>
                <div className="campaignlist-header ">
                  {/* <CustomText
                    p
                    text={"Brand Level Analytics"}
                    textColor={"text_color_primary"}
                    fontSize={"font_size_2xl"}
                    className="typographyH7"
                  /> */}
                </div>
                {!isLoading && (
                  <div
                    className={
                      !isMobile
                        ? "analytics-card-container m-l-10"
                        : "analytics-card-container"
                    }
                  >
                    <AnalyticsCard
                      title={"Total Campaign Views"}
                      value={brandReport.total_view}
                      iconName="viewEyes"
                    />
                    <AnalyticsCard
                      title={"Total Proposal"}
                      value={brandReport.total_bids}
                      iconName="heartLikeIcon"
                    />
                    <AnalyticsCard
                      title={"Total Spent"}
                      value={
                        brandReport.total_spent
                          ? ` ${currencyConverter(brandReport.total_spent)}`
                          : 0
                      }
                      iconName="rupeeIcon"
                    />
                    <AnalyticsCard
                      title={"Total Contracts"}
                      value={brandReport.total_contract}
                      iconName="AssignmentIconActive"
                    />
                  </div>
                )}

                <>
                  {assignmentList && assignmentList.length > 0 && (
                    <div>
                      <div className="campaign-text-heading see-all-button">
                        <CustomText
                          p
                          text={"Active Contracts"}
                          textColor={"text_color_primary"}
                          fontSize={"font_size_2xl"}
                        />
                        <div
                          className="see-all-main-btn"
                          onClick={() => {
                            navigate("/contracts");
                          }}
                        >
                          <CustomText
                            p
                            text={"See All"}
                            textColor={"text_color_primary"}
                            fontSize={"font_size_medium"}
                          />
                        </div>
                      </div>
                      {assignmentList.slice(0, 3).map((row) => (
                        <div className="space-list ">
                          <AssignmentList
                            onClick={() =>
                              navigateToAssignmentDetails(
                                row.assignment_data.assignment_id
                              )
                            }
                            source={
                              row.influencer_data.profile_picture
                                ? utils.generateInfluencerDpUrl(
                                    row.influencer_data.influencer_id,
                                    row.influencer_data.profile_picture
                                  )
                                : require("../resources/images/user-copy.png")
                            }
                            influencerName={row.influencer_data.name}
                            rewardText={currencyConverter(
                              Number(row?.reward_data?.value?.amount)
                            )}
                            deliverablesText={Utils.generateAssignmentSubText(
                              row
                            )}
                            status={Utils.assignmentStatusToString(
                              row.assignment_data.assignment_status
                            )}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </>

                {campaignList.length > 0 ? (
                  <>
                    <div className="campaign-text-heading see-all-button">
                      <CustomText
                        p
                        text={"Latest Campaigns"}
                        textColor={"text_color_primary"}
                        fontSize={"font_size_2xl"}
                      />
                      <div
                        className="see-all-main-btn"
                        onClick={() => {
                          navigate("/campaigns");
                        }}
                      >
                        <CustomText
                          p
                          text={"See All"}
                          textColor={"text_color_primary"}
                          fontSize={"font_size_medium"}
                        />
                      </div>
                    </div>

                    {campaignList.slice(0, 3).map((item, index) => {
                      return (
                        <div
                          style={{
                            color: statusColors[item.campaign_data.status],
                          }}
                          className="space-list"
                        >
                          <CampaignList
                            onClick={() =>
                              CampaignClick(item.campaign_data.campaign_id)
                            }
                            key={index}
                            source={
                              item.brand_data.image_id
                                ? utils.generateBrandLogoUrl(
                                    item.brand_data.org_id,
                                    item.brand_data.image_id
                                  )
                                : require("../resources/images/user-copy.png")
                            }
                            title={item.campaign_data.name}
                            // date={"a day"}
                            type={utils.getCampaignType(
                              item.campaign_data.type
                            )}
                            View={item.campaign_data.seen_count || "0"}
                            Bids={item.campaign_data.bid_count}
                            Contract={item.campaign_data.assignment_count}
                            Status={utils.assignmentStatusToString(
                              item?.campaign_data?.status
                            )}
                            Posted={utils.convertUnixToDate(
                              item.campaign_data.end_date,
                              user_data.time_zone
                            )}
                          />
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <>
                    <div className="fallback">
                      <FallBack
                        heading={"No live campaigns"}
                        title={"Launch a new campaign"}
                      />

                      <div className="m-t-15">
                        <PrimaryCta
                          third
                          onClick={() => createCampaign()}
                          fontSize={"font_size_regular"}
                          className="create-campaign"
                          text={"Create Campaign"}
                        />
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        {/* {modalIsOpen && <ReviewCampaign closeModal={closeModal} />} */}

        {modalIsOpenQuick && (
          <QuickCreateCampaign
            bundleType={bundleType}
            closeModal={closeModalQuick}
            setModalIsOpen={setModalIsOpen}
            modalIsOpen={modalIsOpen}
          />
        )}
      </div>
    </div>
  );
};

export default DashboardScreen;
