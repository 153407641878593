import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Core from "../common/clientSdk";
import * as Actions from "../redux/actions";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
// import toast, { Toaster } from "react-hot-toast";
import {
  Search,
  NotificationsIcon,
  ProfileDropDownIcon,
  FilterIcons,
} from "../components/assets/icons";
import { FallBack } from "../components/complex_component/index";
import VerifyOtpModal from "../modal/verifyOtpModal";
import { Image, CustomText, PrimaryCta, Sidebar } from "../components";
import Icon from "../components/core_component/icon";
import MyCreditsScreen from "./myCredits/myCreditsScreen";
import utils from "../common/utils";
import BrandListScreen from "./brandSetiing/brandListScreen";
import { useSnackbar } from "react-simple-snackbar";
import ReactGA from "react-ga";
import WebStorage from "../common/web_storage";
import { ORG_ID_KEY } from "../common/web_storage_keys";
import CompanySettingModal from "./companyDetails/addressDetailVerify";
import SubscriptionModal from "../modal/subscriptionModal";
import SideMenu from "./sideMenu";
import AddBrandModal from "./brandSetiing/addBrandModal";
import Utils from "../common/utils";
import { useToast } from "../features/toastContainer";
const TRACKING_ID = "G-PTRN15HW4P";
ReactGA.initialize(TRACKING_ID);

const Header = (props) => {
  var navigate = useNavigate();
  var redux_dispatch = useDispatch();
  const [showItem, setShowItem] = useState(false);
  const [logout, setLogout] = useState([]);
  const [logoutDropDown, setLogoutDropDown] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [brandIsOpen, setBrandIsOpen] = useState(false);
  const [creditsDetail, setCreditsDetail] = useState("");
  const [orgData, setOrgData] = useState({});
  const [address, setAddress] = useState({});
  const [modalIsOpenAddress, setModalIsOpenAddress] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [subscriptionSelected, setSubscriptionSelected] = useState(null);
  const [checkOrgSubUser, setCheckOrgSubUser] = useState({});
  const [addNewBrandModalOpen, setAddNewBrandModalOpen] = useState(false);
  const [verifyOtpModal, setVerifyOtpModalOpen] = useState(false);
  const [openSnackbar] = useSnackbar();
  const dropdownRef = useRef(null);
  const showToast = useToast();

  useEffect(() => {
    userSelfData();
    getOrgDataUser(updateProgress);
    getCreditDetail(updateProgress);
    subscriptionUserDetail();
  }, []);

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  function updateProgress(status) {
    setLoading(status);
  }

  var navigate = useNavigate();

  function createCampaign() {
    // if (
    //   Utils.checkRoleAndPermission(
    //     redux_selector,
    //     all_permissions["campaign"]["permissions"]["campaign"]
    //   )
    // ) {
    //   toast.info("You are not authorised to create campaign");
    // } else {
    // }

    navigate("/create-campaign");

    // if (address && is_verified) {
    // if (org_deep_data?.org_data?.credits < 10) {
    //   openModal();
    //   toast.info("Not enough credits for create campaign");
    //   return false;
    // } else {

    // }

    //   navigate("/create-campaign");
    // }

    // } else {
    //   navigate("/company-settings");
    //   openSnackbar("Profile not completed");
    // }
    googleAnalytics();
  }

  //data campaign call start

  var redux_selector = useSelector((state) => {
    return {
      Auth: state.BrandUserAuthReducer,
      UserData: state.BrandUserDataReducer,
    };
  });

  const {
    user_data,
    brand_deep_data,
    org_deep_data = {},
    all_permissions = {},
  } = redux_selector.UserData;

  useEffect(() => {
    setAddress(org_deep_data?.address_data?.address_premise);
  }, [org_deep_data]);

  const { image_id } = brand_deep_data["brand_data"];
  const brandImage = image_id
    ? utils.generateBrandLogoUrl(user_data.org_id, image_id)
    : require("../resources/images/fallback.png");

  async function userLogout() {
  logoutDeepClean();
  try {
    await axios.post("https://aws-sqs-url.com/logout-queue", {
      userId: user_data.user_id,
    });
  } catch (error) {
    console.error("Failed to enqueue logout request:", error);
  }
}
  // function userLogout(progress_cb) {
  //   // var params = {
  //   //   user_id: redux_selector.BrandUser.user_data.user_id,
  //   // }
  //   Core.userLogout(userLogoutSuccess, userLogoutFailed, progress_cb);
  // }

  // function userLogoutSuccess(response_data) {
  //   logoutDeepClean();
  // }
  function logoutDeepClean() {
    redux_dispatch({
      type: Actions.UPDATE_BRAND_USER_DATA,
      payload: { user_data: null },
    });

    // update brand data in redux
    redux_dispatch({
      type: Actions.UPDATE_BRAND_DATA,
      payload: { brand_deep_data: null },
    });

    redux_dispatch({
      type: Actions.UPDATE_ORG_DATA,
      payload: { org_deep_data: null },
    });

    // Update Auth token
    redux_dispatch({
      type: Actions.UPDATE_BRAND_USER_AUTH,
      payload: { auth_token: null },
    });
    WebStorage.clearDataFromWebStorage(ORG_ID_KEY);
    //  localStorage.clear();
    localStorage.removeItem("HttpBrowserCache");
    navigate("/");
  }
  // function userLogoutFailed(errorList = []) {
  //   // alert("errorList");
  //   logoutDeepClean();
  //   toast.error(errorList.map((item) => item.m).join(", "));
  // }

  function getOrgDataUser(progress_cb) {
    const params = {
      org_id: brand_deep_data.brand_data.org_id,
    };
    Core.getOrgDataUser(
      getOrgDataUserSuccess,
      getOrgDataUserFailed,
      progress_cb,
      params
    );
  }

  function getOrgDataUserSuccess(response_data) {
    setOrgData(response_data.org_data.org_data);
    setAddress(response_data.org_data.address_data.address_premise);
    setLoading(false);
  }

  function getOrgDataUserFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  function getCreditDetail(progress_cb) {
    var params = {};
    Core.getCreditDetail(
      getCreditDetailSuccess,
      getCreditDetailFailed,
      progress_cb,
      params
    );
  }

  function getCreditDetailSuccess(response_data) {
    setCreditsDetail(response_data?.credit_data);
    setLoading(false);
  }

  function getCreditDetailFailed(errorList) {

    toast.error(errorList);

  }

  function subscriptionUserDetail() {
    var params = {};
    Core.subscriptionUserDetail(
      subscriptionUserDetailSuccess,
      subscriptionUserDetailFailed,
      updateProgress,
      params
    );
  }

  function subscriptionUserDetailSuccess(response_data) {
    const plans = response_data?.subscription_list;
    setSubscriptionData(plans);
    props.onLoadHeader && props.onLoadHeader(plans);
  }

  function subscriptionUserDetailFailed(errorList) {
    closeModal();
    toast.error(errorList);
  }
  // logout  end

  function userSelfData() {
    var params = { org_id: brand_deep_data?.brand_data?.org_id };

    Core.userSelfData(
      userSelfDataSuccess,
      userSelfDataFailed,
      () => {},
      params
    );
  }

  function userSelfDataSuccess(response) {
    setCheckOrgSubUser(response?.org_data?.subscription_data);
  }
  function userSelfDataFailed() {}

  function toggleDropdown() {
    setIsDropdownOpen(!isDropdownOpen);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function googleAnalytics() {
    ReactGA.event({
      category: "ReelaxBrand",
      action: "REELAX_ADS_WEB_CREATE_CAMPAIGN_CLICK",
    });
  }

  function checkAddressPayment() {
    if (
      Utils.checkRoleAndPermission(
        redux_selector,
        all_permissions["finance"]["permissions"]["manage_subscription"]
      )
    ) {
      openModal();
    } else {

      showToast();
    }
    // if (
    //   org_deep_data?.org_data.pan_number &&
    //   org_deep_data?.address_data?.address_premise
    // ) {
    //   openModal();
    // } else {
    //   openModalAddress();
    // }
  }

  const HideItem = () => {
    setShowItem(!showItem);
  };

  const openModal = () => {
    if (modalIsOpen == false) {
      setModalIsOpen(true);
    } else {
      setModalIsOpen(false);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  const openModalAddress = () => {
    if (modalIsOpenAddress == false) {
      setModalIsOpenAddress(true);
    } else {
      setModalIsOpenAddress(false);
    }
  };

  const closeModalAddress = () => {
    setModalIsOpenAddress(false);
  };

  const brandModal = () => {
    if (brandIsOpen == false) {
      setBrandIsOpen(true);
    } else {
      setBrandIsOpen(false);
    }
  };

  const brandClose = () => {
    setBrandIsOpen(false);
  };

  function checkSubscriptionOrNot() {
    if (checkOrgSubUser?.feature?.max_brand_count > 1) {
    } else {
      toast.info("Unlock Premium!");
    }
  }
  const { is_kyc_done, is_verified } = orgData;

  function mobileViewHamburger() {
    return (
      <div
        onClick={() => {
          HideItem();
        }}
        className="mobile-menu-icon">
        {showItem ? (
          <div className="mobile-view-side-bar-close">
            {" "}
            <Icon iconName="crossSideBar" />
          </div>
        ) : (
          <>
            <div onClick={() => navigate("/")}>
              <Image
                source={"../assets/images/reelax-new-logo.svg"}
                className="left-align-header-icon"
              />
            </div>
            <div
              className="cursor-pointer m-t-5"
              onClick={() => createCampaign()}>
              <div
                className="create-campaign-card"
                style={{
                  height: "40px",
                  width: "155px",
                  display: "flex",
                  justifyContent: "center",
                  background: "#1977F2",
                  alignItems: "center",
                  gap: "6px",
                  fontSize: "13px",
                  borderRadius: "4px",
                  color: "white",
                }}>
                <div style={{ display:"flex" }}>
                  <Icon iconName="plusIcon"/>
                </div>
            <div className="font-medium">Create Campaign</div>
              </div>
            </div>
            <div className="mobile-menu-icon-open ">
              <Icon iconName={"threeLineIcon"} />
            </div>
          </>
        )}
      </div>
    );
  }

  function userProfileList() {
    return (
      <div className="profile-container" ref={dropdownRef}>
        <div className={`dropdown-menu ${isDropdownOpen ? "show" : ""}`}>
          <div className="user-dropdown-login">
            <Icon iconName="UserProfileIcon" />
            <PrimaryCta
              no_width
              className="logout-main-btn"
              text="User Profile"
              // isLoading={isLoading}
              onClick={() => navigate("/user-settings")}
              textAlign={"text_align_left"}
            />
          </div>
          <div className="user-dropdown-login">
            <Icon iconName="CompanyIcon" />
            <PrimaryCta
              no_width
              className="logout-main-btn"
              text="Manage Organisation"
              // isLoading={isLoading}
              onClick={() => navigate("/settings")}
              textAlign={"text_align_left"}
            />
          </div>
          {/* <div className="user-dropdown-login">
            <Icon iconName="ManageTeamIcon" />

            <PrimaryCta
              no_width
              className="logout-main-btn"
              text="Team Members"
              // isLoading={isLoading}
              onClick={() => navigate("/team-members-list")}
              textAlign={"text_align_left"}
            />
          </div> */}
          <div className="user-dropdown-login">
            <Icon iconName="signoutIcon" />

            <PrimaryCta
              no_width
              className="logout-main-btn"
              text="Log out"
              // isLoading={isLoading}
              onClick={() => userLogout(updateProgress)}
              textAlign={"text_align_left"}
            />
          </div>
        </div>
      </div>
    );
  }
  const handleClick = () => {
    if (orgData.organization_type !== "BRAND") {
      brandModal();
    }
  };
  return (
    <div className="header">
      <div className="header-left">
        {!props.hideHeaderLeft && (
          <div
            className="header-search-box .hide-on-small-screens cursor-pointer"
            onClick={() => {
              navigate("/database");
            }}>
            <div className="header-search-box-content">
              <div>
                <CustomText
                  p
                  text={"Find influencers to collaborate with"}
                  className="header-search-box-text"
                />
              </div>
              <div className="header-icon-center">
                <Icon iconName="searchIcon" width={18} height={18} />
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="header-right hide-on-small-screens">
        <div className="header-credit-container">
          <div className="header-credit-left button1">
            {/* {org_deep_data.subscription_data.plan.name ? org_deep_data.subscription_data.plan.name : "Free trial"} */}
          </div>

          <div
            >
              <PrimaryCta
              yellowButton
              iconPosition="left"
              iconName="upgradeIcon"
              text="Upgrade"
              onClick={() => checkAddressPayment()}
              />
          </div>
        </div>

        <div className="cursor-pointer" >
        <PrimaryCta
              third
              iconPosition="left"
              iconName="plusIcon"
              height={40}
              text="Create Campaign"
              onClick={() => createCampaign()}
              />
        </div>

        <div className="profile-card cursor-pointer">
          <Image
            className="profile-pic"
            source={brandImage}
            onClick={handleClick}
          />
          <div
            style={{ position: "relative", top: "2px" }}
            onClick={() => toggleDropdown()}>
            <Icon iconName="threeLineIcon" />
          </div>
        </div>

        <div>{userProfileList()}</div>

        {
          <SubscriptionModal
            subscriptionData={subscriptionData}
            closeModal={closeModal}
            orgData={org_deep_data?.org_data}
            modalIsOpen={modalIsOpen}
          />
        }
        {brandIsOpen && (
          <BrandListScreen
            closeModal={brandClose}
            subscriptionData={subscriptionData}
            checkOrgSubUser={checkOrgSubUser}
            setAddNewBrandModalOpen={setAddNewBrandModalOpen}
            checkAddressPayment={checkAddressPayment}
          />
        )}
        {addNewBrandModalOpen && (
          <AddBrandModal
            onRequestClose={() => setAddNewBrandModalOpen(false)}
            setAddNewBrandModalOpen={setAddNewBrandModalOpen}
          />
        )}
        {modalIsOpenAddress && (
          <CompanySettingModal
            closeModal={closeModalAddress}
            setVerifyOtpModalOpen={setVerifyOtpModalOpen}
          />
        )}
        {verifyOtpModal && (
          <VerifyOtpModal
            onRequestClose={() => setVerifyOtpModalOpen(false)}
            setVerifyOtpModalOpen={setVerifyOtpModalOpen}
          />
        )}
      </div>

      {showItem && (
        <div className="mobile-view-side-bar-background">
          <SideMenu showItem={showItem} />
        </div>
      )}
      {mobileViewHamburger()}
    </div>
  );
};

export default Header;
