import React, { useState, useEffect } from "react";

import Modal from "react-modal";
import {
  CustomText,
  PrimaryCta,
  TeamMembers,
  CustomTextInput,
  CustomTextArea,
  Video,
  UnderLinedLinkCta,
  Popup,
  Sidebar,
  ViewCards,
} from "../../components";
import Core from "../../common/clientSdk";
import { Loader } from "../loader";
import { useNavigate } from "react-router-dom";
import Icon from "../../components/core_component/icon";
import { useSnackbar } from "react-simple-snackbar";
import { Image } from "../../components";
import Select from "react-dropdown-select";
import Multiselect from "multiselect-react-dropdown";
import * as CampaignData from "../../common/data/campaign";
import Style from "../../resources/style";
import { autocompleteClasses } from "@mui/material";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import RichTextEditor from "react-rte";
import ReactHtmlParser from "react-html-parser";
import { WithContext as ReactTags } from "react-tag-input";
import { useDropzone } from "react-dropzone";
import UploadImageS3 from "../../common/s3";
import utils from "../../common/utils";
import Utils from "../../common/utils";

const strictList = [
  { value: false, label: "No" },
  { value: true, label: "Yes" },
];

const toolbarConfig = {
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "LINK_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
    "HISTORY_BUTTONS",
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],

  LINK_BUTTONS: [{ style: { display: "" } }],
};
function EditCampaignDetailsScreen({
  closeModal,
  campaignId,
  successCampaignDetail,
  ...props
}) {
  const [selectValue, setSelectValue] = useState();
  const [campaignDetail, setCampaignDetail] = useState({});
  const [editDetails, setEditDetails] = useState({
    campaignName: "",
    campaignType: "",
    campaignStatus: "",
    collabType: "",
  });
  const [budget, setBudget] = useState();
  const [descriptionOld, setDescriptionOld] = useState();
  const [openSnackbar] = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const [ctaUrl, setCtaUrl] = useState("");
  const [ctaText, setCtaText] = useState("");
  const [tags, setTags] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState();
  const [selectedLocalImage, setSelectedLocalImage] = useState([]);
  const [isUploading, setUploading] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [imageId, setImageId] = useState([]);
  const [productUrl, setProductUrl] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [checked, setChecked] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState();
  const [strict, setStrict] = useState(selectValue);
  const [description, setDescription] = useState(
    RichTextEditor.createEmptyValue()
  );
  const {
    audience_data = {},
    campaign_data = {},
    user_data = {},
    influencer_data = {},
    reward_data = {},
    instruction_data = {},
  } = campaignDetail;
  const [minFollower, setMinFollower] = useState(
    influencer_data.minimum_followers
  );
  const [influencerGender, setInfluencerGender] = useState(
    audience_data.gender
  );
  const [contentLanguage, setContentLanguage] = useState(
    audience_data.language
  );
  const [state, setState] = useState(
    audience_data.state && JSON.parse(audience_data.state)
  );

  const instructionNoteText = description.toString("html");

  useEffect(() => {
    if (campaignDetail?.campaign_data?.is_strict == 1) {
      setStrict([{ value: true, label: "Yes" }]);
    } else {
      setStrict([{ value: false, label: "No" }]);
    }
  }, [campaignDetail?.campaign_data?.is_strict]);

  useEffect(() => {
    getCampaignUserDetail();
  }, []);

  useEffect(() => {
    deliverableData();
  }, [campaignDetail?.content_type_deep_data]);

  useEffect(() => {
    setEditDetails((prev) => ({
      ...prev,
      campaignName: campaignDetail?.campaign_data?.name,
      campaignType: campaignDetail?.campaign_data?.type,
      campaignStatus: campaignDetail?.campaign_data?.status,
      collabType: campaignDetail?.campaign_data?.collab_type,
    }));
    setInfluencerGender(campaignDetail?.audience_data?.gender);
    setBudget(campaignDetail?.campaign_data?.max_budget);
    setCtaUrl(campaignDetail?.campaign_data?.url);
    setProductUrl(campaignDetail?.campaign_data?.product_url);
    setProductPrice(campaignDetail?.campaign_data?.product_price);
    setImageId(
      campaignDetail?.sample_content_data?.map((item) => item.sample_content_id)
    );
    setDescription(
      RichTextEditor.createValueFromString(
        campaignDetail?.instruction_data?.instruction_note,
        "html"
      )
    );
  }, [campaignDetail]);

  function updateProgress(status) {
    setLoading(status);
  }

  const redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const { BrandUser = {} } = redux_selector;
  const { brand_deep_data = {} } = BrandUser;
  const { brand_data = {} } = brand_deep_data;

  function getCampaignUserDetail() {
    const params = {
      brand_id: brand_data.brand_id,
      campaign_id: campaignId,
    };
    Core.getCampaignUserDetail(
      getCampaignUserDetailSuccess,
      getCampaignUserDetailFailed,
      updateProgress,
      params
    );
  }

  function getCampaignUserDetailSuccess(response_data) {
    setCampaignDetail(response_data.campaign_data);
    setLoading(false);
  }

  function getCampaignUserDetailFailed(errorList) {
    toast.info(errorList.map((item) => item.m).join(", "));
  }

  function updateCampaignDetails() {
    if (!formValidate()) {
      return false;
    }
    var params = {
      campaign_deep_data: JSON.stringify({
        campaign_data: {
          name: editDetails.campaignName,
          max_budget: budget,
          url: ctaUrl,
          product_price: productPrice,
          product_url: productUrl,
          is_strict: strict[0].value,
        },
        audience_data: {
          gender: influencerGender,
          language: contentLanguage,
        },
        influencer_data: {
          minimum_followers: Number(minFollower),
        },
        instruction_data: {
          instruction_note: instructionNoteText,
        },
        sample_content_data: imageId,
      }),
      campaign_id: campaignId,
    };
    Core.postUpdateCampaign(
      updateCampaignDetailsSuccess,
      updateCampaignDetailsFail,
      updateProgress,
      params
    );
  }

  function updateCampaignDetailsSuccess(response) {
    toast.info("Campaign details updated Successfully");
    props.setIsPopupOpen(false);
    successCampaignDetail();
  }

  function updateCampaignDetailsFail(errorList) {
    toast.info(errorList.map((item) => item.m).join(", "));
  }

  function generateUploadUrl(uploadedFileObject) {
    var params = {
      action: "sample_content",
      id: brand_data.brand_id,
      file_type: uploadedFileObject.type,
    };
    setUploading(true);
    Core.getGenerateUploadUrl(
      generateUrlSuccess,
      generateUrlFailed,
      () => {},
      params,
      () => {},
      uploadedFileObject
    );
  }

  function generateUrlSuccess(response, uploadedFileObject) {
    if (response) {
      // upload image file on server call uploadImageS3
      UploadImageS3(uploadedFileObject, response.upload_data, (error) => {
        setUploading(false);
        if (error) {
          return toast.info("Upload failed");
        }
        let newImageId = imageId;
        newImageId.push(response.upload_data.media_id);
        setImageId(newImageId);
      });
    } else {
      setUploading(false);
      toast.info("Something went wrong");
    }
  }

  function generateUrlFailed(error) {
    toast.info("Something went wrong");
    setUploading(false);
  }

  const removeHtmlTags = () => {
    const doc = new DOMParser().parseFromString(
      instructionNoteText,
      "text/html"
    );
    return doc.body.textContent || "";
  };

  let descriptionLength = removeHtmlTags();

  function formValidate() {
    if (editDetails.campaignName.length < 6) {
      toast("Please enter minimum 6 character for campaign title");
      return false;
    }
    const urlRegEx = /^(http|https):\/\/[^ "]+$/;
    if (checked && campaignDetail?.campaign_data?.collab_type !== "BARTER") {
      if (!productPrice) {
        toast.info("Please Enter Product Price");
        return false;
      }
      if (Number(productPrice) < 100) {
        toast.info("Product Price should be atleast 100");
        return false;
      }
      if (!productUrl) {
        toast.info("Please enter product url");
        return false;
      }

      if (productUrl.length > 0 && !urlRegEx.test(productUrl)) {
        toast.info("Please enter at correct product url");
        return false;
      }
    }

    if (campaignDetail?.campaign_data?.collab_type !== "BARTER") {
      if (!budget) {
        toast("Please enter budget");
        return false;
      }

      if (Number(budget) <= 99) {
        toast("Please enter more than 100 proposal amount");
        return false;
      }

      // if (Number(budget) >= 50000) {
      //   toast("Please enter less than 50000 proposal amount");
      //   return false;
      // }
    }

    if (descriptionLength.length < 20) {
      toast("Please enter at least 20 character in creator's instruction");
      return false;
    }

    if (ctaUrl && ctaUrl.length > 0 && !urlRegEx.test(ctaUrl)) {
      toast("Please enter at correct url");
      return false;
    }
    if (!influencerGender) {
      toast("Please select gender");
      return false;
    }

    if (contentLanguage?.length == 0) {
      toast("Please select at least one language");
      return false;
    }

    if (campaign_data.type == "social_media" && Number(minFollower) <= 999) {
      toast("Please enter more than 1000 followers");
      return false;
    }

    return true;
  }
  const handleCheckboxChange = () => {
    setChecked(!checked);
  };
  const handleDelete = (i) => {
    const newTags = [...tags];
    newTags.splice(i, 1);
    setTags(newTags);
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const handleClick = (index) => {
    setShowImage(!showImage);
    setSelectedIndex(index);
  };
  const handleCropImage = (acceptedFiles) => {
    if (acceptedFiles.length + selectedLocalImage.length <= 3) {
      const updatedImages = [...selectedLocalImage];
      for (let i = 0; i < acceptedFiles.length; i++) {
        updatedImages.push(acceptedFiles[i]);
        generateUploadUrl(acceptedFiles[i]);
      }
      setSelectedLocalImage(updatedImages);
    } else {
      toast.info("Maximum 3 files allowed");
    }
  };

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    onDrop: handleCropImage,
    accept: "image/*, video/mp4", // Limit to image files
    // maxSize: 5000000, // Limit to 5MB per file
    multiple: true, // Allow multiple file uploads
  });

  const handleSampleDelete = (index) => {
    // const updatedSampleContent = [...campaignDetail.sample_content_data];
    // updatedSampleContent.splice(index, 1);
    // setCampaignDetail({
    //   ...campaignDetail,
    //   sample_content_data: updatedSampleContent,
    // });
  };

  const handleRemoveItem = (indexToRemove) => {
    // Create a copy of the array without the item at the specified index
    const updatedImageId = [...imageId];
    updatedImageId.splice(indexToRemove, 1);

    // Update the state or perform any necessary actions
    // For example, if imageId is a state variable, you might use a state update function
    setImageId(updatedImageId);
  };
  function updateStrict(selectedItem) {
    setStrict(selectedItem);
  }
  function SampleContent() {
    return (
      imageId &&
      imageId?.map((item, index) => {
        if (item?.split(".").pop() == "mp4") {
          return (
            <div key={index} className="product-sample-main m-l-12">
              <div id={index} className="m-r-6 ">
                <div
                  onClick={() => handleRemoveItem(index)}
                  className="sample-content-remove-btn cursor-pointer">
                  <Image
                    source={require("../../resources/images/bid-search-cross.png")}
                  />
                </div>
                <video
                  preload="metadata"
                  style={{ height: 106, width: 106 }}
                  src={utils.generateCampaignSampleUrl(
                    campaignDetail.campaign_data.brand_id,
                    item
                  )}
                />
              </div>
            </div>
          );
        } else {
          return (
            <>
              <div className="product-sample-main">
                <div onClick={() => handleClick()}>
                  <div
                    onClick={() => handleRemoveItem(index)}
                    className=" sample-content-remove-btn cursor-pointer">
                    <Image
                      source={require("../../resources/images/bid-search-cross.png")}
                    />
                  </div>
                  <Image
                    source={utils.generateCampaignSampleUrl(
                      campaignDetail.campaign_data.brand_id,
                      item
                    )}
                    width={106}
                    height={106}
                  />
                </div>
              </div>
              ;
            </>
          );
        }
      })
    );
  }

  const editCampaignDetailsHeader = () => <div>Edit Campaign Details</div>;
  const editCampaignDetailsFooter = () => (
    <div style={{ display: "flex", flex: 1, gap: 10 }}>
      <div style={{ flex: 1 }}>
        <PrimaryCta
          invert
          onClick={() => props.setIsPopupOpen(false)}
          fontSize={"font_size_large"}
          text={"Cancel"}
          isLoading={""}
          textColor={"white"}
        />
      </div>
      <div style={{ flex: 1 }}>
        <PrimaryCta
          fullWidth={true}
          onClick={() => {
            updateCampaignDetails();
          }}
          fontSize={"font_size_large"}
          text={"Save"}
          isLoading={isLoading}
          className="blue-button"
        />
      </div>
    </div>
  );
  function deliverableData() {
    let newSelectedPlatform = {};
    if (campaignDetail?.content_type_deep_data) {
      campaignDetail?.content_type_deep_data.forEach((item) => {
        if (newSelectedPlatform[item.platform_data.platform_id]) {
          newSelectedPlatform[item.platform_data.platform_id].push(
            item.content_data
          );
        } else {
          newSelectedPlatform[item.platform_data.platform_id] = [
            item.content_data,
          ];
        }
      });
    }
    setSelectedPlatform(newSelectedPlatform);
  }
  function DeliverablesFun(platform_id) {
    return (
      selectedPlatform &&
      Object.values(selectedPlatform).map((items) => {
        return items.map((item) => {
          if (platform_id == item.platform_id) {
            return (
              <div className="deliverables-page-inner-card">
                <Icon
                  iconName={utils.socialMediaIcons(item.platform_id)}
                  width={24}
                  height={24}
                />
                <CustomText
                  p
                  ellipsis
                  fontSize={"font_size_large"}
                  textColor={"text_color_primary"}
                  text={utils.getContentTypeNameById(item.content_type_id)}
                />
              </div>
            );
          }
        });
      })
    );
  }

  const cityId = audience_data?.state && JSON.parse(audience_data?.state);
  const cityNames = cityId && cityId?.map((id) => Utils.getLabelByIdA(id));
  function updateLanguage(selectedList, selectedItem) {
    var value = [];
    selectedList.forEach((item) => {
      value.push(item.id);
    });

    setContentLanguage(value);
  }
  function updateGender(selectedItem) {
    setInfluencerGender(
      selectedItem ? selectedItem.map((option) => option.id) : []
    );
  }
  function updateAddress(selectedList, selectedItem) {
    var value = [];
    selectedList.forEach((item) => {
      value.push(item?.id);
    });

    setState(value);
  }
  return (
    <div>
      <Sidebar
        isOpen={props.isPopupOpen}
        onClose={() => props.setIsPopupOpen(false)}
        Header={editCampaignDetailsHeader}
        Footer={editCampaignDetailsFooter}
        sidebarWidth={"700px"}>
        {" "}
        <div className="p-20">
          <div className="edit-campaign-popup-p">
            <CustomText
              p
              text={"Campaign Details"}
              className="sidebar-subheadings"
            />
          </div>
          <div className="edit-campaign-popup-p">
            <div className="edit-campaign-popup-c">
              <div className=" m-b-10">
                <CustomText
                  p
                  text={"Campaign Title"}
                  fontSize={"font_size_large"}
                  textAlign={"text_align_left"}
                  textColor={"text_grey_400_color"}
                  height={"line_height_19_2"}
                />
              </div>
              <div>
                <CustomTextInput
                  height={51}
                  value={editDetails.campaignName}
                  placeholder={"New year sale"}
                  onChange={(e) =>
                    setEditDetails((prev) => ({
                      ...prev,
                      campaignName: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
            <div className="edit-campaign-popup-c">
              <div className="m-b-10">
                <CustomText
                  p
                  text={"Status"}
                  fontSize={"font_size_large"}
                  textAlign={"text_align_left"}
                  textColor={"text_grey_400_color"}
                  height={"line_height_19_2"}
                />
              </div>
              <div>
                <CustomTextInput
                  height={51}
                  value={campaignDetail?.campaign_data?.status}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="edit-campaign-popup-p">
            <div className="edit-campaign-popup-c">
              <div className=" m-b-10">
                <CustomText
                  p
                  text={"Campaign Type"}
                  fontSize={"font_size_large"}
                  textAlign={"text_align_left"}
                  textColor={"text_grey_400_color"}
                  height={"line_height_19_2"}
                />
              </div>
              <div>
                <CustomTextInput
                  height={51}
                  value={editDetails.campaignType}
                  disabled
                />
              </div>
            </div>
            <div className="edit-campaign-popup-c">
              <div className=" m-b-10">
                <CustomText
                  p
                  text={"Collaboration Type"}
                  fontSize={"font_size_large"}
                  textAlign={"text_align_left"}
                  textColor={"text_grey_400_color"}
                  height={"line_height_19_2"}
                />
              </div>
              <div>
                <CustomTextInput
                  height={51}
                  value={campaignDetail?.campaign_data?.collab_type}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="edit-campaign-popup-p">
            {campaignDetail?.campaign_data?.collab_type !== "BARTER" && (
              <div className="edit-campaign-popup-c">
                <div className=" m-b-10">
                  <CustomText
                    p
                    text={"Maximum Proposal Amount"}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_grey_400_color"}
                    height={"line_height_19_2"}
                  />
                </div>
                <div>
                  <CustomTextInput
                    height={51}
                    placeholder={"Maximum Proposal Amount"}
                    value={budget}
                    onChange={(e) =>
                      setBudget(e.target.value.replace(/\D/g, ""))
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="divider"></div>
        <div className="p-20">
          <div className="edit-campaign-popup-p">
            <div className="edit-campaign-popup-c">
              <div>
                <CustomText
                  p
                  text={"Deliverables"}
                  className="sidebar-subheadings"
                />
              </div>
              <div>
                <ViewCards>
                  <div className="deliverables-card">
                    {selectedPlatform &&
                      Object.keys(selectedPlatform).map((item) => {
                        return <>{DeliverablesFun(item)}</>;
                      })}
                  </div>
                </ViewCards>
              </div>
            </div>
          </div>
        </div>
        <div className="divider"></div>
        <div className="p-20">
          <div className="edit-campaign-popup-p">
            <div className="edit-campaign-popup-c">
              <div className="m-b-10">
                <CustomText
                  p
                  text={"URL"}
                  textAlign={"text_align_left"}
                  className="sidebar-subheadings"
                />
              </div>
              <div>
                <CustomTextInput
                  height={51}
                  value={ctaUrl}
                  placeholder={"e.g. https://mystore.com/collection"}
                  onChange={(e) => setCtaUrl(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="divider"></div>
        <div className="p-20">
          <div className="edit-campaign-popup-p">
            <CustomText
              p
              text={"Demographics"}
              className="sidebar-subheadings"
            />
          </div>
          <div className="edit-campaign-popup-p">
            <div className="edit-campaign-popup-c">
              {influencer_data.categories && (
                <div className="">
                  <div style={{ marginBottom: 10 }}>
                    <CustomText
                      p
                      text={"Categories"}
                      fontSize={"font_size_large"}
                      textAlign={"text_align_left"}
                    />
                  </div>
                  <div>
                    <CustomTextInput
                      value={
                        influencer_data.categories &&
                        Object.values(influencer_data.categories)
                          ?.map((item) => item.name)
                          .join(", ")
                      }
                      height={51}
                      disabled
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="edit-campaign-popup-c">
              <div className=" m-b-10">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Content Language"}
                  textAlign={"text_align_left"}
                />
              </div>
              <div>
                <Multiselect
                  selectedValues={Object.values(
                    Utils.getSelectedLanguageObjects([audience_data.language])
                  )}
                  placeholder="Choose language"
                  id="css_custom"
                  options={Object.values(CampaignData.LANGUAGE)}
                  onSelect={(selectedList, selectedItem) =>
                    updateLanguage(selectedList, selectedItem)
                  }
                  onRemove={(selectedList, selectedItem) =>
                    updateLanguage(selectedList, selectedItem)
                  }
                  selectionLimit={3}
                  singleSelect={false}
                  displayValue={"n"}
                  showArrow={false}
                  style={{
                    chips: Style.chips,
                    option: Style.option,
                    multiselectContainer: Style.multiselectContainerFilter,
                    searchBox: Style.searchBox,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="edit-campaign-popup-p">
            <div className="edit-campaign-popup-c">
              <div className=" m-b-10">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Creator's Gender"}
                  textAlign={"text_align_left"}
                />
              </div>
              <div>
                <Multiselect
                  placeholder="Choose gender"
                  id="css_custom1"
                  selectedValues={Object.values(
                    Utils.getSelectedGenderObjects([audience_data.gender])
                  )}
                  options={Object.values(CampaignData.GENDER)}
                  onSelect={(selectedList, selectedItem) =>
                    updateGender(selectedList, selectedItem)
                  }
                  onRemove={(selectedList, selectedItem) =>
                    updateGender(selectedList, selectedItem)
                  }
                  disable={false}
                  singleSelect={true}
                  hidePlaceholder={true}
                  displayValue={"n"}
                  showArrow={false}
                  style={{
                    chips: Style.chips,
                    option: Style.option,
                    multiselectContainer: Style.multiselectContainerFilter,
                    searchBox: Style.searchBox,
                  }}
                />
              </div>
            </div>
            <div className="edit-campaign-popup-c">
              <div className=" m-b-10">
                <CustomText
                  p
                  text={`Location`}
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  textAlign={"text_align_left"}
                />
              </div>
              <div>
                <Multiselect
                  selectedValues={cityNames}
                  placeholder="Select Location"
                  id="css_custom"
                  options={CampaignData.CITY}
                  onSelect={(selectedList, selectedItem) =>
                    updateAddress(selectedList, selectedItem)
                  }
                  onRemove={(selectedList, selectedItem) =>
                    updateAddress(selectedList, selectedItem)
                  }
                  singleSelect={false}
                  displayValue={"label"}
                  showArrow={false}
                  style={{
                    chips: Style.chips,
                    option: Style.option,
                    multiselectContainer: Style.multiselectContainerFilter,
                    searchBox: Style.searchBox,
                  }}
                />
              </div>
            </div>
          </div>
          <div>
            {influencer_data.minimum_followers !== 0 && (
              <div>
                <div className="m-b-10">
                  <CustomText
                    p
                    text={"Creator’s Level"}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                  />
                </div>
                <div style={{ width: `calc(50% - 10px)` }}>
                  <CustomTextInput
                    value={utils.getFollowersValue(
                      influencer_data.minimum_followers
                    )}
                    height={51}
                    disabled
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="divider"></div>
        <div className="p-20">
          <div className="edit-campaign-popup-p" style={{ marginBottom: 10 }}>
            <CustomText
              p
              text={"Collaboration Requirement"}
              className="sidebar-subheadings"
            />
          </div>
          <div className="edit-campaign-popup-p">
            <div className="edit-campaign-popup-c">
              <div className=" m-b-10">
                <CustomText
                  p
                  text={`Expiry Date`}
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  textAlign={"text_align_left"}
                />
              </div>
              <div>
                <CustomTextInput
                  value={Utils.convertUnixToDate(campaign_data.end_date)}
                  height={51}
                  disabled
                />
              </div>
            </div>
            <div className="edit-campaign-popup-c">
              <div className="m-b-10">
                <CustomText
                  p
                  text={"Reward Value"}
                  fontSize={"font_size_large"}
                  textAlign={"text_align_left"}
                />
              </div>
              <div>
                <CustomTextInput
                  value={
                    reward_data.type == "cash"
                      ? Utils.convertFromINR(
                          campaign_data.max_budget,
                          user_data.country
                        )
                      : Utils.convertFromINR(
                          reward_data.value,
                          user_data.country
                        )
                  }
                  height={51}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="edit-campaign-popup-p">
            <div className="edit-campaign-popup-c">
              <div className="m-b-10">
                <CustomText
                  p
                  text={"Reward Mode"}
                  fontSize={"font_size_large"}
                  textAlign={"text_align_left"}
                />
              </div>
              <div className="">
                <CustomTextInput
                  value={reward_data?.type?.toUpperCase()}
                  height={51}
                  disabled
                />
              </div>
            </div>
            <div className="edit-campaign-popup-c">
              <div className="m-b-10">
                <CustomText
                  p
                  text={"Delivery Date"}
                  fontSize={"font_size_large"}
                  textAlign={"text_align_left"}
                />
              </div>
              <div className="">
                <CustomTextInput
                  value={Utils.convertUnixToDate(
                    campaign_data.delivery_date,
                    user_data.time_zone
                  )}
                  height={51}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="edit-campaign-popup-p">
            {campaign_data.product_price !== 0 && (
              <div className="edit-campaign-popup-c">
                <div className=" m-b-10">
                  <CustomText
                    p
                    text={`Product Price`}
                    fontSize={"font_size_medium"}
                    textColor={"text_color_primary"}
                    textAlign={"text_align_left"}
                  />
                </div>
                <div>
                  <CustomTextInput
                    // value={campaign_data.product_price}
                    height={51}
                    disabled
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="divider"></div>
        <div className="p-20">
          <div>
            <div>
              <div>
                {campaignDetail?.campaign_data?.collab_type !== "BARTER" && (
                  <div className="m-t-12 flex-row ">
                    <input
                      type="checkbox"
                      checked={checked}
                      onChange={handleCheckboxChange}
                      style={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                      }}
                    />
                    <CustomText
                      p
                      fontSize={"font_size_medium"}
                      textColor={"text_color_primary"}
                      text={
                        "Does this campaign requires a product to be purchased?"
                      }
                      className="m-l-5"
                    />
                  </div>
                )}
                {(checked ||
                  campaignDetail?.campaign_data?.collab_type == "BARTER") && (
                  <div className="flex-row " style={{ gap: 20, marginTop: 10 }}>
                    {" "}
                    <div style={{ flex: 0.5 }}>
                      <div className=" m-b-8">
                        <CustomText
                          p
                          text={"Product Price"}
                          fontSize={"font_size_large"}
                          textAlign={"text_align_left"}
                          height={"line_height_19_2"}
                        />
                      </div>
                      <div>
                        <CustomTextInput
                          placeholder={"Product Price"}
                          height={51}
                          value={productPrice}
                          onChange={(e) =>
                            setProductPrice(e.target.value.replace(/\D/g, ""))
                          }
                        />
                      </div>
                    </div>
                    <div className="" style={{ flex: 0.5 }}>
                      <div className=" m-b-8">
                        <CustomText
                          p
                          text={"Product url"}
                          fontSize={"font_size_large"}
                          textAlign={"text_align_left"}
                          height={"line_height_19_2"}
                        />
                      </div>
                      <div>
                        <CustomTextInput
                          value={productUrl}
                          height={51}
                          placeholder={"e.g. https://mystore.com/collection"}
                          onChange={(e) => setProductUrl(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="divider"></div>
        <div className="p-20">
          <>
            <div className="">
              <CustomText
                p
                text={"Instruction for Creator"}
                textAlign={"text_align_left"}
                height={"line_height_19_2"}
                className="sidebar-subheadings"
              />
            </div>

            <div className="edit-details-text-area m-t-4">
              <RichTextEditor
                toolbarConfig={toolbarConfig}
                value={description}
                onChange={(newValue) => setDescription(newValue)}
              />
            </div>
          </>
        </div>
        <div className="divider"></div>
        <div className="p-20">
          <div className="">
            <CustomText
              p
              text={"Sample Content (Max 3)"}
              textAlign={"text_align_left"}
              fontSize={"font_size_large"}
              className="sidebar-subheadings"
              height={"line_height_19_2"}
            />
            <div style={{ position: "relative" }}>
              <div {...getRootProps({ className: "dropzone" })}>
                <div className="upload-content m-t-12">
                  <div className="upload-content-inner">
                    <input {...getInputProps()} />

                    <Image
                      source={"../assets/images/Uploadicon.svg"}
                      height={72}
                      width={82}
                    />
                    <div className="flex-row">
                      <CustomText
                        p
                        fontSize={"font_size_large"}
                        text={"Drag & drop files or "}
                        textColor={"text_color_black"}
                      />
                      <UnderLinedLinkCta
                        fontSize={"font_size_large"}
                        text={`\u00A0Browse`}
                        textColor={"text_Gradient_Color"}
                        // onClick={open}
                      />
                    </div>

                    <div>
                      <CustomText
                        p
                        fontSize={"font_size_label"}
                        textColor={"text_light_weight_color"}
                        text={"Supported formates: JPEG, PNG, MP4 "}
                      />
                    </div>
                  </div>

                  <div style={{ position: "absolute" }}>
                    {isUploading && <Loader />}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-row m-t-8 ">{SampleContent()}</div>
        </div>
      </Sidebar>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
}

export default EditCampaignDetailsScreen;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "var(--general-secondary-color)",
    borderColor: "var(--gray-gray-50)",
    textAlign: "center",
    zIndex: "5",
    minHeight: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    paddingLeft: 30,
    width: "768px",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.86)",
    zIndex: 10,
  },
};
