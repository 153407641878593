// Info: Boilerplate library. Contains basic utility functions.

///////////////////////////Public Functions START//////////////////////////////

import Config from "./config";
import * as CampaignData from "./data/campaign";
import countries from "./data/country";
import exchangeRates from "./data/exchange_rate";
const Country = require("../common/data/countries/country/in.json");
const AGE_RANGE = CampaignData.AGE_RANGE;

const GENDER = CampaignData.GENDER;
const CREATOR_LEVEL = CampaignData.CREATOR_LEVEL;
const LANGUAGE = CampaignData.LANGUAGE;
const LOCATIONS = CampaignData.LOCATIONS;
const CITY = CampaignData.CITY;

const Utils = {
  
  isLoggedIn: function (brand_deep_data) {
    return !!brand_deep_data?.brand_data?.org_id ;
  },
  isNull: function (arg) {
    return arg === null;
  },

  isNullOrUndefined: function (arg) {
    return arg === null && typeof arg === undefined;
  },

  isNullOrUndefinedOrZero: function (arg) {
    return (
      arg === null || typeof arg === "undefined" || arg === 0 || !isFinite(arg)
    );
  },
  isObjectOrNullUndefinedZero: function (arg) {
    // Check if it's an object (including null as true), or undefined or zero
    return (
      typeof arg === "object" ||
      arg === null ||
      typeof arg === "undefined" ||
      arg === 0
    );
  },

  isObjectOrArray: function (data) {
    if (Array.isArray(data)) {
      return true; // It's an array
    } else if (data && typeof data === "object" && !Array.isArray(data)) {
      return true; // It's an object
    }
    return false;
  },
  isUndefined: function (arg) {
    return arg === void 0;
  },

  isBoolean: function (arg) {
    return typeof arg === "boolean";
  },

  isNumber: function (arg) {
    return typeof arg === "number";
  },

  isString: function (arg) {
    return typeof arg === "string";
  },

  /********************************************************************
  Advance type checks
  *********************************************************************/
  isObject: function (arg) {
    return typeof arg === "object" && !Utils.isNull(arg); // (null is also an object)
  },

  /********************************************************************
  Basic Util Functions
  *********************************************************************/
  isEmptyString: function (str) {
    return str.length === 0;
  },

  isEmptyObject: function (obj) {
    return Object.keys(obj).length === 0;
  },

  // Whether an array contains a string (return 'true' if does otherwise 'false')
  inArray: function (arr, element) {
    return arr.indexOf(element) > -1;
  },

  isEmpty: function (arg) {
    return (
      Utils.isNullOrUndefined(arg) || // Check for Null or Undefined
      Utils.isEmptyString(arg) || // Check for empty String (Bonus check for empty Array)
      (Utils.isObject(arg) && Utils.isEmptyObject(arg)) // Check for empty Object or Array
    );
  },

  getKYCTypeString: function (id) {
    const ORG_KYC_TYPES = {
      ADDRESS_PROOF: {
        n: "Address Proof",
        d: "AADHAR, Voter ID Card, Electicity Bill Or CIN can be used as address proof",
      },
      TAX_PROOF: {
        n: "Tax Proof",
        d: "Copy of Pan card can be used as tax proof",
      },
      BANK_PROOF: {
        n: "Bank Proof",
        d: "Bank statement or calcelled cheque can be used as bank proof",
      },
    };
    return ORG_KYC_TYPES[id];
  },

  getFollowerValue: function (value) {
    const influencerData = {
      nano: { minFollowers: 1000, maxFollowers: 10000 },
      micro: { minFollowers: 10000, maxFollowers: 50000 },
      macro: { minFollowers: 50000, maxFollowers: 1000000 },
      mega: { minFollowers: 1000000, maxFollowers: null },
    };
    return influencerData[value];
  },

  /********************************************************************
  Custom Error

  @param {String | Integer | Object} err_code_or_obj - Error Code or First Param can be object also
  * @param {String | Integer} code - Error Code
  * @param {String} message - Error Message
  @param {String} message - Error Message

  @return - JSON Object
  *********************************************************************/
  error: function (err_code_or_obj, message) {
    // Error code
    var code;

    // If object is sent, extract code and message
    if (Utils.isObject(err_code_or_obj)) {
      code = err_code_or_obj["code"];
      message = err_code_or_obj["message"];
    } else {
      code = err_code_or_obj;
    }

    var err = Error(message);
    err.code = code;
    err.name = code.toString(); // Instead of showing 'Error' as title in stack trace, show 'Error Code'

    // Return Newly built Error
    return err;
  },

  /********************************************************************
  Null function - For optional callback functions

  None

  @return None
  *********************************************************************/
  nullFunc: function () {},

  /********************************************************************
  Return JSON object from flattened string

  @param {string} str - String to be converted into JSON

  @return - JSON Object
  *********************************************************************/
  stringToJSON: function (str) {
    // Convert flattened-json string into JSON
    if (!Utils.isNull(str)) {
      // Only if not null
      try {
        str = JSON.parse(str);
      } catch (e) {
        // Convert string -> JSON
        str = null;
      } // Set as null if invalid json
    }

    return str;
  },

  getCampaignType: (type) => {
    const campaignTypes = {
      social_media: "Social Media",
      content: "UGC",
      cash: "Cash",
    };
    return campaignTypes[type];
  },

  getFollowersValue: (value) => {
    const influencerValue = {
      1000: "Nano 1K - 10K",
      10000: "Micro 10K - 50K",
      50000: "Mid Tier  50k - 2.5L",
      250000: "Macro  2.5L - 10L",
      1000000: "Top Tier  1M+ ",
    };
    return influencerValue[value];
  },
  getFollowerValue: function (value) {
    const influencerData = {
      nano: { minFollowers: 1000, maxFollowers: 10000 },
      micro: { minFollowers: 10000, maxFollowers: 50000 },
      macro: { minFollowers: 50000, maxFollowers: 1000000 },
      // mega: { minFollowers: 1000000, maxFollowers: null },
    };
    return influencerData[value];
  },

  /********************************************************************
  Return reversed String
  Note: Only works for ASCII strings and some Unicodes

  @param {string} str - String to be reversed

  @return - Reversed string
  *********************************************************************/
  stringReverse: function (str) {
    return Array.from(str).reverse().join("");
  },

  /********************************************************************
  Return cleaned string with only characters from specific regular expresion
  Remove all the dangerous characters excluding those who satisfy RegExp

  @param {string} str - String to be sanatized/cleaned
  @param {string} regx - The regular expression

  @return - Sanatized string
  *********************************************************************/
  sanitizeUsingRegx: function (str, regx) {
    // If null or undefined or zero-length, return value as-it-is
    if (Utils.isNullOrUndefined(str) || str.length === 0) {
      return str;
    }

    // Return Clean String
    return str.replace(regx, ""); // Clean and return
  },

  /********************************************************************
  Return cleaned Integer. Convert String/Decimals to a whole-number.

  @param {Unknown} num - Number to be cleaned

  @return {Number} - Sanitized number. Rounded to 'Floor' in case of decimal.
  *********************************************************************/
  sanitizeInteger: function (num) {
    // Convert to Integer
    var i = parseInt(Number(num)); // Convert String/Decimal or any type to equivalent Integer

    // Check if NaN in case of Alphabates String passed as number
    if (isNaN(i)) {
      return null; // Return Null in case it's not a number
    } else {
      return i; // Return clean Integer
    }
  },

  /********************************************************************
  Return cleaned Boolean. Convert String/Number to true/false

  @param {Unknown} bool - Boolean to be cleaned

  @return {Boolean} - Sanitized boolean value
  *********************************************************************/
  sanitizeBoolean: function (bool) {
    // Return
    return Boolean(Number(bool)); // Return string -> number -> boolean
  },

  /********************************************************************
  Return unix timestamp in seconds for that day at 12:00 AM

  @param {Integer} [unix_timestamp] - Unix Timestamp, whose day is to be calculated

  @return {Integer} - Unix timestamp (Seconds)
  *********************************************************************/
  getUnixDateFromUnixTime: function (unix_timestamp) {
    // Convert unix timestamp to date object
    var date = new Date(unix_timestamp * 1000);

    var dateAt00 = new Date(
      date.getUTCFullYear() +
        "-" +
        (date.getUTCMonth() + 1) +
        "-" + // Add '1', because Month index starts at 0 (Jan is 0 | Dec is 11)
        date.getUTCDate()
    ); // 'yyyy-mm-dd'

    // Return Unix Timestamp for that Day at 12:00 AM (in seconds)
    return Utils.getUnixTime(dateAt00);
  },

  /********************************************************************
  Return specific/current unix timestamp in seconds

  @param {string} [date] - (Optional) Date to be converted into unix timestamp. If not sent in param, then return current time

  @return {String} - Unix timestamp (Seconds)
  *********************************************************************/
  getUnixTime: function (date) {
    // Return Unix Timestamp equivalant of specific date in seconds
    return Math.floor(Utils.getUnixTimeInMilliSeconds(date) / 1000); // Convert Milli-Seconds to Seconds
  },
  // Unix date to date format DD-MM-YYYY
  convertUnixToDate: function (unixDate, timeZone) {
    if (!unixDate) {
      console.log("Error: Invalid Unix Date", unixDate);
      return "Invalid Date";
    }

    let timeZoneUnixTime = Utils.unixtimeToTimezoneTime(unixDate, timeZone);
    console.log("Converted Unix Time:", timeZoneUnixTime); // Debugging

    const moment = require("moment");

    if (!timeZoneUnixTime) {
      console.log("Error: Time Zone conversion failed", timeZoneUnixTime);
      return "Invalid Date";
    }

    return moment.unix(timeZoneUnixTime).format("DD-MM-YYYY");
  },

  formatUnixDateToCustom: function (unixDate, timeZone) {
    let timeZoneUnixTime = Utils.unixtimeToTimezoneTime(unixDate, timeZone);
    const moment = require("moment");
    return moment.unix(timeZoneUnixTime).format("DD MMM YY");
  },
  convertUnixToDateAndTime: function (unixDate, hoursToAdd, minutesToAdd) {
    const moment = require("moment");
    const updatedUnixTimestamp = moment
      .unix(unixDate)
      .add(hoursToAdd, "hours")
      .add(minutesToAdd, "minutes")
      .unix();

    const formattedDate = moment
      .unix(updatedUnixTimestamp)
      .format("hh:mm a DD-MM-YYYY");
    return formattedDate;
  },

  convertUnixToTime: function (unixDate, hoursToAdd, minutesToAdd) {
    const moment = require("moment");
    const updatedUnixTimestamp = moment
      .unix(unixDate)
      .add(hoursToAdd, "hours")
      .add(minutesToAdd, "minutes")
      .unix();

    const formattedDate = moment
      .unix(updatedUnixTimestamp)
      .format("hh:mm a DD-MMM");
    return formattedDate;
  },

  typeMappings: function () {
    return {
      fxt1: "IMAGE",
      cuc3: "IMAGE",
      utio: "IMAGE",
      o5oi: "VIDEO",
      ge03: "VIDEO",
      oops: "VIDEO",
      ttsn: "VIDEO",
      uyts: "VIDEO",
    };
  },
  convertUnixToHumanReadable: function (unixDate, hoursToAdd, minutesToAdd) {
    const moment = require("moment");
    // Create a moment object from the Unix timestamp
    const updatedUnixTimestamp = moment
      .unix(unixDate)
      .add(hoursToAdd, "hours")
      .add(minutesToAdd, "minutes");

    // Format the date into the desired human-readable format
    const formattedDate = updatedUnixTimestamp.format("D MMM");

    return formattedDate;
  },
  convertUnixToHumanSortReadable: function (
    unixDate,
    hoursToAdd,
    minutesToAdd
  ) {
    const moment = require("moment");
    // Create a moment object from the Unix timestamp
    const updatedUnixTimestamp = moment
      .unix(unixDate)
      .add(hoursToAdd, "hours")
      .add(minutesToAdd, "minutes");

    // Format the date into the desired human-readable format
    const formattedDate = updatedUnixTimestamp.format("D MMM, YY");

    return formattedDate;
  },

  /********************************************************************
  Return specific/current unix timestamp in Milli-Seconds

  @param {string} [date] - (Optional) Date to be converted into unix timestamp. If not sent in param, then return current time

  @return {String} - Unix timestamp (Milli-Seconds)
  *********************************************************************/
  getUnixTimeInMilliSeconds: function (date) {
    // Check if custom date is sent
    if (!Utils.isNullOrUndefined(date)) {
      return new Date(date); // Return Unix Timestamp equivalant of specific date in Milliseconds
    } else {
      return new Date().getTime(); // Return Unix Timestamp equivalant of current time in Milliseconds
    }
  },

  /********************************************************************
  Round a Decimal number to specified number of digits after decimal
  Ref: https://stackoverflow.com/questions/11832914/round-to-at-most-2-decimal-places-only-if-necessary
  Note: math.round() is more precise then Number.toFixed()

  @param {Number} num - Number to be rounded off
  @param {Number} digits_after_decimal - Number of digits after decimal

  @return {Number} - Rounded off number
  *********************************************************************/
  round: function (num, digits_after_decimal) {
    // If null or undefined, return value as-it-is
    if (Utils.isNullOrUndefined(num)) {
      return num;
    }

    // Calculate multiplier
    var multiplier = Number(`1e${digits_after_decimal}`); // 1e0 === 1, 1e1 === 10, 1e2 === 100

    // Return
    return Math.round(num * multiplier) / multiplier; // 123.456 = 123.45, 123.4 = 123.4
  },

  /********************************************************************
  Check if number is Integer (Whole Number)

  @param {Number} num - number to be checked

  @return {Boolean} - true if Integer (10.0 | 10)
  @return {Boolean} - false if Decimal number (10.7 | 0.7)
  *********************************************************************/
  isInteger: function (num) {
    // Return
    return num % 1 === 0;
  },

  /********************************************************************
  Break string into array with a delimiter
  (Inbuilt skips all empty elements and trim whitespaces and convert to lowercase)

  @param {String} delimiter - The boundary string
  @param {String} str - The input string. Can be NULL or Empty

  @return {Boolean} - false if input sring is null or ''
  @return {String[]} - Newly converted array of strings
  *********************************************************************/
  stringToArray: function (delimiter, str) {
    if (str.length > 0) {
      // If not empty string
      var arr = str.split(delimiter); // Split into Array
      arr = arr.map(function (item) {
        return item.trim().toLowerCase();
      }); // Convert to lowercase and Trim white spaces including \n \t \r
      arr = arr.filter(Boolean); // Remove Empty element from array in case string is null/''/0/false/undefined. (If you split an empty string, you get back a one-element array with 0 as the key and an empty string for the value.)
    } else {
      return false; //Empty string
    }

    // Check if resultant array is empty, then return false
    if (arr.length > 0) {
      return arr;
    } else {
      return false; // Empty array
    }
  },

  /********************************************************************
  Join 2 Arrays (or String) of 'key' and 'value' into one Object

  @param {String|Array} keys - Array with list of keys or single item string
  @param {String|Array} values - Array with list of values or single item string

  @return {Set} - Object with mearged key vale pairs
  *********************************************************************/
  // keyValueToObject: function(keys, values){
  //
  //   var obj = {};
  //
  //   if( !Array.isArray(keys) ){ // If single item string
  //     obj[keys] = values;
  //   }
  //   else{
  //     keys.map( function(key, index){ obj[key] = values[index] } ); // Create a Set from feilds-array with corrosponding values-array
  //   }
  //
  //   return obj;
  //
  // },

  /********************************************************************
  Check if All chracters in string are of valid charset and string has
  minimum and maximum length

  @param {String} str - The variable to be checked
  @param {Number} [min_length] - (Optional) Minimum required length this string must have
  @param {Number} [max_length] - (Optional) Maximum length this string can have

  @return {Boolean} - true on success
  @return {Boolean} - false if validation fails

  Note: Always check this function output against identic (===) FALSE to
  avoid mismatches with text 'false' or '0' or empty strings
  *********************************************************************/
  // changeNumberFormate: function (val) {
  //   if (val >= 10000000) {
  //     val = (val / 10000000).toFixed(2) + " Cr";
  //   } else if (val >= 100000) {
  //     val = (val / 100000).toFixed(2) + " L";
  //   } else if (val >= 1000) val = (val / 1000).toFixed(2) + " K";
  //   return val;
  // },

  changeNumberFormate: function (value) { 
    if (isNaN(value) || value == null || value == undefined || value === Infinity || value === -Infinity) {
      return "-";
    }
    if (value >= 1000000000) {
      return (value / 1000000000).toFixed(1) + "B";
    } else if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + "M";
    } else if (value >= 1000) {
      return (value / 1000).toFixed(1) + "K";
    }
    return value;
},

  sortByKey: function (array, key, order = "asc") {
    return array.sort((a, b) => {
      // Check if the property exists on both objects
      if (a[key] === undefined || b[key] === undefined) {
        return 0;
      }

      // Determine the sort order for string values using localeCompare
      if (typeof a[key] === "string" && typeof b[key] === "string") {
        return order === "asc"
          ? a[key].localeCompare(b[key])
          : b[key].localeCompare(a[key]);
      }

      // For numeric values (or other types) use subtraction
      if (order === "asc") {
        return a[key] - b[key];
      } else {
        return b[key] - a[key];
      }
    });
  },
  firstUpperCase: function (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  },

  isArray: function (arg) {
    return Array.isArray(arg);
  },
  validateString: function (str, min_length, max_length) {
    // Null/Empty-String Allowed (Only if minimum length is specified)
    if (
      !Utils.isNullOrUndefined(min_length) && // sent in param
      min_length === 0 &&
      (str === null || str === ``)
    ) {
      return true;
    }

    // Check if string type
    if (typeof str !== "string") {
      return false;
    }

    // Check Min and Max length limit
    var len = str.length; //Store var length

    // Check Min Length (Only if specified)
    if (!Utils.isNullOrUndefined(min_length) && len < min_length) {
      return false; // Less then minimum required length
    }

    // Check Max Length (Only if specified)
    if (!Utils.isNullOrUndefined(max_length) && len > max_length) {
      return false; // More then maximum allowed length
    }

    // Reach here means all validations passed
    return true; // Validation successful
  },

  /********************************************************************
  Check if All chracters in string statisfy particular regular expression
  and string has minimum and maximum length

  @param {String} str - The variable to be checked
  @param {String} regx - The regular expression (EX: '[a-z0-9]{6}')
  @param {Number} [min_length] - (Optional) Minimum required length this string must have
  @param {Number} [max_length] - (Optional) Maximum length this string can have

  @return {Boolean} - true on success
  @return {Boolean} - false if validation fails
  *********************************************************************/
  validateStringRegx: function (str, regx, min_length, max_length) {
    // Null/Empty-String Allowed (Onlu if minimum length is specified)
    if (
      !Utils.isNullOrUndefined(min_length) && // Sent in params
      min_length === 0 &&
      (str === null || str === ``)
    ) {
      return true;
    }

    // Check string against regular expression
    if (!regx.test(str)) {
      return false;
    }

    // Check Min and Max length limit
    var len = str.length; //Store var length

    // Check Min Length (Only if specified)
    if (!Utils.isNullOrUndefined(min_length) && len < min_length) {
      return false; // Less then minimum required length
    }

    // Check Max Length (Only if specified)
    if (!Utils.isNullOrUndefined(max_length) && len > max_length) {
      return false; // More then maximum allowed length
    }

    // Reach here means all validations passed
    return true; // Validation successful
  },

  /********************************************************************
  Check if Integer is within Minimum and maximum range (including min and max)

  @param {String} num - The variable to be checked
  @param {Number} [min_value] - (Optional) Minimum required value
  @param {Number} [max_value] - (Optional) Maximum allowed value (including)

  @return {Boolean} - true on success
  @return {Boolean} - false if validation fails
  *********************************************************************/
  validateNumber: function (num, min_value, max_value) {
    // Validate type
    if (typeof num !== "number") {
      return false;
    }

    // Check Minimum Value
    if (!Utils.isNullOrUndefined(min_value) && num < min_value) {
      // If Minimum value set in parameter
      return false;
    }

    // Check Maximum Value
    if (!Utils.isNullOrUndefined(max_value) && num > max_value) {
      // If Maximum value set in parameter
      return false;
    }

    // Reach here means all validations passed
    return true; // Validation successful
  },

  isNaN: function (value) {
    return typeof value === "number" && Number.isNaN(value);
  },

  getSocialUrl: function (id, platform_id) {
    console.log("id, platform_id", id, platform_id);

    let youtubeSuffix = platform_id;
    if (platform_id?.charAt(0) === "@") {
      youtubeSuffix = platform_id;
    }

    const urls = {
      wde2: "https://fb.co/" + platform_id,
      btll: "https://instagram.com/" + platform_id,
      nxjo: "https://youtube.com/" + youtubeSuffix,
      "6u73": "https://twitter.com/" + platform_id,
    };
    return urls[id];
  },

  /********************************************************************
  Extract file directory, name, extension from absolute path
  Example: mydir/some_dir/my_file.jpg -> return 'mydir/some_dir', 'my_file', 'jpg'

  @param {String} absolute_path - Full path of file with directory and extension

  @return {Map} disjoined_path
  * @return {String} disjoined_path.file_dir: file directory and extension. '.' in case no directory
  * @return {String} disjoined_path.file_name: file name without directory and extension. Empty string in case of no filename
  * @return {String} disjoined_path.file_ext: extension without trailing period. Empty string in case of no extension
  *********************************************************************/
  disjoinAbsolutePath: function (absolute_path) {
    // Include path library
    const Path = require("path");

    // result Object
    var result = {};

    // Get file extension
    result["file_ext"] = Path.extname(absolute_path).substring(1); // gives 'jpg' or empty string in case no extension (substring to remove preceding '.')

    // Get file name
    result["file_name"] = Path.basename(
      absolute_path,
      "." + result["file_ext"]
    ); // give 'my_file' or empty string if not found

    // Get file directory
    result["file_dir"] = Path.dirname(absolute_path); // gives 'mydir/some_dir' or '.' in case no directory

    // Return extension
    return result;
  },

  /********************************************************************
  Error list

  @param {String} error_list - error list

  @return {Map} disjoined_path
  * @return {String} disjoined_path.file_dir: file directory and extension. '.' in case no directory
  *********************************************************************/
  getErrorMessage: function (errorList) {
    return errorList.map((error) => error.m).join(", ");
  },
  generateBrandLogoUrl: function (orgId, imageId) {
    const mediaBaseURL = Config.MEDIA_BASE_URL + "/brand_logo/";
    if (orgId && imageId) {
      return mediaBaseURL + orgId + "/" + imageId;
    }
  },
  formatRewardText: function (rewardData) {
    if (rewardData.reward_type == "cash") {
      return "Rs " + rewardData.value.amount;
    } else {
      return rewardData?.value?.amount + " Credits";
    }
  },

  assignmentStatusToString: function (status) {
    const statusString = {
      ACTIVE: `Active`,
      PAYMENT_PENDING: "Payment Pending",
      CANCELLED: "Cancelled",
      IN_PROGRESS: "In Progress",
      COMPLETED: `Completed`,
      INACTIVE: `Inactive`,
      IN_REVIEW: `In review`,
      CONTENT_PENDING: "Content Pending",
    };
    return statusString[status];
  },

  transactionTextToString: function (status) {
    const transactionString = {
      USER_SUBSCRIPTION_DEBIT: `Subscription purchase`,
      USER_CREDIT_PURCHASE_DEBIT: "Credit purchase",
      USER_TASK_PAYMENT_DEBIT: `Paid for contract `,
      INACTIVE: `Inactive`,
      IN_REVIEW: `In review`,
      CONTENT_PENDING: "Content Pending",
      TASK_PAYMENT_WALLET: "Paid for contract",
      USER_WALLET: "Added in Wallet",
      TASK_REFUND_WALLET: "Task cancellation Refund",
    };
    return transactionString[status];
  },

  generateAssignmentSubText: function (assignmentItem) {
    const { contents_deep_data, assignment_data } = assignmentItem;
    const { assignment_status } = assignment_data;

    let assignmentSubText = "";
    if (assignment_status === "ACTIVE") {
      assignmentSubText = "Waiting for Influencer to start work";
    } else if (assignment_status === "IN_PROGRESS") {
      const totalContent = Object.keys(contents_deep_data).length;

      let inReview = 0;
      let contentPending = 0;
      let newAssignment = 0;

      Object.keys(contents_deep_data).forEach((item) => {
        const { status } = contents_deep_data[item].content_data;
        if (status == "IN_REVIEW") {
          inReview++;
        }
        if (status == "CONTENT_PENDING") {
          contentPending++;
        }
        if (status == "NEW") {
          newAssignment++;
        }
      });

      if (newAssignment === totalContent) {
        // All are new
        assignmentSubText = assignmentSubText + "Waiting for content";
      } else if (inReview > 0) {
        // At least one item for review
        assignmentSubText = inReview + " Content(s) ready for review";
      } else if (contentPending === 0) {
        // Content pending from creator
        assignmentSubText = assignmentSubText + "Waiting for content";
      } else if (!inReview && contentPending >= 1) {
        // Content pending from creator
        assignmentSubText = assignmentSubText + "Social media post pending ";
      }
    } else if (assignment_status === "PAYMENT_PENDING") {
      assignmentSubText = "Pay creator to download content";
    } else if (assignment_status === "COMPLETED") {
      assignmentSubText = "Contract closed";
    } else if (assignment_status === "CANCELLED") {
      assignmentSubText = "Contract cancelled";
    }
    return assignmentSubText;
  },
  generateCampaignSampleUrl: function (brandId, mediaId) {
    const mediaBaseURL = Config.MEDIA_BASE_URL + "/sample_content/";
    if (brandId && mediaId) {
      return mediaBaseURL + brandId + "/" + mediaId;
    }
  },

  generateCampaignSampleThumbUrl: function (brandId, mediaId) {
    if (mediaId.split(".")[1] == "mp4") {
      mediaId = mediaId.split(".")[0] + ".jpg";
    }
    const mediaBaseURL = Config.MEDIA_BASE_URL + "/sample_content/";
    if (brandId && mediaId) {
      return mediaBaseURL + brandId + "/" + mediaId;
    }
  },
  // generateCampaignSampleUrl1: function (brandId, mediaId) {
  //   const mediaBaseURL =
  //     "Config.MEDIA_BASE_URL/sample_content/";
  //   if (brandId && mediaId) {
  //     return mediaBaseURL + brandId + "/" + mediaId;
  //   }
  // },

  generateKycImageUrl: function (org_id, imageId) {
    const url =
      Config.MEDIA_BASE_URL + "/org_kyc_documents/" + org_id + "/" + imageId;

    return url;
  },

  getContentTypeNameById: function (content_type_id) {
    const contentTypeName = {
      "3hs1": "Facebook Image Post",
      cuc3: "Twitter Image Post",
      oops: "Twitter Video Post",
      fxt1: "Instagram Image Post",
      ge03: "Instagram Video Post",
      utio: "Instagram Image Story",
      uyts: "Instagram Video Story",
      o5oi: "Instagram Reel",///p/
      ttsn: "Youtube Video",
      vi82: "Youtube Shorts",
    };
    return contentTypeName[content_type_id];
  },

  generateInfluencerDpUrl: function (influencerId, imageId) {
    const mediaBaseURL = Config.MEDIA_BASE_URL + "/influencer_dp/";
    if (influencerId && imageId) {
      return mediaBaseURL + influencerId + "/" + imageId;
    }
  },

  constructContentMediaUrl: function (contract_id, media_id) {
    let media =
      Config.MEDIA_BASE_URL +
      "/contract_content/" +
      contract_id +
      "/" +
      media_id;

    return media;
  },
  generateContentUrl: function (platformId, contentTypeId, shortcode) { 
    if (!platformId || !contentTypeId || !shortcode) {
      console.error("Missing required parameters for content URL generation.");
      return null;
    }  
    return `${platformId=="btll"?  `https://www.instagram.com`:`https://www.youtube.com`}/${contentTypeId=="o5oi"? `reel`:`p`}/${shortcode}`;
  },
  constructContentMediaThumbUrl: function (contractId, mediaId) {
    if (mediaId.split(".")[1] == "mp4") {
      mediaId = mediaId.split(".")[0] + ".jpg";
    }
    let media =
      Config.MEDIA_BASE_URL + "/contract_content/" + contractId + "/" + mediaId;

    return media;
  },

  socialMediaIcons: function (id) {
    const SocialMediaIcon = {
      wde2: "FacebookIcon",
      btll: "instagramNewIcon",
      nxjo: "youtubeNewIcon",
      "6u73": "Twitter",
    };
    return SocialMediaIcon[id];
  },

  socialMediaName: function (id) {
    const SocialMediaName = {
      wde2: "Facebook",
      btll: "Instagram",
      nxjo: "Youtube",
      "6u73": "X App",
    };
    return SocialMediaName[id];
  },
  socialMediaColor: function (id) {
    const SocialMediaName = {
      wde2: "#337FFF",
      btll: "#f55879",
      nxjo: "#FF3000",
      "6u73": "#090A14",
    };
    return SocialMediaName[id];
  },

  // influencerFollowerValue: function (id) {
  //   const InfluencerFollowers = {
  //     1000: "Nano    1K - 10k",
  //     10000: "Micro  10k - 100k",
  //     50000: "Macro  100k - 1M",
  //     100000: "Mega  1M",
  //   };
  //   return InfluencerFollowers[id];
  // },

  generateBrandLogoUrl: function (orgId, imageId) {
    const mediaBaseURL = Config.MEDIA_BASE_URL + "/brand_logo/";
    if (orgId && imageId) {
      return mediaBaseURL + orgId + "/" + imageId;
    }
  },

  getPlatformIconNamebyId: function (platform_id) {
    const platformIconName = {
      wde2: "FacebookIcon",
      btll: "instagramIcon",
      nxjo: "youtubeIcon",
      "6u73": "twitterIcon",
    };
    return platformIconName[platform_id];
  },

  getSelectedAgeObjects: function (selectedAge) {
    console.log("selectedAge", selectedAge);
    return selectedAge.map((ageId) => AGE_RANGE[ageId]).filter(Boolean);
  },
  getSelectedGenderObjects: function (selectedAge) {
    return selectedAge.map((ageId) => GENDER[ageId]).filter(Boolean);
  },
  getSelectedFollowerObjects: function (selectedAge) {
    return selectedAge.map((ageId) => CREATOR_LEVEL[ageId]).filter(Boolean);
  },
  getSelectedLanguageObjects: function (selectedLan) {
    return selectedLan.map((ageId) => LANGUAGE[ageId]).filter(Boolean);
  },
  getSelectedCityObjects: function (city) {
    return city.map((cityId) => CITY[cityId]).filter(Boolean);
  },
  getLabelByIdA: function (id) {
    const item = CITY.find((item) => item.id === id);

    return item ? { label: item.label, id: item.id } : null;
  },

  getLabelById: function (id) {
    const city = CITY.find((city) => city.id === id);
    return city ? city.label : "City not found";
  },

  getCityById: function (id) {
    const city = CITY.find((city) => city.id == id);

    return city ? city.label : "City not found";
  },
  getSelectedLocationObjects: function (selectedLoc) {
    return selectedLoc.map((id) => CITY[id]).filter(Boolean);
  },
  getSelectedStateObjects: function (selectedLoc) {
    return selectedLoc.map((id) => LOCATIONS[id]).filter(Boolean);
  },

  getStateName: function (location) {
    const state = LOCATIONS[location];
    return state ? state.n : "State not found";
  },

  convertAgeIdToHuman: function (id) {
    const ageRange = {
      "18_25": "18-25",
      "26_35": "26-35",
      "36_45": "36-45",
      "46_55": "46-55",
      "56_65+": "56-65+",
    };
    return ageRange[id];
  },
  validatePan: (pan) => {
    const panRegex = new RegExp("[A-Z]{5}[0-9]{4}[A-Z]{1}");
    return panRegex.test(pan);
  },

  validateGst: (gst) => {
    const gstRegex = new RegExp(
      "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$"
    );
    return gstRegex.test(gst);
  },

  validatePin: (pin) => {
    const pinRegex = new RegExp("[0-9]{6}");
    return pinRegex.test(pin);
  },

  // getAgeValue: function (id) {
  //   const AGE_RANGE = {
  //     "18_25": {
  //       n: "18-25",
  //       id: "18_25",
  //     },
  //     "26_35": {
  //       n: "26-35",
  //       id: "26_35",
  //     },
  //     "36_45": {
  //       n: "36-45",
  //       id: "36_45",
  //     },
  //     "46_55": {
  //       n: "46-55",
  //       id: "46_55",
  //     },
  //     "56_65+": {
  //       n: "56-65+",
  //       id: "56_65+",
  //     },
  //   };
  //   return AGE_RANGE[id];
  // },

  generateCategoryImageUrl: function (categoryId) {
    const url =
      "https://s3.amazonaws.com/ind-file-upload/categories/categories/" +
      categoryId +
      ".jpg";

    return url;
  },
  getStateString: function (id) {
    return Country?.sd[id]["sdn"];
  },
  convertToK: function (number) {
    if (number >= 1000000) {
      return (number / 1000000).toFixed(1) + "M";
    } else if (number >= 1000) {
      return (number / 1000).toFixed(1) + "K";
    } else {
      return number;
    }
  },

  // set icon for proposal socialmedia
  getSocialIcon: function (id) {
    const IconName = {
      btll: "InstagramBlack",
      "6u73": "TwitterBlack",
      nxjo: "YoutubeBlack",
    };
    return IconName[id];
  },
  getSocialIconHover: function (id) {
    const IconName = {
      btll: "InstagramHover",
      "6u73": "TwitterHover",
      nxjo: "YoutubeHover",
    };
    return IconName[id];
  },

  truncateWithEllipsis: function (text, maxLength) {
    return text.length > maxLength
      ? text.slice(0, maxLength - 3) + "..."
      : text;
  },

  capitalizeFirstLetter: function (string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  },

  engagementType: function (type) {
    let engagementType;

    if (type == "AUCTION") {
      engagementType = "Public";
    } else {
      engagementType = "Private";
    }
    return engagementType;
  },

  socialMediaPlatform: function (id) {
    let socialMedia;
    if (
      "o5oi" == id ||
      "ge03" == id ||
      "fxt1" == id ||
      "utio" == id ||
      "uyts" == id
    ) {
      socialMedia = "Instagram";
    }

    if ("vi82" == id || "ttsn" == id) {
      socialMedia = "youtube";
    }

    if ("cuc3" == id || "oops" == id) {
      socialMedia = "twitter";
    }

    return socialMedia;
  },

  creditTypeSpent: function (type) {
    let transactionText = "";
    if (
      type === "USER_CREDIT_CAMPAIGN_DEBIT" ||
      type === "USER_CREDIT_CAMPAIGN_CREATE_DEBIT"
    ) {
      transactionText = "Credit spent for campaign";
    } else if (type == "USER_CREDIT_EXTEND_CAMPAIGN_END_DATE_DABIT") {
      transactionText = "Credit spent for campaign extend date";
    } else if (type == "USER_CREDIT_INVITE_INFLUENCER_DEBIT") {
      transactionText = "Credit spent for counter bid";
    } else if (type === "USER_CREDIT_PURCHASE_DEBIT") {
      transactionText = "Credit purchase ";
    } else if (type === "USER_CREDIT_INVITE_INFLUENCER_DEBIT") {
      transactionText = "Credit spent for counter bid";
    } else if (type == "USER_CREDIT_SIGNUP_CREDIT") {
      transactionText = "sign up bonus credit";
    }

    return transactionText;
  },
  creditTypeSpent: function (type) {
    let transactionText = "";
    if (
      type === "USER_CREDIT_CAMPAIGN_DEBIT" ||
      type === "USER_CREDIT_CAMPAIGN_CREATE_DEBIT"
    ) {
      transactionText = "Credit spent for campaign";
    } else if (type == "USER_CREDIT_EXTEND_CAMPAIGN_END_DATE_DABIT") {
      transactionText = "Credit spent for campaign extend date";
    } else if (type == "USER_CREDIT_INVITE_INFLUENCER_DEBIT") {
      transactionText = "Credit spent for counter bid";
    } else if (type === "USER_CREDIT_PURCHASE_DEBIT") {
      transactionText = "Credit purchase ";
    } else if (type === "USER_CREDIT_INVITE_INFLUENCER_DEBIT") {
      transactionText = "Credit spent for counter bid";
    } else if (type == "USER_CREDIT_SIGNUP_CREDIT") {
      transactionText = "Sign up bonus credit";
    } else if (type == "USER_CREDIT_COUNTER_CREATE_DEBIT") {
      transactionText = "Credit counter debit";
    } else if (type == "USER_SUBSCRIPTION_CREDIT") {
      transactionText =
        "Subscription purchased! Credits have been added to your account.";
    } else if (type == "USER_GET_CONTACT_CREDIT_DABIT") {
      transactionText = "Credit spent to get contact";
    }

    return transactionText;
  },

  colorStatus: function (type) {
    switch (type) {
      case "ACCEPTED":
        return "green";
      case "REJECTED":
        return "red";
      case "NOT ACTION":
        return "gray";
      default:
        return "black";
    }
  },
  textStatus: function (type) {
    switch (type) {
      case "ACCEPTED":
        return "Approved";
      case "REJECTED":
        return "Rejected";
      case "NOT ACTION":
        return "Not Action";
      default:
        return "";
    }
  },

  convertFromINR: function (amountInINR, targetCountryCode = "IN") {
    // Find the target country's currency code and symbol
    const country = countries[targetCountryCode];
    if (!country) {
      console.log(`Unsupported country code: ${targetCountryCode}`);
      return null;
    }

    const currencyCode = country.currency.code;
    const currencySymbol = country.currency.symbol;

    // Check if the exchange rate exists for the target currency
    const rate = exchangeRates[currencyCode];
    if (!rate) {
      console.log(`Exchange rate not found for currency: ${currencyCode}`);
      return null;
    }

    // Convert the amount using the exchange rate
    const convertedAmount = amountInINR * rate;

    // Format the result with the currency symbol and round to 2 decimal places
    return `${currencySymbol}${convertedAmount.toFixed(2)}`;
  },

  convertFromINRForWrite: function (amountInINR, targetCountryCode) {
    // Find the target country's currency code and symbol
    const country = countries[targetCountryCode];
    if (!country) {
      console.log(`Unsupported country code: ${targetCountryCode}`);
      return null;
    }

    const currencyCode = country.currency.code;
    const currencySymbol = country.currency.symbol;

    // Check if the exchange rate exists for the target currency
    const rate = exchangeRates[currencyCode];
    if (!rate) {
      console.log(`Exchange rate not found for currency: ${currencyCode}`);
      return null;
    }

    // Convert the amount using the exchange rate
    const convertedAmount = amountInINR * rate;

    // Format the result with the currency symbol and round to 2 decimal places
    return Number(convertedAmount.toFixed(2));
  },

  // convertToINR: function (amount, fromCountryCode) {
  //   const country = countries[fromCountryCode];
  //   if (!country) {
  //     console.log(`Unsupported country code: ${fromCountryCode}`);
  //     return null;
  //   }

  //   const currencyCode = country.currency.code;
  //   const rate = exchangeRates[currencyCode];
  //   if (!rate) {
  //     console.log(`Exchange rate not found for currency: ${currencyCode}`);
  //     return null;
  //   }

  //   // Convert the amount to INR (1 foreign unit / rate gives INR equivalent)
  //   const convertedAmount = amount / rate;
  //   return convertedAmount;
  // },

  convertToINR: function (amount, fromCountryCode) {
    // Default to "IN" if fromCountryCode is not provided or invalid
    const countryCode =
      fromCountryCode && countries[fromCountryCode] ? fromCountryCode : "IN";

    const country = countries[countryCode];
    if (!country) {
      console.log(`Unsupported country code: ${countryCode}`);
      return null;
    }

    const currencyCode = country.currency.code;
    const rate = exchangeRates[currencyCode];
    if (!rate) {
      console.log(`Exchange rate not found for currency: ${currencyCode}`);
      return null;
    }

    // Convert the amount to INR (1 foreign unit / rate gives INR equivalent)
    const convertedAmount = amount / rate;
    return convertedAmount;
  },
  getCurrencySymbol: function (targetCountryCode) {
    // Find the target country's currency code and symbol
    const country = countries[targetCountryCode];
    if (!country) {
      console.log(`Unsupported country code: ${targetCountryCode}`);
      return null;
    }

    const currencySymbol = country.currency.symbol;

    // Format the result with the currency symbol and round to 2 decimal places
    return currencySymbol;
  },

  getTimeZone: function (targetCountryCode) {
    return countries[targetCountryCode]["timezones"];
  },

  /********************************************************************
  Get offset of a Timezone at particular Time (Supports DST)

  @param {Integer} unixtime - Seconds passed since Unix-Epoch (UTC)
  @param {String} timezone - IANA Timezone ID

  @return {Integer} - Offset in Seconds
  *********************************************************************/
  getTimezoneOffset: function (unixtime, timezone) {
    // Convert Unix Time to Data Object
    var date = new Date(unixtime * 1000);

    // Define Options for Formatter (Thursday, March 12, 00:59)
    var options = {
      year: "numeric",
      month: "long",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hourCycle: "h23",
      timeZone: timezone,
    };

    // Get Date in New Timezone
    var new_date_string_parts = Intl.DateTimeFormat(
      "en",
      options
    ).formatToParts(date); // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/formatToParts

    // Format Date in New Timezone
    var new_date_string_data = {};
    new_date_string_parts.forEach(function (item) {
      // Extract Keys for Date parts
      new_date_string_data[item.type] = item.value;
    });

    // Construct Date String in specific format '01 July 2022, 02:48:15'
    var new_date_string = `${new_date_string_data.day} ${new_date_string_data.month} ${new_date_string_data.year},  ${new_date_string_data.hour}:${new_date_string_data.minute}:${new_date_string_data.second}`;

    // New Time in Seconds with Error-Fix for Date Conversion
    var time_with_fix =
      new Date(new_date_string).getTime() / 1000 - // Convert Date into Seconds (In Local Timezone)
      new Date().getTimezoneOffset() * 60; // Local Timezone Offset in Seconds

    // Return Timezone Offset
    return time_with_fix - unixtime; // Return Offset in Seconds
  },

  /********************************************************************
  Convert Unixtime in UTC to Unixtime in Specific Timezone

  @param {Integer} unixtime - Seconds passed since Unix-Epoch (UTC)
  @param {String} timezone - IANA Timezone Name

  @return {Integer} - New time with timezone adjustment
  *********************************************************************/
  unixtimeToTimezoneTime: function (unixtime, timezone) {
    return Utils.calcTimeWithOffset(
      Number(unixtime),
      Utils.getTimezoneOffset(unixtime, timezone)
    );
  },
  /********************************************************************
  Calculate Epoch Seconds with offset fix

  @param {Integer} time - Seconds passed since Epoch
  @param {Number} offset - Offset in Seconds (Can be Negative)

  @return {Integer} - New time with offset adjustment
  *********************************************************************/
  calcTimeWithOffset: function (unixtime, offset) {
    // Return
    return unixtime + offset;
  },

  getSelectedSubCategoryObjects: function (id) {
    let categories = CampaignData.FULL_CATEGORIES;
    for (const subcategoryKey in categories.subcategories) {
      const subcategoryList = categories.subcategories[subcategoryKey];
      const match = subcategoryList.find(
        (subcategory) => subcategory.id === id
      );
      if (match) {
        return match.name;
      }
    }
    return null; // Return null if no match is found
  },

  // has phone number
  getPhoneNumberShow: function (phoneNumber) {
    if (phoneNumber === true) {
      return "*******";
    }
    if (!phoneNumber) {
      return "Not available";
    }
    return `+91 ${phoneNumber}`;
  },
  // has phone number
  getEmailAddressShow: function (email) {
    if (email === true) {
      return "*******";
    }
    if (!email) {
      return "Not available";
    }
    return `${email}`;
  },

  checkRoleAndPermission: function (redux_selector, action) {
    const { UserData = {} } = redux_selector;
    const { brand_deep_data = {} } = UserData;
    const { org_deep_data = {} } = UserData;
    const { user_data } = UserData;
    const { roles_and_permission } = UserData;

    if (user_data?.role == "ADMIN") {
      return true;
    }
    if (roles_and_permission) {
      if (roles_and_permission?.permissions?.includes("all")) {
        return true;
      }

      if (roles_and_permission?.permissions.includes(action.value)) {
        return true;
      }
    }

    return false;
  },

  getLocationInfluencer: function (influencer_data) {
    if (influencer_data?.city_id) {
      return (
        CampaignData.CITY.find((city) => city.id == influencer_data.city_id)
          ?.label || "-"
      );
    } else if (influencer_data?.state) {
      return CampaignData.LOCATIONS[influencer_data.state]?.n || "-";
    } else {
      return "-";
    }
  },

  getEmbedUrl: function (type, id) {
    console.log("sandeep", type, id);

    if (type == "btll") {
      return `https://www.instagram.com/reel/${id}`;
    }

    if (type == "nxjo") {
      return `https://www.youtube.com/watch?v=${id}`;
    }
  },

  getSubCategoryData: function (professionId) {
    let subData = null;
    Object.values(CampaignData.FULL_CATEGORIES.subcategories).forEach(
      (subcategoryArray) => {
        const foundItem = subcategoryArray.find(
          (item) => item.id == professionId
        );
        if (foundItem) {
          subData = foundItem;
        }
      }
    );
    return subData;
  },

  createRoleMember: function (role) {
    const roles = {
      brand_manager: "Brand Manager",
      admin: "ADMIN",
    };

    return roles[role] || role;
  },

  renderBrandName: function (data, brandIds) {
    console.log("brand data", data, brandIds);

    if ((brandIds && !Array.isArray(data)) || !Array.isArray(brandIds)) {
      return "-";
    }

    const filteredBrands = data
      .filter(
        (item) => item.brand_data && brandIds.includes(item.brand_data.brand_id)
      )
      .map((item) => ({
        brand_id: item.brand_data.brand_id,
        name: item.brand_data.name,
      }));

    return filteredBrands.length > 0 ? filteredBrands : "-";
  },

  getRoleName: function (roles, roleId) {
    const role = roles.find((r) => r.role_id === roleId);
    return role ? role.role_name : "Role not found";
  },

  checkVideoOrImage: function (fileName) {
    if (fileName) {
      const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp"];
      const videoExtensions = [
        "mp4",
        "mov",
        "avi",
        "mkv",
        "flv",
        "wmv",
        "webm",
        "video/mp4",
      ];

      const extension = fileName.split(".").pop().toLowerCase();

      if (imageExtensions.includes(extension)) {
        return "image";
      } else if (videoExtensions.includes(extension)) {
        return "video";
      } else {
        return "unknown";
      }
    }
  },
}; // Close Public Functions

////////////////////////////Public Functions END///////////////////////////////

//////////////////////////Private Functions START//////////////////////////////
//None
///////////////////////////Private Functions END///////////////////////////////

export default Utils;
