import { useEffect, useState } from "react";
import Modal from "react-modal";
import { CustomText, CustomTextInput, PrimaryCta } from "../components";
import Icon from "../components/core_component/icon";
import Core from "../common/clientSdk";
import { toast } from "react-toastify";
export const InfluencerContractsShareLink = ({
  closeShareModal,
  campaignId,
  assignment_share_key,
}) => {
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);

  const [url, setUrl] = useState(null);
  console.log("assignment_share_key", assignment_share_key);

  useEffect(() => {
    if (!assignment_share_key) {
      getGenerateShareKeyCampaignContractsList();
    } else {
      setUrl(assignment_share_key);
    }
  }, []);

  console.log("process", window.location);
  // let hostName = `https://${window.location.host}/collection/share/${share_key}`;

  function getGenerateShareKeyCampaignContractsList() {
    var params = {
      campaign_id: campaignId,
    };

    Core.generateShareKeyCampaignContractsList(
      getGenerateShareKeyCampaignContractsListSuccess,
      getGenerateShareKeyCampaignContractsListFailed,
      (status) => setLoading(status),
      params
    );
  }

  function getGenerateShareKeyCampaignContractsListSuccess(response) {
    setUrl(response.share_key);
  }

  function getGenerateShareKeyCampaignContractsListFailed(error) {
    toast.error(error.map((item) => item.m).join(","));
  }

  function constructUrl(share_key) {
    if (window.location.hostname == "localhost") {
      return `http://${window.location.host}/contracts/share/${share_key}`;
    } else {
      return `https://${window.location.host}/contracts/share/${share_key}`;
    }
  }

  const copyToClipboard = () => {
    console.log("navigator", navigator);

    if (!navigator.clipboard) {
      console.error("Clipboard API not supported");
      return;
    }

    navigator.clipboard
      .writeText(constructUrl(url))
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset "copied" status after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <Modal
      isOpen={true}
      onRequestClose={closeShareModal}
      style={CancelReminder}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          background: "#ffffff",

          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 30,
          paddingTop: 15,
        }}
      >
        <div className="collection-link-copy-header">
          <CustomText
            h5
            text={"Contracts"}
            textColor={"text_grey_400_color"}
            className="m-b-20 typography-centerH7"
          />
          <div onClick={closeShareModal} className="close-btn-icon">
            <Icon iconName="CloseRoundButtonIcon" />
          </div>
        </div>
      </div>
      <div className="share-collection-popout" style={{ paddingTop: 40 }}>
        <div className="collection-sheet-url-container">
          <CustomTextInput
            invert
            value={
              loading
                ? "generate url..."
                : `http://${window.location.host}/contracts/share/${url}`
            }
            placeholder={"e.g. https://mystore.com/collection"}
            //   onChange={(e) => setProductUrl(e.target.value)}
          />
          <PrimaryCta
            third
            onClick={() => copyToClipboard()}
            text={copied ? "Copied!" : "Copy link"}
            className="copy-cta-btn"
            disabled={loading}
          />
        </div>

        <div className="collection-link-regenerate">
          <CustomText
            p
            text={"Disable/Regenerate previously generated link:"}
            className="button1"
            textColor="text_grey_100_color"
          />
          <div
            onClick={getGenerateShareKeyCampaignContractsList}
            className="share-icon-regenrate"
            style={{
              display: "flex",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
          >
            <div className="regenerate-url m-t-5 ">
              <Icon iconName="regenerateUrlIcon" />
            </div>
            <div>
              <CustomText
                p
                text={"Regenerate"}
                className="button-text m-l-5"
                textColor="text_grey_100_color"
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const CancelReminder = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#ffffff",
    textAlign: "center",
    zIndex: "5",
    // height: "35%",
    display: "flex",
    flexDirection: "column",
    minWidth: "460px",
    maxWidth:516,
    width:"100%",
    minHeight: "150px",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.95)",
    zIndex: 10,
  },
};
