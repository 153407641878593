import React from "react";
import {
  CustomText,
  FallBack,
  CustomTextInput,
  PrimaryCta,
  CustomTextArea,
  Popup,
} from "../../components";

import { useState, useEffect } from "react";
import Core from "../../common/clientSdk";
import Modal from "react-modal";
import { Loader } from "../loader";
import Icon from "../../components/core_component/icon";
import Utils from "../../common/utils";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import campaign from "../../common/data/campaign";

const customStylesModal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "var(--general-secondary-color)",
    borderColor: "var(--gray-gray-50)",
    textAlign: "center",
    zIndex: "5",
    minHeight: "40%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    paddingLeft: 30,
    width: "368px",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.86)",
    zIndex: 10,
  },
};

function NoteListInfluencer({
  closeNoteModal,
  setCollectionInfluencerList,
  collectionId,
  influencerId,
  noteType,
  userNote,
}) {
  const [note, setNote] = useState(userNote);
  const [loading, setLoading] = useState(false);
  var redux_selector = useSelector((state) => {
    return {
      UserData: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const { UserData = {} } = redux_selector;
  const { brand_deep_data = {} } = UserData || {};
  const { user_data = {} } = UserData || {};
  const { brand_data = {} } = brand_deep_data || {};
  console.log("UserData", UserData);
  function addNoteInfluencer() {
    if (!note) {
      toast.error("Please enter text");
      return;
    }
    const params = {
      collection_id: collectionId,
      influencer_id: influencerId,
    };
    if (noteType == "internal_note") {
      params.note = note;
    }
    if (noteType == "feedback") {
      params.feedback = note;
    }

    if (noteType == "feedback") {
      Core.postClintFeedback(
        addFeedbackInfluencerSuccess,
        addNoteInfluencerFail,
        (status) => setLoading(status),
        params
      );
    } else {
      Core.addPriceToCollectionInfluencer(
        addNoteInfluencerSuccess,
        addNoteInfluencerFail,
        (status) => setLoading(status),
        params
      );
    }
  }

  function addNoteInfluencerSuccess(response) {
    closeNoteModal();

    if (noteType == "internal_note") {
      setCollectionInfluencerList((prevState) => ({
        ...prevState,
        collection_influencer_list: prevState?.collection_influencer_list?.map(
          (influencer) =>
            influencer?.influencer_data?.influencer_id ===
            response.influencer_deep_data?.influencer_data?.influencer_id
              ? {
                  ...influencer,
                  influencer_data: {
                    ...influencer.influencer_data,
                    ...response.influencer_deep_data.influencer_data,
                  },
                  categorys_data: {
                    ...influencer.categorys_data,
                    ...response.influencer_deep_data.categorys_data,
                  },
                  platforms_data:
                    response.influencer_deep_data.platforms_data ||
                    influencer.platforms_data,
                }
              : influencer
        ),
      }));
    }
  }

  function addFeedbackInfluencerSuccess(data, response) {
    closeNoteModal();

    if (noteType == "feedback") {
      setCollectionInfluencerList((prevState) => ({
        ...prevState,
        collection_influencer_list: prevState?.collection_influencer_list?.map(
          (influencer) =>
            influencer?.influencer_data?.influencer_id ===
            response.influencer_deep_data?.influencer_data?.influencer_id
              ? {
                  ...influencer,
                  influencer_data: {
                    ...influencer.influencer_data,
                    ...response.influencer_deep_data.influencer_data,
                  },
                }
              : influencer
        ),
      }));
    }
  }
  function addNoteInfluencerFail(errorList) {
    toast.error(errorList);
  }

  function renderAddNote() {
    return (
      <>
        <Popup
          isOpen={true}
          onClose={() => closeNoteModal()}
          headerText={noteType == "internal_note" ? "Note" : "Feedback"}
          footerButton1Text="Cancel"
          footerButton1Action={() => closeNoteModal()}
          footerButton2Text="Update"
          footerButton2Action={() => addNoteInfluencer()}
          isLoading={loading}
          // useBottomSheet={false}
          width={"480px"}
        >
          <div>
            <div className="m-t-10">
              <div style={{ textAlign: "center" }} className="flex-row">
                <CustomText
                  h5
                  fontSize={"font_size_large"}
                  text={noteType == "internal_note" ? "Note" : "Feedback"}
                  textColor={"text_grey_400_color"}
                  fontWeight={"Poppins_regular_large"}
                  textAlign={"text_align_left"}
                  className="m-b-8"
                />
              </div>
              <div className=" brand-text-area">
                <CustomTextArea
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
              </div>
            </div>
          </div>
        </Popup>
      </>
    );
  }

  return <>{renderAddNote()}</>;
}

export default NoteListInfluencer;
