import {
  Routes, //replaces "Switch" used till v5
  Route,
  useParams,
} from "react-router-dom";
import { useSelector } from "react-redux";
import WebStorage from "../common/web_storage";
import * as WebStorageKey from "../common/web_storage_keys";
import Utils from "../common/utils";
import AddBrandDetails from "../screens/addBrandDetails";
import CampaignDetails from "../screens/campaignDetails";
import CampaignListingScreen from "../screens/campaignListingScreen";
import CreateCampaign from "../screens/createCampaign";
import DashboardScreen from "../screens/dashboardScreen";
import ForgotPassword from "../screens/authentication/forgotPassword";
// Import Screens
import LoginScreen from "../screens/authentication/loginScreen";
import OnboardingScreen1 from "../screens/onboardingScreen1";
import OnboardingScreen2 from "../screens/onboardingScreen2";
import SignUpScreen from "../screens/authentication/signUpScreen";
import Team from "../screens/team";
import UserProfile from "../screens/userProfile";
import VerifyEmail from "../screens/authentication/verifyEmail";
import { Suspense, useEffect, useState } from "react";
import { Loader } from "../screens/loader";
import CampaignDetailsParentScreen from "../screens/campaignDetailsParent/campaignDetailsParentScreen";
// import CompanySettingScreen from "../screens/companyDetails/companySettingScreen";
import BrandSettingsScreen from "../screens/brandSetiing/brandSettingsScreen";
import TransactionsScreen from "../screens/transactionsList/transactionsScreen";
import UserSettingsScreen from "../screens/userSettings/userSettingsScreen";
import ContractsDetailsParentScreen from "../screens/contractDetail/contractDetailParentScreen";
import TeamMembers from "../screens/teamMemberScreen/teamMembers";
import TeamMembersList from "../screens/teamMemberScreen/teamMembers";
import InviteMember from "../screens/teamMemberScreen/inviteMember";
import TransactionHistoryScreen from "../screens/transactionHistory/transactionHistoryScreen";
import MyCreditsScreen from "../screens/myCredits/myCreditsScreen";
import PaymentSuccess from "../screens/myCredits/paymentSuccess";
import EmailVerifyScreen from "../screens/companyDetails/emailVerifyScreen";
import VerifyEmailOtpScreen from "../screens/companyDetails/verifyEmailOtpScreen";
import CompanyNameEdit from "../screens/companyDetails/companyNameEdit";
import CompanyAddressEdit from "../screens/companyDetails/companyAddressEdit";
import KycVerifyScreen from "../screens/companyDetails/kycVerifyListScreen";
// import KycTaxProofScreen from "../screens/companyDetails/kycVerify/kycTaxProofScreen";
import KycAddressProofScreen from "../screens/companyDetails/kycVerify/kycAddressProof";
import UserPhoneNumber from "../sidebars/changeUserPhoneNumber";
import UserEmail from "../sidebars/changeUserEmail";
import ContractListScreen from "../screens/contractDetail/contractListScreen";
import PayoutListScreen from "../screens/payoutList/payoutListScreen";
import CreditsTransactionScreen from "../screens/myCredits/creditsTransactionScreen";
import TermsScreen from "../screens/authentication/terms";
import DeviceScreen from "../screens/authentication/device";
import PrivacyPolicyScreen from "../screens/authentication/privacy-policy";
import { Crisp } from "crisp-sdk-web";
import MobileDetect from "mobile-detect";
import InfluencerDetail from "../screens/campaignDetailsParent/influencerDetailScreen";
import SettingScreen from "../screens/companyDetails/settingScreen";
import MessagesScreen from "../screens/contractDetail/messagesScreen";
import NotificationScreen from "../screens/common/notification";
import ContentData from "../screens/contractDetail/contentDataScreen";
import CreateCollectionList from "../screens/collection/collectionList";
import CreateInfluencerCollectionList from "../screens/collection/influencerCollection";
import InfluencerListTable from "../screens/influecnerScreen/influencerList";
import ScriptScreen from "../screens/contractDetail/scriptScreen";
import CreateScript from "../screens/contractDetail/createScript";
import Share from "../screens/collection/share";
import ShareContracts from "../screens/collection/shareContracts";
import BrandAnalytics from "../screens/brandSetiing/brandAnalytics";
import CreditsPolicyScreen from "../screens/myCredits/creditPolicy";
import MarketPlace from "../screens/marketplace/marketplace";
import NewSignUp from "../screens/authentication/newSignUp";
import NewSignIn from "../screens/authentication/newSignIn";
import ProposalPolicy from "../screens/campaignDetailsParent/proposalPolicy";
import DashboardNewScreen from "../screens/DashboardNewScreen";
import CheckoutPayment from "../screens/common/checkoutScreen";
import InflencerDiscovery from "../screens/influencerDiscovery/influencerDiscovery";
import InfluencerDetailMainPage from "../screens/influecnerScreen/influencerDetailMainPage";
import ContentResearch from "../screens/contentresearch/contentresearch";
import ContentResearchContent from "../screens/contentresearch/contentResearchContent";

const RootNavigator = function (props) {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const md = new MobileDetect(window.navigator.userAgent);
    setIsMobile(md.mobile());
  }, []);
  var { id } = useParams();

  var redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  function determineLoginStatus() {
    if (
      !Utils.isNull(redux_selector.BrandUser) &&
      !Utils.isNull(redux_selector.BrandUser.user_data) &&
      !Utils.isNull(redux_selector.BrandUser.brand_deep_data) &&
      redux_selector.BrandUser.brand_deep_data != undefined
    ) {
      Crisp.user.setEmail(redux_selector.BrandUser.user_data.email);
      Crisp.user.setNickname(redux_selector.BrandUser.user_data.name);
      Crisp.chat.hide();
      return <DashboardNewScreen />;
    } else {
      return <NewSignIn />;
    }
  }

  function determineCreateCampaignPath() {
    if (id != null && id != undefined) {
      return "/create-campaign/:id";
    } else {
      return "/create-campaign";
    }
  }
  function determineCreateAddBrandLogoPath() {
    if (id != null && id != undefined) {
      return "/add-brand-details/:id";
    } else {
      return "/add-brand-details";
    }
  }

  return (
    <Routes>
      <Route path="/" element={determineLoginStatus()} />
      <Route path="/signup" element={<NewSignUp />} />
      <Route path="/verify-email" element={<VerifyEmail />} />
      <Route path="/verify-email/:id" element={<VerifyEmail />} />
      <Route path="/onboard-1" element={<OnboardingScreen1 />} />
      <Route path="/signup/onboard-2" element={<OnboardingScreen2 />} />
      <Route path="/campaigns" element={<CampaignListingScreen />} />
      <Route
        path={determineCreateCampaignPath()}
        element={<CreateCampaign />}
      />
      <Route path="/create-campaign/:id" element={<CreateCampaign />} />
      <Route path="/terms" element={<TermsScreen />} />
      <Route path="/device" element={<DeviceScreen />} />
      <Route path="/credit-policy" element={<CreditsPolicyScreen />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyScreen />} />
      <Route path="/contract-policy" element={<ProposalPolicy />} />
      <Route
        path={determineCreateAddBrandLogoPath()}
        element={<AddBrandDetails />}
      />
      <Route path="add-brand-details/:id" element={<AddBrandDetails />} />
      <Route path="/campaigns/:id" element={<CampaignDetailsParentScreen />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      {/* <Route path="/settings" element={<UserProfile />} /> */}
      <Route path="/team" element={<Team />} />

      {/* <Route path="/company-settings" element={<CompanySettingScreen />} /> */}
      <Route path="/brand-settings" element={<BrandSettingsScreen />} />
      <Route path="/transaction-list" element={<TransactionHistoryScreen />} />
      <Route path="/user-settings" element={<UserSettingsScreen />} />
      <Route path="/contracts/:id" element={<ContractsDetailsParentScreen />} />
      <Route path="/team-members-list" element={<TeamMembersList />} />
      <Route path="/invite-member" element={<InviteMember />} />
      <Route
        path="/transactionHistory"
        element={<TransactionHistoryScreen />}
      />
      <Route path="/open-modal" element={<MyCreditsScreen />} />
      <Route path="/payment_success" element={<PaymentSuccess />} />
      <Route path="/email-verify" element={<EmailVerifyScreen />} />
      <Route path="/email-verify-otp" element={<VerifyEmailOtpScreen />} />
      <Route path="/company-name-edit" element={<CompanyNameEdit />} />
      <Route path="/company-address-edit" element={<CompanyAddressEdit />} />
      <Route path="/kyc-details-screen" element={<KycVerifyScreen />} />
      {/* <Route path="/kyc-tax-proof" element={<KycTaxProofScreen />} /> */}
      <Route
        path="/kyc-address-proof/:id"
        element={<KycAddressProofScreen />}
      />
      <Route path="/phone-number" element={<UserPhoneNumber />} />
      <Route path="/email" element={<UserEmail />} />
      <Route path="/contracts" element={<ContractListScreen />} />
      <Route path="/payout-list" element={<PayoutListScreen />} />
      <Route path="credit-transaction" element={<CreditsTransactionScreen />} />
      <Route path="/influencer/:id" element={<InfluencerDetail />} />
      <Route path="/settings" element={<SettingScreen />} />
      <Route path="/message/:id" element={<MessagesScreen />} />
      <Route path="/message" element={<MessagesScreen />} />
      <Route path="/notification" element={<NotificationScreen />} />
      <Route path="/content-view" element={<ContentData />} />
      <Route path="/collection" element={<CreateCollectionList />} />
      <Route
        path="/collection-influencer/:id"
        element={<CreateInfluencerCollectionList />}
      />
      <Route path="/influencers" element={<InfluencerListTable />} />
      <Route
        path="/influencer-detail/:id"
        element={<InfluencerDetailMainPage />}
      />
      <Route path="/script" element={<ScriptScreen />} />
      <Route path="/create-script" element={<CreateScript />} />
      <Route path="/collection/share/:share_key" element={<Share />} />
      <Route path="/contracts/share/:share_key" element={<ShareContracts />} />
      <Route path="/brand-analytics" element={<BrandAnalytics />} />
      <Route path="/market-place" element={<MarketPlace />} />
      <Route path="/checkout" element={<CheckoutPayment />} />
      <Route path="/database" element={<InflencerDiscovery />} />
      <Route path="/content-research" element={<ContentResearch />} />
      <Route path="/content-research/:keyword" element={<ContentResearchContent />} />
    </Routes>
  );
};
export default RootNavigator;
