import React, { useState, lazy, Suspense, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  CustomText,
  Image,
  CustomTextInput,
  PrimaryCta,
  LinkCta,
  UnderLinedLinkCta,
  CustomTextInputNew,
} from "../../components";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../redux/actions";
import Core from "../../common/clientSdk.js";
import Utils from "../../common/utils.js";
import validator from "validator";
import WebStorage from "../../common/web_storage";
import { ORG_ID_KEY } from "../../common/web_storage_keys";
import { Loader } from "../loader";
import { useSnackbar } from "react-simple-snackbar";
import ReactGA from "react-ga";
const NewSignInRightSide = () => {
  const [checkEmailloading, setCheckEmailLoading] = useState(false);
  const [loading, set_loading] = useState(false);
  const [email, set_email] = useState("");
  const [checkEmail, setCheckEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  var navigate = useNavigate();

  useEffect(() => {
    disableField();
  }, [email]);

  var redux_dispatch = useDispatch();

  const [openSnackbar] = useSnackbar();

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const validateEmail = (value) => {
    if (!emailRegex.test(value)) {
      setEmailError("Please enter a valid email address.");
      return false;
    }
    setEmailError("");
    return true;
  };

  const disableField = () => {
    if (email.trim() !== "") {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  };

  function checkEmailRegistration(e) {
    // e.preventDefault();
    var params = {
      email: email,
    };
    if (validateEmail(email)) {
      Core.checkEmailRegistration(
        checkEmailRegistrationSuccess,
        checkEmailRegistrationFailed,
        updateProgressCheckEmail,
        params
      );
    }
  }
  function checkEmailRegistrationSuccess(err, response) {
    setCheckEmail(response?.is_email_exist);
    if (response?.is_email_exist) {
      setIsEmailValid(true);
    } else {
      navigate("/signup", {
        state: { data: email },
      });
    }
  }

  function checkEmailRegistrationFailed() {}

  function handleKeyPress(e) {
    if (e.key === "Enter") {
      checkEmailRegistration(e);
    }
  }

  function updateProgress(status) {
    set_loading(status);
  }

  function updateProgressCheckEmail(status) {
    setCheckEmailLoading(status);
  }
  function googleAnalytics() {
    ReactGA.event({
      category: "User",
      action: "REELAX_ADS_WEB_LOGIN",
    });
  }
  function brandLogin() {
    var params = {
      user_data: JSON.stringify({
        email: email,
        password: password,
      }),
    };

    Core.brandLogin(
      brandLoginSuccess,
      brandLoginFailed,
      updateProgress,
      params
    );
  }
  function brandLoginSuccess(response_data) {
    //update brand user data in redux
    redux_dispatch({
      type: Actions.UPDATE_BRAND_USER_DATA,
      payload: { user_data: response_data.user_data },
    });

    redux_dispatch({
          type: Actions.RULE_AND_PERMISSIONS,
          payload: {
            roles_and_permission: response_data.roles_and_permission,
            all_permissions: response_data.all_permissions,
          },
        });
        
    redux_dispatch({
      type: Actions.UPDATE_ORG_DATA,
      payload: { org_deep_data: response_data.org_data },
    });

    // update brand data in redux
    redux_dispatch({
      type: Actions.UPDATE_BRAND_DATA,
      payload: { brand_deep_data: response_data.brand_list[0] },
    });

    // Update Auth token
    redux_dispatch({
      type: Actions.UPDATE_BRAND_USER_AUTH,
      payload: { auth_token: response_data.auth_id },
    });
    WebStorage.saveDataInWebStorage(
      ORG_ID_KEY,
      response_data.org_data.org_data.org_id
    );
    if (response_data.brand_list.length == 0) {
      navigate("/add-brand-details");
    } else {
      navigate("/");
    }
    googleAnalytics();
  }
  function brandLoginFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }
  return (
    <div class="signin-right-container">
      <div className="signin-right-side-top-container">
        <div className="login-right-side-top-container-div-1 ">
          <div>
            <Image source={"../assets/images/Reelax.png"} />
          </div>

          <div className="login-right-side-top-container-div-2 ">
            SignIn to Reelax
          </div>
          {!isEmailValid ? (
            <div className="email-validation">
              <div className="login-right-side-top-container-div-3 ">
                <CustomText h5 fontSize={"font_size_large"} text={`Email`} />
              </div>
              <div className="login-right-side-top-container-div-4 ">
                <CustomTextInput
                  newWidth={"login-right-side-top-input-field"}
                  height="51px"
                  placeholder="Enter email address"
                  value={email}
                  onKeyDown={handleKeyPress}
                  onChange={(e) => set_email(e.target.value)}
                  onBlur={(e) => validateEmail(e.target.value)}
                />
                {emailError && <p style={{ color: "#F04248" }}>{emailError}</p>}
              </div>

              <div className="signin-right-side-top-container-div-5">
                <PrimaryCta
                  className="login-right-side-input-field"
                  fifth
                  height="51px"
                  text={"Signin"}
                  fontSize={"font_size_2xl"}
                  iconName="rightArrow"
                  iconPosition="right"
                  isLoading={checkEmailloading}
                  onClick={checkEmailRegistration}
                  disabled={isButtonDisabled}
                />
              </div>
            </div>
          ) : (
            <div className="signin-validation">
              <div className="login-right-side-top-container-div-3 ">
                <CustomText h5 fontSize={"font_size_large"} text={`Email`} />
              </div>
              <div className="login-right-side-top-container-div-4 ">
                <CustomTextInput
                  newWidth={"login-right-side-top-input-field"}
                  height="51px"
                  placeholder="Enter email address"
                  onChange={(e) => set_email(e.target.value)}
                  onBlur={(e) => validateEmail(e.target.value)}
                />
              </div>

              <div className="login-right-side-top-container-div-3 ">
                <CustomText h5 fontSize={"font_size_large"} text={`Password`} />
              </div>

              <div className="signin-right-side-top-container-div-4 ">
                <CustomTextInput
                  newWidth={
                    "login-right-side-top-input-field custom-input-text"
                  }
                  type="password"
                  height="51px"
                  value={password}
                  placeholder="Enter password"
                  required
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <div className="m-t-4 forgot-password">
                <LinkCta
                  p
                  textColor={"text_color_forgot"}
                  text={"Forgot password ?"}
                  onClick={() => {
                    navigate("/forgot-password");
                  }}
                />
              </div>

              <div className="signin-right-side-top-container-div-5">
                <PrimaryCta
                  className="login-right-side-input-field"
                  fifth
                  height="51px"
                  fontSize={"font_size_2xl"}
                  iconName="rightArrow"
                  iconPosition="right"
                  text={"SignIn"}
                  onClick={brandLogin}
                  isLoading={loading}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
const styles = {
  signinRightSide: {
    flex: "1",
    overflowY: "auto",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "34px 80px 34px 50px",
  },
};
export default NewSignInRightSide;
