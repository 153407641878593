import React, { useState, useEffect } from "react";

import Multiselect from "multiselect-react-dropdown";
import Select from "react-dropdown-select";
import Style from "../resources/style";
import styled from "@emotion/styled";
import CampaignData from "../common/data/campaign";
import validator from "validator";
import PhoneInput from "react-phone-number-input";
import { toast, ToastContainer } from "react-toastify";
import {
  CustomText,
  CustomTextInput,
  PrimaryCta,
  Sidebar,
} from "../components";
import Core from "../common/clientSdk";
import { useSelector } from "react-redux";

function UpdateInviteMember({
  inviteUpdateToggle,
  updateIsOpen,
  memberData,
  setMemberList,
}) {
  const [selectedPermission, setSelectedPermission] = useState([]);
  const [updateRoles, setUpdateRoles] = useState([]);
  const [contactMember, setContactMember] = useState();
  const [selectedRole, setSelectedRole] = useState([]);
  const [customRole, setCustomRole] = useState("");
  const [brandList, setBrandList] = useState([]);
  const [brandId, setBrandId] = useState([]);
  const [userRole, setUserRole] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [memberName, setMemberName] = useState("");
  const [number, setNumber] = useState("");
  // const [memberData, setMemberData] = useState({});
  function updateProgress() {}

  useEffect(() => {
    getUserRoleList();

    getBrandList(updateProgress);
  }, []);

  useEffect(() => {
    if (memberData) {
      setMemberName(memberData.name);
      setEmail(memberData.email);
    }
  }, [memberData]);

  const redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });
  const { BrandUser = {} } = redux_selector;
  const { brand_deep_data = {} } = BrandUser;
  const { org_deep_data = {} } = BrandUser;

  function getBrandList(progressCb) {
    var params = {
      org_id: brand_deep_data?.brand_data?.org_id,
    };
    Core.brandList(brandListSuccess, brandListFailed, progressCb, params);
  }

  function brandListSuccess(response) {
    setBrandList(response.brand_list);
  }

  function brandListFailed(errorList) {
    // openSnackbar(errorList.map((item) => item.m).join(", "));
  }
  function getUserRoleList() {
    var params = {
      org_id: brand_deep_data?.brand_data?.org_id,
    };
    Core.getUserRoleList(
      getUserRoleListSuccess,
      getUserRoleListFailed,
      () => {},
      params
    );
  }

  function getUserRoleListSuccess(response, data) {
    setUserRole(data.role_list);
    setLoading(false);
  }

  function getUserRoleListFailed(errorList) {
    // openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function postUpdateInviteMembers() {
    var params = {
      // name: memberName,
      // country: "IN",
      // phone_number: removePlusSign(number),
      // role_name: updateRoles[0]?.value == "custom_role" && customRole,
      // role_id: selectedRole[0]?.value,
      // email: email,
      user_id: memberData.user_id,
      role_id: selectedRole[0]?.value,
      role_data: JSON.stringify({
        role_id: selectedRole[0]?.value,
        brand_ids: brandId.map((item) => item.value),
        user_id: memberData.user_id,
        // permissions: selectedPermission.map((item) => item.value),
      }),
      member_data: JSON.stringify({
        user_id: memberData.user_id,
        wa_number: removePlusSign(number),
      }),
      // org_id: brand_deep_data?.brand_data?.org_id,
      // user_id: memberData.invite_id,
      //  type: selectedRole[0]?.type,
    };

    if (
      validateName(memberName) &&
      validateNumber() &&
      validateEmail(email) &&
      validateBrandsSelect() &&
      validateSelectedRole()
    ) {
      Core.updateAssignUserRole(
        postInviteMembersSuccess,
        postInviteMembersFailed,
        (status) => setLoading(status),
        params
      );
    }
  }

  function postInviteMembersSuccess(data, response) {
    setMemberList((prev) => {
      const updatedList = prev.map((item) =>
        item.user_id === response.member_data.user_id
          ? response.member_data
          : item
      );

      const roleExists = prev.some(
        (item) => item.user_id === response.member_data.user_id
      );

      return roleExists ? updatedList : [...prev, response.member_data];
    });

    toast.success("Update successfully");
    inviteUpdateToggle();
  }

  function postInviteMembersFailed(errorList) {
    toast.error(errorList);
    inviteUpdateToggle();
  }

  function validateName(value) {
    if (validator.isEmpty(value, {})) {
      toast.error("Please enter name");
    } else {
      return true;
    }
  }

  function validateNumber() {
    if (!number) {
      toast.error("Please Enter Whatsapp Number");
      return false;
    }

    return true;
  }
  function validateEmail(value) {
    if (!validator.isEmail(value, {})) {
      toast.error("Please enter a valid email");
      return false;
    }
    return true;
  }
  function validateBrandsSelect() {
    if (brandListSelected()?.length <= 0) {
      toast.error("Please select brand");
      return false;
    }

    return true;
  }
  function validatePermissionsSelect() {
    if (selectedPermission.length <= 0) {
      toast.error("Please select permission");
      return false;
    }

    return true;
  }

  function validateCustomRole() {
    if (selectedRole[0]?.value == "custom_role" && customRole == "") {
      toast.error("Please Enter Custom Role");
      return false;
    }
    return true;
  }
  function validateSelectedRole() {
    if (selectedRole.length <= 0) {
      toast.error("Please select role");
      return false;
    }
    return true;
  }
  const formattedOptions = Object.entries(CampaignData.ROLE_PERMISSION).flatMap(
    ([_, module]) => {
      const category = module.module_name; // Use the module name as category
      return Object.values(module.permission).map((perm) => ({
        module: module.module_name,
        per_name: perm.name, // Display value
        id: perm.id,
        m_id: module.m_id,
      }));
    }
  );

  function removePlusSign(phoneNumber) {
    // Check if the phone number starts with a '+'
    if (number) {
      if (phoneNumber.startsWith("+")) {
        // Return the substring starting from index 1 (excluding the '+')
        return phoneNumber.substring(1);
      }
    }

    // If no '+' is found, return the original phone number
    return phoneNumber;
  }

  function brandListArray() {
    const brandOption = brandList.map((item) => ({
      value: item.brand_data.brand_id,
      label: item.brand_data.name,
    }));

    return brandOption;
  }

  function renderRoleOptions() {
    const roleOptions = userRole.map((item) => {
      const value = item?.role_id || "custom_role";
      const label = item?.role_name;
      const permissions = item?.permissions || [];

      return {
        value,
        label,
        permissions,
      };
    });

    return roleOptions;
  }

  function selectedUpdateRole() {
    const filterRole = renderRoleOptions()?.filter((item) =>
      memberData?.role_id?.includes(item?.value)
    );

    return filterRole;
  }

  function filterBrandList() {
    if (memberData?.brand_ids?.length > 0) {
      const filteredBrands = brandList.filter((brand) =>
        memberData?.brand_ids?.includes(brand?.brand_data?.brand_id)
      );

      return filteredBrands;
    }
  }
  function brandListSelected() {
    if (memberData?.brand_ids?.includes("all")) {
      const brandOption = brandList?.map((item) => ({
        value: item.brand_data.brand_id,
        label: item.brand_data.name,
      }));

      return brandOption;
    } else {
      const brandOption = filterBrandList()?.map((item) => ({
        value: item.brand_data.brand_id,
        label: item.brand_data.name,
      }));

      return brandOption;
    }
  }

  const StyledItem = styled.div`
    padding: 10px;
    color: #555;
    border-radius: 3px;
    margin: 3px;
    cursor: pointer;
    > div {
      display: flex;
      align-items: center;
    }

    input {
      margin-right: 10px;
    }

    :hover {
      background: #f2f2f2;
    }
  `;

  function updateRole(selectedItem) {

    setUpdateRoles(selectedItem);
  }

  function selectedPageRole(selectedList, selectedItem) {

    var value = [];
    selectedList.forEach((item) => {
      value.push(item.id);
    });

    setSelectedPermission(selectedList);
  }
  function updateRole(selectedItem) {
    setSelectedRole(selectedItem);
  }
  function updateBrandId(selectedItem) {

    setBrandId(selectedItem);
  }

  function updateSelectedPageRole(selectedList, selectedItem) {
    var value = [];
    selectedList.forEach((item) => {
      value.push(item.id);
    });

    setSelectedPermission(selectedList);
  }

  function renderPermissionOptions() {
    if (
      Array.isArray(selectedRole) &&
      selectedRole.length > 0 &&
      selectedRole[0]?.value == "custom_role"
    ) {
      const formattedOptions = Object.entries(
        Object.values(selectedRole[0]?.permissions.permissions)
      ).flatMap(([_, module]) => {

        const category = module.module_name; // Use the module name as category
        return Object.values(module.permissions).map((perm) => ({
          module: module.name,
          name: perm.name,
          value: perm.value,
        }));
      });
      return formattedOptions;
    }
  }
  function inviteUpdateHeader() {
    return (
      <CustomText
        p
        text={"Invite Member Update"}
        className="side-bar-heading-text"
      />
    );
  }

  function inviteUpdateFooter() {
    return (
      <PrimaryCta
        fontSize={"font_size_small"}
        className="btn-color-blue"
        onClick={() => postUpdateInviteMembers()}
        fullWidth={true}
        text={"Update"}
        isLoading={isLoading}
      />
    );
  }
  function renderUpdateInviteMember() {
    return (
      <>
        <Sidebar
          isOpen={updateIsOpen}
          sidebarWidth={"480px"}
          onClose={inviteUpdateToggle}
          Header={inviteUpdateHeader}
          Footer={inviteUpdateFooter}
          headerAlignment="left"
        >
          <div className="p-20">
            <div className="">
              <div style={{ gap: 5 }} className="m-b-5 flex-row">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Name"}
                  textAlign={"text_align_left"}
                />
              </div>
              <div className=" full-width">
                <CustomTextInput
                  type="name"
                  placeholder="Name"
                  disabled={true}
                  value={memberName}
                  onChange={(e) => setMemberName(e.target.value)}
                />
              </div>
            </div>
            <div className="m-t-16">
              <div style={{ gap: 5 }} className="m-b-5 flex-row">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Phone number"}
                  textAlign={"text_align_left"}
                />
              </div>
              <div className=" whatsapp-number">
                <PhoneInput
                  defaultCountry="IN"
                  value={number}
                  onChange={setNumber}
                  placeholder="Enter phone number"
                />
              </div>
            </div>
            <div className="m-t-16">
              <div style={{ gap: 5 }} className="m-b-5 flex-row">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Email"}
                  textAlign={"text_align_left"}
                />
              </div>
              <div>
                <CustomTextInput
                  type="text"
                  placeholder="Enter email"
                  value={email}
                  disabled={true}
                  onChange={(e) => setEmail(e.target.value)}
                  // onBlur={(e) => validateEmail(e.target.value)}
                />
              </div>
            </div>
            {org_deep_data.org_data?.type == "agency_manager" && (
              <div className="m-t-16 invite-permission-container invite-permission-inner-container">
                <div className="m-b-5">
                  <CustomText
                    p
                    fontSize={"font_size_medium"}
                    textColor={"text_color_primary"}
                    text={"Select Brand"}
                    textAlign={"text_align_left"}
                  />
                </div>
                <Multiselect
                  placeholder="Select brands"
                  id="css_custom"
                  options={brandListArray()}
                  displayValue="label"
                  showCheckbox
                  // customOption={customOptionRenderer}
                  selectedValues={
                    brandListSelected() ? brandListSelected() : []
                  }
                  onSelect={(selectedList, selectedItem) =>
                    updateBrandId(selectedList, selectedItem)
                  }
                  onRemove={(selectedList, selectedItem) =>
                    updateBrandId(selectedList, selectedItem)
                  }
                  singleSelect={false}
                  // groupBy="name"

                  showArrow={false}
                  style={{
                    chips: Style.chipsInvite,
                    option: Style.optionInvite,
                    multiselectContainer: Style.multiselectContainerInvite,
                    searchBox: Style.searchBox,
                  }}
                />
              </div>
            )}

            <div className="m-t-16 invite-permission-inner-container">
              <div className="m-b-5">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Select role"}
                  textAlign={"text_align_left"}
                />
              </div>
              <Select
                multi={false}
                options={renderRoleOptions()}
                values={selectedUpdateRole()}
                onChange={(value) => updateRole(value)}
                itemRenderer={({ item, methods }) => (
                  <StyledItem>
                    <div onClick={() => methods.addItem(item)}>
                      <input
                        type="checkbox"
                        onChange={() => methods.addItem(item)}
                        checked={methods.isSelected(item)}
                      />
                      {item.label}
                    </div>
                  </StyledItem>
                )}
                placeholder="Select "
                autoFocus={false}
                color="#C8C2C2"
                style={{
                  border: "1px solid #cbd2d9",
                  backgroundColor: "#fff",
                  fontSize: 14,
                  borderRadius: 4,
                  padding: 10,

                  option: {
                    backgroundColor: "red",
                    margin: 0,
                  },
                }}
              />
            </div>
            {selectedRole[0]?.value == "custom_role" && (
              <div className="m-t-16">
                <div style={{ gap: 5 }} className="m-b-5 flex-row">
                  <CustomText
                    p
                    fontSize={"font_size_medium"}
                    textColor={"text_color_primary"}
                    text={"Custom role"}
                    textAlign={"text_align_left"}
                  />
                </div>
                <div>
                  <CustomTextInput
                    type="text"
                    value={customRole}
                    placeholder="Enter custom role"
                    onChange={(e) => setCustomRole(e.target.value)}
                  />
                </div>
              </div>
            )}

            {selectedRole[0]?.value == "custom_role" && (
              <div className="m-t-16 invite-permission-container">
                <div className="m-b-5">
                  <CustomText
                    p
                    fontSize={"font_size_medium"}
                    textColor={"text_color_primary"}
                    text={"Select permissions"}
                    textAlign={"text_align_left"}
                  />
                </div>
                <Multiselect
                  placeholder="Select permissions"
                  id="css_custom"
                  options={renderPermissionOptions()}
                  displayValue="name"
                  groupBy="module"
                  showCheckbox
                  // customOption={customOptionRenderer}
                  // selectedValues={roleFilter()}
                  onSelect={(selectedList, selectedItem) =>
                    selectedPageRole(selectedList, selectedItem)
                  }
                  onRemove={(selectedList, selectedItem) =>
                    selectedPageRole(selectedList, selectedItem)
                  }
                  singleSelect={false}
                  // groupBy="name"

                  showArrow={false}
                  style={{
                    chips: Style.chipsInvite,
                    option: Style.optionInvite,
                    multiselectContainer: Style.multiselectContainerInvite,
                    searchBox: Style.searchBox,
                  }}
                />
              </div>
            )}

            {/* <div className="permission-card-main-container">
              <div className="card-in-grid">
                <div className="permission-card">campaign</div>
                <div className="permission-card">campaign</div>
                <div className="permission-card">campaign</div>
                <div className="permission-card">campaign</div>
                <div className="permission-card">campaign</div>
                <div className="permission-card">campaign</div>
                <div className="permission-card">campaign</div>
              </div>
            </div> */}
            <ToastContainer />
          </div>
        </Sidebar>
      </>
    );
  }
  return <div>{renderUpdateInviteMember()}</div>;
}

export default UpdateInviteMember;
