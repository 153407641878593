// Info: Configuration file (Inputs and Data)
"use strict";

module.exports = {
  // Export configration as key-value Set

  // Constraints on Brand ID
  CAMPAIGN_ID_MIN_LENGTH: 4, // Fixed Length
  CAMPAIGN_ID_MAX_LENGTH: 14, // Fixed Length
  CAMPAIGN_ID_CHARSET: `0123456789abcdefghijklmnopqrstuvwxyz`, // Valid charset. Digits, Lowercase Alphabets
  CAMPAIGN_ID_SANATIZE_REGX: /[^0-9a-z]/g, // Regular expression for valid Characters. Digits. Alphabets. Case sensitive
  CAMPAIGN_ID_CHARSET_REGX: /^[0-9a-z]*$/, // Regular expression for valid Characters. Digits. Alphabets. Case sensitive

  // Constraints on Brand Name
  CAMPAIGN_NAME_MAX_LENGTH: 255, // Maximum length

  CAMPAIGN_LIST_LIMIT_EDITOR: 10,
  EXTEND_UGC_TYPE_CONTENT_CREDIT_DEDUCTION: 20,
  EXTEND_SOCIAL_MEDIA_TYPE_CONTENT_CREDIT_DEDUCTION: 10,

  // Campaign Type
  CAMPAIGN_TYPE: {
    content: {
      n: "Content",
    },
    social_media: {
      n: "Social Media",
    },
  },

  INFLUENCER_GENDER: [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "all", label: "All" },
  ],

  SOCIAL_MEDIA: [
    {
      platform_id: "btll",
      label: "Instagram",
    },
    {
      platform_id: "nxjo",
      label: "Youtube",
    },
  ],

  // Campaign Type
  CAMPAIGN_STATUS: {
    save: "sv",
    active: "at",
    expired: "ex",
    complete: "ct",
  },

  // Platforms
  PLATFORM: {
    instagram: {
      n: "Instagram",
      content_type: {
        posts: {
          n: "Posts",
        },
        carousels: {
          n: "Carousels",
        },
        stories: {
          n: "Stories",
        },
      },
    },
    youtube: {
      n: "Youtube",
      content_type: {
        video: {
          n: "Youtube Video",
        },
        shorts: {
          n: "Youtube Shorts",
        },
      },
    },
    twitter: {
      n: "Twitter",
      content_type: {
        post: {
          n: "Twitter Posts",
        },
      },
    },
    facebook: {
      n: "Facebook",
      content_type: {
        post: {
          n: "Facebook Posts",
        },
      },
    },
  },

  CATEGORY: {
    food_drink: {
      n: "Food & Drink",
    },
    fashion: {
      n: "Fashion",
    },
    beauty: {
      n: "Beauty & Personal Care",
    },
    web: {
      n: "Social Media, Web & Tech",
    },
    art: {
      n: "Photography, Art & Design",
    },
    event: {
      n: "Entertainment & Events",
    },
    health: {
      n: "Health, Fitness & Sport",
    },
    travel: {
      n: "Travel & Destinations",
    },
    hotel: {
      n: "Restaurants, Bars & Hotels",
    },
    auto: {
      n: "Automotive",
    },
    family: {
      n: "Children & Family",
    },
    home: {
      n: "Home & Garden",
    },
    animal: {
      n: "Animals",
    },
    book: {
      n: "Education & Books",
    },
    business: {
      n: "Business, Finance & Insurance",
    },
    social: {
      n: "Social Enterprise & Not-for-profit",
    },
  },

  CAMPAIGN_CONTENT_TYPE: {},

  // Age
  AGE_RANGE: {
    "18_25": {
      n: "18 - 25",
    },
    "26_35": {
      n: "26 - 35",
    },
    "36_45": {
      n: "36 - 45",
    },
    "46_55": {
      n: "46 - 55",
    },
    "56_65+": {
      n: "56 - 65+",
    },
  },

  // GENDER
  GENDER: {
    all: {
      n: "All",
    },
    male: {
      n: "Male",
    },
    female: {
      n: "Female",
    },
  },

  // GENDER
  LOCATIONS: {
    an: {
      n: "Andaman and Nicobar Islands",
    },
    ap: {
      n: "Andhra Pradesh",
    },
    ar: {
      n: "Arunachal Pradesh",
    },
    as: {
      n: "Assam",
    },
    br: {
      n: "Bihar",
    },
    ch: {
      n: "Chandigarh",
    },
    ct: {
      n: "Chhattisgarh",
    },
    dn: {
      n: "Dadra and Nagar Haveli",
    },
    dd: {
      n: "Daman and Diu",
    },
    dl: {
      n: "Delhi",
    },
    ga: {
      n: "Goa",
    },
    gj: {
      n: "Gujarat",
    },
    hr: {
      n: "Haryana",
    },
    hp: {
      n: "Himachal Pradesh",
    },
    jk: {
      n: "Jammu and Kashmir",
    },
    jh: {
      n: "Jharkhand",
    },
    ka: {
      n: "Karnataka",
    },
    kl: {
      n: "Kerala",
    },
    ld: {
      n: "Lakshadweep",
    },
    mp: {
      n: "Madhya Pradesh",
    },
    mh: {
      n: "Maharashtra",
    },
    mn: {
      n: "Manipur",
    },
    ml: {
      n: "Meghalaya",
    },
    mz: {
      n: "Mizoram",
    },
    nl: {
      n: "Nagaland",
    },
    or: {
      n: "Odisha",
    },
    py: {
      n: "Puducherry",
    },
    pb: {
      n: "Punjab",
    },
    rj: {
      n: "Rajasthan",
    },
    sk: {
      n: "Sikkim",
    },
    tn: {
      n: "Tamil Nadu",
    },
    tg: {
      n: "Telangana",
    },
    tr: {
      n: "Tripura",
    },
    up: {
      n: "Uttar Pradesh",
    },
    ut: {
      n: "Uttarakhand",
    },
    wb: {
      n: "West Bengal",
    },
  },

  // LANGUAGE
  LANGUAGE: {
    hindi: {
      n: "Hindi",
    },
    english: {
      n: "English",
    },
  },

  // Followers
  FOLLOWERS: {
    10000: {
      n: "10 K",
    },
    20000: {
      n: "20 K",
    },
    30000: {
      n: "30 K",
    },
    40000: {
      n: "40 K",
    },
    50000: {
      n: "50 K",
    },
    70000: {
      n: "70 K",
    },
    90000: {
      n: "90 K",
    },
    100000: {
      n: "100 K",
    },
    250000: {
      n: "250 K",
    },
    "500000+": {
      n: "500 K+",
    },
  },

  // REWARD TYPE
  REWARD_TYPES: {
    cash: {
      n: "CASH",
    },
    discount: {
      n: "DISCOUNT",
    },
    cashback: {
      n: "CASHBACK",
    },
  },
};
