import React, { useEffect, useState } from "react";
import {
  CustomText,
  CustomTextInput,
  FallBackBtn,
  PrimaryCta,
  Sidebar,
} from "../../components";
import Style from "../../resources/style";
import CustomTable from "../../components/customTable";
import { Loader } from "../loader";
import Core from "../../common/clientSdk";
import Multiselect from "multiselect-react-dropdown";
import styled from "@emotion/styled";
import Select from "react-dropdown-select";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
const RoleAndPermission = () => {
  const [updateRoleData, setUpdateRoleData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [userRole, setUserRole] = useState([]);
  const [updateRoles, setUpdateRoles] = useState([]);
  const [roleIsOpen, setRoleIsOpen] = useState(false);
  const [roleUpdateIsOpen, setRoleUpdateIsOpen] = useState(false);
  const [userPermission, setUserPermission] = useState([]);
  const [isRole, setIsRole] = useState(false);
  const [selectedRole, setSelectedRole] = useState([]);
  const [selectedPermission, setSelectedPermission] = useState([]);
  const [customRole, setCustomRole] = useState("");
  const [customUpdateRole, setCustomUpdateRole] = useState();
  const [updatePermission, setUpdatePermission] = useState([]);
  useEffect(() => {
    getUserRoleList();
    getUserPermissionList();
  }, []);

  useEffect(() => {
    if (updateRoleData) {
      setCustomUpdateRole(updateRoleData.role_name);
      setUpdatePermission(updatePermissionSelected());
    }
  }, [updateRoleData]);
  const redux_selector = useSelector((state) => {
    return {
      UserData: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });
  const { UserData = {} } = redux_selector;
  const { brand_deep_data = {} } = UserData;
  const { org_deep_data = {} } = UserData;

  function getUserRoleList() {
    var params = {
      org_id: brand_deep_data?.brand_data?.org_id,
    };
    Core.getUserRoleList(
      getUserRoleListSuccess,
      getUserRoleListFailed,
      () => {},
      params
    );
  }

  function getUserRoleListSuccess(response, data) {

    setUserRole(data.role_list);
  }

  function getUserRoleListFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  function getUserPermissionList() {
    var params = {};
    Core.getUserPermissionList(
      getUserPermissionListSuccess,
      getUserPermissionListFailed,
      () => {},
      params
    );
  }

  function getUserPermissionListSuccess(response, data) {
    setUserPermission(data?.permissions);
  }

  function getUserPermissionListFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }
  function postCreateRole() {
    var params = {
      role_data: JSON.stringify({
        role_name: customRole,
        permissions: selectedPermission.map((item) => item.value),
        org_id: brand_deep_data?.brand_data?.org_id,
      }),
      org_id: brand_deep_data?.brand_data?.org_id,
    };
    if (validationCustomRole() && validationCustomPermission()) {
      Core.postCreateRole(
        postCreateRoleSuccess,
        postCreateRoleFailed,
        (status) => setIsRole(status),
        params
      );
    }
  }

  function postCreateRoleSuccess(response, data) {
    setUserRole((prev) => [...prev, { ...data.role_data }]);
    roleToggle();
  }

  function postCreateRoleFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
    roleToggle();
    roleUpdateToggle();
  }

  function postUpdateRole() {
    var params = {
      role_data: JSON.stringify({
        role_name: customUpdateRole,
        permissions: updatePermission.map((item) => item.value),
        org_id: brand_deep_data?.brand_data?.org_id,
        role_id: updateRoleData.role_id,
      }),
      role_id: updateRoleData.role_id,
      org_id: brand_deep_data?.brand_data?.org_id,
    };
    if (validationUpdateCustomRole() && validationUpdateCustomPermission()) {
      Core.postUpdateRole(
        postUpdateRoleSuccess,
        postUpdateRoleFailed,
        (status) => setIsRole(status),
        params
      );
    }
  }

  function postUpdateRoleSuccess(response, data) {
    setUserRole((prev) => {
      const updatedRoles = prev.map((role) =>
        role.role_id === data.role_data.role_id ? { ...data.role_data } : role
      );

      const roleExists = prev.some(
        (role) => role?.role_id === data?.role_data?.role_id
      );
      return roleExists ? updatedRoles : [...prev, data.role_data];
    });
    roleUpdateToggle();
  }

  function postUpdateRoleFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
    roleUpdateToggle();
  }

  function validationCustomRole() {
    if (!customRole) {
      toast.error("Please enter role");
      return false;
    }

    return true;
  }
  function validationUpdateCustomRole() {
    if (!customUpdateRole) {
      toast.error("Please enter role");
      return false;
    }

    return true;
  }
  function validationCustomPermission() {
    if (selectedPermission.length <= 0) {
      toast.error("Please select permission");
      return false;
    }

    return true;
  }
  function validationUpdateCustomPermission() {
    if (updatePermission.length <= 0) {
      toast.error("Please select permission");
      return false;
    }

    return true;
  }
  function updateRole(selectedItem) {
    setSelectedRole(selectedItem);
  }

  function selectedPageRole(selectedList, selectedItem) {

    var value = [];
    selectedList.forEach((item) => {
      value.push(item.id);
    });

    setSelectedPermission(selectedList);
  }
  function updatePageRole(selectedList, selectedItem) {

    var value = [];
    selectedList.forEach((item) => {
      value.push(item.id);
    });

    setUpdatePermission(selectedList);
  }

  function roleToggle() {
    setRoleIsOpen(!roleIsOpen);
    setCustomRole("");
    setSelectedPermission([]);
  }
  function roleUpdateToggle(data) {
    setUpdateRoleData(data);
    setUpdatePermission([]);
    setRoleUpdateIsOpen(!roleUpdateIsOpen);
  }

  function renderRoleOptions() {
    const roleOptions = userRole.map((item) => {
      const value = item?.role_id || "custom_role";
      const label = item?.role_name;
      const permissions = item?.permissions || [];

      return {
        value,
        label,
        permissions,
      };
    });

    return roleOptions;
  }

  function filterCustomRole() {
    let filterRole = renderRoleOptions().filter(
      (item) => item.value == "custom_role"
    );
    return filterRole;
  }

  function renderPermissionOptions() {
    if (userPermission) {
      const formattedOptions = Object.entries(
        Object.values(userPermission)
      ).flatMap(([_, module]) => {
        const category = module.module_name; // Use the module name as category
        return Object.values(module.permissions).map((perm) => ({
          module: module.name,
          name: perm.name,
          value: perm.value,
        }));
      });
      return formattedOptions;
    }
  }

  function updatePermissionSelected() {
    if (updateRoleData?.permissions?.permissions) {
      const formattedOptions = Object.entries(
        Object.values(updateRoleData.permissions.permissions)
      ).flatMap(([_, module]) => {

        return Object.values(module.permissions || {}).map((perm) => ({
          module: module.name,
          name: perm.name,
          value: perm.value,
        }));
      });

      return formattedOptions;
    }
    return []; // Return an empty array if no data is available
  }

  function showPermission(data) {
    if (data?.permissions?.permissions) {
      const formattedOptions = Object.entries(
        Object.values(data.permissions.permissions)
      ).flatMap(([_, module]) => {
        return Object.values(module.permissions || {}).map((perm) => ({
          module: module.name,
          name: perm.name,
          value: perm.value,
        }));
      });

      return formattedOptions;
    }
    return []; // Return an empty array if no data is available
  }

  function roleHeader() {
    return (
      <CustomText
        p
        text={"Role & Permission"}
        className="side-bar-heading-text"
      />
    );
  }
  function roleUpdateHeader() {
    return (
      <CustomText
        p
        text={"Update role & permission"}
        className="side-bar-heading-text"
      />
    );
  }

  function roleFooter() {
    return (
      <PrimaryCta
        fontSize={"font_size_small"}
        fullWidth={true}
        onClick={() => postCreateRole()}
        text={"Create role"}
        isLoading={isRole}
        className="btn-color-blue"
      />
    );
  }
  function roleUpdateFooter() {
    return (
      <PrimaryCta
        fontSize={"font_size_small"}
        fullWidth={true}
        onClick={() => postUpdateRole()}
        text={"Update role"}
        isLoading={isRole}
        className="btn-color-blue"
      />
    );
  }

  function renderUpdateRoleMember() {
    return (
      <>
        <Sidebar
          isOpen={roleUpdateIsOpen}
          sidebarWidth={"30%"}
          onClose={roleUpdateToggle}
          Header={roleUpdateHeader}
          Footer={roleUpdateFooter}
          headerAlignment="left"
        >
          <div className="p-20">
            <div className="m-t-16">
              <div style={{ gap: 5 }} className="m-b-5 flex-row">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Role name"}
                  textAlign={"text_align_left"}
                />
              </div>
              <div>
                <CustomTextInput
                  type="text"
                  value={customUpdateRole}
                  placeholder="Enter custom role"
                  onChange={(e) => setCustomUpdateRole(e.target.value)}
                />
              </div>
            </div>

            <div className="m-t-16 invite-permission-container">
              <div className="m-b-5">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Select permissions"}
                  textAlign={"text_align_left"}
                />
              </div>
              <Multiselect
                placeholder="Select permissions"
                id="css_custom"
                options={renderPermissionOptions()}
                displayValue="name"
                groupBy="module"
                showCheckbox
                // customOption={customOptionRenderer}
                selectedValues={updatePermissionSelected()}
                onSelect={(selectedList, selectedItem) =>
                  updatePageRole(selectedList, selectedItem)
                }
                onRemove={(selectedList, selectedItem) =>
                  updatePageRole(selectedList, selectedItem)
                }
                singleSelect={false}
                // groupBy="name"

                showArrow={false}
                style={{
                  chips: Style.chipsInvite,
                  option: Style.optionInvite,
                  multiselectContainer: Style.multiselectContainerInvite,
                  searchBox: Style.searchBox,
                }}
              />
            </div>

            {/* <div className="permission-card-main-container">
              <div className="card-in-grid">
                <div className="permission-card">campaign</div>
                <div className="permission-card">campaign</div>
                <div className="permission-card">campaign</div>
                <div className="permission-card">campaign</div>
                <div className="permission-card">campaign</div>
                <div className="permission-card">campaign</div>
                <div className="permission-card">campaign</div>
              </div>
            </div> */}
          </div>
        </Sidebar>
      </>
    );
  }

  function renderRoleMember() {
    return (
      <>
        <Sidebar
          isOpen={roleIsOpen}
          sidebarWidth={"30%"}
          onClose={roleToggle}
          Header={roleHeader}
          Footer={roleFooter}
          headerAlignment="left"
        >
          <div className="p-20">
            <div className="m-t-16">
              <div style={{ gap: 5 }} className="m-b-5 flex-row">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Custom role"}
                  textAlign={"text_align_left"}
                />
              </div>
              <div>
                <CustomTextInput
                  type="text"
                  value={customRole}
                  placeholder="Enter custom role"
                  onChange={(e) => setCustomRole(e.target.value)}
                />
              </div>
            </div>

            <div className="m-t-16 invite-permission-container">
              <div className="m-b-5">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Select permissions"}
                  textAlign={"text_align_left"}
                />
              </div>
              <Multiselect
                placeholder="Select permissions"
                id="css_custom"
                options={renderPermissionOptions()}
                displayValue="name"
                groupBy="module"
                showCheckbox
                // customOption={customOptionRenderer}
                selectedValues={selectedPermission}
                onSelect={(selectedList, selectedItem) =>
                  selectedPageRole(selectedList, selectedItem)
                }
                onRemove={(selectedList, selectedItem) =>
                  selectedPageRole(selectedList, selectedItem)
                }
                singleSelect={false}
                // groupBy="name"

                showArrow={false}
                style={{
                  chips: Style.chipsInvite,
                  option: Style.optionInvite,
                  multiselectContainer: Style.multiselectContainerInvite,
                  searchBox: Style.searchBox,
                }}
              />
            </div>

            {/* <div className="permission-card-main-container">
              <div className="card-in-grid">
                <div className="permission-card">campaign</div>
                <div className="permission-card">campaign</div>
                <div className="permission-card">campaign</div>
                <div className="permission-card">campaign</div>
                <div className="permission-card">campaign</div>
                <div className="permission-card">campaign</div>
                <div className="permission-card">campaign</div>
              </div>
            </div> */}

            <ToastContainer />
          </div>
        </Sidebar>
      </>
    );
  }

  const inviteMemberTableSchema = [
    {
      name: "Name",
      selector: (row) => row["role_name"],
      width: "300px",
    },
    {
      name: "Type",
      selector: (row) => row["type"],
    },
    {
      name: "Permissions",
      selector: (row) => showPermission(row),
      // width: "100px",
      cell: (item) => (
        <div>
          {showPermission(item)
            .map((item) => item.name)
            .join(" , ")}
        </div>
      ),
    },

    // {
    //   name: "",
    //   // paddingRight: "20px",
    //   textAlign: "right",
    //   cell: (item) => (
    //     <span
    //       style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
    //     >
    //       {item.type != "DEFAULT" && (
    //         <div className="">
    //           <PrimaryCta
    //             fifth
    //             onClick={() => roleUpdateToggle(item)}
    //             text={"Edit"}
    //             fontSize={"font_size_small"}
    //             className="blue-button"
    //           />
    //         </div>
    //       )}
    //     </span>
    //   ),
    // },
  ];

  return (
    <>
      <div>
        {isLoading ? (
          <div className="loader-mid">
            <Loader />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingRight: 20,
              paddingLeft: 20,
            }}
          >
            <div className="setting-team-member-header">
              <PrimaryCta
                fifth
                onClick={() => roleToggle()}
                text={"Create role"}
                fontSize={"font_size_small"}
                className="blue-button p-l-r-20"
              />
            </div>
            {userRole.length == 0 ? (
              <div className="fall-back-center-campaign">
                <FallBackBtn
                  heading={"No Roles available"}
                  title={"Custom roles will be shown here"}
                  // subTitle={"elit, sed do eiusmod tempor incididunt. ipasum"}
                />
              </div>
            ) : (
              <div class="">
                <div style={{ paddingBottom: "20px" }}>
                  <CustomTable
                    className="CustomTable"
                    tableSchema={inviteMemberTableSchema}
                    tableData={userRole}
                    rowPaddingTop={16}
                    onRowClicked={(item) => {
                      item.type != "DEFAULT" && roleUpdateToggle(item);
                    }}
                    rowPaddingBottom={16}
                    customStyles={customBidStyles}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {renderRoleMember()}
      {renderUpdateRoleMember()}
    </>
  );
};

export default RoleAndPermission;

const StyledItem = styled.div`
  padding: 10px;
  color: #555;
  border-radius: 3px;
  margin: 3px;
  cursor: pointer;
  > div {
    display: flex;
    align-items: center;
  }

  input {
    margin-right: 10px;
  }

  :hover {
    background: #f2f2f2;
  }
`;
const customBidStyles = {
  headRow: {
    style: {
      backgroundColor: "var(--general-secondary-color)", // Change header background color
      color: "var(--gray-gray-300)", // Change header text color
      fontSize: "14px",
      fontWeight: 600,
      lineHight: "19.2px",
      border: "none",
    },
  },
  headCells: {
    style: {
      padding: "10px 0px 10px 20px",
    },
  },
  rows: {
    style: {
      cursor: "pointer",
      fontSize: "12px",
      paddingTop: "10px",
      paddingBottom: "10px",
      fontWeight: 400,
      lineHight: "19.2px",
      "&:hover": {
        backgroundColor: "var(--general-secondary-color)",
        fontWeight: 600,
      },
    },
  },
  cells: {
    style: {
      paddingLeft: "20px",
    },
  },
};
