// Info: Contains App specific Constants
"use strict";
import axios from "axios";

export default function UploadImageS3(cropImage, upload_data, cb) {
  console.log("upload_data", cropImage);

  const formData = new FormData();
  formData.append("Policy", upload_data.fields["Policy"]);
  formData.append("X-Amz-Algorithm", upload_data.fields["X-Amz-Algorithm"]);
  formData.append("X-Amz-Credential", upload_data.fields["X-Amz-Credential"]);
  formData.append("X-Amz-Date", upload_data.fields["X-Amz-Date"]);
  formData.append("X-Amz-Signature", upload_data.fields["X-Amz-Signature"]);
  formData.append("bucket", upload_data.fields["bucket"]);
  formData.append("content-type", upload_data.fields["content-type"]);
  formData.append("key", upload_data.fields["key"]);
  formData.append("file", cropImage);

  const headers = {
    "Content-Type": "multipart/form-data", // Important for file upload
  };

  axios
    .post(upload_data.url, formData, headers)
    .then((response) => {
      console.log("s3 response", response);

      cb(false);
    })
    .catch((error) => {
      cb(error);
    });
}
