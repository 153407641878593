import React, { useState, useEffect } from "react";
import {
  CustomText,
  NotificationBanner,
  PrimaryCta,
  Sidebar,
  Video,
} from "../components";

import Core from "../common/clientSdk";
import { Loader } from "../screens/loader";
import Utils from "../common/utils";
import Icon from "../components/core_component/icon";
import { useSnackbar } from "react-simple-snackbar";
import { Image } from "../components";
import { Rating } from "react-simple-star-rating";
import * as Actions from "../redux/actions";

import * as CampaignData from "../common/data/campaign";
import Carousel from "react-multi-carousel";
import { toast, ToastContainer } from "react-toastify";
import Lottie from "react-lottie";
import * as LoadingUI from "../resources/data/loading.json";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { get } from "jquery";
import ContactDetailInfluencer from "./contactDetailInfluencer";
import { useNavigate } from "react-router-dom";

import InfluencerDetailPage from "../screens/influecnerScreen/influencerDetailPage";

import { useToast } from "../features/toastContainer";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: LoadingUI,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
function InfluencerDetailModal({
  influencerFlyOut,
  toggleFlyoutDropdown,
  influencer_id,
}) {
  const [influencerData, setInfluencerData] = useState();
  const [categoryListData, setCategoryListData] = useState([]);
  const [platformsData, setPlatformsData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingContact, setLoadingContact] = useState(false);
  const [portfolio, setPortfolio] = useState([]);
  const [showVideo, setShowVideo] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [portfolioDataSava, setPortfolioDataSava] = useState({});
  const [effectiveFollowers, setEffectiveFollowers] = useState(null);
  const [instagramId, setInstagramId] = useState(null);
  const [checkOrgSub, setCheckOrgSub] = useState({});
  const [effectiveLoad, setEffectiveLoad] = useState(false);
  const [openSnackbar] = useSnackbar();
  const [getContactmodalIsOpen, setGetContactmodalIsOpen] = useState(false);
  const [contactRequest, setContactRequest] = useState(true);
  const [getContactDetails, setGetContactDetails] = useState([]);
  const showToast = useToast();
  const redux_dispatch = useDispatch();
  const navigate = useNavigate();
  function openGetContactDetails() {
    if (
      !Utils.checkRoleAndPermission(
        redux_selector,
        all_permissions["campaign"]["permissions"]["view_contact"]
      )
    ) {
      showToast();
      //   return toast.info("You are not authorised to access contact");
    } else {
      setGetContactmodalIsOpen(true);
    }
  }

  function closeGetContactDetails() {
    setGetContactmodalIsOpen(false);
  }
  // useEffect(() => {
  //   if (
  //     influencerData &&
  //     !effectiveFollowers &&
  //     checkOrgSub?.feature?.max_brand_count > 1 &&
  //     instagramId
  //   ) {
  //     getEffectiveFollowers();
  //   }
  // }, [influencerData, checkOrgSub]);

  // useEffect(() => {
  //   if (influencer_id) {
  //     getInfluencerDetailPublic(updateProgress);
  //     userSelfData();
  //   }
  // }, [influencer_id]);

  function updateProgress(status) {
    setLoading(status);
  }

  const redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });
  const { BrandUser = {} } = redux_selector;
  const { brand_deep_data = {} } = BrandUser;
  const { org_deep_data = {} } = BrandUser;
  const { all_permissions = {} } = BrandUser;

  function userSelfData() {
    var params = { org_id: brand_deep_data?.brand_data?.org_id };

    Core.userSelfData(
      userSelfDataSuccess,
      userSelfDataFailed,
      () => {},
      params
    );
  }

  function userSelfDataSuccess(response) {
    setCheckOrgSub(response?.org_data?.subscription_data);
    //update brand user data in redux
    redux_dispatch({
      type: Actions.UPDATE_BRAND_USER_DATA,
      payload: { user_data: response.user_data },
    });

    redux_dispatch({
      type: Actions.UPDATE_ORG_DATA,
      payload: { org_deep_data: response.org_data },
    });

    // Update Auth token
    redux_dispatch({
      type: Actions.UPDATE_BRAND_USER_AUTH,
      payload: { auth_token: response.auth_id },
    });
  }
  function userSelfDataFailed() {}

  function getEffectiveFollowers() {
    var params = {
      influencer_id: influencer_id,
      platform_id: "btll",
    };

    Core.getEffectiveFollowers(
      getEffectiveFollowersSuccess,
      getEffectiveFollowersFail,
      (status) => setEffectiveLoad(status),
      params
    );
  }

  function getEffectiveFollowersSuccess(response) {
    setEffectiveFollowers(response.effective_followers);
  }

  function getEffectiveFollowersFail(error) {
    toast.error(error.map((item) => item.m).join(""));
  }
  function getInfluencerContact() {
    var params = {
      influencer_id: influencer_id,
      org_id: brand_deep_data?.brand_data?.org_id,
    };

    Core.getInfluencerContact(
      getInfluencerContactSuccess,
      getInfluencerContactFail,
      (status) => setLoadingContact(status),
      params
    );
  }

  function getInfluencerContactSuccess(demo, response) {
    setInfluencerData((prev) => ({
      ...prev,
      phone: response?.influencer_deep_data?.influencer_data?.phone,
      email: response?.influencer_deep_data?.influencer_data?.email,
    }));
    closeGetContactDetails();
    userSelfData();
  }

  function getInfluencerContactFail(error) {
    toast.error(error.map((item) => item.m).join(""));
    closeGetContactDetails();
  }
  function influencerPortfolio() {
    var params = { influencer_id: influencer_id };
    Core.influencerPortfolio(
      influencerPortfolioSuccess,
      influencerPortfolioFailed,
      () => {},
      params
    );
  }

  function influencerPortfolioSuccess(response) {
    setPortfolio(response["portfolio_list"]);
  }

  function influencerPortfolioFailed() {}

  function getInfluencerDetailPublic(progress_cb) {
    var params = { influencer_id: influencer_id };
    Core.getInfluencerDetailPublic(
      getInfluencerDetailPublicSuccess,
      getInfluencerDetailPublicFailed,
      progress_cb,
      params
    );
  }

  function getInfluencerDetailPublicSuccess(response) {
    setInfluencerData(response.influencer_deep_data.influencer_data);
    // setInfluencerData((prev) => ({
    //   ...prev,
    //   has_phone_number: "9696095656",
    //   has_email: "sandeeper@gmail.com",
    // }));
    setPlatformsData(response.influencer_deep_data.platforms_data);
    setCategoryListData(
      Object.values(response.influencer_deep_data.categorys_data)
    );

    setLoading(false);
  }

  function getInfluencerDetailPublicFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  let influencerDp = influencerData?.profile_picture
    ? Utils.generateInfluencerDpUrl(
        influencerData?.influencer_id,
        influencerData?.profile_picture
      )
    : require("../resources/images/user-copy.png");

  function getInfluencerLocation(influencer_data) {
    if (influencer_data["city_id"]) {
      return CampaignData.CITY.find(
        (city) => city.id == influencer_data["city_id"]
      )?.label;
    } else if (influencer_data["state"]) {
      return CampaignData.LOCATIONS[influencer_data["state"]]["n"];
    } else {
      return "-";
    }
  }

  const getSelectedPlatformInstagramData = (platforms_data) => {
    return platforms_data?.find((item) => item.platform_id === "btll");
  };

  const formatPlatformInstagramData = (platforms_data, key) => {
    const selectedPlatformData =
      getSelectedPlatformInstagramData(platforms_data);
    return Number(Utils.changeNumberFormate(selectedPlatformData?.[key]));
  };

  const getEngagementInstagramRate = (avg_comments, avg_likes, avg_views) => {
    var eng_data = Number(avg_likes) + Number(avg_comments);

    var eng_rate = (eng_data / Number(avg_views)) * 100;
    return eng_rate.toFixed(1);
  };
  const getSelectedPlatformYoutubeData = (platforms_data) => {
    return platforms_data?.find((item) => item.platform_id === "nxjo");
  };

  const formatPlatformYoutubeData = (platforms_data, key) => {
    const selectedPlatformData = getSelectedPlatformYoutubeData(platforms_data);
    return Utils.changeNumberFormate(selectedPlatformData?.[key]);
  };

  const getEngagementYoutubeRate = (avg_comments, avg_likes, avg_views) => {
    var eng_data = Number(avg_likes) + Number(avg_comments);

    var eng_rate = (eng_data / Number(avg_views)) * 100;
    return eng_rate.toFixed(1);
  };

  function getContactSuccess(requestedContactResponse) {
    setContactRequest(requestedContactResponse);
  }

  function checkCreditAndContactOpen() {
    if (org_deep_data?.org_data?.credits < 1) {
      navigate("/checkout");
    } else {
      getInfluencerContact();
    }
  }

  // ------------------------------------- get contact details -------------------------------------

  function ModalContent() {
    return (
      <Modal
        isOpen={() => openGetContactDetails()}
        onRequestClose={() => closeGetContactDetails()}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 11000,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          },
          content: {
            position: "relative",
            margin: "auto",
            maxWidth: "400px",
            minHeight: "400px",
            inset: "auto",
            display: "flex",
            flexDirection: "column",
            padding: 24,
            justifyContent: "space-between",
            borderRadius: 8,
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "30px",
          }}
        >
          <CustomText
            h5
            text={"Get Contact Details"}
            fontSize={"font_size_2xl"}
            textColor={"text_color_primary"}
            fontWeight={"Poppins_regular_large"}
          />
          <div className="cursor-pointer" onClick={closeGetContactDetails}>
            <Icon iconName="crossCloseIcon" />
          </div>
        </div>

        <div>
          <p>
            Unlock Contact details of{" "}
            <strong> '{influencerData?.name}' </strong> for 1 credit. Once
            unlocked, you will have lifetime access to it.
          </p>
        </div>

        <div
          style={{
            textAlign: "center",
            paddingTop: "30px",
            paddingBottom: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              color: "rgb(255, 102, 0)",
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            <p>YOU NEED TO PAY</p>
          </div>
          <div
            style={{
              fontSize: "32px",
              fontWeight: "bold",
              color: "#000",
              marginBottom: "10px",
            }}
          >
            1 Credit
          </div>
          <div
            style={{
              borderBottom: "1px solid rgb(230, 230, 230)",
              width: "40%",
              marginBottom: "20px",
              paddingBottom: "10px",
            }}
          ></div>
          <div
            style={{
              fontSize: "14px",
              color: "rgb(102, 102, 102)",
            }}
          >
            <span>Available Credits: </span>
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              {org_deep_data?.org_data?.credits}
            </span>
          </div>
          {org_deep_data?.org_data?.credits < 1 && (
            <NotificationBanner status="credit_low" />
          )}
        </div>

        {/* bottom buttons cancel and next */}
        <div
          style={{
            padding: "20px",
            display: "flex",
            gap: "20px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span style={{ maxWidth: 125, height: 36 }}>
            <PrimaryCta
              invert
              className="back-btn-campaign"
              onClick={() => closeGetContactDetails()}
              fontSize={"font_size_large"}
              text={"Cancel"}
            />
          </span>

          <PrimaryCta
            fifth
            onClick={() => checkCreditAndContactOpen()}
            className="blue-button-p-10-15"
            fontSize={"font_size_large"}
            isLoading={isLoadingContact}
            text={
              org_deep_data?.org_data?.credits < 1
                ? "Buy credits"
                : "Get contact"
            }
          />
        </div>
      </Modal>
    );
  }

  function influencerDetails() {
    return (
      <div className="influencer_detail_main_modal_container">
        <div className="influencer_modal_detail_container p-b-20">
          <Image
            fallBack={require("../resources/images/user-copy.png")}
            source={influencerDp}
            className="influencer-user-modal-profile"
          />
          <div
            className="influencer-details-section"
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div>
              <CustomText
                h5
                text={influencerData?.name}
                fontSize={"font_size_2xl"}
                textColor={"text_color_primary"}
                fontWeight={"Poppins_regular_large"}
              />
              <div className="flex-row">
                {" "}
                <Rating
                  initialValue={influencerData?.ratings}
                  size={20}
                  readonly={true}
                />
                <div className="m-l-8"></div>
              </div>
            </div>

            {/*  ----- conditional render of btn ------ */}
            {!contactRequest?.is_requested && (
              <>
                {" "}
                {((!getContactDetails.length > 0 &&
                  influencerData?.has_phone_number) ||
                  influencerData?.has_email) && (
                  <div>
                    <PrimaryCta
                      fifth
                      className="blue-button-p-10-15"
                      onClick={() => openGetContactDetails()}
                      fontSize={"font_size_large"}
                      text={"Get contact"}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div className="influencer_address_detail_container">
          {/* ---- Conditional Rendering of contact details ---- */}

          {/* {!getContactDetails.length > 0 && (
            <ContactDetailInfluencer
              influencer_id={influencer_id}
              influencerData={influencerData}
              getContactSuccess={getContactSuccess}
              isLoadingContact={isLoadingContact}
            />
          )} */}

          <div className="influencer_address_detail m-b-16">
            <CustomText
              p
              text={"Location"}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"rubik_regular"}
              height={"line_height_19_2"}
            />
            <CustomText
              p
              text={influencerData && getInfluencerLocation(influencerData)}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"Inter_medium_500"}
              height={"line_height_19_2"}
            />
          </div>
          <div className="influencer_address_detail m-b-16">
            <CustomText
              p
              text={"Gender"}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"rubik_regular"}
              height={"line_height_19_2"}
            />
            <CustomText
              p
              text={influencerData?.gender}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"Inter_medium_500"}
              height={"line_height_19_2"}
            />
          </div>
          <div className="influencer_address_detail">
            <CustomText
              p
              text={"Languages"}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"rubik_regular"}
              height={"line_height_19_2"}
            />
            <CustomText
              p
              text={influencerData?.language}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"Inter_medium_500"}
              height={"line_height_19_2"}
            />
          </div>
        </div>
        <div className="influencer-detail-category-modal-platform">
          <div class="">
            <CustomText
              p
              text={"Category"}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"Poppins_regular_large"}
              textAlign={"text_align_left"}
            />
            <div className="category-background-influencer-container">
              {categoryListData &&
                categoryListData.map((item, index) => {
                  return (
                    <>
                      {" "}
                      <div className="category-background-modal-influencer">
                        <CustomText
                          p
                          text={item.name}
                          fontSize={"font_size_large"}
                          textColor={"text_color_primary"}
                          fontWeight={"rubik_regular"}
                        />
                      </div>{" "}
                      <div
                        style={{ color: "#8E8E93", fontSize: "large" }}
                        className="m-t-16 m-r-6"
                      >
                        {index < categoryListData.length - 1 ? "|" : ""}
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    );
  }

  const handleVideoClick = () => {
    setShowVideo(!showVideo);
  };

  let imgSrc =
    portfolioDataSava.link +
    "/" +
    "portfolio_content" +
    "/" +
    portfolioDataSava.influencer_id +
    "/" +
    portfolioDataSava.media_id;

  function recentCollaboration() {
    return (
      <>
        <div>
          <div className="m-b-15">
            <CustomText
              h5
              text={"Social Analytics"}
              fontSize={"font_size_2xl"}
              textColor={"text_color_primary"}
              fontWeight={"Poppins_regular_large"}
              textAlign={"text_align_left"}
            />
          </div>
          {platformsData.map((item) => {
            if (item.platform_id === "btll" && effectiveFollowers === null) {
              setInstagramId(item.platform_id);
              setEffectiveFollowers(item.effective_followers);
            }
            const handlePlatformClick = (url) => {
              // Open the platform URL here
              window.open(url, "_blank");
            };
            if (item.platform_id == "btll") {
              return (
                <div className="engagement-view-container">
                  <div
                    onClick={() =>
                      handlePlatformClick(
                        Utils.getSocialUrl(
                          item?.platform_id,
                          item?.identifier_id
                        )
                      )
                    }
                    className="instagram-data-handle"
                  >
                    <Icon iconName="reelsIconInstagram" />
                    <CustomText
                      p
                      text={`@${item?.identifier_id}`}
                      className="button2 instagram-data-handle-text"
                    />
                  </div>
                  <div className="engagement-view-inner ">
                    <div className="instagram-data-view">
                      <CustomText p text={"Followers"} className="caption_2" />
                      <CustomText
                        p
                        text={
                          Utils.isNullOrUndefinedOrZero(item.followers)
                            ? "-"
                            : Utils.changeNumberFormate(item.followers)
                        }
                        className="button2"
                      />
                    </div>
                    <div className="instagram-data-view">
                      <CustomText p text={"Avg. Views"} className="caption_2" />
                      <CustomText
                        p
                        text={
                          Utils.isNullOrUndefinedOrZero(item.avg_views)
                            ? "-"
                            : Utils.changeNumberFormate(item.avg_views)
                        }
                        className="button2"
                      />
                    </div>
                    <div className="instagram-data-view">
                      <CustomText p text={"Avg. Likes"} className="caption_2" />
                      <CustomText
                        p
                        text={
                          Utils.isNullOrUndefinedOrZero(item.avg_likes)
                            ? "-"
                            : Utils.changeNumberFormate(item.avg_likes)
                        }
                        className="button2"
                      />
                    </div>
                    <div className="instagram-data-view">
                      <CustomText
                        p
                        text={"Avg. Comments"}
                        className="caption_2"
                      />
                      <CustomText
                        p
                        text={
                          Utils.isNullOrUndefinedOrZero(item.avg_comments)
                            ? "-"
                            : Utils.changeNumberFormate(item.avg_comments)
                        }
                        className="button2"
                      />
                    </div>
                    <div className="instagram-data-view">
                      <CustomText p text={"E.R."} className="caption_2" />
                      <CustomText
                        p
                        text={
                          !Utils.isNullOrUndefinedOrZero(item.engagement_rate)
                            ? item.engagement_rate.toFixed(2) + " %"
                            : "-"
                        }
                        className="button2"
                      />
                    </div>

                    <div className="instagram-data-view">
                      <CustomText
                        p
                        text={"Effective Followers"}
                        className="caption_2"
                      />
                      {effectiveLoad ? (
                        <Lottie
                          options={defaultOptions}
                          height={15}
                          width={100}
                        />
                      ) : (
                        <CustomText
                          p
                          text={
                            effectiveFollowers
                              ? effectiveFollowers.toFixed(2) + " %"
                              : "NA"
                          }
                          className={
                            checkOrgSub?.feature?.max_brand_count > 1 &&
                            effectiveFollowers
                              ? "button2"
                              : "blur-text"
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <>
                  <div>
                    <div className="engagement-view-container">
                      <div
                        onClick={() =>
                          handlePlatformClick(
                            Utils.getSocialUrl(
                              item?.platform_id,
                              item?.identifier_id
                            )
                          )
                        }
                        className="instagram-data-handle"
                      >
                        <Icon iconName="youtubeNewIcon" />
                        <CustomText
                          p
                          text={
                            !item?.full_name
                              ? item.identifier_id
                              : `@${item?.full_name}`
                          }
                          className="button2 instagram-data-handle-text"
                        />
                      </div>
                      <div className="engagement-view-inner">
                        <div className="instagram-data-view">
                          <CustomText
                            p
                            text={"Subscribers"}
                            className="caption_2"
                          />
                          <CustomText
                            p
                            text={
                              Utils.isNullOrUndefinedOrZero(item.followers)
                                ? "-"
                                : Utils.changeNumberFormate(item.followers)
                            }
                            className="button2"
                          />
                        </div>
                        <div className="instagram-data-view">
                          <CustomText
                            p
                            text={"Avg. Views"}
                            className="caption_2"
                          />
                          <CustomText
                            p
                            text={
                              Utils.isNullOrUndefinedOrZero(item.avg_views)
                                ? "-"
                                : Utils.changeNumberFormate(item.avg_views)
                            }
                            className="button2"
                          />
                        </div>
                        <div className="instagram-data-view">
                          <CustomText
                            p
                            text={"Avg. Likes"}
                            className="caption_2"
                          />
                          <CustomText
                            p
                            text={
                              Utils.isNullOrUndefinedOrZero(item.avg_likes)
                                ? "-"
                                : Utils.changeNumberFormate(item.avg_likes)
                            }
                            className="button2"
                          />
                        </div>
                        <div className="instagram-data-view">
                          <CustomText
                            p
                            text={"Avg. Comments"}
                            className="caption_2"
                          />
                          <CustomText
                            p
                            text={
                              Utils.isNullOrUndefinedOrZero(item.avg_comments)
                                ? "-"
                                : Utils.changeNumberFormate(item.avg_comments)
                            }
                            className="button2"
                          />
                        </div>
                        <div className="instagram-data-view">
                          <CustomText p text={"E.R."} className="caption_2" />
                          <CustomText
                            p
                            text={
                              !Utils.isNullOrUndefinedOrZero(
                                item.engagement_rate
                              )
                                ? item.engagement_rate + " %"
                                : "-"
                            }
                            className="button2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            }
          })}
        </div>

        {portfolio && portfolio?.length !== 0 && (
          <div className="recent_collaboration_main_container">
            <div className="m-b-15">
              <CustomText
                h5
                text={"Portfolio"}
                fontSize={"font_size_2xl"}
                textColor={"text_color_primary"}
                fontWeight={"Poppins_regular_large"}
              />
            </div>
            <div className="all_post_image_container">
              <Carousel
                additionalTransfrom={0}
                arrows
                autoPlaySpeed={5000}
                centerMode={false}
                className="carousel"
                containerClass="container"
                draggable
                focusOnSelect={false}
                itemClass="carousel-item"
                keyBoardControl
                minimumTouchDrag={20}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 2000,
                      min: 1024,
                    },
                    items: 3,
                    partialVisibilityGutter: 40,
                  },
                  mobile: {
                    breakpoint: {
                      max: 464,
                      min: 0,
                    },
                    items: 1,
                    partialVisibilityGutter: 30,
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 464,
                    },
                    items: 2,
                    partialVisibilityGutter: 30,
                  },
                }}
                sliderClass=""
                slidesToSlide={1}
              >
                {portfolio &&
                  portfolio?.map((items, index) => {
                    let thumbSrc =
                      items.link +
                      "/" +
                      "portfolio_thumbnail" +
                      "/" +
                      items.influencer_id +
                      "/" +
                      items.thumbnail_id;

                    let imgSrc =
                      items.link +
                      "/" +
                      "portfolio_content" +
                      "/" +
                      items.influencer_id +
                      "/" +
                      items.media_id;
                    if (items.item_type == "IMAGE") {
                      return (
                        <div
                          onClick={() => {
                            handleVideoClick(index);
                            setPortfolioDataSava(items);
                          }}
                          className="post_image_container"
                        >
                          <Image
                            fallBack={require("../resources/images/influencer-post-fall-back.png")}
                            source={imgSrc}
                            alt={"influencer post"}
                            className="post_image"
                          />

                          <div></div>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          onClick={() => {
                            handleVideoClick();
                            setPortfolioDataSava(items);
                          }}
                          className="post_image_container"
                        >
                          <Image
                            fallBack={require("../resources/images/influencer-post-fall-back.png")}
                            source={thumbSrc}
                            alt={"influencer post"}
                            className="post_image"
                          />

                          <div></div>
                        </div>
                      );
                    }
                  })}
              </Carousel>
              <div>
                <span>
                  <span>
                    {portfolioDataSava.item_type == "IMAGE" && (
                      <>
                        {showVideo && (
                          <>
                            <div className="fullscreen-image">
                              <div style={{ position: "relative" }}>
                                <div
                                  onClick={() => handleVideoClick()}
                                  className="fullscreen-image-close-btn"
                                >
                                  <Icon iconName="CloseRoundButtonIcon" />
                                </div>
                                <Image
                                  fallBack={require("../resources/images/influencer-post-fall-back.png")}
                                  source={imgSrc}
                                  alt={"influencer post"}
                                  width={600}
                                  height={337}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </span>
                  {portfolioDataSava.item_type !== "IMAGE" && (
                    <>
                      {showVideo && (
                        <>
                          <div className="fullscreen-image">
                            <div style={{ position: "relative" }}>
                              <div
                                onClick={() => handleVideoClick()}
                                className="fullscreen-image-close-btn"
                              >
                                <Icon iconName="CloseRoundButtonIcon" />
                              </div>
                              <Video
                                source={imgSrc}
                                type={"video/mp4"}
                                width={600}
                                height={337}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </span>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
  const influencerSidebarHeader = () => (
    <CustomText
      p
      text={"Influencer Details"}
      className="side-bar-heading-text"
    />
  );
  return (
    <>
      <Sidebar
        sidebarWidth={"85%"}
        isOpen={influencerFlyOut}
        onClose={toggleFlyoutDropdown}
        Header={influencerSidebarHeader}
        headerAlignment="left"
        headerClassName={"influencer-detail-sidebar-header"}
      >
        <InfluencerDetailPage influencer_id={influencer_id} />
      </Sidebar>
    </>
  );
}

export default InfluencerDetailModal;
