import React from "react";

// Import Redux State (Private scope)
import { useDispatch } from "react-redux";

import * as Actions from "../redux/actions";

import { CustomText, Image, MenuItem } from "../components";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import ReactGA from "react-ga";
// Import Redux State (Private scope)
import { useSelector } from "react-redux";

import {
  CampaignsIcon,
  ReportsIcon,
  DashboardIcon,
  ManageTeamIcon,
  SettingIcon,
  TransactionIcon,
  UserIcon,
  FilterIcon,
} from "../components/assets/icons";
import { useState } from "react";
import { useEffect } from "react";
import Core from "../common/clientSdk";
import Sdk from "../common/core/module";
import Icon from "../components/core_component/icon";
import WebStorage from "../common/web_storage";
import { BRAND_ID_KEY } from "../common/web_storage_keys";
import { useSnackbar } from "react-simple-snackbar";
import { useToast } from "../features/toastContainer";
import Utils from "../common/utils";

const SideMenu = function (props) {
  const [userData, setUserdata] = useState({});
  const [assignmentList, setAssignmentList] = useState([]);
  const [openSnackbar] = useSnackbar();
  const [brandList, setBrandList] = useState([]);
  const [hovered, setHovered] = useState(false);
  const showToast = useToast();
  // Initialize Private Functions
  var redux_dispatch = useDispatch();

  // start

  // useEffect(() => {
  //   getBrandList();
  // }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    getAssignmentBrandDetails();
  }, []);

  var redux_selector = useSelector((state) => {
    return {
      UserData: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const { UserData = {} } = redux_selector;
  const { user_data = {} } = UserData;
  const { all_permissions = {} } = UserData;
  const { role = "ADMIN" } = user_data;

  function getAssignmentBrandDetails() {
    var params = {
      brand_id: redux_selector.UserData.brand_deep_data.brand_data.brand_id,
    };

    Core.getAssignmentBrandDetails(
      getAssignmentBrandDetailsSuccess,
      getAssignmentBrandDetailsFailed,
      updateProgress,
      params
    );
  }

  function getAssignmentBrandDetailsSuccess(response_data) {
    setAssignmentList(response_data["assignment_list"]);
  }

  function getAssignmentBrandDetailsFailed(errorList) {

    openSnackbar(errorList);

  }

  function getBrandList() {
    var params = {
      org_id: Sdk.User.getUserOrgId(redux_selector.UserData.user_data),
    };
    Core.brandList(brandListSuccess, brandListFailed, progress_cb, params);
  }

  function brandListSuccess(response) {
    setBrandList(response.brand_list);
  }

  function brandListFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }
  function progress_cb(status) {}

  function getBrandData(progress_cb, brand_id) {
    var params = {
      brand_id: brand_id,
      org_id: Sdk.User.getUserOrgId(redux_selector.UserData.user_data),
    };
    Core.getBrandData(
      getBrandDataSuccess,
      getBrandDataFailed,
      progress_cb,
      params,
      redux_selector.Auth.auth_token
    );
  }

  function getBrandDataSuccess(response_data) {
    //update brand data in redux
    redux_dispatch({
      type: Actions.UPDATE_BRAND_DATA,
      payload: { brand_deep_data: response_data.b_d },
    });

    WebStorage.saveDataInWebStorage(
      BRAND_ID_KEY,
      response_data.b_d.brand_data.brand_id
    );
  }

  function getBrandDataFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function updateProgress(status) {}

  let pendingMessage = assignmentList.filter(
    (item) => item?.assignment_data?.unseen_brand == 1
  );

  //end

  const menu_item_data_admin = [
    {
      title: "Dashboard",
      svgIconName: "DashboardIcon",
      svgIconNameActive: "DashboardIconActive",
      navlink: "/",
    },
    {
      title: "Database",
      svgIconName: "discoveryInfluencerIcon",
      svgIconNameActive: "discoveryInfluencerActiveIcon",
      navlink: "/database",
    },
    {
      title: "Campaigns",
      svgIconName: "CampaignsIcon",
      svgIconNameActive: "CampaignsIconActive",
      navlink: "/campaigns",
    },
    {
      title: "Contracts",
      svgIconName: "contractIcon",
      svgIconNameActive: "contractIconActive",
      navlink: "/contracts",
    },
    {
      title: "Content Research",
      svgIconName: "ContentResearch",
      svgIconNameActive: "ContentResearchActive",
      navlink: "/content-research",
    },
    
    {
      title: "Creatives",
      svgIconName: "creativesIcons",
      svgIconNameActive: "creativesIconsActive",
      navlink: "/content-view",
    },
    {
      title: "Lists",

      svgIconName: "collectionIcons",
      svgIconNameActive: "collectionIconsActive",
      navlink: "/collection",
    },
    {
      title: "Chats",
      svgIconName: "chatInactiveSideBarIcon",
      svgIconNameActive: "chatIConsSideBar",
      navlink: "/message",
      hasPendingMessages: pendingMessage.length > 0,
    },
    {
      title: "Wallet",
      svgIconName: "TransactionIcon",
      svgIconNameActive: "TransactionIconActive",
      navlink: "/transaction-list",
      hideNav: !Utils.checkRoleAndPermission(
        redux_selector,
        all_permissions?.finance?.permissions?.wallet_payment
      ),
    },
  ];

  const menu_item_data = [
    {
      title: "Dashboard",
      svgIconName: "DashboardIcon",
      svgIconNameActive: "DashboardIconActive",
      navlink: "/",
    },
    {
      title: "Database",
      svgIconName: "discoveryInfluencerIcon",
      svgIconNameActive: "discoveryInfluencerActiveIcon",
      navlink: "/database",
    },
    {
      title: "Campaigns",
      svgIconName: "CampaignsIcon",
      svgIconNameActive: "CampaignsIconActive",
      navlink: "/campaigns",
    },

    {
      title: "Contracts",
      svgIconName: "contractIcon",
      svgIconNameActive: "contractIconActive",
      navlink: "/contracts",
    },
    
    {
      title: "Creatives",
      svgIconName: "creativesIcons",
      svgIconNameActive: "creativesIconsActive",
      navlink: "/content-view",
    },
    {
      title: "List",
      svgIconName: "collectionIcons",
      svgIconNameActive: "collectionIconsActive",
      navlink: "/collection",
    },

    {
      title: "Transactions",
      svgIconName: "TransactionIcon",
      svgIconNameActive: "TransactionIconActive",
      navlink: "/transaction-list",
    },
    // {
    //   title: "Payout",
    //   svgIconName: "PayoutIcon",
    //   svgIconNameActive: "PayoutIconActive",
    //   navlink: "/payout-list",
    // },
  ];

  const location = useLocation();
  const currentPath = "/" + location.pathname.split("/")[1];

  function googleAnalytics() {
    ReactGA.event({
      category: "User",
      action: "REELAX_ADS_WEB_LOGIN",
    });
  }
  const navigate = useNavigate();

  return (
    <div
      className={!props.showItem ? "side-menu" : "side-menu-hide"}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}>
      <div className="side-menu-logo">
        {hovered ? (
          <div onClick={() => navigate("/")} className="cursor-pointer">
            <Image
              source={"../assets/images/reelax-new-logo.svg"}
              className="side-hovered-logo"
            />
          </div>
        ) : (
          <div className="cursor-pointer" onClick={() => navigate("/")}>
            <Image
              source={"../assets/images/Reelax.png"}
              height={40}
              width={40}
              className="side-non-hovered-logo"
            />
          </div>
        )}
      </div>

      <div className="section">
        <div className="main-menu">
          <ul className="menu-items">
            <>
              {menu_item_data_admin.map((items, index) => {
                return (
                  <MenuItem
                    key={index}
                    svgIconName={items.svgIconName}
                    svgIconNameActive={items.svgIconNameActive}
                    title={items.title}
                    navlink={items.navlink}
                    hideNav={items.hideNav}
                    currentPath={currentPath}
                    hasPendingMessages={items.hasPendingMessages}
                  />
                );
              })}
            </>
            {/* {role == "ADMIN" && (
              <>
                {menu_item_data_admin.map((items, index) => {
                  return (
                    <MenuItem
                      key={index}
                      svgIconName={items.svgIconName}
                      svgIconNameActive={items.svgIconNameActive}
                      title={items.title}
                      navlink={items.navlink}
                      hideNav={items.hideNav}
                      currentPath={currentPath}
                      hasPendingMessages={items.hasPendingMessages}
                    />
                  );
                })}
              </>
            )} */}
            {/* {role == "MEMBER" && (
              <>
                {menu_item_data.map((items, index) => {
                  return (
                    <MenuItem
                      key={index}
                      svgIconName={items.svgIconName}
                      svgIconNameActive={items.svgIconNameActive}
                      title={items.title}
                      hideNav={items.hideNav}
                      navlink={items.navlink}
                      currentPath={currentPath}
                    />
                  );
                })}
              </>
            )} */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
