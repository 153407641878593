import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  CustomText,
  PrimaryCta,
  CampaignList,
  FilterBtn,
  FallBack,
  CampaignListAllData,
  ProposalsList,
  CampaignListDropdown,
} from "../../components";
import Core from "../../common/clientSdk.js";
import CustomTable from "../../components/customTable";
import { useNavigate } from "react-router-dom";
import { Loader } from "../loader.js";
import Utils from "../../common/utils.js";
import { useSnackbar } from "react-simple-snackbar";
import { ToastContainer, toast } from "react-toastify";
import { isMobile } from "react-device-detect";

const limit = 10;
const campaignStatusList = [
  "ALL",
  "IN_REVIEW",
  "ACTIVE",
  "COMPLETED",
  "INACTIVE",
];

const MarketPlaceCampaign = function ({filterStatus}) {
  const [campaignList, setCampaignList] = useState([]);
  const [start, setStart] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [next, setNext] = useState(10);
  const [isActive, setIsActive] = useState(false);
  const [creditsDetail, setCreditsDetail] = useState({});
  const [orgData, setOrgData] = useState({});
  const [address, setAddress] = useState({});
  const [totalRows, setTotalRows] = useState(null);
  const [statusColors] = useState({
    ACTIVE: "#FABC13",
    INACTIVE: "red",
    COMPLETED: "#009A29",
    IN_REVIEW: "#1977f2",
  });

  var navigate = useNavigate();

  useEffect(() => {
    getCampaignListUser(updateProgress);
    getCreditDetail(updateProgress);
    getOrgDataUser(updateProgress);
  }, [filterStatus]);

  useEffect(() => {
    getCampaignListUser(filterStatus); // Pass filterStatus to API
  }, [filterStatus]);

  function updateProgress(status) {
    setLoading(status);
  }

  function createCampaign() {
    // if (address && is_verified) {
    if (creditsDetail < 10) {
      toast.info("Not enough credits for create campaign");
      return false;
    } else {
      navigate("/create-campaign");
    }
  }

  var redux_selector = useSelector((state) => {
    return {
      UserData: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const {
    user_data,
    brand_deep_data,
    org_deep_data = {},
  } = redux_selector.UserData;

  console.log("ccc user_data", user_data);

  function getCampaignListUser(page) {
    setIsLoading(true);
    let newPage = 0;
    if (page == 10) {
      newPage = 0 * Number(page);
    } else {
      newPage = 10 * Number(page) - 10;
    }

    var params = {
      brand_id: redux_selector.UserData.brand_deep_data.brand_data.brand_id,
    };
    if (filterStatus != "ALL") {
      params.status = filterStatus;
    }

    Core.getCampaignListUser(
      getCampaignListUserSuccess,
      getCampaignListUserFailed,
      updateProgress,
      params
    );
  }

  function getCampaignListUserSuccess(response_data) {
    setCampaignList(response_data["campaign_list"]);
    setTotalRows(response_data?.pagination_data?.count);
    setIsLoading(false);
  }
  console.log("campaignList",campaignList)
  const filterFunction = () => {
    return (campaignList ?? []).filter(
      (item) => item?.campaign_data?.is_marketplace === 1
    );
  };
  function getCampaignListUserFailed(errorList) {
    toast.info(errorList.map((item) => item.m).join(", "));
    setIsLoading(false);
  }

  function getCreditDetail(progress_cb) {
    var params = {};
    Core.getCreditDetail(
      getCreditDetailSuccess,
      getCreditDetailFailed,
      progress_cb,
      params
    );
  }

  function getCreditDetailSuccess(response_data) {
    setCreditsDetail(response_data.credit_data);
  }

  function getCreditDetailFailed(errorList) {
    toast.info(errorList.map((item) => item.m).join(", "));
  }

  function getOrgDataUser(progress_cb) {
    const params = {
      org_id: redux_selector.UserData.brand_deep_data.brand_data.org_id,
    };
    Core.getOrgDataUser(
      getOrgDataUserSuccess,
      getOrgDataUserFailed,
      progress_cb,
      params
    );
  }

  function getOrgDataUserSuccess(response_data) {
    setOrgData(response_data.org_data.org_data);
    setAddress(response_data.org_data.address_data.address_premise);
  }

  function getOrgDataUserFailed(errorList) {
    toast.info(errorList.map((item) => item.m).join(", "));
  }

  function handlePageChange(page) {
    getCampaignListUser(page);
  }

  function renderTableSchema() {
    return [
      {
        name: "CAMPAIGN NAME",
        cell: (data) => <span>{data.campaign_data.name}</span>,
        //right: true,
        center: true,
        //width: '200px'
        flex: 1,
      },
      {
        name: "STATUS",
        cell: (data) => <span> {data.campaign_data.status}</span>,
        //left: true,
        // width: '200px'
        center: true,
        flex: 1,
      },
      {
        name: "BIDS",
        cell: (data) => <span> {data.name}</span>,
        //left: true,
        //width: '200px'
        center: true,
        flex: 1,
      },
      {
        name: "BIDS ACCEPTED",
        cell: (data) => <span> {data.name}</span>,
        //right: true,
        //width: '200px'
        center: true,
        flex: 1,
      },
      {
        name: "MAX BID AMOUNT",
        cell: (data) => <span> {data.name}</span>,
        //left: true,
        center: true,
        //width: '200px'
        flex: 1,
      },
      {
        name: "BUDGET",
        cell: (data) => <span> {data.campaign_data.max_budget}</span>,
        //right: true,
        center: true,
        //width: '200px'
        flex: 1,
      },
      {
        name: "EXPIRES ON",
        cell: (data) => <span> {data.name}</span>,
        //right: true,
        center: true,
        //width: '200px'
        flex: 1,
      },
      {
        name: "CREATED ON",
        cell: (data) => <span> {data.name}</span>,
        //right: true,
        center: true,
        //width: '200px'
        flex: 1,
      },
    ];
  }

  function CampaignClick(campaignId) {
    navigate(`/campaigns/${campaignId}`);
  }
  const { is_kyc_done, is_verified } = orgData;

  // Custom table data start

  const tableSchema = [
    {
      name: "Campaign Name",
      selector: (row) => row["campaign_data"]["name"],
      // sortable: true,
      cell: (data) => (
        <span
          style={{ textAlign: "left" }}
          onClick={(e) => {
            CampaignClick(data.campaign_data.campaign_id);
            e.stopPropagation();
          }}
        >
          {Utils.truncateWithEllipsis(data.campaign_data.name, 60)}
        </span>
      ),
    },
    {
      name: "Views",
      selector: (row) => row["campaign_data"]["seen_count"],
      // sortable: true,
      maxWidth: "121px",
      omit: isMobile,
    },
    {
      name: "Proposals",
      selector: (row) => row["campaign_data"]["bid_count"],
      // sortable: true,
      maxWidth: "121px",

      //cell: (data) => <span>{data.campaign_data.bid_count}</span>,
    },
    {
      name: "Contracts",
      selector: (row) => row["campaign_data"]["assignment_count"],
      // sortable: true,
      maxWidth: "121px",
      omit: isMobile,
      //cell: (data) => <span>{data.campaign_data.assignment_count}</span>,
    },
    {
      name: "Status",
      selector: (row) =>
        Utils.assignmentStatusToString(row["campaign_data"]["status"]),
      // sortable: true,
      maxWidth: "195px",
      omit: isMobile,
      cell: (data) => (
        <span
          style={{ color: statusColors[data.campaign_data.status] }}
          onClick={(e) => {
            CampaignClick(data.campaign_data.campaign_id);
            e.stopPropagation();
          }}
        >
          {Utils.assignmentStatusToString(data.campaign_data.status)}
        </span>
      ),
    },
    {
      name: "Type",
      selector: (row) => Utils.getCampaignType(row["campaign_data"]["type"]),
      // sortable: true,
      maxWidth: "149px",
      omit: isMobile,
    },    
  ];

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "var(--general-secondary-color)", // Change header background color
        color: "var(--gray-gray-300)", // Change header text color
        fontSize: "16px",
        fontWeight: 600,
        lineHight: "19.2px",
        border: "none",
      },
    },
    headCells: {
      style: {
        padding: "10px 0px 10px 20px",
      },
    },
    rows: {
      style: {
        cursor: "pointer",
        fontSize: "16px",
        fontWeight: 400,
        lineHight: "19.2px",
        "&:hover": {
          backgroundColor: "var(--general-secondary-color)",
          fontWeight: 600,
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "20px",
      },
    },
  };

  function renderLoader() {
    if (isLoading) {
      return (
        <div className="fall-back-center-campaign">
          <Loader />
        </div>
      );
    }
    return null;
  }
  

  function renderFallBack() {
    if (!isLoading && filterFunction()?.length == 0) {
      return (
        <div className="fallback">
          <FallBack
            heading={"No live campaigns"}
            title={"Launch a new campaign"}
          />
          <div className="m-t-15">
            <PrimaryCta
              third
              iconPosition="left"
              iconName="plusIcon"
              fontSize={"font_size_regular"}
              className="create-campaign"
              text={"Create Campaign"}
              onClick={() => createCampaign()}
            />
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  }

  return (
      <div className="">    
        <div
          style={{ minHeight: "75%" }}
          className=""
        >
          <>            
            <>
              <div className="custom-table">
                <CustomTable
                  className="CustomTable"
                  tableSchema={tableSchema}
                  tableData={filterFunction() ? filterFunction() : []}
                  paginationPerPage={15}
                  rowPaddingTop={16}
                  rowPaddingBottom={16}
                  customStyles={customStyles}
                  onRowClicked={(item) => {
                    CampaignClick(item.campaign_data.campaign_id);
                  }}
                  noDataComponent={renderFallBack()}
                  progressComponent={renderLoader()}
                  progressPending={isLoading}
                  paginationServer
                  pagination
                />
              </div>
            </>
          </>
        </div>
      </div>
  );
};

export default MarketPlaceCampaign;
