import { CustomText, Popup } from "../components";

function ContactInfluencerModal(props) {
  return (
    <Popup
      isOpen={true}
      onClose={() => props.closeGetContactDetails()}
      headerText={"Influencer Contact"}
      footerButton1Text={"Close"}
      footerButton1Action={() => props.closeGetContactDetails()}
      footerButton2Text={""}
      footerButton2Action={() => ""}
      isLoading={""}
      width="50%"
      // height={"300px"}
      bottomSheetMaxHeight={0.6}
    >
      <div className="contract-show-influencer-container m-t-20 m-b-20">
        <div className="influencer_address_detail ">
          <div className="flex-row " style={{ gap: "4px" }}>
            <CustomText
              p
              text={"Phone Number"}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"rubik_regular"}
              height={"line_height_19_2"}
            />
            <div
              className="new-content-box"
              style={{ position: "relative", bottom: "4px" }}
            >
              <p> New </p>
            </div>
          </div>

          <CustomText
            p
            text={ props?.influencerContactData?.phone ? `+91 ${props?.influencerContactData?.phone}` : "-"}
            fontSize={"font_size_large"}
            textColor={"text_color_primary"}
            fontWeight={"Inter_medium_500"}
            height={"line_height_19_2"}
          />
        </div>
        <div className="influencer_address_detail ">
          <div className="flex-row " style={{ gap: "4px" }}>
            <CustomText
              p
              text={"Email Address"}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"rubik_regular"}
              height={"line_height_19_2"}
            />
            <div
              className="new-content-box"
              style={{ position: "relative", bottom: "4px" }}
            >
              <p> New </p>
            </div>
          </div>

          <CustomText
            p
            text={props?.influencerContactData?.email ? props?.influencerContactData?.email : "-" }
            fontSize={"font_size_large"}
            textColor={"text_color_primary"}
            fontWeight={"Inter_medium_500"}
            height={"line_height_19_2"}
          />
        </div>
      </div>
    </Popup>
  );
}

export default ContactInfluencerModal;
