"use-strict";

import { useEffect, useState } from "react";
import {
  CampaignPlan,
  CustomRadioButton,
  CustomText,
  PrimaryCta,
} from "../../components";
import SubscriptionPlan from "../../modal/planSubscription";
import ReviewCampaign from "./reviewCampaign";
import { param } from "jquery";
import Core from "../../common/clientSdk";
import { useDispatch, useSelector } from "react-redux";
import Utils from "../../common/utils";
import React from "react";
import CampaignBestMatchInfluencer from "./campaignBestMatchInfluencer";
import CreateCampaignSuccess from "./createCampaignSuccess";
import { ToastContainer, toast } from "react-toastify";
import * as Actions from "../../redux/actions";
import { useNavigate } from "react-router-dom";

function CampaignSubscriptionPlan(props) {
  const [showSubscription, setShowSubscription] = useState(true);
  const [showSubscriptionActive, setShowSubscriptionActive] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [checkoutSummary, setCheckoutSummary] = useState();
  const [subscriptionBasicDetail, setSubscriptionBasicDetail] = useState(null);
  const [createCampaignResponse, setCreateCampaignResponse] = useState(null);
  const [bestMatchModal, setBestMatchModal] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [modalIsOpenSuccess, setModalIsOpenSuccess] = useState(false);
  const [isPaymentType, setIsPaymentType] = useState("online_payment");

  useEffect(() => {
    subscriptionUserDetailBasic();
  }, []);

  const navigate = useNavigate();

  const redux_dispatch = useDispatch();
  const endDate = props?.campaignDeepData?.campaign_data?.end_date;
  const deliveryDate = props?.campaignDeepData?.campaign_data?.delivery_date;
  const title = props?.campaignDeepData?.campaign_data?.name;
  const maxBidAmount = props?.campaignDeepData?.campaign_data?.max_budget;
  const isMarketPlace = props?.campaignDeepData?.campaign_data?.is_marketplace;
  const imageId = props?.campaignDeepData?.sample_content_data;

  const redux_selector = useSelector((state) => {
    return {
      UserData: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });
  const { UserData = {} } = redux_selector;
  const { brand_deep_data = {} } = UserData;
  const { org_deep_data = {} } = UserData;
  const { user_data = {} } = UserData;
  function checkCredits() {
    return org_deep_data?.org_data?.credits > 9;
  }

  let paymentData = [
    {
      id: "online_payment",
      title: "Online payment",
    },
    {
      id: "reelax_wallet",
      title: `Reelax wallet (blnc. ${Utils.convertFromINR(
        org_deep_data.org_data.balance,
        user_data?.country
      )})`,
    },
  ];
  function subscriptionUserDetailBasic() {
    const params = {
      org_id: org_deep_data?.org_data?.org_id,
    };

    Core.subscriptionUserDetailBasic(
      subscriptionUserDetailBasicSuccess,
      subscriptionUserDetailBasicFail,
      () => {},
      params
    );
  }
  function subscriptionUserDetailBasicFail(error) {
    toast.error(error);
  }
  function subscriptionUserDetailBasicSuccess(response, data) {
    setSubscriptionBasicDetail(data.response);
  }

  function postCreateOrderReelaxOrder() {
    const params = {
      org_id: org_deep_data?.org_data?.org_id,
      c_id: checkoutSummary?.credit_id,
      quantity: checkoutSummary?.quantity,
      value: checkoutSummary?.actual_value
        ? checkoutSummary?.actual_value
        : checkoutSummary?.value,
    };

    Core.postCreateOrderReelaxOrder(
      postCreateOrderReelaxOrderSuccess,
      postCreateOrderReelaxOrderFail,
      (status) => setIsLoading(status),
      params
    );
  }

  function postCreateOrderReelaxOrderSuccess(response_data, data) {
    toast.success("Payment successful! Thank you for your purchase.");
    createCampaign();

    //update brand user data in redux
    // redux_dispatch({
    //   type: Actions.UPDATE_BRAND_USER_DATA,
    //   payload: { user_data: response_data.user_data },
    // });

    // redux_dispatch({
    //   type: Actions.UPDATE_ORG_DATA,
    //   payload: { org_deep_data: response_data.org_data },
    // });

    // // Update Auth token
    // redux_dispatch({
    //   type: Actions.UPDATE_BRAND_USER_AUTH,
    //   payload: { auth_token: response_data.auth_id },
    // });
    //  navigate("/");
  }

  function postCreateOrderReelaxOrderFail(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  function subscriptionUserCreateCreditNew() {
    const params = {
      org_id: org_deep_data?.org_data?.org_id,
      c_id: checkoutSummary?.credit_id,
      quantity: checkoutSummary?.quantity,
      value: checkoutSummary?.actual_value
        ? checkoutSummary?.actual_value
        : checkoutSummary?.value,
      redirect_url: window.location.origin + "/create-campaign",
      platform: "web",
    };

    Core.subscriptionUserCreateCreditNew(
      subscriptionUserCreateCreditNewSuccess,
      subscriptionUserCreateCreditNewFail,
      (status) => setIsLoading(status),
      params
    );
  }

  function subscriptionUserCreateCreditNewSuccess(response, data) {
    console.log("demo1", response);
    console.log("demo2", data);

    // const pgOrderId = response.credit_order_data.pg_order_id;
    // redirectToPaymentPage(pgOrderId);
    createCreditOrder(data.credit_order_data);
    // createCampaign();
  }

  const createCreditOrder = (order_data) => {
    console.log("order_data", order_data);

    // Make an API call to your server to create a test order
    const options = {
      key: order_data.payment_gateway_id,
      order_id: order_data?.pg_order_id,
      amount: order_data?.amount,
      currency: "INR",
      handler: function (response) {
        console.log("demo3", response);
        createCampaign();
        if (response) {
        }

        // getTransactionHistoryList(start, updateProgress);
        // getOrgDataUser();
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  function subscriptionUserCreateCreditNewFail() {}

  function userSelfData() {
    var params = { org_id: org_deep_data?.org_data?.org_id };

    Core.userSelfData(
      userSelfDataSuccess,
      userSelfDataFailed,
      () => {},
      params
    );
  }

  /********************************************************************
  Brand Login success

  @param {Set} response_data - consisting user_data and brand_data

  No return
  *********************************************************************/

  function userSelfDataSuccess(response_data) {
    //update brand user data in redux
    redux_dispatch({
      type: Actions.UPDATE_BRAND_USER_DATA,
      payload: { user_data: response_data.user_data },
    });

    redux_dispatch({
      type: Actions.UPDATE_ORG_DATA,
      payload: { org_deep_data: response_data.org_data },
    });

    // Update Auth token
    redux_dispatch({
      type: Actions.UPDATE_BRAND_USER_AUTH,
      payload: { auth_token: response_data.auth_id },
    });
  }

  /********************************************************************
  Brand Login Failed

  @param {Array} error_list - consisting Error List

  No return
  *********************************************************************/
  function userSelfDataFailed(error) {}
  const phonePayGateWay = process.env.REACT_APP_API_PHONE_PAY_URL;

  function redirectToPaymentPage(pgOrderId) {
    console.log("pg order", pgOrderId);

    // const paymentUrl = `https://mercury-t2.phonepe.com/transact/pg?token=${pgOrderId}`;
    const paymentUrl = `${phonePayGateWay}${pgOrderId}`;
    window.location.href = paymentUrl;
  }
  function handleClick() {
    setShowSubscription(!showSubscription);
  }

  const openModalSuccess = () => {
    if (modalIsOpenSuccess == false) {
      setModalIsOpenSuccess(true);
    } else {
      setModalIsOpenSuccess(false);
    }
  };

  const closeModalSuccess = () => {
    setModalIsOpenSuccess(false);
  };

  function CreateCampaignAndBuyCredits() {
    if (!checkCredits() && isMarketPlace == 1) {
      createOrderCondition();
    } else {
      createCampaign();
    }

    // props.nextStep();
  }

  function createCampaign() {
    const params = {
      campaign_deep_data: JSON.stringify(props.campaignDeepData),
    };
    Core.createCampaignUser(
      createCampaignUserSuccess,
      createCampaignUserFailed,
      (status) => setIsLoading(status),
      params
    );
  }

  function createCampaignUserSuccess(response) {
    setCreateCampaignResponse(response?.campaign_deep_data);
    // closeModal();
    // // openModalSuccess();
    // openModalBestMatch();
    userSelfData();
    if (!isMarketPlace) {
      navigate(
        `/campaigns/${response?.campaign_deep_data?.campaign_data?.campaign_id}`,
        {
          state: { data: "invite-page" },
        }
      );
    }

    if (isMarketPlace) {
      openModalSuccess();

      setTimeout(() => {
        closeModalSuccess();
        openModalBestMatch();
      }, 2000);
    }
  }

  function createCampaignUserFailed(errorList) {
    // closeModal();
    // toast.info(errorList.map((item) => item.m).join(", "));
    // setLoading(false);
  }
  const openModalBestMatch = () => {
    if (bestMatchModal == false) {
      setBestMatchModal(true);
    } else {
      setBestMatchModal(false);
    }
  };

  const closeModalBestMatch = () => {
    setBestMatchModal(false);
  };
  const handleBack = () => {
    props.previousStep();
  };

  const openModal = () => {
    if (modalIsOpen == false) {
      setModalIsOpen(true);
    } else {
      setModalIsOpen(false);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  function createOrderCondition() {
    if (isPaymentType == "reelax_wallet") {
      postCreateOrderReelaxOrder();
    } else {
      subscriptionUserCreateCreditNew();
    }
  }

  const handlePaymentChange = (e) => {
    const totalAmount = checkoutSummary?.value || 0;
    const walletBalance = org_deep_data?.org_data?.balance || 0;
    const selectedPayment = e.target.value;
    console.log("eeee", selectedPayment);
    if (selectedPayment === "reelax_wallet" && walletBalance < totalAmount) {
      toast.error("You don't have enough balance to complete this order.");
      return false;
    }

    setIsPaymentType(selectedPayment);
  };

  function paymentRadioButton() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          // alignItems: "center",
          gap: "13px",
          marginTop: "15px",
          fontSize: "14px",
          color: "#3A3B43",
        }}
      >
        <div className="m-w-85" style={{ cursor: "pointer" }}>
          <CustomRadioButton
            name="online_payment"
            value="online_payment"
            checked={isPaymentType === "online_payment"}
            label="Online payment"
            onChange={handlePaymentChange}
          />
        </div>
        <div className="m-w-85" style={{ cursor: "pointer" }}>
          <CustomRadioButton
            name="Reelax_wallet"
            value="reelax_wallet"
            checked={isPaymentType === "reelax_wallet"}
            label={`Reelax wallet ( ${Utils.convertFromINR(
              org_deep_data.org_data.balance,
              user_data?.country
            )} )`}
            onChange={handlePaymentChange}
          />
        </div>
      </div>
    );
  }

  function checkoutPerCampaign() {
    return (
      <div className="p-20">
        <div>
          <CustomText p text={"Checkout"} className="h6Text" />
        </div>

        <div className="m-t-20">
          <CustomText p text={"Post Campaign Summary"} className="button2" />
          <div className="purchase-summary-inner-container  m-t-12 m-b-10">
            <CustomText
              p
              text={"Total Available Credits"}
              className="typographyH5"
            />
            <CustomText
              p
              // text={checkoutSummary?.quantity}
              text={org_deep_data?.org_data?.credits}
              className="typographyH5 "
            />
          </div>
          <div className="purchase-summary-inner-container  m-t-12 m-b-10">
            <CustomText
              p
              text={"Campaign Credits Charge"}
              className="typographyH5"
            />
            <CustomText
              p
              // text={checkoutSummary?.quantity}
              text={isMarketPlace == 0 ? 0 : 10}
              className="typographyH5 "
            />
          </div>

          <div className="dash-border-bottom m-b-10 m-t-16"></div>
          <div className="purchase-summary-inner-container p-b-10 ">
            <CustomText p text={`After Deduction`} className="typographyH5 " />

            <CustomText
              p
              // text={Utils.convertFromINR(
              //   checkoutSummary?.value,
              //   props.user_data.country
              // )}
              text={
                isMarketPlace == 0
                  ? org_deep_data?.org_data?.credits
                  : org_deep_data?.org_data?.credits - 10
              }
              className="typographyH5 "
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="create-campaign-checkbox-main-container">
      {checkCredits() || isMarketPlace == 0 ? (
        checkoutPerCampaign()
      ) : (
        <>
          <div className="checkout-summary-header-container">
            <CustomText
              p
              className="button1"
              text={
                "You need a minimum of 10 credits to create this campaign. Currently, you don’t have enough credits"
              }
              textColor={""}
              fontWeight={"Inter_regular"}
            />
          </div>
          {/* <div style={{ padding: 20 }}>{paymentRadioButton()}</div> */}
          {subscriptionBasicDetail?.basic_subscription_data && (
            <SubscriptionPlan
              setCheckoutSummary={setCheckoutSummary}
              handlePaymentChange={handlePaymentChange}
              basic_subscription_data={
                subscriptionBasicDetail?.basic_subscription_data
              }
              active_subscription_data={
                subscriptionBasicDetail?.active_subscription_data
              }
              user_data={user_data}
              isPaymentType={isPaymentType}
              setIsPaymentType={setIsPaymentType}
            />
          )}
        </>
      )}

      <>
        <div className="divide-line"></div>
        <div className="create-campaign-bottom-container">
          <div className="type-campaign-btn">
            {/* <PrimaryCta
              fifth
              className=""
              onClick={openModal}
              fontSize={"font_size_large"}
              text={"Preview"}
            /> */}
          </div>

          <div className="type-campaign-btn">
            <div style={{ maxWidth: 150 }}>
              <PrimaryCta
                invert
                className="back-btn-campaign"
                onClick={handleBack}
                fontSize={"font_size_large"}
                text={"Cancel"}
              />
            </div>

            <PrimaryCta
              fifth
              isLoading={loading}
              className="blue-button"
              onClick={() => CreateCampaignAndBuyCredits()}
              fontSize={"font_size_large"}
              text={"Submit"}
            />
          </div>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            zIndex={9999999}
          />
        </div>
      </>

      {bestMatchModal && (
        <CampaignBestMatchInfluencer
          createCampaignResponse={createCampaignResponse}
          closeModal={closeModalBestMatch}
        />
      )}

      {modalIsOpenSuccess && (
        <CreateCampaignSuccess closeModal={closeModalSuccess} />
      )}
      {modalIsOpen && (
        <ReviewCampaign
          closeModal={closeModal}
          // handleNext={handleNext}
          imageId={imageId}
          // isLoading={isLoading}
          title={title}
          maxBidAmount={maxBidAmount}
          endDate={endDate}
          deliveryDate={deliveryDate}
          instructionNote={
            "<p>&nbsp;Script and Instructions for Creator&nbsp;</p>"
          }
          props={props}
          // postSaveCampaignUser={postSaveCampaignUser}
          // isLoadingDraft={isLoadingDraft}
        />
      )}
    </div>
  );
}

export default CampaignSubscriptionPlan;
