import React, { useEffect, useState } from "react";
import Core from "../common/clientSdk";
import Icon from "../components/core_component/icon";
import { CustomText } from "../components";
import Utils from "../common/utils";

function InfluencerContentSocialMedia({ item, brand_deep_data }) {
  const [contentAnalyticsData, setContentAnalyticsData] = useState("");
  const [loading, setLoading] = useState(false);
  const data = item;

  // useEffect(() => {
  //   if (item.campaignId) {
  //     AssignmentContentAnalytics(item);
  //   }
  // }, []);

  function updateProgress(status) {
    setLoading(status);
  }

  function AssignmentContentAnalytics() {
    const params = {
      org_id: brand_deep_data.brand_data.org_id,
      brand_id: brand_deep_data.brand_data.brand_id,
      campaign_id: item.campaignId,
      assignment_id: item.assignmentId,
      content_id: item.content_id,
      media_url: item.url,
    };
    Core.AssignmentContentListByContent(
      AssignmentContentAnalyticsSuccess,
      AssignmentContentAnalyticsFailed,
      updateProgress,
      params
    );
  }

  function AssignmentContentAnalyticsSuccess(response) {
    setContentAnalyticsData(response.content_analytics_data);
  }
  function AssignmentContentAnalyticsFailed(errorList) {
    // toast.error(errorList.map((item) => item.m).join(", "));
  }

  console.log("item?.views", item);

  return (
    <>
      {" "}
      {(contentAnalyticsData || item) && (
        <>
          <div className="content-media-card-icons-main">
            <div className="content-media-card-icons">
              {(contentAnalyticsData?.like_count > 0 || item?.like_count > 0) && (
                <div className="flex-row">
                  <Icon iconName="heartIcon" height={15} width={15} />
                  <CustomText
                    p
                    text={Utils.convertToK(
                      contentAnalyticsData?.like_count || item?.like_count
                    )}
                    className="caption_3 m-l-3"
                  />
                </div>
              )}

              {(contentAnalyticsData?.comment_count > 0 || item?.comment_count> 0) && (
                <div className="flex-row">
                  <Icon iconName="commentCount" height={15} width={15} />
                  <CustomText
                    p
                    text={Utils.convertToK(
                      contentAnalyticsData?.comment_count || item?.comment_count
                    )}
                    className="caption_3 m-l-3"
                  />
                </div>
              )}

              {contentAnalyticsData?.play_count > 0 || item?.view_count > 0 ? (
                <div className="flex-row">
                  <Icon iconName="viewEyes" height={15} width={15} />
                  <CustomText
                    p
                    text={Utils.convertToK(
                      contentAnalyticsData?.play_count || item?.view_count
                    )}
                    className="caption_3 m-l-3"
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            <div>
              <CustomText
                p
                text={
                  contentAnalyticsData?.last_modified
                    ? Utils.formatUnixDateToCustom(
                        contentAnalyticsData?.last_modified
                      )
                    : null
                }
                className="caption_3 m-l-3"
              />
            </div>
          </div>
          <div className="content-text-analytic-container">
            <CustomText
              p
              text={contentAnalyticsData?.caption_text || item?.caption}
              className="caption_3 m-l-3"
              textColor={"text_color_light"}
            />
          </div>
        </>
      )}
    </>
  );
}

export default InfluencerContentSocialMedia;
