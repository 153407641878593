import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../header";
import SideMenu from "../sideMenu";
import Icon from "../../components/core_component/icon";

const ContentResearch = () => {
  const [searchValue, setSearchValue] = useState(""); // Store input value
  const navigate = useNavigate();

  const handleSearch = () => {
    if (searchValue.trim() !== "") {
      navigate(`/content-research/${encodeURIComponent(searchValue)}`); // Navigate with input value
    }
  };

  return (
    <div className="dashboard-container background-color-primary">
      <SideMenu />
      <div className="main-screen">
        <Header />
        <div className="page-container-campaignList">
          <div className="content-research-main-container">
            <div className="content-research-main-container-title">
              <h1>
                Use Reelax AI to Find Relevant Content Ideas and Influencers
              </h1>
              <p className="description">
                Reelax AI trained with 40 lacs influencer content and 2.5 lacs
                influencers data, search your topic to get instant results.
              </p>
            </div>
            <div className="content-research-search-box">
              <div style={{ height: "100%", width: "70%" }}>
                <input
                  className="content-research-search-box-input"
                  placeholder="Type keyword like `beauty product review` to see the magic"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                  style={{ fontSize: "13px" }}
                />
              </div>
              <div style={{ height: "100%", width: "30%" }}>
                <button
                  className="content-research-search-box-button"
                  onClick={handleSearch}
                >
                  <div style={{ display: "flex" }}>
                    <Icon iconName="insightsIcon" />
                  </div>
                  <div>Get Insights</div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentResearch;
