import React, { useEffect, useState } from "react";
import { CustomRadioButton, CustomText } from "../components";
import Utils from "../common/utils";

function PaymentTypeCheckout(props) {
  return (
    <label
      className={`custom-radio-container checkout-main-container  ${props.className}`}
    >
      <div className="flex-column gap-10">
        <span className="radio-label">{props.label}</span>
        {props.subText && <span className="text-12">{props.subText}</span>}
      </div>
      <input
        className="custom-radio-input"
        type="radio"
        name={props.name}
        value={props.value}
        checked={props.checked}
        onChange={props.onChange}
        disabled={props.disabled}
      />
    </label>
  );
}

export default PaymentTypeCheckout;
