import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Core from "../common/clientSdk";
import { useNavigate } from "react-router-dom";
import CustomTable from "../components/customTable";
// import Tooltip from "@mui/material/Tooltip";
import { Loader } from "../screens/loader";
import DatePicker from "react-datepicker";
import { Tooltip } from "react-tooltip";
import {
  CustomButton,
  CustomText,
  FallBack,
  FallBackBtn,
  Image,
  Popup,
  PrimaryCta,
} from "../components";
import Utils from "../common/utils";
import Icon from "../components/core_component/icon";
import Lottie from "react-lottie";
import * as LoadingUI from "../resources/data/loading.json";
import { useSelector } from "react-redux";
import InfluencerDetailModal from "./influencerDetialModal";
import { useToast } from "../features/toastContainer";
import PriceToCollectionInfluencer from "../screens/collection/priceToCollectionInfluencer";
import NoteListInfluencer from "../screens/collection/listNoteInfluencer";
import Sdk from "../common/core/module";
const customStyles = {
  headRow: {
    style: {
      backgroundColor: "var(--general-secondary-color)", // Change header background color
      color: "var(--gray-gray-300)", // Change header text color
      fontSize: "14px",
      fontWeight: 600,
      lineHight: "19.2px",
      border: "none",
    },
  },
  headCells: {
    style: {
      padding: "10px 0px 10px 20px",
    },
  },
  rows: {
    style: {
      minHeight: "60px",
      cursor: "pointer",
      fontSize: "14px",
      paddingTop: "5px",
      paddingBottom: "5px",
      fontWeight: 400,
      lineHight: "19.2px",
      "&:hover": {
        backgroundColor: "var(--general-secondary-color)",
        fontWeight: 500,
      },
    },
  },
  cells: {
    style: {
      paddingLeft: "20px",
      minHeight: "60px",
    },
  },
};

function CollectionCampaignInfluencerTable(props) {
  const [collectionInfluencerList, setCollectionInfluencerList] = useState([]);
  const [influencerInviteList, setInfluencerInviteList] = useState([]);
  const [isInviteLoading, setInviteLoading] = useState({});
  const [loading, setListLoading] = useState(false);
  const [hoveredIcons, setHoveredIcons] = useState([]);
  const [influencerFlyOut, setInfluencerFlyOut] = useState(false);
  const [influencerId, setInfluencerId] = useState("");
  const [priceValue, setPriceValue] = useState(null);
  const [priceType, setPriceType] = useState(null);
  const [noteType, setNoteType] = useState(null);
  const [modalIsOpenPrice, setModalIsOpenPrice] = useState(false);
  const [modalIsOpenNote, setModalIsOpenNote] = useState(false);
  const [approvePopupOpen, setApprovePopupOpen] = useState(false);
  const [contractSignData, setContractSignData] = useState({});
  const currentUnixDate = Sdk?.Campaign?.getDateString(new Date());
  const [deliveryDate, setDeliveryDate] = useState(currentUnixDate);
  const campaignStatus = props?.campaignDetail?.campaign_data?.status;
  const isMarketPlace = props?.campaignDetail?.campaign_data?.is_marketplace==1;
  const [assignmentList, setAssignmentList] = useState([]);
  const [addNote, setAddNote] = useState();
  const showToast = useToast();
  var redux_selector = useSelector((state) => {
    return {
      UserData: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const { UserData = {} } = redux_selector;
  const { brand_deep_data = {} } = UserData || {};
  const { org_deep_data = {} } = UserData || {};
  const { user_data = {} } = UserData || {};
  const { all_permissions = {} } = UserData || {};

  const navigate = useNavigate();
  useEffect(() => {
    if (collectionInfluencerList?.campaignDetail?.campaign_data?.end_date) {
      const expired = isCampaignExpired(
        collectionInfluencerList?.campaignDetail?.campaign_data?.end_date
      );
      if (expired) {
        setListLoading(false); 
        return; 
      }
    }
  }, []);

  useEffect(() => {
    if (props?.collectionId) {
      setListLoading(true)
      getCollectionInfluencerList();     
    }
  }, [props?.collectionId]);

  useEffect(()=>{
    getInfluencerInviteList();
  },[props?.isAddInfluencer])

  const isCampaignExpired = (endDate) => {
    if (!endDate) return false; // If no expiry date, assume it's not expired
    const currentDate = new Date();
    const campaignExpiryDate = Utils.convertUnixToDate(endDate);
    return currentDate > campaignExpiryDate; // Check if the current date is past the expiry date
  };

  function getAssignmentUser() {
    var params = {
      campaign_id: props?.campaignId,
    };

    Core.getAssignmentUser(
      getAssignmentUserSuccess,
      getAssignmentUserFailed,
      () => {},
      params
    );
  }

  function getAssignmentUserSuccess(response_data) {
    setAssignmentList(response_data?.["assignment_list"]);
  }
  console.log("getAssignmentUser", assignmentList);

  function getAssignmentUserFailed(errorList) {
    // openSnackbar(errorList.map((err) => err.m).join(", "));
  }
  function inviteInfluencer(influencer_id) {
    if (
      !Utils.checkRoleAndPermission(
        redux_selector,
        all_permissions?.["campaign"]?.["permissions"]?.["shortlist_influencer"]
      )
    ) {
      showToast();
      // toast.info("You are not authorised to sent request price");
    } else {
      postInfluencerInvite(influencer_id);
    }
  }

  function postInfluencerInvite(influencer_id) {
    var params = {
      campaign_id: props?.campaignId,
      influencer_id: influencer_id,
    };
    Core.postInfluencerInvite(
      postInfluencerInviteSuccess,
      postInfluencerInviteFailed,
      (status) => setInviteLoading({ [influencer_id]: status }),
      params
    );
  }

  function postInfluencerInviteSuccess(response_data) {
    toast.success("Request Price sent to Influencer");
    getInfluencerInviteList();
  }

  function postInfluencerInviteFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }
  function getInfluencerInviteList() {
    var params = {
      campaign_id: props?.campaignId,
    };
    Core.getInfluencerInviteList(
      getInfluencerInviteListSuccess,
      getInfluencerInviteListFailed,
      () => {},
      params
    );
  }

  function getInfluencerInviteListSuccess(response_data) {
    setInfluencerInviteList(response_data?.invite_list);
  }

  function getInfluencerInviteListFailed(errorList) {
    toast.error(errorList);
    setListLoading(false);
  }
  function getCollectionInfluencerList() {
    const params = {
      collection_id: props?.collectionId,
    };
    Core.getCollectionInfluencerList(
      getCollectionInfluencerListSuccess,
      getCollectionInfluencerListFailed,
      (status) => setListLoading(status),
      params
    );
  }

  function getCollectionInfluencerListSuccess(response_data) {
    setCollectionInfluencerList(response_data?.collection_deep_data);
    getAssignmentUser();
    setListLoading(false)
  }

  function getCollectionInfluencerListFailed(errorList) {
    setCollectionInfluencerList([]);
    setListLoading(false);
  }
  function postOutboundSignContract() {
    const params = {
      campaign_id: props?.campaignId,
      org_id: brand_deep_data?.brand_data?.org_id,
      influencer_id: contractSignData?.influencer_id,
      assignment_delivery_date: deliveryDate,
      value: contractSignData?.internal_price
        ? contractSignData?.internal_price
        : props?.campaignDetail?.campaign_data?.max_budget,
      client_cost: contractSignData?.client_price,
    };
    Core.postOutboundSignContract(
      postOutboundSignContractSuccess,
      postOutboundSignContractFailed,
      (status) => setListLoading(status),
      params
    );
  }

  function postOutboundSignContractSuccess(response_data) {
    setApprovePopupOpen(false);
    getAssignmentUser();
    showToast("Contract signed");
  }

  function postOutboundSignContractFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
    setApprovePopupOpen(false);
  }

  function inviteAndReject(id) {
    let inviteId = influencerInviteList.find(
      (item) => item?.influencer_id == id
    );

    return inviteId;
  }
  function awardedContractInfluencer(id) {
    let inviteId = assignmentList.find(
      (item) => item?.influencer_data?.influencer_id == id
    );

    return inviteId;
  }

  const platFormName = (platforms_data, influencer_id) => {
    const handleMouseEnter = (index) => {
      const newHoveredIcons = [...hoveredIcons];
      newHoveredIcons[index] = true;
      setHoveredIcons(newHoveredIcons);
    };

    const handleMouseLeave = (index) => {
      const newHoveredIcons = [...hoveredIcons];
      newHoveredIcons[index] = false;
      setHoveredIcons(newHoveredIcons);
    };

    const handlePlatformClick = (url) => {
      window.open(url, "_blank");
      setHoveredIcons(new Array(platforms_data?.length).fill(false));
    };

    const filteredPlatforms = platforms_data?.find(
      (item) =>
        item?.platform_id ==
          collectionInfluencerList?.collection_data?.platform_id || "btll"
    );

    return (
      <div style={{ marginTop: 2 }} className="flex-row">
        <div
          className={"platform-name-collection"}
          data-tooltip-id={`tooltip-${influencer_id}${filteredPlatforms?.platform_id}`}
          onMouseEnter={() =>
            handleMouseEnter(`${influencer_id}${filteredPlatforms?.platform_id}`)
          }
          onMouseLeave={() =>
            handleMouseLeave(`${influencer_id}${filteredPlatforms?.platform_id}`)
          }
          onClick={() =>
            handlePlatformClick(
              Utils.getSocialUrl(
                filteredPlatforms?.platform_id,
                filteredPlatforms?.identifier_id
              )
            )
          }
        >
          <CustomText
            p
            text={
              collectionInfluencerList?.collection_data?.platform_id == "btll"
                ? `@${filteredPlatforms?.identifier_id}`
                : filteredPlatforms?.identifier_id
            }
            className="caption_2"
            textColor={"text_color_blue_500"}
          />
        </div>
      </div>
    );
  };
  function openModalPrice() {
    if (modalIsOpenPrice == false) {
      setModalIsOpenPrice(true);
    } else {
      setModalIsOpenPrice(false);
    }
  }

  function closePriceModal() {
    setModalIsOpenPrice(false);
  }
  function openModalNote() {
    if (modalIsOpenNote == false) {
      setModalIsOpenNote(true);
    } else {
      setModalIsOpenNote(false);
    }
  }

  function closeNoteModal() {
    setModalIsOpenNote(false);
  }

  const getSelectedPlatformData = (platforms_data) => {
    return platforms_data?.find(
      (item) => item?.platform_id === props?.platformId
    );
  };

  const formatPlatformData = (platforms_data, key) => {
    const selectedPlatformData = getSelectedPlatformData(platforms_data);
    console.log("selectedPlatformData",selectedPlatformData)
    return Utils.changeNumberFormate(selectedPlatformData?.[key]);
  };

  const toggleFlyoutDropdown = () => {
    if (
      !Utils.checkRoleAndPermission(
        redux_selector,
        all_permissions?.["campaign"]?.["permissions"]?.["view_profile"]
      )
    ) {
      showToast();
    } else {
      setInfluencerFlyOut(!influencerFlyOut);
    }
  };

  function awardContractAcceptPop() {
    setApprovePopupOpen(true);
  }

  const updateDeliveryDate = (date) => {
    var unixDate = Sdk?.Campaign?.getDateString(date);

    setDeliveryDate(unixDate);
  };
  function renderAwardContractPop() {
    return (
      <Popup
        isOpen={approvePopupOpen}
        onClose={() => setApprovePopupOpen(false)}
        headerText={"Accept Proposal"}
        footerButton1Text={"Cancel"}
        footerButton1Action={() => setApprovePopupOpen(false)}
        footerButton2Text={"Accept"}
        footerButton2Action={() => postOutboundSignContract()}
        isLoading={""}
        width="50%"
        // height={"300px"}
        bottomSheetMaxHeight={0.6}
      >
        <div className="contract-cancellation-confirm">
          <div className="contract-cancellation-top-p">
            <div style={{ marginTop: "10px" }}>
              By accepting the proposal, you agree to accept &nbsp;
              <u
                // onClick={() => {}
                style={{ color: "#1977f2", cursor: "pointer" }}
              >
                Contract cancellation terms.
              </u>
            </div>
          </div>
          <div
            className=" m-t-12"
            style={{
              display: "flex",
              justifyContent: "center",
              gap: 20,
              alignItems: "center",
            }}
          >
            <div style={{ marginRight: "10px", fontSize: "14px" }}>
              <CustomText
                p
                textColor="text_color_primary"
                text="Delivery Date"
              />
            </div>
            <div className="datepicker-container">
              <DatePicker
                selected={new Date(deliveryDate * 1000)}
                onChange={(date) => updateDeliveryDate(date)}
                className="datepicker-bid-list"
              />
            </div>
          </div>
        </div>
      </Popup>
    );
  }

  const tableInfluencerSchema = [
    {
      name: "Influencer Name",
      selector: (row) => row?.["influencer_data"]?.["name"],
      width: "300px",
      cell: (data) => (
        <span
          onClick={() => {
            toggleFlyoutDropdown();
            setInfluencerId(data?.influencer_data?.influencer_id);
          }}
          style={{ display: "flex", gap: 8, alignItems: "center" }}
        >
          <Image
            source={
              data?.influencer_data?.profile_picture
                ? Utils?.generateInfluencerDpUrl(
                    data?.influencer_data?.influencer_id,
                    data?.influencer_data?.profile_picture
                  )
                : require("../resources/images/user-copy.png")
            }
            className="collection-user-profile"
          />
          <div style={{ flexDirection: "row", display: "flex", gap: 5 }}>
            <div>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  gap: 5,
                  alignItems: "baseline",
                }}
              >
                <CustomText
                  p
                  text={data?.influencer_data?.name}
                  className="Body-1"
                />
              </div>
              <div style={{ width: 100 }}>
                {platFormName(
                  data?.platforms_data,
                  data?.influencer_data?.influencer_id
                )}
              </div>
            </div>
          </div>
        </span>
      ),
    },

    {
      name: "Followers",
      selector: (row) => formatPlatformData(row?.platforms_data, "followers"),
      sortable: false,
    },
    {
      name: "Avg Views",
      selector: (row) =>
        Utils.isObjectOrNullUndefinedZero(
          formatPlatformData(row?.platforms_data, "avg_views")
        )
          ? "-"
          : formatPlatformData(row?.platforms_data, "avg_views"),
      
    },
    {
      name: "ER",
      selector: (row) =>
        Utils.isObjectOrNullUndefinedZero(
          formatPlatformData(row?.platforms_data, "engagement_rate")
        )
          ? "-"
          : formatPlatformData(row?.platforms_data, "engagement_rate"),
    },
    {
      name: "Internal Cost",
      omit: org_deep_data?.org_data?.type == "brand_manager",
      selector: (row) =>
        row?.influencer_data?.price
          ? Utils.convertFromINR(row?.influencer_data?.price, user_data.country)
          : "...",
      sortable: false,

      cell: (data) => (
        <span
          onClick={(index) => {
            openModalPrice();
            setInfluencerId(data?.influencer_data.influencer_id);
            setPriceValue(data?.influencer_data?.internal_price);
            setPriceType("internal_price");
          }}
          style={{ color: "rgb(25, 119, 242)" }}
          className="flex-row"
        >
          <CustomText
            p
            text={
              data?.influencer_data?.internal_price
                ? Utils.convertFromINR(
                    data?.influencer_data?.internal_price,
                    user_data.country
                  )
                : Utils.convertFromINR(
                    props?.campaignDetail?.campaign_data?.max_budget,
                    user_data.country
                  )
            }
            className=" m-r-4"
          />
          <div>
            <Icon iconName="EditIconCounter" />
          </div>
        </span>
      ),
    },
  ];
  if (org_deep_data?.org_data?.organization_type== "AGENCY") {
    tableInfluencerSchema.push(
      {
        name: "Client Cost",
        selector: (row) =>
          row?.influencer_data?.price
            ? Utils.convertFromINR(
                row?.influencer_data?.client_price,
                user_data.country
              )
            : "...",
        sortable: false,
        cell: (data) => (
          <span
            onClick={(index) => {
              openModalPrice();
              setInfluencerId(data?.influencer_data?.influencer_id);
              setPriceValue(data?.influencer_data?.client_price);
              setPriceType("price");
            }}
            style={{ color: "rgb(25, 119, 242)" }}
            className="flex-row"
          >
            <CustomText
              p
              text={
                data?.influencer_data?.client_price
                  ? Utils.convertFromINR(
                      data?.influencer_data?.client_price,
                      user_data.country
                    )
                  : "..."
              }
              className=" m-r-4"
            />
            <div>
              <Icon iconName="EditIconCounter" />
            </div>
          </span>
        ),
      },
   );
  }
  if(isMarketPlace){
    tableInfluencerSchema.push(
      {
        name: "Note",
        selector: (row) => "...",
        sortable: false,
        cell: (data) => (
          <span
            onClick={(index) => {
              openModalNote();
              setInfluencerId(data?.influencer_data.influencer_id);
              setNoteType("internal_note");
              setAddNote(data?.influencer_data?.user_note);
            }}
            style={{ color: "rgb(25, 119, 242)" }}
            className="flex-row note-show-contract"
            data-tooltip-id="tooltip"
            data-tooltip-content={data?.influencer_data?.user_note}
          >
            <Tooltip id="tooltip" />
            <div className="truncate">
              <CustomText
                p
                text={
                  data?.influencer_data?.user_note
                    ? data?.influencer_data?.user_note
                    : "..."
                }
                className=" m-r-4"
              />
            </div>
            <div>
              <Icon iconName="EditIconCounter" />
            </div>
          </span>
        ),
      },
      {
        name: "Feedback",
        selector: (row) => "...",
        sortable: false,  
        cell: (data) => (
          <span
            onClick={(index) => {
              // openModalNote();
              // setInfluencerId(data?.influencer_data.influencer_id);
              // setNoteType("clint_note");
            }}
            data-tooltip-id="tooltip"
            data-tooltip-content={data?.influencer_data?.internal_note}
            style={{ color: "rgb(25, 119, 242)" }}
            className="flex-row note-show-contract"
          >
            <Tooltip title={<span>{data?.influencer_data?.feedback}</span>}>
              <div className="truncate">
                <CustomText
                  p
                  text={
                    data?.influencer_data?.feedback
                      ? data?.influencer_data?.feedback
                      : "..."
                  }
                  className=" m-r-6"
                />
              </div>
              <div>
                <Icon iconName="infoIcon" />
              </div>
            </Tooltip>
          </span>
        ),
      },
      {
        name: "Action",
        lastCol: true,
        paddingRight: "20px",
        conditionalCellStyles: [
          {
            when: (row) => true,
            style: {
              justifyContent: "right",
            },
          },
        ],
  
        cell: (data) => (
          <snap
            style={{
              display: "flex",
              gap: 24,
              alignItems: "center",
            }}
          >
            <>
              {inviteAndReject(data?.influencer_data?.influencer_id) ? (
                <PrimaryCta
                  third
                  width="144px"
                  disabled={true}
                  fontSize={"font_size_small"}
                  height="40px"
                  text={"Price Requested"}
                  isLoading={""}
                />
              ) : (
                <PrimaryCta
                  third
                  width="134px"
                  iconName="InviteIcon"
                  onClick={() =>
                    inviteInfluencer(data?.influencer_data?.influencer_id)
                  }
                  fontSize={"font_size_small"}
                  text={"Request Price"}
                  height="40px"
                  isLoading={
                    isInviteLoading[data?.influencer_data?.influencer_id]
                  }
                  disabled={campaignStatus != "ACTIVE"}
                />
              )}
            </>
          </snap>
        ),
      },
    )
  }
  
  if(!isMarketPlace){
    tableInfluencerSchema.push(
      {
        name: "Action",
        lastCol: true,
        paddingRight: "20px",
        width:230,
        conditionalCellStyles: [
          {
            when: (row) => true,
            style: {
              justifyContent: "center",
            },
          },
        ],
  
        cell: (data) => (
          <snap
            style={{
              display: "flex",
              gap: 24,
              alignItems: "center",
              minWidth:180,
            }}
          >
            <>
              {awardedContractInfluencer(data?.influencer_data?.influencer_id) ? (
                <PrimaryCta
                  fifth
                  height="40px"
                  disabled={true}
                  onClick={() => {
                    setContractSignData(data?.influencer_data);
                    awardContractAcceptPop();
                  }}
                  fontSize={"font_size_small"}
                  text={"Contract Awarded"}
                  className="blue-button award-contract"
                  isLoading={""}
                />
              ) : (
                <PrimaryCta
                  fifth
                  height="40px"
                  className="blue-button award-contract"
                  iconName="InviteIcon"
                  onClick={() => {
                    setContractSignData(data?.influencer_data);
                    awardContractAcceptPop();
                  }}
                  fontSize={"font_size_small"}
                  text={"Award Contract"}
                  isLoading={
                    isInviteLoading[data?.influencer_data?.influencer_id]
                  }
                  disabled={campaignStatus != "ACTIVE"}
                />
              )}
            </>
          </snap>
        ),
      },
    )
  } 

  function renderNavigate() {
    if (!isMarketPlace) {
      navigate("/database");
    } else {
      props.handleAddInfluencersClick();
    }
  }
  function renderLoader() {
    return (
        <div className="fall-back-center-campaign">
          <Loader />
        </div>
    );
  }

  function renderFallback() {
      return (
        <div className="fall-back-center-campaign">
          <FallBackBtn
            heading={"!! Oops you don't have any Shortlisted Influencer..."}
            title={
              "Shortlist influencer to request pricing from suggested list"
            }
            widthIcon={100}
            heightIcon={100}
            onClick={renderNavigate}
            text={"Shortlist Influencer"}
          />
        </div>
      );
  }

  function customTableData() {
    return (
      <>
        <CustomTable
          className="CustomTable"
          tableSchema={tableInfluencerSchema}
          tableData={collectionInfluencerList?.collection_influencer_list}
          rowPaddingTop={16}
          rowPaddingBottom={16}
          customStyles={customStyles}
          noDataComponent={renderFallback()}
          progressComponent={renderLoader()}
          progressPending={loading}
        />
      </>
    );
  }

  return (
    <div className="">
      {customTableData()}
      {
        <InfluencerDetailModal
          influencerFlyOut={influencerFlyOut}
          toggleFlyoutDropdown={toggleFlyoutDropdown}
          influencer_id={influencerId}
          org_id={brand_deep_data.brand_data.org_id}
        />
      }
      {renderAwardContractPop()}
      {modalIsOpenPrice && (
        <PriceToCollectionInfluencer
          closePriceModal={closePriceModal}
          collectionId={props.collectionId}
          influencerId={influencerId}
          setCollectionInfluencerList={setCollectionInfluencerList}
          priceType={priceType}
          priceValue={priceValue}
        />
      )}
      {modalIsOpenNote && (
        <NoteListInfluencer
          closeNoteModal={closeNoteModal}
          collectionId={props.collectionId}
          influencerId={influencerId}
          setCollectionInfluencerList={setCollectionInfluencerList}
          noteType={noteType}
          userNote={addNote}
        />
      )}
    </div>
  );
}

export default CollectionCampaignInfluencerTable;
