import React, { useEffect, useState } from "react";
import { CustomText, CustomTextInput, PrimaryCta } from "../components";
import Utils from "../common/utils";
import Core from "../common/clientSdk";
import { useSnackbar } from "react-simple-snackbar";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../redux/actions";

import { ToastContainer, toast } from "react-toastify";
import Modal from "react-modal";
import { Loader } from "../screens/loader";
import Icon from "../components/core_component/icon";

function UpdateGSTModal({ closeModal, setVerifyOtpModalOpen }) {
  const [isLoading, setLoading] = useState(false);

  const [gst, setGst] = useState("");

  const [addressData, setAddressData] = useState({});

  const [error, set_error] = useState({
    gst: "",
  });

  const navigate = useNavigate();
  const redux_dispatch = useDispatch();
  var redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const { BrandUser = {} } = redux_selector;
  const { user_data = {} } = BrandUser;

  const { brand_deep_data = {} } = BrandUser;
  const { brand_data = {} } = brand_deep_data;
  const { org_deep_data = {} } = BrandUser;
  const openSnackbar = useSnackbar();
  function updateProgress(status) {
    setLoading(status);
  }

  useEffect(() => {
    // getOrgDataUser();
  }, []);

  useEffect(() => {
    setGst(org_deep_data?.org_data?.gst_number || "");
  }, []);

  function postUpdateUserOrg(progress_cb) {
    const params = {
      org_id: brand_data.org_id,

      gst_number: gst,
    };
    if (validateGSTIN()) {
      Core.postUpdateUserOrg(
        postUpdateUserOrgSuccess,
        postUpdateUserOrgFailed,
        progress_cb,
        params
      );
    }
  }

  function postUpdateUserOrgSuccess(response_data) {
    toast.success("Company details updated");
    getOrgDataUser();

    setLoading(false);
    closeModal();
  }

  function postUpdateUserOrgFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  function getOrgDataUser() {
    const params = { org_id: brand_data.org_id };
    Core.getOrgDataUser(
      getOrgDataUserSuccess,
      getOrgDataUserFailed,
      updateProgress,
      params
    );
  }

  function getOrgDataUserSuccess(response_data) {
    setAddressData(response_data.org_data.address_data);
    redux_dispatch({
      type: Actions.UPDATE_ORG_DATA,
      payload: {
        org_deep_data: response_data.org_data,
      },
    });
    setLoading(false);
  }

  function getOrgDataUserFailed(errorList) {
    toast.info(errorList.map((item) => item.m).join(", "));
  }

  function postVerifyEmailOrg() {
    const params = {};

    Core.postVerifyEmailOrg(
      postVerifyEmailOrgSuccess,
      postVerifyEmailOrgFailed,
      () => {},
      params
    );
    setVerifyOtpModalOpen(true);
    closeModal();
  }

  function postVerifyEmailOrgSuccess(response_data) {
    setLoading(true);
    toast.success("We sent an OTP on your Email, check inbox");
  }

  function postVerifyEmailOrgFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  function validateGSTIN() {
    if (gst.length <= 0) {
      set_error({ ...error, gst: "Please enter a valid GST Number" });
      return false;
    } else if (gst.length <= 0 || !Utils.validateGst(gst)) {
      set_error({ ...error, gst: "Please enter a valid GSTIN" });
      return false;
    } else {
      set_error({ ...error, gst: "" });
      return true;
    }

    return true;
  }

  return (
    <Modal isOpen={true} onRequestClose={closeModal} style={customStyles1}>
      <div className="header-modal-container justify-between">
        <CustomText
          h5
          className="typographyH7"
          text={`GST Update`}
          textColor={"text_grey_400_color"}
          fontWeight={"Poppins_regular_large"}
          height={"line_height_24"}
          textAlign="text_align_left"
        />
        <div onClick={closeModal} className="close-icon-campaign-success">
          <Icon iconName="CloseRoundButtonIcon" />
        </div>
      </div>

      <div>
        <div
          style={{
            flexDirection: "column",
            display: "flex",
            marginTop: 54,
          }}
        >
          <div style={{ height: "100px" }}>
            <div className="m-b-4">
              <CustomText
                h5
                className="typography14"
                text={`GST Number`}
                textColor={"text_grey_400_color"}
                fontWeight={"Poppins_regular_large"}
                height={"line_height_19_2"}
                textAlign="text_align_left"
              />
            </div>
            <CustomTextInput
              newWidth
              className="update_input_text"
              type="name"
              placeholder="GST Number"
              value={gst}
              onChange={(e) => setGst(e.target.value)}
            />
            <h5 className="error">{error.gst}</h5>
          </div>
          <div className="m-t-20">
            <PrimaryCta
              fontSize={"font_size_large"}
              fullWidth100
              isLoading={isLoading}
              text={"Save"}
              onClick={() => postUpdateUserOrg(updateProgress)}
              disabled={isLoading}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default UpdateGSTModal;

const customStyles1 = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "var(--general-secondary-color)",
    borderColor: "var( --blue-blue-500)",
    textAlign: "center",
    zIndex: "5",
    // height: "80%",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "space-around",
    width: "370px",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.86)",
    zIndex: 10,
  },
};
